import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '@progress/kendo-angular-dialog';
import {
  ListSupplyLocationQuantityHistorysCommand,
  ListSupplyLocationQuantityHistorysCommandResult,
  OrganizationService,
  SupplyLocationQuantityHistoryService,
  SupplyLocationQuantityService
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { CachedDataService, ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-supply-history',
  templateUrl: './supply-history.component.html',
  styleUrls: ['./supply-history.component.scss']
})
export class SupplyHistoryComponent extends EntityBaseComponent implements OnInit {
  historyList: Observable<ListSupplyLocationQuantityHistorysCommandResult>;

  constructor(
    private supplyHistoryService: SupplyLocationQuantityHistoryService,
    private logger: NGXLogger,
    public cachedDataService: CachedDataService,
    private impersonationService: ImpersonationService,
    private organizationService: OrganizationService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService,
    private SupplyLocationQuantityService: SupplyLocationQuantityService,
    private _breadcrumbService: BreadcrumbsService,
    private _title: Title
  ) {
    super(EntityGlobals.SUPPLIES, router, route, impersonationService, logger, _breadcrumbService, _title);
    this.editMode = false;
  }

  ngOnInit(): void {
    this.logger.debug('Getting History for item ' + this.id);
    let cmd: ListSupplyLocationQuantityHistorysCommand = {};
    cmd.supplyLocationQuantityId = this.id;
    this.historyList = this.supplyHistoryService.list(cmd);
  }
}
