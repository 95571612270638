
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {
  ChecklistService,
  CreateChecklistCommand,
  CreateChecklistCommandResult,
  ListRoomsCommand,
  OrganizationService,
  ReadChecklistCommandResult,
  RoomService,
  UpdateChecklistCommand,
  UpdateChecklistCommandResult
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { CachedDataService, ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
@Component({
  selector: 'app-checklist-detail',
  templateUrl: './checklist-detail.component.html',
  styleUrls: ['./checklist-detail.component.scss']
})
export class ChecklistDetailComponent extends EntityBaseComponent implements OnInit {
  override showForm: boolean;
  dataModel: ReadChecklistCommandResult;

  readChecklistCommandResult: ReadChecklistCommandResult = {};
  updateChecklistCommand: UpdateChecklistCommand = {};
  createChecklistCommand: CreateChecklistCommand = {};
  listRoomsCommand: ListRoomsCommand = {};

  form: FormGroup;
  originalFormState: FormGroup;
  fromKey: string;
  selectedOrganizationId: string;
  checkedRooms: any[] = [];

  constructor(
    public cachedDataService: CachedDataService,
    private logger: NGXLogger,
    private impersonationService: ImpersonationService,
    private organizationService: OrganizationService,
    private checklistService: ChecklistService,
    private roomService: RoomService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService
  ) {
    super(EntityGlobals.CHECKLISTS, router, route, impersonationService, logger);
    this.createForm();
    this.OrganizationId.subscribe((value: number) => {
      this.showForm = false;
      this.fromKey = 'FormModel';
      if (this.editMode) {
        this.getData();
      } else {
        this.showForm = true;
      }
    });
  }

  convertServerDataToFormModel(result: ReadChecklistCommandResult) {
    this.logger.debug(result);
    this.dataModel = result;
    this.form.patchValue(this.dataModel);
    this.logger.debug(this.originalFormState.pristine, this.originalFormState);
    if (this.originalFormState.pristine) {
      this.logger.debug('Setting Original Form State Value');
      this.originalFormState.patchValue(this.dataModel);
    }
  }

  getData() {
    this.checklistService.read(this.id).subscribe((result: ReadChecklistCommandResult) => {
      this.convertServerDataToFormModel(result);
      this.showForm = true;
      if (result && result.rooms) {
        this.form.controls['rooms'].setValue(result.rooms);
        this.checkedRooms = result.rooms;
      }
    });
  }

  ngOnInit() {}

  resetForm() {
    this.logger.debug(this.originalFormState);
    this.form.reset(this.originalFormState.value);
    this.routeToEntityList();
  }

  createForm() {
    this.form = this.fb.group({
      id: [''],
      organizationId: [''],
      organizationName: [''],
      name: ['', [Validators.required, Validators.minLength(5)]],
      description: [''],
      checklistType: [''],
      rooms: [''],
      created: [''],
      createByUsername: [''],
      createdByUserId: [''],
      lastUpdated: [''],
      lastUpdatedByUsername: [''],
      lastUpdatedByUserId: ['']
    });
    this.originalFormState = this.form;
  }

  toggleRoom(option) {
    const foundBook = this.checkedRooms.filter(room => room.id === option.id);
    if (foundBook.length === 0) {
      this.checkedRooms.push(option);
    } else {
      const indexOfItem = this.checkedRooms.indexOf(option);
      this.checkedRooms.splice(indexOfItem, 1);
    }
  }

  roomIsChecked(option) {
    const selectedIds = this.checkedRooms.map(({ id }) => id);
    const indexOfItem = selectedIds.indexOf(option.id);
    return indexOfItem > -1;
  }

  onSubmit(closeOnSuccess) {
    this.form.controls['rooms'].setValue(this.checkedRooms);

    // If the Form is in Edit Mode, Update Object
    if (this.editMode) {
      this.updateChecklistCommand = this.form.value;
      this.logger.debug(this.updateChecklistCommand);
      this.checklistService
        .update(this.updateChecklistCommand.id.toString(), this.updateChecklistCommand)
        .subscribe((result: UpdateChecklistCommandResult) => {
          this.logger.debug(result);
          if (closeOnSuccess) {
            this.routeToEntityList();
          } else {
            this.convertServerDataToFormModel(result);
            this.toastService.success('Success!', 'This item has been saved.');
          }
        });
    } else {
      // Otherwise, create a new object
      this.createChecklistCommand = this.form.value;
      this.logger.debug(this.createChecklistCommand);
      this.checklistService.create(this.createChecklistCommand).subscribe((result: CreateChecklistCommandResult) => {
        this.convertServerDataToFormModel(result);
        this.routeToEntityList();
        this.editMode = true;
        this.toastService.success('Success!', 'This item has been saved.');
      });
    }
  }
  submitAndClose() {
    this.onSubmit(true);
  }
  disable() {}
  delete() {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this checklist?',
      actions: [{ text: 'No' }, { text: 'Yes', primary: true }],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
      }
      this.toastService.success('Oops.', 'This item has been saved.');
    });
  }
}
