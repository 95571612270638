import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthResult, NbAuthService } from '@nebular/auth';
import { ConfigLoaderService } from '@wo-app/core/services';
import { NGXLogger } from 'ngx-logger';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-oauth2-logout',
  templateUrl: './oauth2-logout.component.html',
  styleUrls: ['./oauth2-logout.component.scss']
})
export class OAuth2LogoutComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    private logger: NGXLogger,
    private configLoader: ConfigLoaderService,
    public router: Router,
    private nbAuthService: NbAuthService
  ) {
    this.configLoader.Config.subscribe(config => {
      this.nbAuthService.logout(config.authServiceStrategyName).subscribe(() => {
        this.logger.debug('Logging out...');
        this.nbAuthService
          .authenticate(config.authServiceStrategyName)
          .pipe(takeUntil(this.destroy$))
          .subscribe((authResult: NbAuthResult) => {});
      });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {}
}
