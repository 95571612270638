<form [formGroup]="form">
    <div class="row">
        <div class="col-sm-12 col-md-12">
            <kendo-dropdownlist class="form-control" [data]="manikinMakesAndModels" [textField]="'manufacturerName'" (valueChange)="manikinManufacturerChange($event)"></kendo-dropdownlist>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 col-md-6">
            <h3>Manikins</h3>
            <ul class="manikin-list">
                <li *ngFor="let item of visibleModels; let i = index" [hidden]="shouldItemBeHidden(item)">
                    <span (click)="addToList(item)">
                        {{item.name}} <i class="fa-light fa-arrow-circle-right"></i>
                    </span>
                </li>
            </ul>
        </div>
        <div class="col-sn-12 col-md-6">
            <h3>Currently Selected</h3>
            <ul class="manikin-list">
                <li *ngFor="let item of selectedItems; let i = index">
                    <span (click)="removeFromList(item)">
                        <i class="fa-light fa-arrow-circle-left"></i> {{ getLabelForItem(item) }}
                    </span>
                </li>
            </ul>
        </div>
    </div>
</form>