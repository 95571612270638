import { BuildingService } from '@wo-api/index';
import { EntityGlobals, MenuGroupType } from '@wo-app/app.global';
import { BuildingDetailComponent } from '@wo-app/components/dashboard/buildings/building-detail/building-detail.component';
import { BuildingInventoryDetailComponent } from '@wo-app/components/dashboard/buildings/building-inventory-detail/building-inventory-detail.component';
import { BuildingInventoryListComponent } from '@wo-app/components/dashboard/buildings/building-inventory-list/building-inventory-list.component';
import { BuildingsComponent } from '@wo-app/components/dashboard/buildings/buildings.component';
import { ImpersonatingOrganizationCanActivate } from '@wo-app/core/guards';
import { MenuGroupListingItem } from '@wo-app/shared/models/menu';
import { RouteChild, RouteParent } from '@wo-app/shared/models/route';

export class BuildingsRegistration {
  static Routes: RouteParent = {
    path: EntityGlobals.BUILDINGS.baseRoute,
    canActivate: [ImpersonatingOrganizationCanActivate],
    entityInformation: EntityGlobals.BUILDINGS,
    children: [
      {
        path: '',
        component: BuildingsComponent,
        data: {
          breadcrumb: 'View All ' + EntityGlobals.BUILDINGS.pluralName,
          menuGroups: [new MenuGroupListingItem(0, 1, MenuGroupType.PhysicalSpaces, EntityGlobals.BUILDINGS.pluralName)],
          entityInformation: EntityGlobals.BUILDINGS
        }
      } as RouteChild,
      {
        path: 'view-inventory/:id',
        component: BuildingInventoryListComponent,
        data: {
          breadcrumb: 'View Inventory for ' + EntityGlobals.BUILDINGS.singleName,
          entityInformation: EntityGlobals.BUILDINGS
        }
      } as RouteChild,
      {
        path: 'edit/:id',
        component: BuildingDetailComponent,
        data: {
          breadcrumb: 'Edit ' + EntityGlobals.BUILDINGS.singleName,
          entityInformation: EntityGlobals.BUILDINGS
        }
      } as RouteChild,
      {
        path: 'add',
        component: BuildingDetailComponent,
        data: {
          breadcrumb: 'Add ' + EntityGlobals.BUILDINGS.singleName,
          entityInformation: EntityGlobals.BUILDINGS
        }
      } as RouteChild
    ]
  };

  static Services: any = [BuildingService];

  static Components: any = [BuildingsComponent, BuildingDetailComponent, BuildingInventoryDetailComponent, BuildingInventoryListComponent];
}
