import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { State, orderBy } from '@progress/kendo-data-query';
import { CourseService, ListCourseViewModel, ListCoursesCommand, ListCoursesCommandResult } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent extends EntityBaseComponent implements OnInit {
  constructor(
    private loadingBar: LoadingBarService,
    private logger: NGXLogger,
    private impersonationService: ImpersonationService,
    private courseService: CourseService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super(EntityGlobals.COURSES, router, route, impersonationService, logger);
    this.loadingBar.useRef('router').start();
    this.listCoursesCommand.skip = this.state.skip;
    this.listCoursesCommand.take = this.state.take;
    this.listCoursesCommand.sort = this.state.sort;
    this.courseService.list(this.listCoursesCommand).subscribe((result: ListCoursesCommandResult) => {
      this.loadingBar.useRef('router').complete();
      if (result && result.data) {
        this.gridData = result.data;
        this.query();
      }
    });
  }

  gridData: ListCourseViewModel[];

  listCoursesCommand: ListCoursesCommand = {};
  public view: GridDataResult;
  public override state: State = {
    skip: 0,
    take: 100,
    sort: [{ dir: 'desc', field: 'lastUpdated' }],
    filter: {
      logic: 'and',
      filters: []
    }
  };

  ngOnInit() {}

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.query();
  }

  public query() {
    const sortedGridData = orderBy(this.gridData, this.state.sort ?? [{ dir: 'desc', field: 'lastUpdated' }]);
    this.view = {
      data: sortedGridData.slice(this.state.skip, this.state?.skip ?? 0 + this.state.take),
      total: sortedGridData.length
    };
  }
}
