<div class="container-fluid">
    <div class="row">
        <div class="col-sm-6 offset-sm-3">
            <img [src]="logoUrl" alt="Logo" style="margin-top:30px;" width="300px">
            <h1>Page Not Found</h1>
            <p>You may have mistyped the address or the page may have been moved.</p>
            <a href="javascript:void(0)" (click)="backClicked()">
                <i class="fa-light fa-backward"></i> Back
            </a>
            OR
            <a [routerLink]="['/']"><i class="fa-light fa-home"></i> Main Dashboard</a>
        </div>
    </div>
</div>