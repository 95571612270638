<div class="card" *ngFor="let item of data" [hidden]="hideElements">
    <div class="card-header">
        {{item.labRequestName}} starting on {{item.startDate | amLocal | amDateFormat: 'MM/DD/YYYY'}} at {{item.startDate | amLocal | amDateFormat: 'h:mm:ss a'}}
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-4">
                <h5 class="card-title">Dates and Times</h5>
                <p class="card-text">
                    Name: {{item.requestorName}}<br/> Phone: {{item.requestorPhone}}<br/> Email: <a href="mailto:{{item.requestorEmail}}">{{item.requestorEmail}}</a>
                </p>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4">
                <h5 class="card-title">Requestor Information</h5>
                <p class="card-text">
                    Name: {{item.requestorName}}<br/> Phone: {{item.requestorPhone}}<br/> Email: <a href="mailto:{{item.requestorEmail}}">{{item.requestorEmail}}</a>
                </p>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4">
                <h5 class="card-title">Rooms</h5>
            </div>
        </div>
    </div>
</div>