import { Injectable, TemplateRef, ViewContainerRef } from '@angular/core';
import { ApiResponse } from '@wo-api/index';
import { NGXLogger } from 'ngx-logger';

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: Toast[] = [];
  rootViewContainer: ViewContainerRef;

  constructor(private logger: NGXLogger) {}
  public DefaultToastOptions: ToastOptions = {
    ClassNames: 'bg-purple text-white',
    Autohide: true,
    DelayInMilliseconds: 8000
  };

  private show(templateRef: TemplateRef<any> | null, title: string, content: string, options: ToastOptions, timestamp: Date = new Date()) {
    let toast: Toast = {
      TemplateRef: templateRef,
      Title: title,
      Content: content,
      Options: options,
      Timestamp: timestamp
    };
    this.toasts.push(toast);
  }

  public remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  public clear() {
    this.toasts.splice(0, this.toasts.length);
  }

  public success(title: string, message: string, timestamp: Date = new Date()): void {
    var options: ToastOptions = { ...this.DefaultToastOptions };
    options.ClassNames = 'bg-success text-light';
    this.show(null, title, message, options, timestamp);
  }

  public information(title: string, message: string, timestamp: Date = new Date()): void {
    var options: ToastOptions = { ...this.DefaultToastOptions };
    options.ClassNames = 'bg-info text-light';
    this.show(null, title, message, options, timestamp);
  }

  public warning(title: string, message: string, timestamp: Date = new Date()): void {
    var options: ToastOptions = { ...this.DefaultToastOptions };
    options.ClassNames = 'bg-warning text-dark';
    options.Autohide = false;
    this.show(null, title, message, options, timestamp);
  }

  public error(title: string, message: string, timestamp: Date = new Date()): void {
    var options: ToastOptions = { ...this.DefaultToastOptions };
    options.ClassNames = 'bg-danger text-light';
    options.Autohide = false;
    this.show(null, title, message, options, timestamp);
  }

  showApplicationError(apiResponse: ApiResponse, timestamp: Date = new Date()) {
    let messages = apiResponse.messages;
    let title = apiResponse.errorLabel;
    let messagesStr = '';
    messages.forEach((item: any) => {
      messagesStr += `<li>${item}</li>`;
    });
    messagesStr = `<ul>${messagesStr}</ul>`;

    var options: ToastOptions = { ...this.DefaultToastOptions };
    options.Autohide = false;
    options.ClassNames = 'bg-warning text-dark';
    this.show(null, title, 'There are errors with some of the information you submitted.<br/>' + messagesStr, options, timestamp);
  }
}

export class Toast {
  public TemplateRef: TemplateRef<any> | null;
  public Title: string;
  public Content: string;
  public Options: ToastOptions;
  public Timestamp: Date = new Date();
}
export class ToastOptions {
  public Autohide: boolean;
  public ClassNames: string;
  public DelayInMilliseconds: number | null;
}
