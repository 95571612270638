/**
 * Wingman Operations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EquipmentStatus = 'Active' | 'Inactive' | 'In-Repair' | 'Retired';

export const EquipmentStatus = {
    Active: 'Active' as EquipmentStatus,
    Inactive: 'Inactive' as EquipmentStatus,
    InRepair: 'In-Repair' as EquipmentStatus,
    Retired: 'Retired' as EquipmentStatus
};

