<!-- Account Menu -->
<div id="account-menu" class="dropdown text-end d-md-block {{ additionalClasses }}">
  <a
    href="#"
    class="d-flex align-items-center text-decoration-none dropdown-toggle"
    [class.text-white]="darkMode"
    data-bs-toggle="dropdown"
    aria-expanded="false">
    <i class="fa-user-circle" [class.fa-light]="darkMode"></i>
    <strong class="me-2">{{ IdToken?.username }}</strong>
  </a>
  <ul class="dropdown-menu dropdown-menu-dark text-small shadow">
    <li>
      <a class="dropdown-item" routerLink="/my-profile">
        <span><i class="fa-user me-3" [class.fa-light]="darkMode"></i></span>
        <span>Profile & Password</span>
      </a>
    </li>
    <li><hr class="dropdown-divider" /></li>
    <li>
      <a class="dropdown-item" routerLink="/oauth/logout">
        <span><i class="fa-sign-out me-3" [class.fa-light]="darkMode"></i></span>
        <span>Sign out</span>
      </a>
    </li>
  </ul>
</div>
