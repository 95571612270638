import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {
  CommunicationBoardActionItemService,
  CreateCommunicationBoardActionItemsCommand,
  CreateCommunicationBoardActionItemsCommandResult,
  OrganizationService,
  ReadCommunicationBoardActionItemsCommandResult,
  UpdateCommunicationBoardActionItemsCommand,
  UpdateCommunicationBoardActionItemsCommandResult
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-communication-board-action-item-detail',
  templateUrl: './communication-board-action-item-detail.component.html',
  styleUrls: ['./communication-board-action-item-detail.component.scss']
})
export class CommunicationBoardActionItemDetailComponent extends EntityBaseComponent implements OnInit {
  override showForm: boolean;
  readCommunicationBoardActionItemCommandResult: ReadCommunicationBoardActionItemsCommandResult = {};
  dataModel: ReadCommunicationBoardActionItemsCommandResult;
  updateCommunicationBoardActionItemCommand: UpdateCommunicationBoardActionItemsCommand = {};
  createCommunicationBoardActionItemCommand: CreateCommunicationBoardActionItemsCommand = {};
  form: FormGroup;
  originalFormState: FormGroup;
  fromKey: string;
  organizations: any[];
  selectedOrganizationId: string;

  constructor(
    private logger: NGXLogger,
    private impersonationService: ImpersonationService,
    private organizationService: OrganizationService,
    private communicationBoardActionItemService: CommunicationBoardActionItemService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService
  ) {
    super(EntityGlobals.COMMUNICATION_BOARD_ACTION_ITEMS, router, route, impersonationService, logger);
    this.createForm();
    this.showForm = false;
    this.fromKey = 'FormModel';
    if (this.editMode) {
      this.getData();
    } else {
      this.showForm = true;
    }
  }

  convertServerDataToFormModel(result: ReadCommunicationBoardActionItemsCommandResult) {
    this.dataModel = result;
    this.logger.debug(this.dataModel);
    this.form.patchValue(this.dataModel);
    this.logger.debug(this.originalFormState.pristine, this.originalFormState);
    if (this.originalFormState.pristine) {
      this.logger.debug('Setting Original Form State Value');
      this.originalFormState.patchValue(this.dataModel);
    }
  }

  getData() {
    this.communicationBoardActionItemService.read(this.id).subscribe((result: ReadCommunicationBoardActionItemsCommandResult) => {
      this.convertServerDataToFormModel(result);
      this.showForm = true;
    });
  }

  ngOnInit() {}

  resetForm() {
    this.logger.debug(this.originalFormState);
    this.form.reset(this.originalFormState.value);
    this.routeToEntityList();
  }

  createForm() {
    this.form = this.fb.group({
      id: [''],
      organizationId: [''],
      organizationName: [''],
      name: ['', [Validators.required, Validators.minLength(5)]],
      description: [''],
      created: [''],
      createByUsername: [''],
      createdByUserId: [''],
      lastUpdated: [''],
      lastUpdatedByUsername: [''],
      lastUpdatedByUserId: ['']
    });
    this.originalFormState = this.form;
  }

  onSubmit(closeOnSuccess) {
    // If the Form is in Edit Mode, Update Object
    if (this.editMode) {
      this.updateCommunicationBoardActionItemCommand = this.form.value;
      this.logger.debug(this.updateCommunicationBoardActionItemCommand);
      this.communicationBoardActionItemService
        .update(this.updateCommunicationBoardActionItemCommand.id.toString(), this.updateCommunicationBoardActionItemCommand)
        .subscribe((result: UpdateCommunicationBoardActionItemsCommandResult) => {
          this.logger.debug(result);
          if (closeOnSuccess) {
            this.routeToEntityList();
          } else {
            this.convertServerDataToFormModel(result);
            this.toastService.success('Success!', 'This item has been saved.');
          }
        });
    } else {
      // Otherwise, create a new object
      this.createCommunicationBoardActionItemCommand = this.form.value;
      this.logger.debug(this.createCommunicationBoardActionItemCommand);
      this.communicationBoardActionItemService
        .create(this.createCommunicationBoardActionItemCommand)
        .subscribe((result: CreateCommunicationBoardActionItemsCommandResult) => {
          this.convertServerDataToFormModel(result);
          this.routeToEntityList();
          this.editMode = true;
          this.toastService.success('Success!', 'This item has been saved.');
        });
    }
  }
  submitAndClose() {
    this.onSubmit(true);
  }
  disable() {}
  delete() {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this communicationBoardActionItem?',
      actions: [{ text: 'No' }, { text: 'Yes', primary: true }],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
      }
      this.toastService.success('Oops.', 'This item has been saved.');
    });
  }
}
