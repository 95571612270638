import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { IntlService } from '@progress/kendo-angular-intl';
import {
  CreateManikinInventoryItemProcedureCommand,
  CreateManikinInventoryItemProcedureCommandResult,
  EnumValue,
  ManikinInventoryItemProcedureService,
  ManikinMakeAndModelProcedureService,
  ManikinMakeAndModelService,
  OrganizationService,
  ReadManikinInventoryItemProcedureCommandResult,
  UpdateManikinInventoryItemProcedureCommand,
  UpdateManikinInventoryItemProcedureCommandResult
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { CachedDataService, ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class ManikinInventoryItemProcedureDetailComponent extends EntityBaseComponent implements OnInit {
  override showForm = false;
  readManikinInventoryItemCommandResult: ReadManikinInventoryItemProcedureCommandResult = {};
  dataModel: ReadManikinInventoryItemProcedureCommandResult;
  updateManikinInventoryItemProcedureCommand: UpdateManikinInventoryItemProcedureCommand = {};
  createManikinInventoryItemProcedureCommand: CreateManikinInventoryItemProcedureCommand = {};
  form: FormGroup;
  originalFormState: FormGroup;
  fromKey: string;
  frequencyTypes: EnumValue[] = [];

  constructor(
    private logger: NGXLogger,
    public cachedDataService: CachedDataService,
    private manikinMakeAndModelProcedureService: ManikinMakeAndModelProcedureService,
    private manikinMakeAndModelService: ManikinMakeAndModelService,
    private intl: IntlService,
    private impersonationService: ImpersonationService,
    private organizationService: OrganizationService,
    private manikinInventoryItemProcedureService: ManikinInventoryItemProcedureService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService
  ) {
    super(EntityGlobals.MANIKIN_INVENTORY_PROCEDURES, router, route, impersonationService, logger);
    this.createForm();
    this.OrganizationId.subscribe(() => {
      this.fromKey = 'FormModel';
      this.cachedDataService.manikinMakeAndModelProcedureFrequencies.subscribe((result: EnumValue[]) => {
        this.frequencyTypes = result;
        if (this.editMode) {
          this.getData();
        } else {
          this.showForm = true;
        }
      });
    });
  }

  convertServerDataToFormModel(result: ReadManikinInventoryItemProcedureCommandResult) {
    this.dataModel = result;

    this.logger.debug(this.dataModel);
    this.form.patchValue(this.dataModel);
    this.logger.debug(this.originalFormState.pristine, this.originalFormState);
    if (this.originalFormState.pristine) {
      this.logger.debug('Setting Original Form State Value');
      this.originalFormState.patchValue(this.dataModel);
    }
  }

  getData() {
    this.manikinInventoryItemProcedureService.read(this.id).subscribe((result: ReadManikinInventoryItemProcedureCommandResult) => {
      this.convertServerDataToFormModel(result);
      this.showForm = true;
    });
  }

  ngOnInit() {}

  resetForm() {
    this.logger.debug(this.originalFormState);
    this.form.reset(this.originalFormState.value);
    this.routeToEntityList();
  }

  createForm() {
    this.form = this.fb.group({
      id: [''],
      name: [''],
      instructions: [''],
      manikinProcedureFrequency: [''],
      onlyWhenInUse: [''],
      manikinsAssignedThisProcedure: [''],
      organizationId: [''],
      created: [''],
      createByUsername: [''],
      createdByUserId: [''],
      lastUpdated: [''],
      lastUpdatedByUsername: [''],
      lastUpdatedByUserId: ['']
    });
    this.originalFormState = this.form;
  }

  onSubmit(closeOnSuccess) {
    // If the Form is in Edit Mode, Update Object
    if (this.editMode) {
      this.updateManikinInventoryItemProcedureCommand = this.form.value;
      this.logger.debug(this.updateManikinInventoryItemProcedureCommand);
      this.manikinInventoryItemProcedureService
        .update(this.updateManikinInventoryItemProcedureCommand.id.toString(), this.updateManikinInventoryItemProcedureCommand)
        .subscribe((result: UpdateManikinInventoryItemProcedureCommandResult) => {
          this.logger.debug(result);
          if (closeOnSuccess) {
            this.routeToEntityList();
          } else {
            this.convertServerDataToFormModel(result);
            this.toastService.success('Success!', 'This item has been saved.');
          }
        });
    } else {
      // Otherwise, create a new object
      this.createManikinInventoryItemProcedureCommand = this.form.value;
      this.manikinInventoryItemProcedureService
        .create(this.createManikinInventoryItemProcedureCommand)
        .subscribe((result: CreateManikinInventoryItemProcedureCommandResult) => {
          this.convertServerDataToFormModel(result);
          this.routeToEntityList();
          this.editMode = true;
          this.toastService.success('Success!', 'This item has been saved.');
        });
    }
  }
  submitAndClose() {
    this.onSubmit(true);
  }
  disable() {}
  delete() {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this manikinInventoryItem?',
      actions: [{ text: 'No' }, { text: 'Yes', primary: true }],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
      }
      this.toastService.success('Oops.', 'This item has been saved.');
    });
  }
}
