import { SupplyLocationQuantityHistoryService, SupplyService } from '@wo-api/index';
import { EntityGlobals, MenuGroupType } from '@wo-app/app.global';
import { InventoryItemDetailComponent } from '@wo-app/components/dashboard/inventoryitems/inventory-item-detail/inventory-item-detail.component';
import { SupplyHistoryComponent } from '@wo-app/components/dashboard/inventoryitems/supply-history/supply-history.component';
import { ImpersonatingOrganizationCanActivate } from '@wo-app/core/guards';
import { MenuGroupListingItem } from '@wo-app/shared/models/menu';
import { RouteChild, RouteParent } from '@wo-app/shared/models/route';
import { InventoryChangeTrackerComponent } from '../components/dashboard/inventoryitems/inventory-change-tracker/inventory-change-tracker.component';
import { InventoryItemListBuilderRowComponent } from '../components/dashboard/inventoryitems/inventory-item-list-builder-row/inventory-item-list-builder-row.component';
import { InventoryItemListBuilderComponent } from '../components/dashboard/inventoryitems/inventory-item-list-builder/inventory-item-list-builder.component';
import { InventoryRoomQrLandingComponent } from '../components/dashboard/inventoryitems/inventory-room-qr-landing/inventory-room-qr-landing.component';
import { InventoryItemsComponent } from '../components/dashboard/inventoryitems/inventoryitems/inventoryitems.component';
import { RoomInventoryItemStockListComponent } from '../components/dashboard/inventoryitems/room-inventory-item-stock-list/room-inventory-item-stock-list.component';
import { SuppliesInventoryLocationListComponent } from '../components/dashboard/inventoryitems/supplies-inventory-location-list/supplies-inventory-location-list.component';
import { QrLandingCanActivate } from '@wo-app/components/dashboard/inventoryitems/shared';
import { InventoryRoomQrNotFoundComponent } from '@wo-app/components/dashboard/inventoryitems/inventory-room-qr-not-found/inventory-room-qr-not-found.component';

export class SuppliesRegistration {
  static Routes: RouteParent = {
    path: EntityGlobals.SUPPLIES.baseRoute,
    canActivate: [ImpersonatingOrganizationCanActivate],
    entityInformation: EntityGlobals.SUPPLIES,
    children: [
      {
        path: '',
        component: SuppliesInventoryLocationListComponent,
        data: {
          breadcrumb: 'View All ' + EntityGlobals.SUPPLIES.pluralName,
          menuGroups: [new MenuGroupListingItem(0, 0, MenuGroupType.InventoryAndEquipment, EntityGlobals.SUPPLIES.pluralName)],
          entityInformation: EntityGlobals.SUPPLIES
        }
      } as RouteChild,
      {
        path: 'edit-list',
        component: InventoryItemsComponent,
        data: {
          breadcrumb: 'Manage ' + EntityGlobals.SUPPLIES.pluralName,
          //menuGroups: [new MenuGroupListingItem(1, 0, MenuGroupType.InventoryAndEquipment, 'Edit')],
          entityInformation: EntityGlobals.SUPPLIES
        }
      } as RouteChild,
      {
        path: 'room-inventory-item-stock/:id',
        component: RoomInventoryItemStockListComponent,
        data: {
          breadcrumb: 'Edit Location and Stocks for ' + EntityGlobals.SUPPLIES.singleName,
          entityInformation: EntityGlobals.SUPPLIES
        }
      } as RouteChild,
      {
        path: 'room-inventory-item-stock/:id/history',
        component: SupplyHistoryComponent,
        data: {
          breadcrumb: 'View History for ' + EntityGlobals.SUPPLIES.singleName,
          entityInformation: EntityGlobals.SUPPLIES
        }
      } as RouteChild,
      {
        path: 'edit/:id',
        component: InventoryItemDetailComponent,
        data: {
          breadcrumb: 'Edit ' + EntityGlobals.SUPPLIES.singleName,
          entityInformation: EntityGlobals.SUPPLIES
        }
      } as RouteChild,
      {
        path: 'add',
        component: InventoryItemDetailComponent,
        data: {
          breadcrumb: 'Add ' + EntityGlobals.SUPPLIES.singleName,
          entityInformation: EntityGlobals.SUPPLIES
        }
      } as RouteChild,
      {
        path: 'create-change-request/:labRequestId',
        component: InventoryChangeTrackerComponent,
        data: {
          breadcrumb: 'Create Inventory Tracking Item',
          entityInformation: EntityGlobals.SUPPLIES
        }
      } as RouteChild,
      {
        path: 'qr/supply-location-quantity/:supplyLocationQuantityId',
        canActivate: [QrLandingCanActivate],
        component: InventoryRoomQrLandingComponent,
        data: {
          breadcrumb: 'Edit Supply Room Quantities',
          entityInformation: EntityGlobals.SUPPLIES
        }
      } as RouteChild,
      {
        path: 'qr/not-found',
        component: InventoryRoomQrNotFoundComponent
      } as RouteChild
    ]
  };

  static Services: any = [SupplyService, SupplyLocationQuantityHistoryService];

  static Components: any = [
    InventoryItemsComponent,
    InventoryItemDetailComponent,
    InventoryItemListBuilderComponent,
    InventoryItemListBuilderRowComponent,
    InventoryChangeTrackerComponent,
    InventoryRoomQrLandingComponent,
    InventoryRoomQrNotFoundComponent,
    RoomInventoryItemStockListComponent,
    SupplyHistoryComponent,
    SuppliesInventoryLocationListComponent
  ];
}
