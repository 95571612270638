import { LabRequestService } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { LabRequestDetailComponent } from '@wo-app/components/dashboard/lab-request/lab-request-detail';
import { LabRequestsListComponent } from '@wo-app/components/dashboard/lab-request/lab-request-list';
import { ImpersonatingOrganizationCanActivate } from '@wo-app/core/guards';
import { RouteChild, RouteParent } from '@wo-app/shared/models/route';

export class LabRequestsRegistration {
  static Routes: RouteParent = {
    path: EntityGlobals.LAB_REQUESTS.baseRoute,
    canActivate: [ImpersonatingOrganizationCanActivate],
    entityInformation: EntityGlobals.LAB_REQUESTS,
    children: [
      {
        path: '',
        component: LabRequestsListComponent,
        data: {
          breadcrumb: 'View All'
        }
      } as RouteChild,
      {
        path: 'edit/:id',
        component: LabRequestDetailComponent,
        data: {
          breadcrumb: 'Edit ' + EntityGlobals.LAB_REQUESTS.singleName
        }
      } as RouteChild,
      {
        path: 'add',
        component: LabRequestDetailComponent,
        data: {
          breadcrumb: 'Add ' + EntityGlobals.LAB_REQUESTS.singleName
        }
      } as RouteChild
    ]
  };

  static Services: any = [LabRequestService];

  static Components: any = [LabRequestsListComponent, LabRequestDetailComponent];
}
