import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-building-inventory-detail',
  templateUrl: './building-inventory-detail.component.html',
  styleUrls: ['./building-inventory-detail.component.scss']
})
export class BuildingInventoryDetailComponent implements OnInit  {
  @Input() public item: any;
  gridData: any[] = [];

  public ngOnInit(): void {
    this.gridData.push(this.item);
  }

}
