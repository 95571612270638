import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogCloseResult, DialogRef, DialogResult, DialogService } from '@progress/kendo-angular-dialog';
import { MultipleSortSettings } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { OrganizationService, SetUserAccountStatusCommand, UserService, UserViewModel } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent extends EntityBaseComponent implements OnInit {
  constructor(
    private toastService: ToastService,
    private loadingBar: LoadingBarService,
    private logger: NGXLogger,
    private userService: UserService,
    private impersonationService: ImpersonationService,
    private organizationService: OrganizationService,
    private dialogService: DialogService,
    route: ActivatedRoute,
    router: Router
  ) {
    super(EntityGlobals.USERS, router, route, impersonationService, logger);
    this.loadingBar.useRef('router').start();
    this.state.take = 60;
  }

  gridData: any[];

  public sort: SortDescriptor[] = [{ dir: 'asc', field: 'username' }];
  public sortSettings: MultipleSortSettings = {
    mode: 'multiple',
    initialDirection: 'desc',
    allowUnsort: true,
    showIndexes: true
  };

  public getUsers() {
    this.userService.list().subscribe((result: any) => {
      this.loadingBar.useRef('router').complete();
      this.logger.debug(result);
      this.gridData = result.data.map(item => ({
        ...item,
        createdDate: new Date(item.created),
        lastUpdatedDate: new Date(item.lastUpdated)
      }));
    });
  }

  ngOnInit() {
    this.getUsers();
  }

  toggleUserStatus(user: UserViewModel) {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this user? The user will immediately lose access to the application.',
      actions: [
        { text: 'No', actionResult: false },
        { text: 'Yes', primary: true, actionResult: true }
      ],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe((result: DialogResult) => {
      let doAction = result['actionResult'] as boolean;
      if (doAction) {
        let cmd: SetUserAccountStatusCommand = {
          username: user.username,
          enabled: !user.enabled
        };
        this.userService.setAccountStatus(cmd).subscribe((result: any) => {
          this.toastService.success('Success!', "This user's status has been updated.");
          this.getUsers();
        });
      }
    });
  }

  deleteUser(dataItem: any) {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this bed?',
      actions: [{ text: 'No' }, { text: 'Yes', primary: true }],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
      }
      this.toastService.success('Success!', 'This item has been saved.');
    });
  }
}
