<autopilot-grid>
  <kendo-grid
      kendoGridSelectBy="id"
      [kendoGridBinding]="gridData"
      [selectedKeys]="selectedIds"
      [selectable]="{ checkboxOnly: true }"
      [style.maxHeight.%]="100"
      [pageSize]="state.take"
      [filterable]="'menu'"
      [sortable]="sortSettings"
      [sort]="sort"
      [pageable]="true"
      (selectionChange)="onSelectionChange($event)">
      <ng-template kendoGridToolbarTemplate>
          <div class="container-fluid g-0 d-flex flex-wrap justify-content-center">
              <form class="col-auto me-auto" role="search">
                  <button kendoButton type="button" class="btn btn-secondary text-light" routerLink="/manikin-make-and-model/edit-list"><i class="fa-light fa-gears"></i> Manage {{this.GetPluralEntityName()}}</button>
                  <button
                    kendoButton
                    type="button"
                    class="btn btn-secondary text-light"
                    [disabled]="selectedIds.length == 0"
                    title="To print labels, select one or more from below."
                    (click)="onPrintLabels()">
                    <i class="fa-light fa-qrcode"></i> Print Labels
                </button>
              </form>
              <div class="text-end">
                      <button kendoGridPDFCommand class="btn btn-secondary text-white" title="Export as PDF"><i class="fa-light fa-file-pdf"></i></button>
                      <button kendoGridExcelCommand title="Export to Excel" class="btn btn-secondary text-white"><i class="fa-light fa-file-excel"></i></button>
              </div>
          </div>
      </ng-template>
      <kendo-grid-checkbox-column [width]="50" class="printer-checkbox-item">
        <ng-template kendoGridHeaderTemplate>
          <button class="btn btn-unstyled" title="Select/Deselect all rows for label printing" (click)="clickSelectAllCheckbox()">
            <i
              class="fa-light fa-print"
              [ngClass]="{ 'text-purple': selectAllState == 'checked', 'fw-bold': selectAllState == 'checked' }"></i>
            <input
              #selectAllCheckbox
              class="d-none k-checkbox k-checkbox-md k-rounded-md"
              type="checkbox"
              title="Select item(s) to print label(s)"
              kendoCheckbox
              kendoGridSelectAllCheckbox
              [state]="selectAllState"
              (selectAllChange)="onSelectAll($event)" />
          </button>
        </ng-template>
      </kendo-grid-checkbox-column>
    <kendo-grid-column field="wingmanId" title="Wingman ID">
        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
            <kendo-grid-string-filter-menu
                [column]="column"
                [filter]="filter"
                [filterService]="filterService"
                [extra]="false">
            </kendo-grid-string-filter-menu>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <a href="#" class="link" [routerLink]="'/manikin-make-and-model/' + dataItem.manikinMakeAndModelId + '/line-items/edit/' + dataItem.id"
            >{{ dataItem.wingmanId }} <i class="fa-light fa-arrow-up-right-from-square"></i
          ></a>
        </ng-template>
    </kendo-grid-column>
      <kendo-grid-column field="vendorName" title="Manufacturer">
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
              <kendo-grid-string-filter-menu
                  [column]="column"
                  [filter]="filter"
                  [filterService]="filterService"
                  [extra]="false">
              </kendo-grid-string-filter-menu>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="modelName" title="Model Name">
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
              <kendo-grid-string-filter-menu
                  [column]="column"
                  [filter]="filter"
                  [filterService]="filterService"
                  [extra]="false">
              </kendo-grid-string-filter-menu>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="modelNumber" title="Model Number">
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
              <kendo-grid-string-filter-menu
                  [column]="column"
                  [filter]="filter"
                  [filterService]="filterService"
                  [extra]="false">
              </kendo-grid-string-filter-menu>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <a href="#" class="link" [routerLink]="'/manikin-make-and-model/' + dataItem.manikinMakeAndModelId + '/line-items'"
              >{{ dataItem.modelNumber }} <i class="fa-light fa-arrow-up-right-from-square"></i
            ></a>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="serialNumber" title="Serial Number">
        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
            <kendo-grid-string-filter-menu
                [column]="column"
                [filter]="filter"
                [filterService]="filterService"
                [extra]="false">
            </kendo-grid-string-filter-menu>
        </ng-template>
    </kendo-grid-column>
      <kendo-grid-column field="manikinOrTrainerBaseType" title="Type">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
              <kendo-multiselect
                  [data]="baseTypes"
                  [placeholder]="enumFilterPlaceholder"
                  [value]="filter | filterValues"
                  (valueChange)="enumFilterChange($event, filterService, 'manikinOrTrainerBaseType')">
              </kendo-multiselect>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="manikinType" title="Manikin Type">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
              <kendo-multiselect
                  [data]="manikinTypes"
                  [placeholder]="enumFilterPlaceholder"
                  [value]="filter | filterValues"
                  (valueChange)="enumFilterChange($event, filterService, 'manikinType')">
              </kendo-multiselect>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="trainerType" title="Trainer Type">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
              <kendo-multiselect
                  [data]="trainerTypes"
                  [placeholder]="enumFilterPlaceholder"
                  [value]="filter | filterValues"
                  (valueChange)="enumFilterChange($event, filterService, 'trainerType')">
              </kendo-multiselect>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="nickname" title="Nick Name">
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
              <kendo-grid-string-filter-menu
                  [column]="column"
                  [filter]="filter"
                  [filterService]="filterService"
                  [extra]="false">
              </kendo-grid-string-filter-menu>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="gender" title="Gender">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
              <kendo-multiselect
                  [data]="genders"
                  [placeholder]="enumFilterPlaceholder"
                  [value]="filter | filterValues"
                  (valueChange)="enumFilterChange($event, filterService, 'gender')">
              </kendo-multiselect>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="skinTone" title="Skin Tone">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
              <kendo-multiselect
                  [data]="skinTones"
                  [placeholder]="enumFilterPlaceholder"
                  [value]="filter | filterValues"
                  (valueChange)="enumFilterChange($event, filterService, 'skinTone')">
              </kendo-multiselect>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="status" title="Status">
          <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
              <kendo-multiselect
                  [data]="equipmentStatuses"
                  [placeholder]="enumFilterPlaceholder"
                  [value]="filter | filterValues"
                  (valueChange)="enumFilterChange($event, filterService, 'status')">
              </kendo-multiselect>
          </ng-template>
      </kendo-grid-column>
      <!-- <kendo-grid-column-group title="Current Location"> -->
          <kendo-grid-column field="fullLocationName" title="Location">
              <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                  <kendo-grid-string-filter-menu
                      [column]="column"
                      [filter]="filter"
                      [filterService]="filterService"
                      [extra]="false">
                  </kendo-grid-string-filter-menu>
              </ng-template>
          </kendo-grid-column>
          <!-- <kendo-grid-column field="buildingName" title="Building">
              <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                  <kendo-grid-string-filter-menu
                      [column]="column"
                      [filter]="filter"
                      [filterService]="filterService"
                      [extra]="false">
                  </kendo-grid-string-filter-menu>
              </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="roomName" title="Room">
              <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                  <kendo-grid-string-filter-menu
                      [column]="column"
                      [filter]="filter"
                      [filterService]="filterService"
                      [extra]="false">
                  </kendo-grid-string-filter-menu>
              </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="bedName" title="Bed">
              <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                  <kendo-grid-string-filter-menu
                      [column]="column"
                      [filter]="filter"
                      [filterService]="filterService"
                      [extra]="false">
                  </kendo-grid-string-filter-menu>
              </ng-template>
          </kendo-grid-column> -->
      <!-- </kendo-grid-column-group> -->
      <kendo-grid-pdf [fileName]="pdfFileName" paperSize="A4" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
      </kendo-grid-pdf>
      <kendo-grid-excel [fileName]="excelFileName"></kendo-grid-excel>
  </kendo-grid>
</autopilot-grid>

<router-outlet></router-outlet>
<div kendoDialogContainer></div>
