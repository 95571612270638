import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { FilterService, MultipleSortSettings, SelectAllCheckboxState, SelectionEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import {
  BedService,
  BuildingService,
  EquipmentStatus,
  Gender,
  LocationService,
  ManikinMakeAndModelService,
  ManikinOrTrainerBaseType,
  ManikinType,
  RoomService,
  SkinTone,
  TrainerType,
  VendorService
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { ImpersonationService, LabelType, QrLabelService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-manikin-make-and-model-inventory-location-list',
  templateUrl: './manikin-make-and-model-inventory-location-list.component.html',
  styleUrls: ['./manikin-make-and-model-inventory-location-list.component.scss']
})
export class ManikinMakeAndModelInventoryLocationListComponent extends EntityBaseComponent implements OnInit {
  constructor(
    private loadingBar: LoadingBarService,
    private logger: NGXLogger,
    private impersonationService: ImpersonationService,
    private manikinMakeAndModelService: ManikinMakeAndModelService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private dialogService: DialogService,
    private vendorService: VendorService,
    private locationService: LocationService,
    private buildingService: BuildingService,
    private roomService: RoomService,
    private bedService: BedService,
    private qrLabelService: QrLabelService
  ) {
    super(EntityGlobals.MANIKIN_MAKES_AND_MODELS, router, route, impersonationService, logger);
  }
  gridData: any[];

  public sort: SortDescriptor[] = [];
  public sortSettings: MultipleSortSettings = {
    mode: 'multiple',
    initialDirection: 'desc',
    allowUnsort: true,
    showIndexes: true
  };

  public baseTypes = Object.values(ManikinOrTrainerBaseType);
  public manikinTypes = Object.values(ManikinType);
  public trainerTypes = Object.values(TrainerType);
  public equipmentStatuses = Object.values(EquipmentStatus);
  public skinTones = Object.values(SkinTone);
  public genders = Object.values(Gender);
  public enumFilterPlaceholder = 'Select one or more filter values';

  public selectedIds: number[] = [];
  public selectAllState: SelectAllCheckboxState = 'unchecked';

  ngOnInit(): void {
    this.reloadData();
  }

  public enumFilterChange(values: string[], filterService: FilterService, fieldName: string): void {
    filterService.filter({
      filters: values.map(value => ({
        field: fieldName,
        operator: 'eq',
        value
      })),
      logic: 'or'
    });
  }

  public reloadData(): void {
    this.loadingBar.useRef('router').start();

    const vendorData = this.vendorService.list();
    const locationData = this.locationService.list();
    const buildingData = this.buildingService.list();
    const roomData = this.roomService.list();
    const bedData = this.bedService.list();
    const inventoryLocationData = this.manikinMakeAndModelService.inventoryLocationList();

    forkJoin([vendorData, locationData, buildingData, roomData, bedData, inventoryLocationData]).subscribe(
      ([vendors, locations, buildings, rooms, beds, inventoryLocations]) => {
        this.loadingBar.useRef('router').complete();
        const vendorMap = new Map(vendors.data?.map(vendor => [vendor.id, vendor.vendorName]));
        const locationMap = new Map(locations.data?.map(location => [location.id, location.name]));
        const buildingMap = new Map(buildings.data?.map(building => [building.id, building.name]));
        const roomMap = new Map(rooms.data?.map(room => [room.id, room.name]));
        const bedMap = new Map(beds.data?.map(bed => [bed.id, bed.customId]));
        this.gridData = inventoryLocations.data?.map(inventoryLocationRecord => ({
          ...inventoryLocationRecord,
          vendorName: vendorMap.get(inventoryLocationRecord.manufacturerVendorId),
          locationName: locationMap.get(inventoryLocationRecord.currentLocation.locationId),
          buildingName: buildingMap.get(inventoryLocationRecord.currentLocation.buildingId),
          roomName: roomMap.get(inventoryLocationRecord.currentLocation.roomId),
          bedName: bedMap.get(inventoryLocationRecord.currentLocation.bedId),
          fullLocationName: `${locationMap.get(inventoryLocationRecord.currentLocation.locationId) ?? '-'} / 
                        ${buildingMap.get(inventoryLocationRecord.currentLocation.buildingId) ?? '-'} / 
                        ${roomMap.get(inventoryLocationRecord.currentLocation.roomId) ?? '-'} / 
                        ${bedMap.get(inventoryLocationRecord.currentLocation.bedId) ?? '-'}`
        }));
      }
    );
  }

  delete(id) {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this item?',
      actions: [
        { text: 'No' },
        {
          text: 'Yes',
          primary: true,
          id: id
        }
      ],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe((result: any) => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
        this.manikinMakeAndModelService
          ._delete(result.id.toString())
          .toPromise()
          .then((newResult: any) => {
            this.toastService.success('', 'This item has been deleted.');
          });
      }
    });
  }

  public onSelectionChange(e: SelectionEvent) {
    e.selectedRows.forEach(row => {
      if (!this.selectedIds.includes(row.dataItem.id)) {
        this.selectedIds.push(row.dataItem.id);
      }
    });
    e.deselectedRows.forEach(row => {
      const index = this.selectedIds.indexOf(row.dataItem.id);
      if (index > -1) {
        this.selectedIds.splice(index, 1);
      }
    });

    const selectionLength = this.selectedIds.length;
    if (selectionLength === 0) {
      this.selectAllState = 'unchecked';
    } else if (selectionLength > 0 && selectionLength < this.gridData.length) {
      this.selectAllState = 'indeterminate';
    } else {
      this.selectAllState = 'checked';
    }
  }

  public onSelectAll(checkedState: SelectAllCheckboxState) {
    if (checkedState === 'checked') {
      this.selectedIds = this.gridData.map(item => item.id);
      this.selectAllState = 'checked';
    } else {
      this.selectedIds = [];
      this.selectAllState = 'unchecked';
    }
  }

  public async onPrintLabels(): Promise<void> {
    const labelType = LabelType.ManikinAndTrainer;
    const selectedItems = this.gridData.filter(gridRow => this.selectedIds.includes(gridRow.id));
    await this.qrLabelService.setQrLabelsInBrowserStorage(
      (item: any) => `${location.origin}/${EntityGlobals.MANIKIN_MAKES_AND_MODELS.baseRoute}/line-item/${item.id}/qr`,
      labelType,
      selectedItems
    );
    this.router.navigateByUrl(`/printer/print-labels/${labelType.toLowerCase()}`);
  }

  @ViewChild('selectAllCheckbox') selectAllCheckbox: ElementRef;
  clickSelectAllCheckbox() {
    this.selectAllCheckbox.nativeElement.click();
  }
}
