import { UrlSegment } from '@angular/router';
import { EntityGlobals, MenuGroupType } from '@wo-app/app.global';
import { LabelPreviewComponent } from '@wo-app/components/dashboard/printers/label-preview/label-preview.component';
import { ListPrintersComponent } from '@wo-app/components/dashboard/printers/list-printers/list-printers.component';
import { PrintLabelsComponent } from '@wo-app/components/dashboard/printers/print-labels/print-labels.component';
import { ImpersonatingOrganizationCanActivate } from '@wo-app/core/guards';
import { MenuGroupListingItem } from '@wo-app/shared/models/menu';
import { RouteChild, RouteParent } from '@wo-app/shared/models/route';

export class PrintersRegistration {
  static Routes: RouteParent = {
    path: EntityGlobals.PRINTERS.baseRoute,
    canActivate: [ImpersonatingOrganizationCanActivate],
    entityInformation: EntityGlobals.PRINTERS,
    children: [
      {
        path: '',
        component: ListPrintersComponent,
        data: {
          breadcrumb: 'View All ' + EntityGlobals.PRINTERS.pluralName,
          //menuGroups: [new MenuGroupListingItem(0, 0, MenuGroupType.Settings, EntityGlobals.PRINTERS.pluralName)],
          entityInformation: EntityGlobals.PRINTERS
        }
      } as RouteChild,
      {
        path: 'print-labels/:labelType',
        component: PrintLabelsComponent,
        data: {
          breadcrumb: 'Print Labels',
          entityInformation: EntityGlobals.PRINTERS
        }
      } as RouteChild,
      {
        path: 'print-labels',
        component: PrintLabelsComponent,
        data: {
          breadcrumb: 'Print Labels',
          entityInformation: EntityGlobals.PRINTERS
        }
      } as RouteChild
      /*{
                path: 'edit/:id',
                component: SkillDetailComponent,
                data: {
                    breadcrumb: 'Edit ' + EntityGlobals.PRINTERS.SingleEntityName
                }
            } as RouteChild,
            {
                path: 'add',
                component: SkillDetailComponent,
                data: {
                    breadcrumb: 'Add ' + EntityGlobals.PRINTERS.SingleEntityName
                }
            } as RouteChild*/
    ]
  };

  static Services: any = [];

  static Components: any = [ListPrintersComponent, PrintLabelsComponent, LabelPreviewComponent];
}
