import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CommunicationBoardItemService,
  CommunicationBoardTaskListViewModel,
  ReadCommunicationBoardItemCommandResult,
  SupplyService
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
@Component({
  selector: 'app-communication-board-detail',
  templateUrl: './communication-board-detail.component.html',
  styleUrls: ['./communication-board-detail.component.scss']
})
export class CommunicationBoardDetailComponent extends EntityBaseComponent implements OnInit {
  item: ReadCommunicationBoardItemCommandResult = {};
  labRequestDatesAndTimes: any[] = [];
  tasks: Array<CommunicationBoardTaskListViewModel> | null = [];
  inventoryChanges: any[] = [];

  constructor(
    private impersonationService: ImpersonationService,
    private logger: NGXLogger,
    private SupplyService: SupplyService,
    private communicationBoardItemService: CommunicationBoardItemService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super(EntityGlobals.COMMUNICATION_BOARD, router, route, impersonationService, logger);
    this.getLabRequestData();
  }

  ngOnInit() {}

  getLabRequestData(): void {
    this.logger.debug(this.id);
    this.communicationBoardItemService.read(this.id).subscribe((result: ReadCommunicationBoardItemCommandResult) => {
      this.logger.debug(result);
      if (result) {
        this.item = result;
        this.labRequestDatesAndTimes = result.labRequestDatesAndTimes ?? [];
        this.tasks = result.tasks ?? [];
        this.inventoryChanges = result?.inventoryChanges?.data ?? [];
      }
    });
  }
}
