import { EntityGlobals } from '@wo-app/app.global';
import { EmailTemplateDetailComponent } from '@wo-app/components/dashboard/email-templates/email-template-detail/email-template-detail.component';
import { EmailTemplateListingComponent } from '@wo-app/components/dashboard/email-templates/email-template-listing/email-template-listing.component';
import { NotImpersonatingOrganizationCanActivate } from '@wo-app/core/guards';
import { RouteChild, RouteParent } from '@wo-app/shared/models/route';

export class EmailTemplatesRegistration {
  static Routes: RouteParent = {
    path: EntityGlobals.EMAIL_TEMPLATES.baseRoute,
    canActivate: [NotImpersonatingOrganizationCanActivate],
    entityInformation: EntityGlobals.EMAIL_TEMPLATES,
    children: [
      {
        path: '',
        component: EmailTemplateListingComponent,
        data: {
          breadcrumb: 'View All'
        }
      } as RouteChild,
      {
        path: 'edit/:id',
        component: EmailTemplateDetailComponent,
        data: {
          breadcrumb: 'Edit ' + EntityGlobals.EMAIL_TEMPLATES.singleName
        }
      } as RouteChild,
      {
        path: 'add',
        component: EmailTemplateDetailComponent,
        data: {
          breadcrumb: 'Add ' + EntityGlobals.EMAIL_TEMPLATES.singleName
        }
      } as RouteChild
    ]
  };

  static Services: any = [];

  static Components: any = [EmailTemplateDetailComponent, EmailTemplateListingComponent];
}
