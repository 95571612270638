<kendo-grid 
    [kendoGridBinding]="gridData"
    (cellClick)="cellClickHandler($event)"
    (cellClose)="cellCloseHandler($event)"
    (remove)="removeHandler($event)"
    (edit)="reserveHandler($event)"
    >
    <kendo-grid-column field="id" title="Id" [hidden]="true"></kendo-grid-column>
    <kendo-grid-column field="pickOrderId" title="pickOrderId" [hidden]="true"></kendo-grid-column>
    <kendo-grid-column field="inventoryItemId" title="inventoryItemId" [hidden]="true"></kendo-grid-column>
    <kendo-grid-column field="manikinModelName" title="Model"></kendo-grid-column>
    <kendo-grid-column field="manikinModelNumber" title="Model Number"></kendo-grid-column>
    <kendo-grid-column field="manikinType" title="Type"></kendo-grid-column>
    <kendo-grid-column field="manikinOrTrainerBaseType" title="Trainer Type"></kendo-grid-column>
    <kendo-grid-column field="status" title="Status">
      <ng-template kendoGridCellTemplate let-dataItem>
         <span class="badge rounded-pill" [ngClass]="getStatusColumnClass(dataItem.status)">{{dataItem.status}}</span>
      </ng-template>
   </kendo-grid-column>
   <kendo-grid-column field="quantityRequested" title="Qty Requested" editor="numeric"></kendo-grid-column>
   <kendo-grid-column *ngIf="isInProgress" field="quantityAvailable" title="Qty Available" class="no-padding">
      <ng-template kendoGridCellTemplate let-dataItem>
         <span [ngClass]="dataItem.quantityRequested > dataItem.quantityAvailable || dataItem.quantityAvailable === 0 ? 'whole-cell-red fw-bold' : ''">
            {{ dataItem.quantityAvailable }}
         </span>   
      </ng-template>
   </kendo-grid-column>
   <kendo-grid-command-column *ngIf="isInProgress" title="" [width]="100">
      <ng-template kendoGridCellTemplate let-dataItem>
         <button kendoGridEditCommand [primary]="true"
         [disabled]="dataItem.quantityRequested <= 0
            || dataItem.quantityRequested > dataItem.quantityAvailable
            || dataItem.status === 'NotSaved'
            || dataItem.status === 'Pending'
            || dataItem.status === 'Reserved'
            || dataItem.status === 'CheckedOut'"
           >Reserve</button>
      </ng-template>
   </kendo-grid-command-column>
    <kendo-grid-command-column *ngIf="isInProgress || isStarted" title="" [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem>
           <button kendoGridRemoveCommand
            [disabled]="dataItem.status === 'Pending'
            || dataItem.status === 'Reserved'
            || dataItem.status === 'CheckedOut'"
            >Remove</button>
        </ng-template>
     </kendo-grid-command-column>
</kendo-grid>