import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {
  CreateUserCommand,
  CreateUserCommandResult,
  ListRolesCommandResult,
  ReadUserCommandResult,
  UpdateUserCommand,
  UserService
} from '@wo-api/index';
import { EntityGlobals, PhoneNumberRegex, ValidationMaxStringLength } from '@wo-app/app.global';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { CachedDataService, ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { patternValidator } from '@wo-app/shared/utils';
import { NGXLogger } from 'ngx-logger';
@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent extends EntityBaseComponent implements OnInit {
  override showForm: boolean;
  userId: string;
  public username: string;
  readOrganizationCommandResult: ReadUserCommandResult = {};
  dataModel: ReadUserCommandResult;
  form: FormGroup;
  passwordForm: FormGroup;
  originalFormState: FormGroup;
  fromKey: string;
  globalRoles: any[] = [];
  tenantRoles: any[] = [];
  hideGlobalRolesSelector: boolean;

  get firstName() {
    return this.form.get('firstName');
  }

  get lastName() {
    return this.form.get('lastName');
  }

  get userNameCtrl() {
    return this.form.get('username');
  }

  get emailAddressCtrl() {
    return this.form.get('emailAddress');
  }

  get phoneNumberCtrl() {
    return this.form.get('phoneNumber');
  }

  constructor(
    private logger: NGXLogger,
    public cachedDataService: CachedDataService,
    private userService: UserService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService,
    private impersonationService: ImpersonationService
  ) {
    super(EntityGlobals.USERS, router, route, impersonationService, logger);

    this.form = this.fb.group({
      id: ['', []],
      username: ['', [Validators.required, Validators.maxLength(ValidationMaxStringLength.Short)]],
      firstName: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      lastName: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      emailAddress: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, patternValidator(PhoneNumberRegex)]],
      password: [],
      globalRoles: [],
      tenantRoles: []
    });
    this.originalFormState = this.form;

    this.cachedDataService.applicationRoles.subscribe((value: ListRolesCommandResult) => {
      if (value && value.globalRoles && value.tenantRoles) {
        this.globalRoles = value.globalRoles;
        this.tenantRoles = value.tenantRoles;
      }
    });
    this.username = this.route.snapshot.params['id'];
    this.fromKey = 'FormModel';
    this.hideGlobalRolesSelector = false;
  }

  convertServerDataToFormModel(result: ReadUserCommandResult) {
    this.dataModel = result;
    this.form.patchValue(this.dataModel);
    this.logger.debug('convertServerDataToFormModel this.passwordForm', this.passwordForm);
    this.form.setControl('password', this.passwordForm);
  }

  getData() {
    this.userService.read(this.username).subscribe((result: ReadUserCommandResult) => {
      console.log(result);
      if (result && result.id) {
        this.userId = result.id;
        this.convertServerDataToFormModel(result);
        this.showForm = true;
      }
    });
  }

  ngOnInit() {
    if (this.editMode) {
      this.getData();
    } else {
      this.showForm = true;
    }
  }

  resetForm() {
    this.logger.debug(this.originalFormState);
    this.form.reset(this.originalFormState.value);
    this.router.navigate(['/users']);
  }

  public onFormGroupChangeEvent(passwordFormGroup: FormGroup) {
    this.logger.debug('onFormGroupChangeEvent', passwordFormGroup);
    this.passwordForm = passwordFormGroup;
    this.form.addControl('password', this.passwordForm);
  }

  onSubmit(closeOnSuccess) {
    // If the Form is in Edit Mode, Update Object
    if (this.editMode) {
      let updateCommand: UpdateUserCommand = {};
      updateCommand = this.form.value;
      updateCommand.password = this.passwordForm?.value.password;
      this.userService.update(updateCommand).subscribe(result => {
        this.logger.debug(result);
        if (closeOnSuccess) {
          this.router.navigate(['/users']);
        } else {
          this.convertServerDataToFormModel(result);
          this.toastService.success('Success!', 'This item has been saved.');
        }
      });
    } else {
      // Otherwise, create a new object
      let createCommand: CreateUserCommand = {};
      createCommand = this.form.value;
      this.logger.debug(createCommand, this.form.value);
      this.userService.create(createCommand).subscribe((result: CreateUserCommandResult) => {
        this.toastService.success('Success!', 'This item has been saved.');
        this.router.navigate(['/users']);
      });
    }
  }

  submitAndClose() {
    this.onSubmit(true);
  }

  disable() {}

  delete() {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this item?',
      actions: [{ text: 'No' }, { text: 'Yes', primary: true }],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
      }
      this.toastService.success('Oops.', 'This item has been saved.');
    });
  }
}
