<div class="m-3">
    <kendo-grid *ngIf="inventoryItem?.rooms?.length"
        [data]="inventoryItem.rooms"
        [kendoGridInCellEditing]="createFormGroup"
        >
        <!-- <kendo-grid-column field="inventoryItemId" title="Id" [hidden]="true"></kendo-grid-column>
        <kendo-grid-column field="roomId" title="Room Id"></kendo-grid-column> -->
        <kendo-grid-column field="roomName" title="Room"></kendo-grid-column>
        <kendo-grid-column *ngIf="inventoryItem?.rooms?.length > 1" field="quantity" editor="numeric" title="Quantity" [width]="150"></kendo-grid-column>
    </kendo-grid>
</div>
<div class="mb-3" *ngIf="inventoryItem.status !== 'CheckedOut'">
    <kendo-dropdownlist
        [data]="rooms"
        [valuePrimitive]="false"
        textField="name"
        valueField="id"
        [(value)]="selectedValue"
        #ddrooms
        (valueChange)="handleVelueChange($event)"
    ></kendo-dropdownlist>
</div>