<form [formGroup]="form" (ngSubmit)="onSubmit(false)" [hidden]="!showForm" class="entity-form">
    <div class="row">
        <div class="col-sm-12 col-md-6" [hidden]="editMode">
            <button kendoButton type="submit" [disabled]="!form.valid" [primary]="true" [icon]="'save'">Create {{this.GetSingleEntityName()}}</button>
        </div>
        <div class="col-sm-12 col-md-6" [hidden]="!editMode">
            <kendo-buttongroup>
                <!--<button kendoButton type="submit" [disabled]="!form.valid" [primary]="true" [icon]="'save'">Save and Continue</button>-->
                <button kendoButton type="button" (click)="submitAndClose()" [primary]="true" [disabled]="!form.valid" [icon]="'save'">Save and Close</button>
                <button kendoButton type="button" (click)="resetForm()" [primary]="false" [icon]="'cancel-circle'">Cancel</button>
            </kendo-buttongroup>
        </div>
        <div class="col-sm-12 col-md-6" [hidden]="!editMode">
            <div class="float-end">
                <kendo-buttongroup>
                    <!--<button kendoButton type="button" (click)="disable()" [icon]="'cancel-circle'">Disable {{this.GetSingleEntityName()}}</button>
                <button kendoButton type="button" (click)="delete()" [icon]="'delete'">Delete {{this.GetSingleEntityName()}}</button>-->
                </kendo-buttongroup>
                <div kendoDialogContainer></div>
            </div>
        </div>
        <div class="col-sm-12" [hidden]="true">
            <kendo-buttongroup>
                <button kendoButton type="button" [primary]="true" [icon]="'user'">View Users</button>
            </kendo-buttongroup>
        </div>
        <div class="col-sm-12">
            <hr/>
        </div>
    </div>
    <app-validation-errors></app-validation-errors>
    <input type="hidden" id="id" formControlName="id" />
    <kendo-tabstrip>
        <kendo-tabstrip-tab [title]="'General'" [selected]="true">
            <ng-template kendoTabContent>
                <div class="row">
                    <div class=" col-sm-12">
                        <label for="name" class="col-form-label col-form-label-sm">Name</label>
                        <input kendoTextBox formControlName="name" id="name" class="form-control" placeholder="Name" />
                    </div>
                    <div class=" col-md-12">
                        <label for="instructions" class="col-form-label col-form-label-sm">Instructions</label>
                        <kendo-editor type="text" formControlName="instructions" id="instructions" class="form-control" placeholder="Instructions"/>
                    </div>
                    <div class=" col-md-12">
                        <label for="manikinProcedureFrequency" class="col-form-label col-form-label-sm">Procedure Frequency</label>
                        <app-radio-button-list formControlName="manikinProcedureFrequency" [orientation]="'horizontal'" [data]="frequencyTypes" [groupName]="'manikinProcedureFrequency'" [showInline]="true" [dataLabelField]="'name'" [dataValueField]="'value'"></app-radio-button-list>
                    </div>
                    <div class=" col-md-12">
                        <label for="onlyWhenInUse" class="col-form-label col-form-label-sm">Only When In Use</label>
                        <kendo-switch type="checkbox" formControlName="onlyWhenInUse" id="onlyWhenInUse"></kendo-switch>
                    </div>
                    <div class=" col-md-12">
                        <label for="manikinsAssignedThisProcedure" class="col-form-label col-form-label-sm">Manikins Assigned to this Procedure</label>
                        <app-manikin-selector [form]="form" (manikinsAssignedThisProcedureChange)="manikinsAssignedThisProcedureChange($event)"></app-manikin-selector>
                    </div>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Metadata'" [disabled]="!editMode">
            <ng-template kendoTabContent>
                <app-metadata [metadata]="dataModel"></app-metadata>
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>
</form>