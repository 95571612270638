<autopilot-grid>
  <kendo-grid
      [kendoGridBinding]="gridData"
      [style.maxHeight.%]="100"
      [pageSize]="state.take"
      [filterable]="'menu'"
      [sortable]="sortSettings"
      [sort]="sort"
      [pageable]="true">
      <ng-template kendoGridToolbarTemplate>
          <div class="col-auto mb-2 me-auto">
              <button kendoButton class="btn btn-primary" type="button" (click)="addNew()" [icon]="'plus'">Add {{this.GetSingleEntityName()}}</button>
          </div>
          <div class="text-end">
              <button kendoGridPDFCommand class="btn btn-secondary text-white me-1" title="Export as PDF"><i class="fa-light fa-file-pdf"></i></button>
              <button kendoGridExcelCommand title="Export to Excel" class="btn btn-secondary text-white"><i class="fa-light fa-file-excel"></i></button>
          </div>
      </ng-template>
      <kendo-grid-column title="Actions" [width]="108" class="k-col-actions text-center">
          <ng-template kendoGridCellTemplate let-dataItem>
            <button kendoButton type="button" class="btn btn-outline-primary default-action-button" (click)="routeToEditorTemplate(dataItem.username)" title="Edit User"><i class="fas fa-edit"></i></button>
            <!--<button kendoButton type="button" class="btn btn-primary default-action-button" (click)="deleteUser(dataItem)"><i class="fas fa-trash" title="Delete user"></i></button>-->
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="enabled" title="Enabled" filter="boolean">
          <kendo-grid-messages
              filterIsTrue="Enabled"
              filterIsFalse="Disabled">
          </kendo-grid-messages>
          <ng-template kendoGridCellTemplate let-dataItem>
              <div (click)="toggleUserStatus(dataItem)" class="kendo-switch-wrapper text-center">
                  <kendo-switch [readonly]="true" [(ngModel)]="dataItem.enabled" onLabel="Yes" offLabel="No" [title]="(dataItem.enabled ? 'Disabled user' : 'Enable user')"></kendo-switch>
              </div>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="id" title="User ID">
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
              <kendo-grid-string-filter-menu
                  [column]="column"
                  [filter]="filter"
                  [filterService]="filterService"
                  [extra]="false">
              </kendo-grid-string-filter-menu>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="username" title="Username">
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
              <kendo-grid-string-filter-menu
                  [column]="column"
                  [filter]="filter"
                  [filterService]="filterService"
                  [extra]="false">
              </kendo-grid-string-filter-menu>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="emailAddress" title="Email Address">
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
              <kendo-grid-string-filter-menu
                  [column]="column"
                  [filter]="filter"
                  [filterService]="filterService"
                  [extra]="false">
              </kendo-grid-string-filter-menu>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="firstName" title="First Name">
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
              <kendo-grid-string-filter-menu
                  [column]="column"
                  [filter]="filter"
                  [filterService]="filterService"
                  [extra]="false">
              </kendo-grid-string-filter-menu>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="lastName" title="Last Name">
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
              <kendo-grid-string-filter-menu
                  [column]="column"
                  [filter]="filter"
                  [filterService]="filterService"
                  [extra]="false">
              </kendo-grid-string-filter-menu>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="confirmationStatus" title="Confirmation Status">
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
              <kendo-grid-string-filter-menu
                  [column]="column"
                  [filter]="filter"
                  [filterService]="filterService"
                  [extra]="false">
              </kendo-grid-string-filter-menu>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="createdDate" title="Date Created">
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
              <kendo-grid-date-filter-menu
                  [column]="column"
                  [filter]="filter"
                  [filterService]="filterService"
                  [extra]="false">
              </kendo-grid-date-filter-menu>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.created | amCalendar }}
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="lastUpdatedDate" title="Date Last Updated">
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
              <kendo-grid-date-filter-menu
                  [column]="column"
                  [filter]="filter"
                  [filterService]="filterService"
                  [extra]="false">
              </kendo-grid-date-filter-menu>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.lastUpdated | amCalendar }}
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-pdf [fileName]="pdfFileName" paperSize="A4"
                      [scale]="0.8"
                      [repeatHeaders]="true"
                      [allPages]="true"
                      [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
      </kendo-grid-pdf>
      <kendo-grid-excel [fileName]="excelFileName"></kendo-grid-excel>
  </kendo-grid>
</autopilot-grid>
<router-outlet></router-outlet>
<div kendoDialogContainer></div>
