import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {
  BuildingService,
  CreateCustomerCommand,
  CreateCustomerCommandResult,
  CustomerService,
  ListBuildingsCommand,
  ListBuildingsCommandResult,
  ReadCustomerCommandResult,
  UpdateCustomerCommand,
  UpdateCustomerCommandResult
} from '@wo-api/index';
import { EntityGlobals, PhoneNumberRegex, ValidationMaxStringLength } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { patternValidator } from '@wo-app/shared/utils';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-customers-detail',
  templateUrl: './customers-detail.component.html',
  styleUrls: ['./customers-detail.component.scss']
})
export class CustomersDetailComponent extends EntityBaseComponent implements OnInit {
  override showForm: boolean;
  readCustomerCommandResult: ReadCustomerCommandResult = {};
  dataModel: ReadCustomerCommandResult;
  updateCustomerCommand: UpdateCustomerCommand = {};
  createCustomerCommand: CreateCustomerCommand = {};
  form: FormGroup;
  originalFormState: FormGroup;
  fromKey: string;
  organizations: any[];
  selectedOrganizationId: string;

  listBuildingsCommand: ListBuildingsCommand = {};
  public $buildings: Observable<ListBuildingsCommandResult>;

  get firstName() {
    return this.form.get('firstName');
  }

  get lastName() {
    return this.form.get('lastName');
  }

  get phone() {
    return this.form.get('phone');
  }

  get email() {
    return this.form.get('email');
  }

  get organizationName() {
    return this.form.get('organizationName');
  }

  get notes() {
    return this.form.get('notes');
  }

  get customId() {
    return this.form.get('customId');
  }

  constructor(
    private logger: NGXLogger,
    private impersonationService: ImpersonationService,
    private locationService: CustomerService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService,
    breadcrumbService: BreadcrumbsService,
    titleService: Title,
    private buildingService: BuildingService
  ) {
    super(EntityGlobals.CUSTOMERS, router, route, impersonationService, logger, breadcrumbService, titleService);
    this.$buildings = this.buildingService.list();
  }

  public ngOnInit() {
    this._createForm();
    this.showForm = false;
    this.fromKey = 'FormModel';
    if (this.editMode) {
      this._getData();
    } else {
      this.showForm = true;
    }
  }

  private _createForm() {
    this.form = this.fb.group({
      id: [''],
      firstName: ['', [Validators.required, Validators.maxLength(ValidationMaxStringLength.Default)]],
      lastName: ['', [Validators.required, Validators.maxLength(ValidationMaxStringLength.Default)]],
      phone: ['', [Validators.required, patternValidator(PhoneNumberRegex)]],
      email: ['', [Validators.required, Validators.email]],
      organizationName: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      notes: ['', [Validators.maxLength(ValidationMaxStringLength.Long)]],
      customId: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      created: [''],
      createByUsername: [''],
      createdByUserId: [''],
      lastUpdated: [''],
      lastUpdatedByUsername: [''],
      lastUpdatedByUserId: [''],
      buildingName: [''],
      buildingId: ['']
    });
    this.originalFormState = this.form;
  }

  private _convertServerDataToFormModel(result: ReadCustomerCommandResult) {
    this.dataModel = result;
    this.form.patchValue(this.dataModel);
    if (this.originalFormState.pristine) {
      this.logger.debug('Setting Original Form State Value');
      this.originalFormState.patchValue(this.dataModel);
    }
  }

  private _getData() {
    this.locationService
      .read(this.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: ReadCustomerCommandResult) => {
        this.UpdateBreadcrumbPageTitle(result.id, `${result.firstName} ${result.lastName}`);
        this.logger.debug(result);
        this._convertServerDataToFormModel(result);
        this.showForm = true;
      });
  }

  public resetForm() {
    this.logger.debug(this.originalFormState);
    this.form.reset(this.originalFormState.value);
    this.routeToEntityList();
  }

  public onSubmit(closeOnSuccess) {
    // If the Form is in Edit Mode, Update Object
    if (this.editMode) {
      this.updateCustomerCommand = this.form.value;
      this.updateCustomerCommand.buildingId = this.form.value.buildingId ? this.form.value.buildingId : null;
      this.logger.debug(this.updateCustomerCommand);
      this.locationService
        .update(this.updateCustomerCommand.id.toString(), this.updateCustomerCommand)
        .pipe(takeUntil(this.destroy$))
        .subscribe((result: UpdateCustomerCommandResult) => {
          this.logger.debug(result);
          if (closeOnSuccess) {
            this.routeToEntityList();
          } else {
            this._convertServerDataToFormModel(result);
            this.toastService.success('Success!', 'This item has been saved.');
          }
        });
    } else {
      // Otherwise, create a new object
      this.createCustomerCommand = this.form.value;
      this.logger.debug(this.createCustomerCommand);
      this.locationService
        .create(this.createCustomerCommand)
        .pipe(takeUntil(this.destroy$))
        .subscribe((result: CreateCustomerCommandResult) => {
          this._convertServerDataToFormModel(result);
          this.routeToEntityList();
          this.editMode = true;
          this.toastService.success('Success!', 'This item has been saved.');
        });
    }
  }

  public submitAndClose() {
    this.onSubmit(true);
  }

  public disable() {}

  public delete() {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this location?',
      actions: [{ text: 'No' }, { text: 'Yes', primary: true }],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.pipe(takeUntil(this.destroy$)).subscribe(result => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
      }
      this.toastService.success('Oops.', 'This item has been saved.');
    });
  }
}
