import { ScenarioService } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { ScenarioDetailComponent } from '@wo-app/components/dashboard/scenarios/scenario-detail/scenario-detail.component';
import { ScenariosComponent } from '@wo-app/components/dashboard/scenarios/scenarios/scenarios.component';
import { ImpersonatingOrganizationCanActivate } from '@wo-app/core/guards';
import { RouteChild, RouteParent } from '@wo-app/shared/models/route';

export class ScenariosRegistration {
  static Routes: RouteParent = {
    path: EntityGlobals.SCENARIOS.baseRoute,
    canActivate: [ImpersonatingOrganizationCanActivate],
    entityInformation: EntityGlobals.SCENARIOS,
    children: [
      {
        path: '',
        component: ScenariosComponent,
        data: {
          breadcrumb: 'View All'
        }
      } as RouteChild,
      {
        path: 'add',
        component: ScenarioDetailComponent,
        data: {
          breadcrumb: 'Add ' + EntityGlobals.SCENARIOS.singleName
        }
      } as RouteChild,
      {
        path: 'edit/:id',
        component: ScenarioDetailComponent,
        data: {
          breadcrumb: 'Edit ' + EntityGlobals.SCENARIOS.singleName
        }
      } as RouteChild
    ]
  };

  static Services: any = [ScenarioService];

  static Components: any = [ScenariosComponent, ScenarioDetailComponent];
}
