<autopilot-grid>
  <kendo-grid [data]="view" [style.maxHeight.%]="100">
      <ng-template kendoGridToolbarTemplate>
          <div class="float-start">
              <a [routerLink]="['/calendar']" class="btn btn-primary"><i class="fa-light fa-calendar"></i> View Calendar List</a>
          </div>
          <div class="float-end">
              <button kendoGridPDFCommand class="btn btn-secondary text-white me-1" title="Export as PDF"><i class="fa-light fa-file-pdf"></i></button>
              <button kendoGridExcelCommand title="Export to Excel" class="btn btn-secondary text-white"><i class="fa-light fa-file-excel"></i></button>
          </div>
      </ng-template>
      <kendo-grid-column field="labRequestName" title="Lab Request Name">
          <ng-template kendoGridCellTemplate let-dataItem>
              <a [routerLink]="['/', organizationId, dataItem.id]">{{dataItem.labRequestName}}</a>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="scenarioStartDate" title="Start Date">
          <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem.scenarioStartDate | amCalendar}}
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="tasks" title="Tasks">
          <ng-template kendoGridCellTemplate let-dataItem>
              <div *ngFor="let cellValue of dataItem.tasks">
                  <app-board-cell [cellValue]='cellValue' [organizationId]='organizationId'></app-board-cell>
              </div>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-pdf [fileName]="pdfFileName" paperSize="A4" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
      </kendo-grid-pdf>
      <kendo-grid-excel [fileName]="excelFileName"></kendo-grid-excel>
  </kendo-grid>
</autopilot-grid>

<router-outlet></router-outlet>
