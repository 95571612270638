import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { ListOrganizationViewModel, ListOrganizationsCommand } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { CachedDataService, ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss']
})
export class OrganizationsComponent extends EntityBaseComponent implements OnInit {
  constructor(
    private logger: NGXLogger,
    public cachedDataService: CachedDataService,
    private route: ActivatedRoute,
    private router: Router,
    private impersonationService: ImpersonationService
  ) {
    super(EntityGlobals.ORGANIZATIONS, router, route, impersonationService, logger);
    this.cachedDataService.organizations.subscribe((data: ListOrganizationViewModel[]) => {
      this.logger.debug('Organizations from the DB');
      this.logger.debug(data);
      this.view = {
        data: data,
        total: data.length
      };
    });
  }

  organizations: ListOrganizationViewModel[] = [];
  listOrganizationsCommand: ListOrganizationsCommand = {};
  public view: GridDataResult;
  public override state: State = {
    skip: 0,
    take: 100,
    sort: [{ dir: 'desc', field: 'lastUpdated' }],
    filter: {
      logic: 'and',
      filters: []
    }
  };

  public dataStateChange(state: DataStateChangeEvent): void {
    this.logger.debug(state);
    this.state = state;
  }

  ngOnInit() {}

  override routeToEditorTemplate(id) {
    this.router.navigate(['/organizations/' + id]);
  }
}
