import { Injectable } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, publishReplay, refCount } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ImpersonationService {
  public readonly organizationIdKeyName = 'OrganizationId';
  public readonly organizationNameKeyName = 'OrganizationName';
  public readonly impersonationPrefix = 'SOS_Impersonation_';

  constructor(private localStorage: LocalStorage, private logger: NGXLogger) {
    this.localStorage.getItem<number>(this.impersonationPrefix + this.organizationIdKeyName, { type: 'number' }).subscribe(value => {
      if (value !== null) {
        this._organizationId.next(value);
      }
    });

    this.localStorage.getItem<number>(this.impersonationPrefix + this.organizationNameKeyName, { type: 'string' }).subscribe(value => {
      if (value !== null) {
        this._organizationName.next(value);
      }
    });
  }

  private _organizationId: BehaviorSubject<number> = new BehaviorSubject(-1);
  public readonly organizationId: Observable<number> = this._organizationId.asObservable().pipe(publishReplay(1), refCount());

  private _organizationName: BehaviorSubject<string> = new BehaviorSubject('');
  public readonly organizationName: Observable<string> = this._organizationName.asObservable().pipe(publishReplay(1), refCount());

  public async impersonateOrganization(organizationId: number, organizationName: string) {
    this.localStorage.setItem(this.impersonationPrefix + this.organizationIdKeyName, organizationId).subscribe(() => {
      this.logger.debug('ImpersonationService setting OrganizationId to ' + organizationId);
      this._organizationId.next(organizationId);
    });
    this.localStorage.setItem(this.impersonationPrefix + this.organizationNameKeyName, organizationName).subscribe(() => {
      this.logger.debug('ImpersonationService setting OrganizationName to ' + organizationName);
      this._organizationName.next(organizationName);
    });
  }

  public async isImpersonatingOrganization() {
    return this.localStorage
      .getItem<number>(this.impersonationPrefix + this.organizationIdKeyName)
      .pipe(
        map(organizationId => {
          if (organizationId == -1 || organizationId == null) {
            return false;
          } else {
            return true;
          }
        })
      )
      .toPromise();
  }

  public async getImpersonatedOrganizationId() {
    return this.localStorage
      .getItem<number>(this.impersonationPrefix + this.organizationIdKeyName)
      .pipe(
        map(organizationId => {
          return organizationId;
        })
      )
      .toPromise();
  }

  public async getImpersonatedOrganizationName() {
    return this.localStorage
      .getItem<string>(this.impersonationPrefix + this.organizationNameKeyName)
      .pipe(
        map(organizationName => {
          return organizationName;
        })
      )
      .toPromise();
  }

  public async getOrganizationImpersonationObject() {
    let organizationId = 0;
    let organizationName = '';

    await this.getImpersonatedOrganizationId().then(value => {
      organizationId = value as number;
    });

    await this.getImpersonatedOrganizationName().then(value => {
      organizationName = value as string;
    });

    if (organizationId !== -1 && organizationName !== '') {
      return {
        organizationId,
        organizationName
      };
    } else {
      return null;
    }
  }

  public async stopImpersonization() {
    this.logger.debug('Stopping Impersonation');
    await this.localStorage.removeItem(this.impersonationPrefix + this.organizationIdKeyName).subscribe(() => {
      this._organizationId.next(-1);
    });
    await this.localStorage.removeItem(this.impersonationPrefix + this.organizationNameKeyName).subscribe(() => {
      this._organizationName.next('');
    });
  }
}
