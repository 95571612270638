/**
 * Wingman Operations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Gender = 'Female' | 'Male' | 'Non-Binary' | 'N/A';

export const Gender = {
    Female: 'Female' as Gender,
    Male: 'Male' as Gender,
    NonBinary: 'Non-Binary' as Gender,
    NA: 'N/A' as Gender
};

