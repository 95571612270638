import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import {
  DrugAPISearchViewModel,
  DrugService,
  GetDrugDeliveryMethodTypesCommandResult,
  LabRequestDrugService,
  ListLabRequestDrugsCommand,
  ListLabRequestDrugsCommandResult,
  SearchForDrugCommandResult,
  UpsertLabRequestDrugItemsCommand,
  UpsertLabRequestDrugItemsCommandResult
} from '@wo-api/index';
import { NGXLogger } from 'ngx-logger';
import { debounceTime, tap } from 'rxjs/operators';

@Component({
  selector: 'app-drug-list-builder',
  templateUrl: './drug-list-builder.component.html',
  styleUrls: ['./drug-list-builder.component.scss'],
})
export class DrugListBuilderComponent
  implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('drugSearchList') drugSearchList: AutoCompleteComponent;
  @Input() organizationId: any;
  @Input() labRequestId: any;
  drugSearchView: DrugAPISearchViewModel[] = [];
  drugList: any[] = [];
  drugDeliveryMethods: any[];
  public events: string[][] = [[], []];
  constructor(
    private logger: NGXLogger,
    private drugService: DrugService,
    private labRequestDrugService: LabRequestDrugService
  ) {
    this.getViewData();
  }

  ngAfterViewInit() {
    this.drugSearchList.filterChange
      .asObservable()
      //.switchMap(value => value)
      .pipe(
        tap(() => (this.drugSearchList.loading = true)),
        debounceTime(400)
      )
      .subscribe((searchTerm) => {
        if (searchTerm.length === 0) {
          this.drugSearchView = [];
          this.drugSearchList.loading = false;
        } else if (searchTerm.length > 4) {
          this.logger.debug('Searching for Drug with search term: ' + searchTerm);
          this.drugService
            .search({
              drugName: searchTerm,
              manufacturerName: null,
              //drugNameType: SearchForDrugCommand.DrugNameTypeEnum.Generic,
            })
            .subscribe((result: SearchForDrugCommandResult) => {
              this.logger.debug(result);
              if(result && result.data){
                this.drugSearchView = result.data;
                this.drugSearchList.loading = false;
              }
            });
        } else {
          this.logger.error('You must enter more than 3 characters.');
        }
      });
  }

  /**Method used when the autocomplete value changes */
  drugSearchListValueChange(value: string): void {
    const newDrug = this.createDrug(null, '', '', value, '', '', '', 1, '');
    this.drugList.push(newDrug);
    this.recalculateSortOrders();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['labRequestId']) {
      this.labRequestId = changes['labRequestId'].currentValue;
    }
    if (changes['organizationId']) {
      this.organizationId = changes['organizationId'].currentValue;
    }
    this.getLabRequestDrugs();
  }

  getLabRequestDrugs(): void {
    const listLabRequestRoomsCommand: ListLabRequestDrugsCommand = {};
    listLabRequestRoomsCommand.labRequestId = this.labRequestId;
    this.labRequestDrugService
      .list(this.labRequestId, listLabRequestRoomsCommand)
       .subscribe((result: ListLabRequestDrugsCommandResult) => {
         if(result && result.data){
          this.drugList = result.data;
         }
      });
  }

  getViewData() {
    this.drugService
      .deliveryMethodTypes()
       .subscribe((result: GetDrugDeliveryMethodTypesCommandResult) => {
        if(result && result.data){
          this.drugDeliveryMethods = result.data;
        }
      });
  }

  ngOnInit() {}

  addDrug() {
    const newDrug = this.createDrug(null, '', '', '', '', '', '', 1, '');
    this.drugList.push(newDrug);
  }

  deleteRow(index) {
    this.drugList.splice(index, 1);
  }

  saveDrugs() {
    this.recalculateSortOrders();
    this.logger.debug(this.drugList);
    const command: UpsertLabRequestDrugItemsCommand = {};
    command.labRequestId = this.labRequestId;
    command.items = this.drugList;
    this.labRequestDrugService
      .upsertItems(this.labRequestId, command)
       .subscribe((result: UpsertLabRequestDrugItemsCommandResult) => {
        this.logger.debug(result);
        if(result && result.data){
          this.drugList = result.data;
        }
      });
  }

  createDrug(
    id,
    sortOrder,
    brandName,
    genericName,
    drugDeliveryMethodId,
    customDeliveryMethod,
    doseLabel,
    quantity,
    notes
  ) {
    return {
      id: id,
      sortOrder: sortOrder,
      brandName: brandName,
      genericName: genericName,
      drugDeliveryMethodId: drugDeliveryMethodId,
      customDeliveryMethod: customDeliveryMethod,
      doseLabel: doseLabel,
      quantity: quantity,
      notes: notes,
    };
  }

  recalculateSortOrders() {
    this.logger.debug(this.drugList);
    let index = 0;
    for (const drug of this.drugList) {
      drug.sortOrder = index;
      index++;
    }
  }

  onDrugDeliveryMethodChange(drug, method) {
    drug.drugDeliveryMethodId = method.value;
  }

  public onDataAdd(e: any): void {
    this.recalculateSortOrders();
  }

  public onDataRemove(e: any): void {
    this.recalculateSortOrders();
  }

  public onDragEnd(e: any): void {
    this.recalculateSortOrders();
  }

  public onDragOver(e: any): void {}

  public onDragStart(e: any): void {}
}
