import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import {
  BedAvailabilityViewModel,
  BedService,
  CreateLabRequestRoomCommand,
  CreateLabRequestRoomCommandResult,
  GetBedAvailabilityCommand,
  GetBedAvailabilityCommandResult,
  LabRequestRoomBedService,
  LabRequestRoomService,
  LabRequestRoomViewModel,
  ListBedsCommandViewModel,
  ListLabRequestRoomsCommand,
  ListLabRequestRoomsCommandResult,
  RoomService,
  RoomViewModel,
  UpdateLabRequestRoomCommand,
  UpdateLabRequestRoomCommandResult,
  UpsertLabRequestRoomBedCommand
} from '@wo-api/index';
import { CachedDataService } from '@wo-app/core/services';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-room-list-builder',
  templateUrl: './room-list-builder.component.html',
  styleUrls: ['./room-list-builder.component.scss']
})
export class RoomListBuilderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() labRequestId: any;
  listData: any[] = [];
  selectedRoom: RoomViewModel = {};
  filteredRoomItems: RoomViewModel[] = [];
  rooms: RoomViewModel[] = [];
  beds: ListBedsCommandViewModel[] = [];
  unavailableRooms: RoomViewModel[] = [];
  availableRooms: RoomViewModel[] = [];
  labRequestRooms: LabRequestRoomViewModel[] = [];
  bedsForRooms: BedAvailabilityViewModel[][] = [];
  public defaultItem: any = {
    id: -1,
    name: 'Select a Room'
  };
  public destroy$ = new Subject<void>();

  constructor(
    private logger: NGXLogger,
    private roomService: RoomService,
    private bedService: BedService,
    public cachedDataService: CachedDataService,
    private labRequestRoomBedService: LabRequestRoomBedService,
    private labRequestRoomService: LabRequestRoomService
  ) {
    this.cachedDataService.rooms.pipe(takeUntil(this.destroy$)).subscribe((rooms: RoomViewModel[]) => {
      this.rooms = rooms;
      this.logger.debug(rooms);
      //this.updateAvailableRooms();
    });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  ngOnInit() {
    this.getLabRequestRooms();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.logger.debug('Changes');
    if (changes['labRequestId']) {
      this.labRequestId = changes['labRequestId'].currentValue;
    }
    //this.getLabRequestRooms();
  }

  selectedRoomValueChange(row, room: RoomViewModel): void {
    this.selectedRoom = room;
  }

  getLabRequestRooms(): void {
    const listLabRequestRoomsCommand: ListLabRequestRoomsCommand = {};
    listLabRequestRoomsCommand.labRequestId = this.labRequestId;
    this.labRequestRoomService.list(this.labRequestId, listLabRequestRoomsCommand).subscribe((result: ListLabRequestRoomsCommandResult) => {
      this.logger.debug(result);
      if (result && result.data) {
        this.labRequestRooms = result.data;
        this.labRequestRooms.forEach(element => {
          let cmd: GetBedAvailabilityCommand = {
            labRequestId: this.labRequestId,
            labRequestRoomId: element.id
          };
          this.labRequestRoomBedService.getBedAvailability(cmd).subscribe((getBedsResult: GetBedAvailabilityCommandResult) => {
            this.logger.debug(getBedsResult);
            if (getBedsResult && getBedsResult.beds) this.bedsForRooms.push(getBedsResult.beds);
            //this.selectedRoom.beds = result.beds;
            //this.filteredRoomItems.push(this.selectedRoom);
            //this.updateAvailableRooms();
            //this.selectedRoom = this.defaultItem;
          });
        });
        //this.filterLabRequestRooms();
        //this.updateAvailableRooms();
      }
    });
  }

  addNewLabRequestRoom(): void {
    if (this.rooms.length > 0 && this.selectedRoom.id > 0) {
      let foundLabRequestRoom = this.labRequestRooms.filter(x => x.roomId === this.selectedRoom.id);
      if (foundLabRequestRoom.length > 0) {
        this.logger.debug('Do not add this room again to the lab request');
        //TODO: Scroll the item in the list builder
      } else {
        let cmd: GetBedAvailabilityCommand = {
          labRequestId: this.labRequestId,
          roomId: this.selectedRoom.id
        };
        this.labRequestRoomBedService.getBedAvailability(cmd).subscribe((result: GetBedAvailabilityCommandResult) => {
          this.logger.debug(result);
          if (result && result.beds) {
            this.bedsForRooms.push(result.beds);
            //this.selectedRoom.beds = result.beds;
            //this.filteredRoomItems.push(this.selectedRoom);
            //this.updateAvailableRooms();
            //this.selectedRoom = this.defaultItem;
          }
        });
      }
    }
  }

  public onAfterValueChange(bed: BedAvailabilityViewModel, value: any): void {
    this.logger.debug('Debounced Checkbox Value: ' + value);
    this.logger.debug(bed);
    let cmd: UpsertLabRequestRoomBedCommand = {
      scheduled: bed.scheduled,
      labRequestRoomBedId: bed.labRequestRoomBedId,
      labRequestRoomId: bed.labRequestRoomId,
      bedId: bed.bedId,
      roomId: bed.roomId,
      bedCustomId: bed.bedCustomId
    };
    this.labRequestRoomBedService.create(cmd).subscribe(result => {
      this.logger.debug(result);
      this.logger.debug('Bed has been scheduled.');
    });
    /*.catch(x => {
      bed.scheduled = !bed.scheduled;
    })*/
  }

  //Gets the LabRequestRooms for this Lab Request
  filterLabRequestRooms(): void {
    const array = this.labRequestRooms;
    for (let x = 0; x < array.length; x++) {
      //this.filteredRoomItems.push(array[x]);
    }
  }

  createRow(id: number, roomId: number, name: string): any {
    return {
      id,
      roomId,
      name
    };
  }

  //Adds a new lab Request room to the
  removeLabRequestRoomRow(row): void {
    this.labRequestRoomService._delete(this.labRequestId, row.id).subscribe(result => {
      let index = this.filteredRoomItems.indexOf(row);
      this.filteredRoomItems.splice(index, 1);
      this.updateAvailableRooms();
    });
  }

  roomValueChange(row, room: RoomViewModel): void {
    this.logger.debug(row, room);
    if (row.id < 0) {
      const command: CreateLabRequestRoomCommand = {};
      command.roomId = room.id;
      command.labRequestId = this.labRequestId;
      this.labRequestRoomService.create(this.labRequestId, command).subscribe((result: CreateLabRequestRoomCommandResult) => {
        this.logger.debug(result);
        this.updateAvailableRooms();
      });
    } else {
      const command: UpdateLabRequestRoomCommand = {};
      command.roomId = row.roomId;
      command.labRequestId = this.labRequestId;
      this.labRequestRoomService.update(this.labRequestId, row.id, command).subscribe((result: UpdateLabRequestRoomCommandResult) => {
        this.logger.debug(result);
        this.updateAvailableRooms();
      });
    }
  }

  updateAvailableRooms(): void {
    const allRoomIds = [];
    this.rooms.forEach(room => {
      allRoomIds.push(room.id);
    });

    this.logger.debug('AllRoomIds = ', allRoomIds);

    const unavailableRoomIds = [];
    this.filteredRoomItems.forEach(item => {
      if (item.id > -1) {
        unavailableRoomIds.push(item.id);
      }
    });

    const availableRoomIds = allRoomIds.filter(item => unavailableRoomIds.indexOf(item) < 0);
    this.availableRooms = [];
    availableRoomIds.forEach(id => {
      const foundItem = this.rooms.find(room => room.id === id);
      this.availableRooms.push(foundItem);
    });
    this.logger.debug(this.availableRooms);
  }
}
