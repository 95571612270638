<div class="row">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-body">
                <table class="table">
                    <tbody>
                        <tr>
                            <td>Create Date</td>
                            <td>{{metadata.created | amCalendar}}</td>
                        </tr>
                        <tr>
                            <td>Created by User Id</td>
                            <td>{{metadata.createdByUserId}}</td>
                        </tr>
                        <tr>
                            <td>Created by Username</td>
                            <td>{{metadata.createByUsername}}</td>
                        </tr>
                        <tr>
                            <td>Last Updated Date</td>
                            <td>{{metadata.lastUpdated | amCalendar}}</td>
                        </tr>
                        <tr>
                            <td>Last Updated by User Id</td>
                            <td>{{metadata.lastUpdatedByUserId}}</td>
                        </tr>
                        <tr>
                            <td>Last Updated by Username</td>
                            <td>{{metadata.lastUpdatedByUsername}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>