/**
 * Wingman Operations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TrainerType = 'Anatomy' | 'Airway Management' | 'Auscultation' | 'Breast Exam' | 'Blood Pressure' | 'Canine' | 'CPR' | 'Dental' | 'Injection' | 'Intravenous' | 'Obstetrics' | 'Gynecological' | 'Obesity and Edema' | 'Resuscitation' | 'Surgical' | 'Suture' | 'Trauma' | 'Urinary / Catheter' | 'Ultrasound' | 'WoundCare' | 'Wearable Task' | 'Virtual';

export const TrainerType = {
    Anatomy: 'Anatomy' as TrainerType,
    AirwayManagement: 'Airway Management' as TrainerType,
    Auscultation: 'Auscultation' as TrainerType,
    BreastExam: 'Breast Exam' as TrainerType,
    BloodPressure: 'Blood Pressure' as TrainerType,
    Canine: 'Canine' as TrainerType,
    Cpr: 'CPR' as TrainerType,
    Dental: 'Dental' as TrainerType,
    Injection: 'Injection' as TrainerType,
    Intravenous: 'Intravenous' as TrainerType,
    Obstetrics: 'Obstetrics' as TrainerType,
    Gynecological: 'Gynecological' as TrainerType,
    ObesityAndEdema: 'Obesity and Edema' as TrainerType,
    Resuscitation: 'Resuscitation' as TrainerType,
    Surgical: 'Surgical' as TrainerType,
    Suture: 'Suture' as TrainerType,
    Trauma: 'Trauma' as TrainerType,
    UrinaryCatheter: 'Urinary / Catheter' as TrainerType,
    Ultrasound: 'Ultrasound' as TrainerType,
    WoundCare: 'WoundCare' as TrainerType,
    WearableTask: 'Wearable Task' as TrainerType,
    Virtual: 'Virtual' as TrainerType
};

