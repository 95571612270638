import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@wo-api/index';
import { NGXLogger } from 'ngx-logger';
import { EMPTY, Observable, catchError, throwError } from 'rxjs';
import { ToastService } from '../common/toast-message/shared/services';

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {
  constructor(private toastService: ToastService, private logger: NGXLogger) {}
  public readonly DefaultToastTimeoutInSeconds: number = 10;

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.logger.debug('Request being called', request);
    let isRequestToGetTokenBeforeTokenEndpointConfigIsUpdated = this.isRequestToGetTokenBeforeTokenEndpointConfigIsUpdated(request);
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        this.logger.debug('Error logged in ErrorCatchingInterceptor', response);
        let error = response.error;
        //If the error is a ApiResponse, then show the error information in a toast message
        if (<ApiResponse>error !== undefined) {
          this.handleApiResponseError(response);
          return EMPTY;
        } else {
          if (!isRequestToGetTokenBeforeTokenEndpointConfigIsUpdated) {
            this.toastService.error('Application Error', 'There was an error fulfilling your request. Please try again.');
          }
          return throwError(() => new Error(response.message));
        }
      })
    );
  }

  isRequestToGetTokenBeforeTokenEndpointConfigIsUpdated(request: HttpRequest<unknown>): boolean {
    if (request.url.toLowerCase() == `token`) {
      return true;
    }
    return false;
  }

  handleApiResponseError(error: HttpErrorResponse): any {
    let apiResponseError = <ApiResponse>error.error;
    this.logger.debug('ApiResponse Error', apiResponseError);
    this.toastService.showApplicationError(apiResponseError);
    return throwError(() => new Error(error.error));
  }
}
