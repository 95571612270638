import { RoomService } from '@wo-api/index';
import { EntityGlobals, MenuGroupType } from '@wo-app/app.global';
import { RoomDetailComponent } from '@wo-app/components/dashboard/rooms/room-detail/room-detail.component';
import { RoomInventoryListComponent } from '@wo-app/components/dashboard/rooms/room-inventory-list/room-inventory-list.component';
import { RoomListBuilderComponent } from '@wo-app/components/dashboard/rooms/room-list-builder/room-list-builder.component';
import { RoomsComponent } from '@wo-app/components/dashboard/rooms/rooms/rooms.component';
import { ImpersonatingOrganizationCanActivate } from '@wo-app/core/guards';
import { MenuGroupListingItem } from '@wo-app/shared/models/menu';
import { RouteChild, RouteParent } from '@wo-app/shared/models/route';

export class RoomsRegistration {
  static Routes: RouteParent = {
    path: EntityGlobals.ROOMS.baseRoute,
    canActivate: [ImpersonatingOrganizationCanActivate],
    entityInformation: EntityGlobals.ROOMS,
    children: [
      {
        path: '',
        component: RoomsComponent,
        data: {
          breadcrumb: 'View All ' + EntityGlobals.ROOMS.pluralName,
          menuGroups: [new MenuGroupListingItem(0, 2, MenuGroupType.PhysicalSpaces, EntityGlobals.ROOMS.pluralName)],
          entityInformation: EntityGlobals.ROOMS
        }
      } as RouteChild,
      {
        path: 'view-inventory/:id',
        component: RoomInventoryListComponent,
        data: {
          breadcrumb: 'View Inventory for ' + EntityGlobals.ROOMS.singleName,
          entityInformation: EntityGlobals.ROOMS
        }
      } as RouteChild,
      {
        path: 'edit/:id',
        component: RoomDetailComponent,
        data: {
          breadcrumb: 'Edit ' + EntityGlobals.ROOMS.singleName,
          entityInformation: EntityGlobals.ROOMS
        }
      } as RouteChild,
      {
        path: 'add',
        component: RoomDetailComponent,
        data: {
          breadcrumb: 'Add ' + EntityGlobals.ROOMS.singleName,
          entityInformation: EntityGlobals.ROOMS
        }
      } as RouteChild
    ]
  };

  static Services: any = [RoomService];

  static Components: any = [RoomsComponent, RoomDetailComponent, RoomListBuilderComponent, RoomInventoryListComponent];
}
