/**
 * Wingman Operations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ManikinType = 'Premie' | 'Newborn' | 'Toddler' | 'Pedi' | 'Teen' | 'Adult' | 'Birthing' | 'Geriatric';

export const ManikinType = {
    Premie: 'Premie' as ManikinType,
    Newborn: 'Newborn' as ManikinType,
    Toddler: 'Toddler' as ManikinType,
    Pedi: 'Pedi' as ManikinType,
    Teen: 'Teen' as ManikinType,
    Adult: 'Adult' as ManikinType,
    Birthing: 'Birthing' as ManikinType,
    Geriatric: 'Geriatric' as ManikinType
};

