/**
 * Wingman Operations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TimesheetEntryType = 'Task' | 'Scenario' | 'Course' | 'Room' | 'Building';

export const TimesheetEntryType = {
    Task: 'Task' as TimesheetEntryType,
    Scenario: 'Scenario' as TimesheetEntryType,
    Course: 'Course' as TimesheetEntryType,
    Room: 'Room' as TimesheetEntryType,
    Building: 'Building' as TimesheetEntryType
};

