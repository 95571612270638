import { Validators } from "@angular/forms";

export const PasswordCharacterCountRangeRegex = /(.{8,99})/;
export const HasOneNumberRegex = /(.*\d.*)/;
export const HasOneUpperCaseLetterRegex = /(.*[A-Z].*)/;
export const HasOneLowerCaseLetterRegex = /(.*[a-z].*)/;
export const HasOneSpecialCharacterRegex = /(.*[\^\$\*\.\[\]\{\}\(\)\?\-\"\!\@\#\%\&\/\,\>\<\'\:\;\|\_\~\`\+\=].*)/;

export const PasswordCharacterCountRangeValidator = Validators.pattern(PasswordCharacterCountRangeRegex);
export const HasOneNumberValidator = Validators.pattern(HasOneNumberRegex);
export const HasOneUpperCaseLetterValidator = Validators.pattern(HasOneUpperCaseLetterRegex);
export const HasOneLowerCaseLetterValidator = Validators.pattern(HasOneLowerCaseLetterRegex);
export const HasOneSpecialCharacterValidator = Validators.pattern(HasOneSpecialCharacterRegex);
export const PasswordValidator = Validators.compose([HasOneNumberValidator, HasOneUpperCaseLetterValidator, HasOneLowerCaseLetterValidator, HasOneSpecialCharacterValidator]);
