<form [formGroup]="form" (ngSubmit)="onSubmit(false)" [hidden]="!showForm" class="entity-form">
    <app-validation-errors></app-validation-errors>
    <input type="hidden" id="id" formControlName="id" />
    <kendo-tabstrip>
        <kendo-tabstrip-tab [title]="'General'" [selected]="true">
            <ng-template kendoTabContent>
                <div class="row">
                    <div class="col-sm-12" [hidden]="editMode">
                        <button kendoButton type="submit" [primary]="true" [icon]="'save'">Create</button>
                    </div>
                    <div class="col-sm-12" [hidden]="!editMode">
                        <kendo-buttongroup>
                            <button kendoButton type="button" (click)="submitAndClose()" [primary]="true" [icon]="'save'">Save and Close</button>
                            <button kendoButton type="button" (click)="resetForm()" [primary]="false" [icon]="'cancel-circle'">Cancel</button>
                        </kendo-buttongroup>
                    </div>
                    <div class="col-sm-12" [hidden]="!editMode">
                        <div class="float-end">
                            <div kendoDialogContainer></div>
                        </div>
                    </div>
                    <div class="col-sm-12" [hidden]="true">
                        <kendo-buttongroup>
                            <button kendoButton type="button" [primary]="true" [icon]="'user'">View Users</button>
                        </kendo-buttongroup>
                    </div>
                    <div class="col-sm-12">
                        <hr/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="row">
                            <div class="col-sm-6">
                                <h3>Requestor Information</h3>
                            </div>
                            <div class="col-sm-6">
                                <div class="float-end">
                                    <div class=" col-md-12">
                                        <label for="description" class="col-form-label col-form-label-sm">Status</label>
                                        <kendo-dropdownlist class="form-control" [valuePrimitive]="true" formControlName="labRequestStatus" [data]="cachedDataService.labRequestStatuses | async" [textField]="'name'" [valueField]="'name'"></kendo-dropdownlist>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" col-md-4">
                        <label for="courseName" class="col-form-label col-form-label-sm">Course Name</label>
                        <input kendoTextBox type="text" formControlName="courseName" id="courseName" class="form-control" placeholder="Course Title" />
                    </div>
                    <div class=" col-md-4">
                        <label for="totalNumberOfStudents" class="col-form-label col-form-label-sm">Total Number of Students</label>
                        <kendo-numerictextbox class="form-control" [min]="1" [max]="240" [format]="'n0'"  type="text" formControlName="totalNumberOfStudents" id="totalNumberOfStudents" class="form-control" placeholder="Total Number of Students">
                        </kendo-numerictextbox>
                    </div>
                    <div class=" col-md-6">
                        <label for="rotatingThroughPeriodOfDates" class="col-form-label col-form-label-sm">Are you rotating groups through a period of dates?</label>
                        <kendo-switch formControlName="rotatingThroughPeriodOfDates" [onLabel]="'Y'" [offLabel]="'N'"></kendo-switch>
                    </div>
                    <div class=" col-md-6">
                        <label for="rotatingThroughPeriodOfDatesMaxStudents" class="col-form-label col-form-label-sm">Rotating Through Period Of Dates Max Students</label>
                        <kendo-numerictextbox class="form-control" [min]="1" [max]="240" [format]="'n0'"  type="text" formControlName="rotatingThroughPeriodOfDatesMaxStudents" id="rotatingThroughPeriodOfDatesMaxStudents" class="form-control" placeholder="Rotating Through Period Of Dates Max Students">
                        </kendo-numerictextbox>
                    </div>
                    <div class=" col-md-12">
                        <label for="totalNumberOfStudents" class="col-form-label col-form-label-sm">How many students will be a in a group in each scenario?</label>
                        <kendo-numerictextbox class="form-control" [min]="1" [max]="240" [format]="'n0'"  type="text" formControlName="numberOfStudentsInGroup" id="numberOfStudentsInGroup" class="form-control" placeholder="Number of Students in Group">
                        </kendo-numerictextbox>
                    </div>
                    <div class=" col-md-4">
                        <label for="requestorName" class="col-form-label col-form-label-sm">Requestor Name</label>
                        <input kendoTextBox type="text" formControlName="requestorName" id="requestorName" class="form-control" placeholder="Requestor Name" />
                    </div>
                    <div class=" col-md-4">
                        <label for="requestorEmail" class="col-form-label col-form-label-sm">Requestor Email</label>
                        <input kendoTextBox type="email" formControlName="requestorEmail" id="requestorEmail" class="form-control" placeholder="Requestor Email" />
                    </div>
                    <div class=" col-md-4">
                        <label for="requestorPhone" class="col-form-label col-form-label-sm">Requestor Phone</label>
                        <input kendoTextBox type="text" formControlName="requestorPhone" id="requestorPhone" class="form-control" placeholder="Requestor Phone" />
                    </div>
                </div>
                <hr/>
                <div class="row">
                    <div class="col-sm-12">
                        <h3>Lab Information</h3>
                    </div>
                    <div class=" col-md-12">
                        <app-multi-date-and-time-selector [data]="labRequestDatesAndTimes" formControlName="labRequestDatesAndTimes"></app-multi-date-and-time-selector>
                    </div>
                    <div class=" row">
                        <label for="numberOfSimRoomsRequested" class="col-sm-6 col-form-label">Number Of Sim Rooms Requested</label>
                        <div class="col-sm-6">
                            <kendo-numerictextbox class="form-control" [min]="1" [max]="240" [format]="'n0'"  type="text" formControlName="numberOfSimRoomsRequested" id="numberOfSimRoomsRequested" class="form-control" placeholder="Number Of Sim Rooms Requested">
                            </kendo-numerictextbox>
                        </div>
                    </div>
                    <div class=" row">
                        <label for="usingSimRoomsSimultaneously" class="col-sm-6 col-form-label">Use SIM Rooms simultaneously?</label>
                        <div class="col-sm-6">
                            <kendo-switch formControlName="usingSimRoomsSimultaneously" [onLabel]="'Yes'" [offLabel]="'No'"></kendo-switch>
                        </div>
                    </div>
                </div>
                <hr/>
                <div class="row">
                    <div class="col-sm-12">
                        <h3>Debrief and Live Streaming</h3>
                    </div>
                    <div class=" col-md-4">
                        <label for="liveStreamToDebriefingRoom" class="col-form-label col-form-label-sm">Live stream to debriefing room?</label>
                        <kendo-switch formControlName="liveStreamToDebriefingRoom" [onLabel]="'Yes'" [offLabel]="'No'"></kendo-switch>
                    </div>
                    <div class=" col-md-4">
                        <label for="numberOfPeopleLiveStreamingToDebriefingRoom" class="col-form-label col-form-label-sm">Number of People Live Streaming to Debriefing Room</label>
                        <kendo-numerictextbox class="form-control" [min]="1" [max]="240" [format]="'n0'"  type="text" formControlName="numberOfPeopleLiveStreamingToDebriefingRoom" id="numberOfPeopleLiveStreamingToDebriefingRoom" class="form-control"
                            placeholder="Number of People Live Streaming to Debriefing Room">
                        </kendo-numerictextbox>
                    </div>
                    <div class=" col-md-4">
                        <label for="numberOfPeopleToDebrief" class="col-form-label col-form-label-sm">Number of People to Debrief</label>
                        <kendo-numerictextbox class="form-control" [min]="1" [max]="240" [format]="'n0'"  type="text" formControlName="numberOfPeopleToDebrief" id="numberOfPeopleToDebrief" class="form-control" placeholder="Number of People to Debrief">
                        </kendo-numerictextbox>
                    </div>
                    <div class=" col-md-4">
                        <label for="numberOfSimRoomsNeedingRecording" class="col-form-label col-form-label-sm">Number Of Sim Rooms Needing Recording</label>
                        <kendo-numerictextbox class="form-control" [min]="1" [max]="240" [format]="'n0'"  type="text" formControlName="numberOfSimRoomsNeedingRecording" id="numberOfSimRoomsNeedingRecording" class="form-control" placeholder="Total Number of Students">
                        </kendo-numerictextbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <h3>Request Informaton</h3>
                    </div>
                    <div class=" col-md-12">
                        <label for="description" class="col-form-label col-form-label-sm">Description</label>
                        <textarea kendoTextBox type="text" formControlName="description" id="description" class="form-control" placeholder="Description"></textarea>
                    </div>
                    <div class=" col-md-12">
                        <label for="learningObjectives" class="col-form-label col-form-label-sm">Learning Objectives</label>
                        <textarea kendoTextBox type="text" formControlName="learningObjectives" id="learningObjectives" class="form-control" placeholder="Learning Objectives"></textarea>
                    </div>
                    <div class=" col-md-12">
                        <label for="roomSetting" class="col-form-label col-form-label-sm">Room Setting</label>
                        <input kendoTextBox type="text" formControlName="roomSetting" id="roomSetting" class="form-control" placeholder="Room Setting">
                    </div>
                    <div class=" col-md-12">
                        <label for="lengthOfScenarioInMinutes" class="col-form-label col-form-label-sm">Length of Scenario in Minutes</label>
                        <kendo-numerictextbox class="form-control" [min]="1" [max]="240" [format]="'n0'"  formControlName="lengthOfScenarioInMinutes"></kendo-numerictextbox>
                    </div>
                    <div class=" col-md-12">
                        <label for="patientMonitorDisplay" class="col-form-label col-form-label-sm">Patient Monitor Display</label>
                        <input kendoTextBox type="text" formControlName="patientMonitorDisplay" id="patientMonitorDisplay" class="form-control" placeholder="Patient Monitor Display">
                    </div>
                    <div class=" col-md-12">
                        <label for="specialInstructions" class="col-form-label col-form-label-sm">Special Instructions</label>
                        <textarea kendoTextBox type="text" formControlName="specialInstructions" id="specialInstructions" class="form-control" placeholder="Special Instructions"></textarea>
                    </div>
                    <input formControlName="organizationId" type="hidden">
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Rooms and Beds'" [disabled]="!editMode">
            <ng-template kendoTabContent>
                <app-room-list-builder [labRequestId]="id"></app-room-list-builder>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Patients'" [disabled]="!editMode">
            <ng-template kendoTabContent>
                <app-patient-list-builder (patientListNotification)='onNotificationOfPatients($event)' [patients]="patients"></app-patient-list-builder>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Drugs'" [disabled]="!editMode">
            <ng-template kendoTabContent>
                <app-drug-list-builder [labRequestId]="id"></app-drug-list-builder>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Supplies'" [disabled]="!editMode">
            <ng-template kendoTabContent>
                <app-inventory-item-list-builder [inventoryItemTypeId]='0' [labRequestId]="id" [emitToParentComponent]="false"></app-inventory-item-list-builder>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Equipments'" [disabled]="!editMode">
            <ng-template kendoTabContent>
                <app-inventory-item-list-builder [inventoryItemTypeId]="1" [labRequestId]="id" [emitToParentComponent]="false"></app-inventory-item-list-builder>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Metadata'" [disabled]="!editMode">
            <ng-template kendoTabContent>
                <app-metadata [metadata]="dataModel"></app-metadata>
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>
</form>