import { BedService } from '@wo-api/index';
import { EntityGlobals, MenuGroupType } from '@wo-app/app.global';
import { BedDetailComponent } from '@wo-app/components/dashboard/beds/bed-detail/bed-detail.component';
import { BedListingComponent } from '@wo-app/components/dashboard/beds/bed-listing/bed-listing.component';
import { ImpersonatingOrganizationCanActivate } from '@wo-app/core/guards';
import { MenuGroupListingItem } from '@wo-app/shared/models/menu';
import { RouteChild, RouteParent } from '@wo-app/shared/models/route';

export class BedsRegistration {
  static Routes: RouteParent = {
    path: EntityGlobals.BEDS.baseRoute,
    canActivate: [ImpersonatingOrganizationCanActivate],
    entityInformation: EntityGlobals.BEDS,
    children: [
      {
        path: '',
        component: BedListingComponent,
        data: {
          breadcrumb: 'View All ' + EntityGlobals.BEDS.pluralName,
          menuGroups: [new MenuGroupListingItem(0, 3, MenuGroupType.PhysicalSpaces, EntityGlobals.BEDS.pluralName)],
          entityInformation: EntityGlobals.BEDS
        }
      } as RouteChild,
      {
        path: 'edit/:id',
        component: BedDetailComponent,
        data: {
          breadcrumb: 'Edit ' + EntityGlobals.BEDS.singleName,
          entityInformation: EntityGlobals.BEDS
        }
      } as RouteChild,
      {
        path: 'add',
        component: BedDetailComponent,
        data: {
          breadcrumb: 'Add ' + EntityGlobals.BEDS.singleName,
          entityInformation: EntityGlobals.BEDS
        }
      } as RouteChild
    ]
  };

  static Services: any = [BedService];

  static Components: any = [BedListingComponent, BedDetailComponent];
}
