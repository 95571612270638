import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { State, orderBy } from '@progress/kendo-data-query';
import { CommunicationBoardItemService, ListCommunicationBoardItemsCommand } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
@Component({
  selector: 'app-communication-board-item-list',
  templateUrl: './communication-board-item-list.component.html',
  styleUrls: ['./communication-board-item-list.component.scss']
})
export class CommunicationBoardItemListComponent extends EntityBaseComponent implements OnInit {
  customGridData: any[] = [];
  tableHeaders: any[];
  tableRows: any[];
  gridData: any[];

  listCommunicationBoardItemsCommand: ListCommunicationBoardItemsCommand = {};
  public view: GridDataResult;
  public override state: State = {
    skip: 0,
    take: 100,
    sort: [{ dir: 'desc', field: 'lastUpdated' }],
    filter: {
      logic: 'and',
      filters: []
    }
  };

  constructor(
    private logger: NGXLogger,
    private impersonationService: ImpersonationService,
    private communicationBoardItemService: CommunicationBoardItemService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super(EntityGlobals.COMMUNICATION_BOARD, router, route, impersonationService, logger);
    this.OrganizationId.subscribe((value: number) => {
      this.listCommunicationBoardItemsCommand.skip = this.state.skip;
      this.listCommunicationBoardItemsCommand.take = this.state.take;
      this.listCommunicationBoardItemsCommand.sort = this.state.sort;
      this.communicationBoardItemService.list(this.listCommunicationBoardItemsCommand).subscribe((result: any) => {
        this.gridData = result.data;
        this.query();
      });
    });
  }

  ngOnInit() {}

  public convertTableCellValue(data) {
    if (typeof data === 'object') {
      return '';
    } else {
      return data;
    }
  }

  /*
  public setupTable() {
    this.tableHeaders = [], this.tableRows = [];
    this.tableHeaders.push('Scenario Start Date');
    this.tableHeaders.push('Course Number');
    this.tableHeaders.push('Course Title');

    const tasks = this.gridData[0].tasks;
    for (let x = 0; x < tasks.length; x++) {
      this.tableHeaders.push(tasks[x].name);
    }

    this.gridData.forEach(element => {
      const rowData = [];

      rowData.push(moment(element.scenarioStartDate).local().toString());
      rowData.push(element.courseTitle);
      for (let x = 0; x < tasks.length; x++) {
        rowData.push(tasks[x]);
      }
      this.tableRows.push(rowData);
    });
  }*/

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.query();
  }

  public query() {
    const sortedGridData = orderBy(this.gridData, this.state.sort ?? [{ dir: 'desc', field: 'lastUpdated' }]);
    this.view = {
      data: sortedGridData.slice(this.state.skip, (this.state.skip ?? 0) + this.state.take),
      total: sortedGridData.length
    };
  }
}
