<div class="my-3">
    <div *ngIf="this.ImageUrl !=null" class="card">
        <div class="card-body">
            <h5 class="card-title">Below is a preview of how your label will print.</h5>
            <img class="label mw-100" [src]="this.ImageUrl" alt="Preview of the printed label"  />
        </div>
    </div>
    <div *ngIf="this.ImageUrl == null && this.BypassPreviewImage == false">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
    </div>
</div>