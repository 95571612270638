import { PickOrderService } from '@wo-api/index';
import { EntityGlobals, MenuGroupType } from '@wo-app/app.global';
import { PickOrderDetailComponent } from '@wo-app/components/dashboard/pickorders/pick-order-detail/pick-order-detail.component';
import { PickOrderEquipmentGridComponent } from '@wo-app/components/dashboard/pickorders/pick-order-equipment-grid/pick-order-equipment-grid.component';
import { PickOrderEquipmentSelectionDialogComponent } from '@wo-app/components/dashboard/pickorders/pick-order-equipment-selection-dialog/pick-order-equipment-selection-dialog.component';
import { PickOrderListingComponent } from '@wo-app/components/dashboard/pickorders/pick-order-listing/pick-order-listing.component';
import { PickOrderManikinsGridComponent } from '@wo-app/components/dashboard/pickorders/pick-order-manikins-grid/pick-order-manikins-grid.component';
import { PickOrderManikinsSelectionDialogComponent } from '@wo-app/components/dashboard/pickorders/pick-order-manikins-selection-dialog/pick-order-manikins-selection-dialog.component';
import { PickOrderSuppliesGridComponent } from '@wo-app/components/dashboard/pickorders/pick-order-supplies-grid/pick-order-supplies-grid.component';
import { PickOrderSuppliesSelectionDialogComponent } from '@wo-app/components/dashboard/pickorders/pick-order-supplies-selection-dialog/pick-order-supplies-selection-dialog.component';
import { PickOrderItemFillRoomSelectionComponent } from '@wo-app/components/dashboard/pickorders/pick-order-item-fill-room-selection/pick-order-item-fill-room-selection.component';
import { PickOrderSupplyRoomSelectionDialogComponent } from '@wo-app/components/dashboard/pickorders/pick-order-supply-room-selection-dialog/pick-order-supply-room-selection-dialog.component';
import { PickOrderUserSelectionDialogComponent } from '@wo-app/components/dashboard/pickorders/pick-order-user-selection-dialog/pick-order-user-selection-dialog.component';
import { ImpersonatingOrganizationCanActivate } from '@wo-app/core/guards';
import { MenuGroupListingItem } from '@wo-app/shared/models/menu';
import { RouteChild, RouteParent } from '@wo-app/shared/models/route';

export class PickOrdersRegistration {
  static Routes: RouteParent = {
    path: EntityGlobals.PICKORDERS.baseRoute,
    canActivate: [ImpersonatingOrganizationCanActivate],
    entityInformation: EntityGlobals.PICKORDERS,
    children: [
      {
        path: '',
        component: PickOrderListingComponent,
        data: {
          breadcrumb: 'View All ' + EntityGlobals.PICKORDERS.pluralName,
          menuGroups: [new MenuGroupListingItem(0, 3, MenuGroupType.InventoryAndEquipment, EntityGlobals.PICKORDERS.pluralName)],
          entityInformation: EntityGlobals.PICKORDERS
        }
      } as RouteChild,
      {
        path: 'add',
        component: PickOrderDetailComponent,
        data: {
          breadcrumb: 'Add ' + EntityGlobals.PICKORDERS.singleName,
          entityInformation: EntityGlobals.PICKORDERS
        }
      } as RouteChild,
      {
        path: 'add/:id',
        component: PickOrderDetailComponent,
        data: {
          breadcrumb: 'Add ' + EntityGlobals.PICKORDERS.singleName,
          entityInformation: EntityGlobals.PICKORDERS
        }
      } as RouteChild,
      {
        path: 'edit/:id',
        component: PickOrderDetailComponent,
        data: {
          breadcrumb: 'Edit ' + EntityGlobals.PICKORDERS.singleName,
          entityInformation: EntityGlobals.PICKORDERS
        }
      } as RouteChild
    ]
  };

  static Services: any = [PickOrderService];

  static Components: any = [
    PickOrderListingComponent,
    PickOrderDetailComponent,
    PickOrderSuppliesSelectionDialogComponent,
    PickOrderSuppliesGridComponent,
    PickOrderEquipmentSelectionDialogComponent,
    PickOrderEquipmentGridComponent,
    PickOrderManikinsSelectionDialogComponent,
    PickOrderManikinsGridComponent,
    PickOrderUserSelectionDialogComponent,
    PickOrderSupplyRoomSelectionDialogComponent,
    PickOrderItemFillRoomSelectionComponent
  ];
}
