import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ValidationErrorsService } from './validation-errors.service';

@Component({
  selector: 'app-validation-errors',
  templateUrl: './validation-errors.component.html',
  styleUrls: ['./validation-errors.component.scss']
})
export class ValidationErrorsComponent implements OnInit {
  validationErrors: any[];
  subscription: Subscription;
  public validationErrorCount(): number {
    try {
      return this.validationErrors.length;
    } catch (e) {
      return 0;
    }
  }

  constructor(private validationErrorsService: ValidationErrorsService) {
    this.subscription = this.validationErrorsService.GetValidationErrors().subscribe(errors => {
      this.validationErrors = errors;
    });
  }

  ngOnInit() {}
}
