import { Component, OnInit, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { State } from '@progress/kendo-data-query';
import {
  DeleteTimesheetEntryCommand,
  EnumValue,
  GetTimeSheetEntriesForDateRangeCommand,
  GetTimeSheetEntriesForDateRangeCommandResult,
  ListBuildingsCommandViewModel,
  ListCourseViewModel,
  ListSkillsCommand,
  ListTasksViewModel,
  RoomViewModel,
  ScenarioViewModel,
  TimesheetDayGroupViewModel,
  TimesheetEntryMiniViewModel,
  TimesheetEntryStatus,
  TimesheetEntryType,
  TimesheetRowGroupViewModel,
  TimesheetService,
  UpsertTimesheetEntryCommand,
  UpsertTimesheetEntryCommandResult
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { CachedDataService, ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { Nullable } from '@wo-app/shared/models/nullable-type';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { IModalDialogOptions, ModalDialogService } from 'ngx-modal-dialog';
import { TimesheetCellModalComponent } from './timesheet-cell-modal/timesheet-cell-modal.component';

@Component({
  selector: 'app-timesheets',
  templateUrl: './timesheets.component.html',
  styleUrls: ['./timesheets.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TimesheetsComponent extends EntityBaseComponent implements OnInit {
  startOfWeek: Date;
  endOfWeek: Date;
  weekStartsOnSunday: boolean;
  listSkillsCommand: ListSkillsCommand = {};

  public timeAssignmentFieldIsDisabled: boolean;
  public scenarios: ScenarioViewModel[] = [];
  public tasks: ListTasksViewModel[] = [];
  public courses: ListCourseViewModel[] = [];
  public rooms: RoomViewModel[] = [];
  public buildings: ListBuildingsCommandViewModel[] = [];
  public gridData: TimesheetRowGroupViewModel[] = [];
  public timesheetEntryItems: EnumValue[] = [];
  public timesheetEntryTypes: EnumValue[] = [];
  public timesheetEntryStatuses: EnumValue[] = [];
  public columns: TimesheetColumn[];
  public footerTypeModel: any;
  public footerAssignmentModel: any;
  public timesheetEntryTypesDefaultItem: any = {
    value: '',
    name: 'Select a Type'
  };
  public timesheetEntryItemsDefaultItem: any = {
    value: -1,
    name: 'Select an Item'
  };
  public override state: State = {
    skip: 0,
    take: 100,
    sort: [{ dir: 'desc', field: 'lastUpdated' }],
    filter: {
      logic: 'and',
      filters: []
    }
  };
  public currentTypeValue: string;
  public TimesheetEntryTypeForeignKeyValue: -1;
  public UserId: string;
  constructor(
    private loadingBar: LoadingBarService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalDialogService,
    private viewRef: ViewContainerRef,
    private logger: NGXLogger,
    public cachedDataService: CachedDataService,
    private impersonationService: ImpersonationService,
    private timesheetService: TimesheetService
  ) {
    super(EntityGlobals.TIMESHEETS, router, route, impersonationService, logger);
    this.timeAssignmentFieldIsDisabled = true;
    this.currentTypeValue = '';
    this.cachedDataService.timesheetEntryTemporaryCache.subscribe((result: TimesheetEntryMiniViewModel[]) => {
      this.logger.debug('Modal was saved', result);
      this.processChildValueChange(result);
    });

    this.cachedDataService.timesheetEntryTemporaryRecycleBin.subscribe((entry: TimesheetEntryMiniViewModel) => {
      this.deleteTimesheetEntry(entry);
    });
    this.OrganizationId.subscribe((value: number) => {
      if (value !== null) {
        // TODO: Finish setting up the call to get the userid
        /*userId.subscribe((userId: string) => {
          this.logger.debug('UserId was updated to: ', userId);
          this.UserId = userId;
        });*/

        this.cachedDataService.timesheetEntryStatuses.subscribe((data: EnumValue[]) => {
          this.logger.debug('Timesheet Cached Data Update: timesheetEntryStatuses');
          this.timesheetEntryStatuses = data;
        });

        this.cachedDataService.timesheetEntryTypes.subscribe((data: EnumValue[]) => {
          this.logger.debug('Timesheet Cached Data Update: timesheetEntryTypes');
          this.timesheetEntryTypes = data;
        });

        this.cachedDataService.rooms.subscribe((data: RoomViewModel[]) => {
          this.logger.debug('Timesheet Cached Data Update: Rooms');
          this.rooms = data;
        });

        this.cachedDataService.buildings.subscribe((data: ListBuildingsCommandViewModel[]) => {
          this.logger.debug('Timesheet Cached Data Update: Buildings');
          this.buildings = data;
        });

        this.weekStartsOnSunday = true;
        this.goToThisWeek();
      }
    });
  }

  get isTodayButtonDisabled(): boolean {
    let startOfThisWeek = this.weekStartsOnSunday ? moment().startOf('isoWeek').toDate() : moment().startOf('week').toDate();
    return this.startOfWeek === startOfThisWeek;
  }

  deleteTimesheetEntry(entry: TimesheetEntryMiniViewModel): void {
    if (entry) {
      let cmd: DeleteTimesheetEntryCommand = {
        id: entry.id
      };
      this.timesheetService.deleteTimesheetEntry(cmd).subscribe(result => {
        this.findUpdatedOrDeletedEntry(entry, false);
      });
    }
  }

  ngOnInit() {}

  public getTimesheetAssignmentName(entryType: string, timesheetEntryTypeForeignKeyValue: number): string {
    if (entryType === 'Task') {
      // Tasks
      return this.tasks.find(x => x.id === timesheetEntryTypeForeignKeyValue).name;
    }
    if (entryType === 'Scenario') {
      // Scenarios
      return this.scenarios.find(x => x.id === timesheetEntryTypeForeignKeyValue).name;
    }
    if (entryType === 'Course') {
      // Courses
      return this.courses.find(x => x.id === timesheetEntryTypeForeignKeyValue).name;
    }
    if (entryType === 'Room') {
      // Rooms
      return this.rooms.find(x => x.id === timesheetEntryTypeForeignKeyValue).name;
    }
    if (entryType === 'Building') {
      // Buildings
      return this.buildings.find(x => x.id === timesheetEntryTypeForeignKeyValue).name;
    } else {
      throw new Error();
    }
  }

  convertToEntryTypeEnum(value: string): Nullable<TimesheetEntryType> {
    if (value === 'Task') {
      return TimesheetEntryType.Task;
    } else if (value === 'Scenario') {
      return TimesheetEntryType.Scenario;
    } else if (value === 'Course') {
      return TimesheetEntryType.Course;
    } else if (value === 'Room') {
      return TimesheetEntryType.Room;
    } else if (value === 'Building') {
      return TimesheetEntryType.Building;
    } else {
      return null;
    }
  }

  addNewRow(currentTypeValue: string, timesheetEntryTypeForeignKeyValue: number) {
    this.logger.debug('Adding New Row');
    let days: TimesheetDayGroupViewModel[] = [];
    let daysToCreate = moment(this.endOfWeek).diff(moment(this.startOfWeek), 'day');
    let entryType = this.convertToEntryTypeEnum(currentTypeValue);
    for (let x = 0; x <= daysToCreate; x++) {
      let newGroup: TimesheetDayGroupViewModel = {
        //dateOfEntry: moment(this.startOfWeek).add(x, 'day').toDate(),
        entries: [],
        entryStatus: TimesheetEntryStatus[currentTypeValue],
        entryType: entryType,
        timesheetEntryTypeForeignKeyValue: timesheetEntryTypeForeignKeyValue
      };
      days.push(newGroup);
    }

    let row: TimesheetRowGroupViewModel = {
      entryStatus: TimesheetEntryStatus.Created,
      entryType: entryType,
      timesheetEntryTypeForeignKeyValue: timesheetEntryTypeForeignKeyValue,
      rows: days
    };
    this.gridData.push(row);
    this.refreshFooterControllers();
  }

  public refreshFooterControllers() {
    this.TimesheetEntryTypeForeignKeyValue = -1;
    this.currentTypeValue = '';

    this.footerTypeModel = this.timesheetEntryTypesDefaultItem;
    this.footerAssignmentModel = this.timesheetEntryItemsDefaultItem;
    this.timesheetEntryItems = [];
    this.timeAssignmentFieldIsDisabled = true;
  }

  public handleEntryItemChange($event) {
    if ($event !== -1) {
      this.TimesheetEntryTypeForeignKeyValue = $event;
      this.addNewRow(this.currentTypeValue, this.TimesheetEntryTypeForeignKeyValue);
      this.refreshFooterControllers();
    }
  }

  findUpdatedOrDeletedEntry(entry: TimesheetEntryMiniViewModel, updateEntry: boolean): void {
    let filteredRows = this.gridData.filter(
      row =>
        row.assignedUserId === entry.assignedUserId &&
        row.entryStatus === entry.entryStatus &&
        row.entryType === entry.entryType &&
        row.timesheetEntryTypeForeignKeyValue === entry.timesheetEntryTypeForeignKeyValue
    );
    if (filteredRows.length > 0) {
      let dayGrouping = filteredRows[0].rows.filter(column => {
        return column.dateOfEntry === entry.dateOfEntry;
      });
      if (dayGrouping.length > 0) {
        let day = dayGrouping[0];
        let foundEntry = day.entries.find(currentEntry => {
          return currentEntry.id === entry.id || currentEntry.durationInMinutes === entry.durationInMinutes;
        });
        this.logger.debug(foundEntry);
        this.logger.debug('Replacing Upserted Entries with DB Entries');
        if (updateEntry === true) {
          foundEntry = entry;
        } else {
          day.entries.splice(day.entries.indexOf(foundEntry), 1);
        }
      } else {
        this.logger.error('Could not find Day Grouping');
      }
    } else {
      this.logger.error('Could not find filteredRow');
    }
  }

  public processChildValueChange($event: TimesheetEntryMiniViewModel[]) {
    if ($event.length > 0) {
      for (let x = 0; x < $event.length; x++) {
        $event[x].assignedUserId = this.UserId;
      }
      let upsertCmd: UpsertTimesheetEntryCommand = {
        dataToUpdate: $event
      };
      this.timesheetService.upsertTimesheetEntry(upsertCmd).subscribe((result: UpsertTimesheetEntryCommandResult) => {
        // ensure that the values get added back to the grid
        this.logger.debug('Entries were successfully upserted.');
        for (let x = 0; x < result.data.length; x++) {
          let entry = result.data[x];
          this.findUpdatedOrDeletedEntry(entry, true);
        }
      });
    }
  }

  public handleEntryTypeChange($event: string) {
    this.currentTypeValue = $event;
    this.logger.debug($event);
    let typeToGet = this.timesheetEntryTypes.find(type => type.name === $event);
    this.logger.debug(typeToGet);
    /*if (typeToGet.value === '') {
      this.timeAssignmentFieldIsDisabled = true;
    }*/
    if (typeToGet.name) {
      this.timeAssignmentFieldIsDisabled = false;
    }

    if (typeToGet.name === 'Room') {
      // Rooms
      this.logger.debug('Room was selected');
      this.logger.debug(this.rooms);
      this.timesheetEntryItems = this.rooms.map(function (vm) {
        let newItem: EnumValue = {
          value: vm.id,
          name: vm.name
        };
        return newItem;
      });
    } else if (typeToGet.name === 'Building') {
      // Buildings
      this.timesheetEntryItems = this.buildings.map(function (vm) {
        let newItem: EnumValue = {
          value: vm.id,
          name: vm.name
        };
        return newItem;
      });
    }
  }

  getEntriesForDay(rows: TimesheetDayGroupViewModel[], columnDate: Date): TimesheetDayGroupViewModel {
    if (rows === null || rows === undefined || rows.length === 0) return null;
    let momentColumnDate = moment(columnDate);
    let dayItems = rows.filter(row => moment(row.dateOfEntry).isSame(momentColumnDate, 'day'));
    if (dayItems.length > 0) {
      return dayItems[0];
    } else {
      return null;
    }
  }

  createColumns(startOfTheWeek: Date): TimesheetColumn[] {
    let arrayOfColumns: TimesheetColumn[] = [];
    for (let x = 0; x < 7; x++) {
      const date = moment(startOfTheWeek).add(x, 'd');
      arrayOfColumns.push(new TimesheetColumn(date.format('dddd D'), date.toDate()));
    }
    return arrayOfColumns;
  }

  getTimesheetEntriesForDateRange(startOfWeek: Date, endOfWeek: Date) {
    this.loadingBar.useRef('router').start();
    let cmd: GetTimeSheetEntriesForDateRangeCommand = {
      userId: this.UserId
      //startOfWeek: startOfWeek,
      //endOfWeek: endOfWeek,
    };
    this.logger.debug(cmd, this.organizationId);
    this.timesheetService.getTimeSheetEntriesForDateRange(cmd).subscribe((result: GetTimeSheetEntriesForDateRangeCommandResult) => {
      this.loadingBar.useRef('router').complete();
      if (result && result.data) {
        this.gridData = result.data;
      }
    });
  }

  goToThisWeek() {
    let startOfWeek = this.weekStartsOnSunday ? moment().startOf('isoWeek').toDate() : moment().startOf('week').toDate();
    let endOfWeek = this.weekStartsOnSunday ? moment().endOf('isoWeek').toDate() : moment().endOf('week').toDate();
    this.columns = this.createColumns(startOfWeek);
    this.getTimesheetEntriesForDateRange(startOfWeek, endOfWeek);
    this.startOfWeek = startOfWeek;
    this.endOfWeek = endOfWeek;
  }

  goToPreviousWeek() {
    let startOfWeek = moment(this.startOfWeek).subtract(7, 'd').toDate();
    let endOfWeek = moment(this.endOfWeek).subtract(7, 'd').toDate();
    this.columns = this.createColumns(startOfWeek);
    this.getTimesheetEntriesForDateRange(startOfWeek, endOfWeek);
    this.startOfWeek = startOfWeek;
    this.endOfWeek = endOfWeek;
  }

  goToNextWeek() {
    let startOfWeek = moment(this.startOfWeek).add(7, 'd').toDate();
    let endOfWeek = moment(this.endOfWeek).add(7, 'd').toDate();
    this.columns = this.createColumns(startOfWeek);
    this.getTimesheetEntriesForDateRange(startOfWeek, endOfWeek);
    this.startOfWeek = startOfWeek;
    this.endOfWeek = endOfWeek;
  }

  public dataForPopupChanged($event: TimesheetEntryMiniViewModel[]) {
    let title: string = moment($event[0].dateOfEntry).format('dddd, MMM D');
    let vm: TimesheetDayGroupViewModel = {
      dateOfEntry: $event[0].dateOfEntry,
      entries: $event
    };
    let options: Partial<IModalDialogOptions<TimesheetDayGroupViewModel>> = {
      title: title,
      data: vm,
      childComponent: TimesheetCellModalComponent
    };
    this.modalService.openDialog(this.viewRef, options);
  }
}

export class TimesheetColumn {
  public ColumnTitle: string;
  public ColumnDate: Date;
  constructor(columnTitle: string, columnDate: Date) {
    this.ColumnTitle = columnTitle;
    this.ColumnDate = columnDate;
  }
}
