import { LabMaintenanceTaskService } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { LabMaintenanceTaskDetailComponent } from '@wo-app/components/dashboard/labmaintenancetasks/labmaintenancetask-detail/labmaintenancetask-detail.component';
import { LabMaintenanceTasksComponent } from '@wo-app/components/dashboard/labmaintenancetasks/labmaintenancetasks/labmaintenancetasks.component';
import { ImpersonatingOrganizationCanActivate } from '@wo-app/core/guards';
import { RouteChild, RouteParent } from '@wo-app/shared/models/route';

export class LabMaintenanceTasksRegistration {
  static Routes: RouteParent = {
    path: EntityGlobals.LAB_MAINTENANCE_TASKS.baseRoute,
    canActivate: [ImpersonatingOrganizationCanActivate],
    entityInformation: EntityGlobals.LAB_MAINTENANCE_TASKS,
    children: [
      {
        path: '',
        component: LabMaintenanceTasksComponent,
        data: {
          breadcrumb: 'View All'
        }
      } as RouteChild,
      {
        path: 'edit/:id',
        component: LabMaintenanceTaskDetailComponent,
        data: {
          breadcrumb: 'Edit ' + EntityGlobals.LAB_MAINTENANCE_TASKS.singleName
        }
      } as RouteChild,
      {
        path: 'add',
        component: LabMaintenanceTaskDetailComponent,
        data: {
          breadcrumb: 'Add ' + EntityGlobals.LAB_MAINTENANCE_TASKS.singleName
        }
      } as RouteChild
    ]
  };

  static Services: any = [LabMaintenanceTaskService];

  static Components: any = [LabMaintenanceTasksComponent, LabMaintenanceTaskDetailComponent];
}
