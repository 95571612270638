import { MenuGroupType } from '@wo-app/app.global';
import { MenuItem } from './menu-item.model';
export class MenuGrouping {
  public GroupName: string;
  public MenuItems: MenuItem[];
  public HideIfImpersonatingOrganization: boolean;
  public MenuGroup: MenuGroupType;
  constructor(groupName: string, menuGroup: MenuGroupType, parentMenuItem: MenuItem[], hideIfImpersonatingOrganization: boolean) {
    this.GroupName = groupName;
    this.MenuGroup = menuGroup;
    this.MenuItems = parentMenuItem;
    this.HideIfImpersonatingOrganization = hideIfImpersonatingOrganization;
  }
}
