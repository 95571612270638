import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '@progress/kendo-angular-dialog';
import {
  EmailTemplatesService,
  ReadEmailTemplateCommand,
  ReadEmailTemplateCommandResult,
  SendEmailUsingEmailTemplateCommand,
  UpsertEmailTemplateCommand
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
declare var $: any;

@Component({
  selector: 'app-email-template-detail',
  templateUrl: './email-template-detail.component.html',
  styleUrls: ['./email-template-detail.component.scss']
})
export class EmailTemplateDetailComponent extends EntityBaseComponent implements OnInit {
  form: FormGroup;
  originalFormState: FormGroup;
  template: ReadEmailTemplateCommandResult;
  fromKey: string;
  constructor(
    private emailTemplateService: EmailTemplatesService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private impersonationService: ImpersonationService,
    private logger: NGXLogger,
    private toastService: ToastService
  ) {
    super(EntityGlobals.EMAIL_TEMPLATES, router, route, impersonationService, logger);
    this.createForm();
    this.fromKey = 'FormModel';
    if (this.editMode) {
      this.getData();
    } else {
      this.showForm = true;
    }
  }

  public SendTestEmail() {
    let cmd: SendEmailUsingEmailTemplateCommand = {
      templateName: this.form.controls['templateName'].value,
      organizationId: this.form.controls['organizationId'].value,
      username: this.form.controls['username'].value,
      toAddresses: [this.form.controls['email'].value]
    };
    this.emailTemplateService.sendEmailUsingEmailTemplate(cmd).subscribe(result => {
      this.logger.debug('Email was successfully queued.');
    });
  }

  resetForm() {
    this.logger.debug(this.originalFormState);
    this.form.reset(this.originalFormState.value);
    this.routeToEntityList();
  }

  ngOnInit() {}

  getData(): void {
    let cmd: ReadEmailTemplateCommand = {
      templateName: this.id
    };
    this.emailTemplateService.read(cmd).subscribe((result: ReadEmailTemplateCommandResult) => {
      this.convertServerDataToFormModel(result);
      this.showForm = true;
    });
  }

  convertServerDataToFormModel(result: ReadEmailTemplateCommandResult) {
    this.template = result;
    this.form.patchValue(this.template);

    if (this.editMode) {
      this.form.controls['templateName'].disable({ onlySelf: true });
    }

    if (this.originalFormState.pristine) {
      this.logger.debug('Setting Original Form State Value');
      this.originalFormState.patchValue(this.template);
    }
  }

  onSubmit(closeOnSuccess) {
    let cmd: UpsertEmailTemplateCommand;
    cmd = this.form.getRawValue();
    this.logger.debug(cmd);
    this.emailTemplateService.upsert(cmd).subscribe(result => {
      this.convertServerDataToFormModel(result);
      this.routeToEntityList();
      this.editMode = true;
      this.toastService.success('Success!', 'This item has been saved.');
    });
  }

  submitAndClose() {
    this.onSubmit(true);
  }
  createForm() {
    this.form = this.fb.group({
      templateName: [''],
      subjectPart: [''],
      textPart: [''],
      htmlPart: [''],
      organizationId: [''],
      username: [''],
      email: ['']
    });
    this.originalFormState = this.form;
  }
}
