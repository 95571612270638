import { Component, Input, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-metadata',
  templateUrl: './metadata.component.html',
  styleUrls: ['./metadata.component.scss']
})
export class MetadataComponent implements OnInit {
  @Input() metadata: any;
  constructor(private logger: NGXLogger) {}
  ngOnInit() {
    this.logger.debug('Metadata = ', this.metadata);
  }
}
