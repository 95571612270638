import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BreadcrumbComponent } from './breadcrumbs.component';
import { BreadcrumbsService } from './shared/services';

@NgModule({
  declarations: [BreadcrumbComponent],
  providers: [BreadcrumbsService],
  imports: [RouterModule, BrowserModule, CommonModule],
  exports: [BreadcrumbComponent]
})
export class BreadcrumbsModule {
  constructor() {}
}
