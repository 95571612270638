/**
 * Wingman Operations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TimesheetEntryStatus = 'Created' | 'Submitted' | 'Approved';

export const TimesheetEntryStatus = {
    Created: 'Created' as TimesheetEntryStatus,
    Submitted: 'Submitted' as TimesheetEntryStatus,
    Approved: 'Approved' as TimesheetEntryStatus
};

