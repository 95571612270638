import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InventoryRoomQrService implements OnDestroy {
  private readonly _validationMessage = new BehaviorSubject<string | null>(null);
  readonly validationMessage$ = this._validationMessage.asObservable();

  constructor() {}

  public setValidationMessage(message: string) {
    this._validationMessage.next(message);
  }

  public clearValidationMessage() {
    this._validationMessage.next(null);
  }

  public ngOnDestroy(): void {
    this._validationMessage.complete();
  }
}
