import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TaskService, TaskStatus, ToggleTaskCommand } from '@wo-api/index';

@Component({
  selector: 'app-task-check-box',
  templateUrl: './task-check-box.component.html',
  styleUrls: ['./task-check-box.component.scss']
})
export class TaskCheckBoxComponent implements OnInit, OnChanges {
  @Input() isChecked: boolean;
  @Input() taskId: number;
  @Input() dueDate: String;
  @Input() organizationId: number;
  @Input() taskName: string;
  @Input() labRequestName: string;
  constructor(private taskService: TaskService) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {}

  changeCheckboxValue() {
    const command: ToggleTaskCommand = {};
    command.id = this.taskId;
    command.taskStatus = this.isChecked ? TaskStatus.Completed : TaskStatus.Created;
    /*this.taskService.tasksTogglestatusPost(command) .subscribe((result: ToggleTaskCommandResult) => {

    });*/
  }
}
