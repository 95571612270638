import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpRequest } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { KendoModule } from '@kendo/kendo.module';
import {
  NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
  NbAuthJWTInterceptor,
  NbAuthModule,
  NbAuthOAuth2Token,
  NbAuthService,
  NbOAuth2AuthStrategy,
  NbOAuth2AuthStrategyOptions,
  NbOAuth2ClientAuthMethod,
  NbOAuth2GrantType,
  NbOAuth2ResponseType,
  NbTokenLocalStorage,
  NbTokenStorage
} from '@nebular/auth';
import { NbRoleProvider, NbSecurityModule } from '@nebular/security';
import { ConfigLoaderService, RoleProviderService } from '@wo-app/core/services';
import { NGXLogger } from 'ngx-logger';
import { AuthRoutingModule } from './auth-routing.module';
import { OAuth2CallbackComponent } from './components/oauth2-callback';
import { OAuth2LoginComponent } from './components/oauth2-login';
import { OAuth2LogoutComponent } from './components/oauth2-logout';

@NgModule({
  declarations: [OAuth2LoginComponent, OAuth2LogoutComponent, OAuth2CallbackComponent],
  imports: [
    CommonModule,
    KendoModule,
    HttpClientModule,
    NbAuthModule.forRoot({
      strategies: [
        NbOAuth2AuthStrategy.setup({
          name: 'cognito'
        } as NbOAuth2AuthStrategyOptions)
      ]
    }),
    NbSecurityModule.forRoot({
      accessControl: {
        guest: {
          view: ['news', 'comments']
        },
        user: {
          parent: 'guest',
          create: 'comments'
        },
        moderator: {
          parent: 'user',
          create: 'news',
          remove: '*'
        }
      }
    }),
    AuthRoutingModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NbAuthJWTInterceptor,
      multi: true
    },
    {
      provide: NbTokenStorage,
      useClass: NbTokenLocalStorage
    },
    {
      provide: NbRoleProvider,
      useClass: RoleProviderService
    },
    {
      provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
      useValue: function (req: HttpRequest<any>) {
        //If loading app-config
        if (req.url == 'token') {
          return true;
        }
        if (req.url.indexOf('app-config.json') > -1) {
          return true;
        }
        var tryingToRefreshToken = req.url.indexOf('oauth2/token') > -1;
        if (tryingToRefreshToken) {
          return true;
        }
        if (req.url.indexOf('labelary.com') > -1) {
          return true;
        }
        return false;
      }
    }
  ]
})
export class AuthModule {
  public static OAuthRootPath = '/oauth';
  public static OAuthCallbackPath = '/callback';
  constructor(
    private logger: NGXLogger,
    private configLoader: ConfigLoaderService,
    private authService: NbAuthService, // force construction of the auth service
    private authStrategy: NbOAuth2AuthStrategy
  ) {
    this.logger.debug('AuthModule loaded');
    this.configLoader.Config.subscribe(config => {
      this.logger.debug('Auth Config Updated', config);
      this.authStrategy.setOptions({
        name: config.authServiceStrategyName,
        clientId: config.cognitoClientId,
        clientAuthMethod: NbOAuth2ClientAuthMethod.NONE,
        authorize: {
          endpoint: config.oAuthAuthorizeEndpoint,
          responseType: NbOAuth2ResponseType.CODE,
          redirectUri: `${config.applicationURL}${AuthModule.OAuthRootPath}${AuthModule.OAuthCallbackPath}`,
          requireValidToken: true
        },
        token: {
          endpoint: config.oAuthTokenEndpoint,
          grantType: NbOAuth2GrantType.AUTHORIZATION_CODE,
          class: NbAuthOAuth2Token,
          redirectUri: `${config.applicationURL}${AuthModule.OAuthRootPath}${AuthModule.OAuthCallbackPath}`
        },
        refresh: {
          endpoint: config.oAuthTokenEndpoint,
          grantType: NbOAuth2GrantType.REFRESH_TOKEN,
          requireValidToken: true
        },
        redirect: {
          success: '/',
          failure: null
        }
      } as NbOAuth2AuthStrategyOptions);
    });
  }
}
