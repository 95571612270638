<autopilot-grid>
  <kendo-grid
    kendoGridSelectBy="id"
    [kendoGridBinding]="gridData"
    [selectedKeys]="selectedIds"
    [selectable]="{ checkboxOnly: true }"
    [filterable]="'menu'"
    [sortable]="sortSettings"
    [sort]="sort"
    [style.maxHeight.%]="100"
    (edit)="editHandler($event)"
    (cancel)="cancelHandler($event)"
    (save)="saveHandler($event)"
    (remove)="removeHandler($event)"
    (selectionChange)="onSelectionChange($event)">
      <ng-template kendoGridToolbarTemplate>
          <kendo-button
              icon="qr-code"
              (click)="onPrintLabels()">
              Print Labels
          </kendo-button>
      </ng-template>
      <kendo-grid-checkbox-column [width]="50" class="printer-checkbox-item">
        <ng-template kendoGridHeaderTemplate>
          <button class="btn btn-unstyled" title="Select/Deselect all rows for label printing" (click)="clickSelectAllCheckbox()">
            <i
              class="fa-light fa-print"
              [ngClass]="{ 'text-purple': selectAllState == 'checked', 'fw-bold': selectAllState == 'checked' }"></i>
            <input
              #selectAllCheckbox
              class="d-none k-checkbox k-checkbox-md k-rounded-md"
              type="checkbox"
              title="Select item(s) to print label(s)"
              kendoCheckbox
              kendoGridSelectAllCheckbox
              [state]="selectAllState"
              (selectAllChange)="onSelectAll($event)" />
          </button>
        </ng-template>
      </kendo-grid-checkbox-column>
      <kendo-grid-command-column title="Actions" [width]="240">
          <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem>
              <button kendoGridEditCommand [primary]="true">Edit</button>
              <button kendoGridEditCommand [routerLink]="'/supplies/room-inventory-item-stock/' + dataItem.id + '/history'">View History</button>
              <button kendoGridSaveCommand [disabled]="formGroup?.invalid">
              {{ isNew ? "Add" : "Update" }}
              </button>
              <button kendoGridCancelCommand>
              {{ isNew ? "Discard changes" : "Cancel" }}
              </button>
              <kendo-button icon="qr-code" (click)="onQrClick(dataItem.id)"></kendo-button>
          </ng-template>
      </kendo-grid-command-column>
      <kendo-grid-column field="wingmanId" title="Wingman ID">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.wingmanId}}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="trackInventoryInThisRoom" title="Track Inventory In This Room" filter="boolean">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.trackInventoryInThisRoom ? 'Yes': 'No'}}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column-group title="Stocking Levels">
          <kendo-grid-column field="quantity" title="Quantity">
            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <kendo-grid-numeric-filter-menu
                    [column]="column"
                    [filter]="filter"
                    [filterService]="filterService"
                    [extra]="false">
                </kendo-grid-numeric-filter-menu>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="stockingQuantity" title="Stocking Quantity">
            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <kendo-grid-numeric-filter-menu
                    [column]="column"
                    [filter]="filter"
                    [filterService]="filterService"
                    [extra]="false">
                </kendo-grid-numeric-filter-menu>
            </ng-template>
          </kendo-grid-column>
      </kendo-grid-column-group>
      <kendo-grid-column field="stockingQuantityUnitOfMeasurement" title="Stocking Quantity Unit of Measurement">
        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
            <kendo-grid-string-filter-menu
                [column]="column"
                [filter]="filter"
                [filterService]="filterService"
                [extra]="false">
            </kendo-grid-string-filter-menu>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column-group title="Storage Room Location">
          <kendo-grid-column field="locationName" title="Location Name">
            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
              <kendo-grid-string-filter-menu
                  [column]="column"
                  [filter]="filter"
                  [filterService]="filterService"
                  [extra]="false">
              </kendo-grid-string-filter-menu>
            </ng-template>
            <ng-template kendoGridFilterMenuTemplate kendoGridCellTemplate let-dataItem let-filterService="filterService">
              <a class="link" [routerLink]="'/locations/edit/' + dataItem.locationId">{{ dataItem.locationName }} <i class="fa-light fa-arrow-up-right-from-square"></i></a>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="buildingName" title="Building Name">
              <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <kendo-grid-string-filter-menu
                    [column]="column"
                    [filter]="filter"
                    [filterService]="filterService"
                    [extra]="false">
                </kendo-grid-string-filter-menu>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                  <a class="link" [routerLink]="'/buildings/edit/' + dataItem.buildingId">{{ dataItem.buildingName }} <i class="fa-light fa-arrow-up-right-from-square"></i></a>
              </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="roomName" title="Room Name">
              <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <kendo-grid-string-filter-menu
                    [column]="column"
                    [filter]="filter"
                    [filterService]="filterService"
                    [extra]="false">
                </kendo-grid-string-filter-menu>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                  <a class="link" [routerLink]="'/rooms/edit/' + dataItem.roomId">{{ dataItem.roomName }} <i class="fa-light fa-arrow-up-right-from-square"></i></a>
              </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="dimension1" title="Dimension 1">
            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <kendo-grid-string-filter-menu
                    [column]="column"
                    [filter]="filter"
                    [filterService]="filterService"
                    [extra]="false">
                </kendo-grid-string-filter-menu>
              </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="dimension2" title="Dimension 2">
            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <kendo-grid-string-filter-menu
                    [column]="column"
                    [filter]="filter"
                    [filterService]="filterService"
                    [extra]="false">
                </kendo-grid-string-filter-menu>
              </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="dimension3" title="Dimension 3">
            <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <kendo-grid-string-filter-menu
                    [column]="column"
                    [filter]="filter"
                    [filterService]="filterService"
                    [extra]="false">
                </kendo-grid-string-filter-menu>
              </ng-template>
          </kendo-grid-column>
      </kendo-grid-column-group>
      <kendo-grid-messages
        filterIsTrue="Yes"
        filterIsFalse="No">
      </kendo-grid-messages>
  </kendo-grid>
</autopilot-grid>

<kendo-dialog *ngIf="isQrModalVisible" title="QR Code" (close)="isQrModalVisible=false">
    <kendo-qrcode
        [value]="qrLink"
    >
    </kendo-qrcode>
</kendo-dialog>
