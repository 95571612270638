import { CourseService } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { CourseDetailComponent } from '@wo-app/components/dashboard/courses/course-detail/course-detail.component';
import { CoursesComponent } from '@wo-app/components/dashboard/courses/courses/courses.component';
import { ImpersonatingOrganizationCanActivate } from '@wo-app/core/guards';
import { RouteChild, RouteParent } from '@wo-app/shared/models/route';

export class CoursesRegistration {
  static Routes: RouteParent = {
    path: EntityGlobals.COURSES.baseRoute,
    canActivate: [ImpersonatingOrganizationCanActivate],
    entityInformation: EntityGlobals.COURSES,
    children: [
      {
        path: '',
        component: CoursesComponent,
        data: {
          breadcrumb: 'View All'
        }
      } as RouteChild,
      {
        path: 'edit/:id',
        component: CourseDetailComponent,
        data: {
          breadcrumb: 'Edit ' + EntityGlobals.COURSES.singleName
        }
      } as RouteChild,
      {
        path: 'add',
        component: CourseDetailComponent,
        data: {
          breadcrumb: 'Add ' + EntityGlobals.COURSES.singleName
        }
      } as RouteChild
    ]
  };

  static Services: any = [CourseService];

  static Components: any = [CoursesComponent, CourseDetailComponent];
}
