<div class="">
    {{startOfWeek | amDateFormat:'MMM D' }} - {{endOfWeek | amDateFormat:'MMM D, YYYY' }}
</div>
<div>
    <button class="btn btn-primary" (click)="goToPreviousWeek()"><i class="fa-light fa-angle-left"></i></button>
    <button class="btn btn-primary"><i class="fa-light fa-calendar"></i></button>
    <button class="btn btn-primary" (click)="goToNextWeek()"><i class="fa-light fa-angle-right"></i></button>
    <button class="btn btn-primary" [disabled]="isTodayButtonDisabled" (click)="goToThisWeek()">Today</button>
</div>
<div>
    Select a User (Admin Only)
</div>
<kendo-grid [data]="gridData" [ngClass]="'timesheet-grid'">
    <kendo-grid-column [title]="'Type'">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.entryType}}
        </ng-template>
        <ng-template kendoGridFooterTemplate let-column>
            <kendo-dropdownlist class="btn btn-primary" [data]="timesheetEntryTypes" [(ngModel)]="footerTypeModel" [defaultItem]="timesheetEntryTypesDefaultItem" [valuePrimitive]="true" [valueField]="'name'" [textField]="'name'" [filterable]="false" (valueChange)="handleEntryTypeChange($event)"></kendo-dropdownlist>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [title]="'Assignment'">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{getTimesheetAssignmentName(dataItem.entryType, dataItem.timesheetEntryTypeForeignKeyValue)}}
        </ng-template>
        <ng-template kendoGridFooterTemplate let-column>
            <kendo-dropdownlist class="btn btn-primary" [data]="timesheetEntryItems" [disabled]="timeAssignmentFieldIsDisabled" [(ngModel)]="footerAssignmentModel" [defaultItem]="timesheetEntryItemsDefaultItem" [valuePrimitive]="true" [valueField]="'value'" [textField]="'name'"
                [filterable]="false" (valueChange)="handleEntryItemChange($event)"></kendo-dropdownlist>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column *ngFor="let column of columns" field="ColumnDate" [title]="column.ColumnTitle">
        <ng-template kendoGridCellTemplate let-dataItem>
            <app-timesheet-cell-editor [dateOfEntry]="column.ColumnDate" [timesheetDayGroupViewModel]="getEntriesForDay(dataItem.rows,column.ColumnDate)" (dataForPopupChanged)="dataForPopupChanged($event)" (minutesForEntryChanged)="processChildValueChange($event)"></app-timesheet-cell-editor>
        </ng-template>
        <ng-template kendoGridFooterTemplate let-column>
        </ng-template>
    </kendo-grid-column>
    <ng-template kendoGridNoRecordsTemplate></ng-template>
    <kendo-grid-pdf [fileName]="pdfFileName" paperSize="A4" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
    </kendo-grid-pdf>
    <kendo-grid-excel [fileName]="excelFileName"></kendo-grid-excel>
</kendo-grid>
<router-outlet></router-outlet>