import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { State, orderBy } from '@progress/kendo-data-query';
import { ImportJobService, ImportJobViewModel, ListImportJobsCommand, ListImportJobsCommandResult } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { CachedDataService, ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
import { takeUntil } from 'rxjs';
@Component({
  selector: 'app-import-jobs',
  templateUrl: './import-jobs.component.html',
  styleUrls: ['./import-jobs.component.scss']
})
export class ImportJobsComponent extends EntityBaseComponent implements OnInit, OnDestroy {
  gridData: ImportJobViewModel[] = [];
  listImportJobsCommand: ListImportJobsCommand = {};
  public view: GridDataResult;
  public override state: State = {
    skip: 0,
    take: 100,
    sort: [{ dir: 'asc', field: 'name' }],
    filter: {
      logic: 'and',
      filters: []
    }
  };

  constructor(
    private dialogService: DialogService,
    private logger: NGXLogger,
    private impersonationService: ImpersonationService,
    private importJobService: ImportJobService,
    public cachedDataService: CachedDataService,
    private route: ActivatedRoute,
    private router: Router,
    private loadingBar: LoadingBarService
  ) {
    super(EntityGlobals.IMPORT_JOBS, router, route, impersonationService, logger);
  }

  ngOnInit() {
    this.reloadData();
  }

  private reloadData() {
    this.loadingBar.useRef('router').start();
    let cmd: ListImportJobsCommand = {};
    this.importJobService
      .list(cmd)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: ListImportJobsCommandResult) => {
        this.loadingBar.useRef('router').complete();
        if (result && result.data) {
          this.gridData = result.data;
          this.query();
        }
      });
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.query();
  }

  public query() {
    const sortedGridData = orderBy(this.gridData, this.state.sort ?? [{ dir: 'asc', field: 'name' }]);
    this.view = {
      data: sortedGridData.slice(this.state.skip, (this.state.skip ?? 0) + this.state.take),
      total: sortedGridData.length
    };
  }

  delete(id) {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this item?',
      actions: [
        { text: 'No' },
        {
          text: 'Yes',
          primary: true,
          id: id
        }
      ],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe((result: any) => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
        this.importJobService._delete(result.id.toString()).subscribe((result: any) => {
          this.logger.debug(result);
          this.reloadData();
        });
      }
    });
  }
}
