import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { 
  RoomViewModel,
  RoomQuantityViewModel
} from '@wo-api/index';
import { CustomEmmitterService } from '../../../../core/services/custom-emmitter.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-pick-order-item-fill-room-selection',
  templateUrl: './pick-order-item-fill-room-selection.component.html',
  styleUrls: ['./pick-order-item-fill-room-selection.component.scss']
})
export class PickOrderItemFillRoomSelectionComponent implements OnInit  {
  @Input() public inventoryItem: any;
  @Input() public rooms: RoomViewModel[] = [];
  public inventoryItemChange = new EventEmitter<any>(); 
  public selectedValue: RoomViewModel = {};

  constructor(
    private customEmmitterService: CustomEmmitterService,
    private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.selectedValue = {
      id: -1,
      name: this.inventoryItem.rooms?.length ? 'Add a split' : 'Select room'
    };
  }

  public handleVelueChange(value) {
    if (!this.inventoryItem.rooms) {
      this.inventoryItem.rooms = [];
    }

    const tempSelection = this.inventoryItem.rooms.find(e => e.roomId === value.id);
    if (value.id > 0 && !tempSelection) {
      const newSelectedItem: RoomQuantityViewModel = {
        roomId: value.id,
        roomName: value.name,
        quantity: !this.inventoryItem.rooms || this.inventoryItem.rooms.length === 0 ? this.inventoryItem.quantityNeeded : 0
      }
      this.inventoryItem.rooms.push(newSelectedItem);
      this.customEmmitterService.emitInventoryItemRoomSelectionEvent(this.inventoryItem);
    }
    this.selectedValue = { id: -1, name: this.inventoryItem.rooms?.length ? 'Add a split' : 'Select room' };
  }

  public createFormGroup = ({ dataItem }): FormGroup => {
    if (this.inventoryItem.status !== 'CheckedOut') {
      return this.formBuilder.group({
        quantity: dataItem.quantity,
      });
    }
    return this.formBuilder.group({});
  };
}
