import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { MultipleSortSettings } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { BuildingService, ListBedsCommandResult, ListBuildingsCommand, ListBuildingsCommandViewModel } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { CachedDataService, ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-buildings',
  templateUrl: './buildings.component.html',
  styleUrls: ['./buildings.component.scss']
})
export class BuildingsComponent extends EntityBaseComponent implements OnInit {
  gridData: ListBuildingsCommandViewModel[] = [];
  listBuildingsCommand: ListBuildingsCommand = {};
  public sort: SortDescriptor[] = [{ dir: 'asc', field: 'name' }];
  public sortSettings: MultipleSortSettings = {
    mode: 'multiple',
    initialDirection: 'desc',
    allowUnsort: true,
    showIndexes: true
  };

  constructor(
    private dialogService: DialogService,
    private logger: NGXLogger,
    public cachedDataService: CachedDataService,
    private impersonationService: ImpersonationService,
    private buildingService: BuildingService,
    private route: ActivatedRoute,
    private router: Router,
    private loadingBar: LoadingBarService,
    breadcrumbService: BreadcrumbsService,
    titleService: Title
  ) {
    super(EntityGlobals.BUILDINGS, router, route, impersonationService, logger, breadcrumbService, titleService);
  }

  ngOnInit() {
    this.reloadData();
  }

  private reloadData() {
    this.loadingBar.useRef('router').start();
    let cmd: ListBuildingsCommand = {};
    this.buildingService
      .list(cmd)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: ListBedsCommandResult) => {
        this.loadingBar.useRef('router').complete();
        if (result && result.data) {
          this.gridData = result.data.map(item => ({
            ...item,
            createdDate: new Date(item.created),
            lastUpdatedDate: new Date(item.lastUpdated)
          }));
        }
      });
  }

  delete(id) {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this item?',
      actions: [
        { text: 'No' },
        {
          text: 'Yes',
          primary: true,
          id: id
        }
      ],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe((result: any) => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
        this.buildingService._delete(result.id.toString()).subscribe((result: any) => {
          this.reloadData();
        });
      }
    });
  }
}
