import { Injectable, Pipe } from "@angular/core";

@Pipe({
  name: "email",
})
export class EmailPipe {
  transform(email, args: any = []) {

    if(email == null) return "";
    var value = email.toString().trim().replace(/^\+/, "");
    return `<a href="mailto:${value}"><i class="fa-light fa-envelope"></i> ${value}</a>`;
  }
}