import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, filterBy, orderBy } from '@progress/kendo-data-query';
import {
  BuildingService,
  CreateRoomCommand,
  GetLocationQuantityListForRoomCommandResult,
  ListBuildingsCommand,
  ListBuildingsCommandResult,
  ListBuildingsCommandViewModel,
  ListLocationsCommandResult,
  ListRoomsCommandResult,
  LocationService,
  OrganizationService,
  ReadRoomCommandResult,
  RoomService,
  RoomSupplyReportViewModel,
  UpdateRoomCommand
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { CachedDataService, ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import ArrayUtilities from '@wo-app/shared/utils/array-utils';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-room-inventory-list',
  templateUrl: './room-inventory-list.component.html',
  styleUrls: ['./room-inventory-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RoomInventoryListComponent extends EntityBaseComponent implements OnInit {
  readRoomCommandResult: ReadRoomCommandResult = {};
  dataModel: ReadRoomCommandResult;
  listBuildingsCommand: ListBuildingsCommand = {};
  updateRoomCommand: UpdateRoomCommand = {};
  createRoomCommand: CreateRoomCommand = {};
  form: FormGroup;
  originalFormState: FormGroup;
  fromKey: string;
  buildingsForOrganization: ListBuildingsCommandViewModel[];
  buildingId: number;
  public GetLocationQuantityListForRoomCommand: Observable<GetLocationQuantityListForRoomCommandResult>;
  public view: GridDataResult;
  public locations: ListLocationsCommandResult;
  public buildings: ListBuildingsCommandResult;
  public rooms: ListRoomsCommandResult;

  gridData: any[];
  ArrayUtilities = ArrayUtilities;

  constructor(
    private sanitizer: DomSanitizer,
    private loadingBar: LoadingBarService,
    private logger: NGXLogger,
    private impersonationService: ImpersonationService,
    private organizationService: OrganizationService,
    public cachedDataService: CachedDataService,
    private roomService: RoomService,
    private buildingService: BuildingService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService,
    private locationService: LocationService,
    _breadcrumbService: BreadcrumbsService,
    _titleService: Title
  ) {
    super(EntityGlobals.ROOMS, router, route, impersonationService, logger, _breadcrumbService, _titleService);
    this.editMode = false;

    this.locationService.list().subscribe((data: any) => (this.locations = data));
    this.buildingService.list().subscribe((data: any) => (this.buildings = data));
    this.roomService.list().subscribe((data: any) => (this.rooms = data));
  }

  ngOnInit(): void {
    this.state.sort = [{ dir: 'asc', field: 'supplyName' }];
    this.reloadData();
  }

  reloadData(): void {
    this.loadingBar.useRef('router').start();
    this.roomService.getLocationQuantityListForRoom(this.id).subscribe((value: GetLocationQuantityListForRoomCommandResult) => {
      this.logger.debug('Got Data from GetLocationQuantityListForRoomCommandResult', value);
      this.loadingBar.useRef('router').complete();
      this.gridData = value.data;
      this.UpdateBreadcrumbPageTitle(value.data[0].roomId, 'View Inventory for ' + value.data[0].roomName + ` (${value.data[0].roomId})`);
      this.query();
    });
  }

  public query() {
    const filteredData = filterBy(this.gridData, this.filter);
    const sortedGridData = orderBy(filteredData, this.state.sort ?? [{ dir: 'asc', field: 'supplyName' }]);
    this.view = {
      data: sortedGridData.slice(this.state.skip, (this.state.skip ?? 0) + this.state.take),
      total: sortedGridData.length
    };
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.logger.debug('Filtering has changed');
    this.filter = filter;
    this.query();
  }

  public filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: []
  };

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.query();
  }

  public getQuantityWrapperTitle(dataItem: RoomSupplyReportViewModel): string {
    let returnMessage = '';
    if (dataItem.quantity <= dataItem.stockingQuantity) {
      returnMessage = 'This items quantity in this room is below the stocking quantity.';
    } else if (dataItem.quantity <= dataItem.stockingQuantity / 2) {
      returnMessage = 'This items quantity in this room is approaching the stocking quantity.';
    }
    return `${returnMessage}`;
  }
}
