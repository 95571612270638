import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BluetoothCore } from '@manekinekko/angular-web-bluetooth';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { MultipleSortSettings } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { EntityGlobals } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { BluetoothPrinterService, CachedDataService, ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-list-printers',
  templateUrl: './list-printers.component.html',
  styleUrls: ['./list-printers.component.scss']
})
export class ListPrintersComponent extends EntityBaseComponent implements OnInit {
  gridData: any[] = [];
  public sortSettings: MultipleSortSettings = {
    mode: 'multiple',
    initialDirection: 'desc',
    allowUnsort: true,
    showIndexes: true
  };
  public sort: SortDescriptor[] = [{ dir: 'asc', field: 'name' }];
  public defaultPrinter: any = null;

  constructor(
    public bluetoothPrinterService: BluetoothPrinterService,
    public readonly ble: BluetoothCore,
    private dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute,
    private impersonationService: ImpersonationService,
    private logger: NGXLogger,
    public cachedDataService: CachedDataService,
    private loadingBar: LoadingBarService,
    breadcrumbService: BreadcrumbsService,
    titleService: Title
  ) {
    super(EntityGlobals.PRINTERS, router, route, impersonationService, logger, breadcrumbService, titleService);
    this.logger.log('Zebra Printer', this.bluetoothPrinterService.ZebraService);
    this.discoverDevices();
  }

  ngOnInit(): void {
    this.logger.log('ListPrintersComponent Init Event');
    this.bluetoothPrinterService.ZebraService;
  }

  discoverDevicesClickEvent(event: PointerEvent) {
    this.gridData = [];
    this.logger.log(event);
    this.discoverDevices();
  }

  discoverDevices() {
    this.loadingBar.useRef('router').start();
    this.bluetoothPrinterService.ZebraService.getDefaultPrinter().then((defaultPrinter: any) => {
      this.logger.log('Zebra Default Printer', defaultPrinter);
      this.defaultPrinter = defaultPrinter;
    });

    this.bluetoothPrinterService.ZebraService.getAvailablePrinters().then((printers: any[]) => {
      let allowedPrinters = printers.filter(val => val.connection == 'usb');
      this.logger.log('printers', allowedPrinters);
      this.gridData = allowedPrinters;
      this.loadingBar.useRef('router').stop();
    });
  }

  setAsDefaultPrinter(printer: any) {
    this.logger.log('Set as default printer', printer);
    this.defaultPrinter = printer;
  }
}
