import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ListTasksViewModel } from '@wo-api/index';

@Component({
  selector: 'app-task-group',
  templateUrl: './task-group.component.html',
  styleUrls: ['./task-group.component.scss']
})
export class TaskGroupComponent implements OnInit, OnChanges {
  @Input() tasks: Array<ListTasksViewModel> = [];
  @Input() organizationId: number;
  @Input() title: string;
  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['organizationId']) {
      this.organizationId = changes['organizationId'].currentValue;
    }
    if (changes['tasks']) {
      this.tasks = changes['tasks'].currentValue;
    }
    if (changes['title']) {
      this.title = changes['title'].currentValue;
    }
  }
}
