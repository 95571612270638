<kendo-grid [data]="Entries">
    <kendo-grid-column [title]="'Time'" field="durationInMinutes">
        <ng-template kendoGridCellTemplate let-dataItem>
            <app-timesheet-cell-editor [dateOfEntry]="dataItem.dateOfEntry" [timesheetDayGroupViewModel]="createTimesheetDayGroupViewModel(dataItem)" (dataForPopupChanged)="dataForPopupChanged($event)" (minutesForEntryChanged)="processChildValueChange($event)"></app-timesheet-cell-editor>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [title]="'Notes'" field="durationInMinutes">
        <ng-template kendoGridCellTemplate let-dataItem>
            <input kendoTextBox class="form-control" [(ngModel)]="dataItem.notes" maxlength="300" />
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [title]="''">
        <ng-template kendoGridCellTemplate let-dataItem>
            <button class="btn btn" (click)="deleteEntry(dataItem)">
                <i class="fa-light fa-trash-alt"></i>
            </button>
        </ng-template>
    </kendo-grid-column>
    <ng-template kendoGridNoRecordsTemplate>
        <span>Add Another Time Entry</span>
    </ng-template>
    <!--<kendo-grid-pdf [fileName]="pdfFileName" paperSize="A4" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
    </kendo-grid-pdf>
    <kendo-grid-excel [fileName]="excelFileName"></kendo-grid-excel>-->
</kendo-grid>
<router-outlet></router-outlet>