import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ImportJobLineItemViewModel } from '@wo-api/index';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-line-item-error-resolver',
  templateUrl: './line-item-error-resolver.component.html',
  styleUrls: ['./line-item-error-resolver.component.scss']
})
export class LineItemErrorResolverComponent implements OnInit, OnChanges {
  @Input() public importJobLineItem: ImportJobLineItemViewModel;

  constructor(
    private logger: NGXLogger,
  ){

  }
  ngOnInit(): void {

  }

  ngOnChanges():void{
    this.logger.debug(this.importJobLineItem);
  }
}
