<a *ngIf="showBrandLogo" class="brand-logo" [routerLink]="['/']">
  <img class="brand-logo--img" [src]="logoUrl" alt="Wingman Logo" />
</a>

<!-- Left Nav Menu -->
<div *ngIf="!slideAnimationActive" id="nav-content" [class.slide-animation]="slideAnimationActive">
  <div *ngFor="let group of MenuGroups" class="nav-group">
    <h6 class="text-body-secondary px-3">
      <span>{{ group.GroupName }}</span>
    </h6>
    <ul class="nav nav-pills flex-column">
      <div *ngFor="let menuItem of group.MenuItems">
        <ng-container *ngIf="menuItem.children?.length === 0; else itemWithSubMenu">
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link text-white" routerLinkActive="active" [routerLink]="[menuItem.path]">
              <span class="icon-wrapper" [hidden]="shouldHideIcon(menuItem.icon)">
                <i class="fa-light fa-{{ menuItem.icon }}"></i>
              </span>
              <span class="menu-title">{{ menuItem.title }}</span>
            </a>
          </li>
        </ng-container>
        <ng-template #itemWithSubMenu>
          <div ngbAccordion (shown)="onMenuShown($event)">
            <div ngbAccordionItem>
              <button ngbAccordionButton class="nav-link">
                <span class="icon-wrapper" [hidden]="shouldHideIcon(menuItem.icon)">
                  <i class="fa-light fa-{{ menuItem.icon }}"></i>
                </span>
                <span class="menu-title">{{ menuItem.title }}</span>
              </button>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <ng-template>
                    <ul class="nav-sub-pills">
                      <li class="nav-item" routerLinkActive="active">
                        <a
                          class="nav-link text-white"
                          routerLinkActive="active"
                          [routerLink]="[menuItem.path]"
                          [routerLinkActiveOptions]="{ exact: true }">
                          <span class="menu-title">View All</span>
                        </a>
                      </li>
                      <li *ngFor="let childMenuItem of menuItem.children" class="nav-item">
                        <a
                          class="nav-link text-white"
                          routerLinkActive="active"
                          [routerLink]="childMenuItem.path"
                          [routerLinkActiveOptions]="{ exact: true }">
                          <span class="menu-title" [innerHTML]="getLabelForMenuItemAndMenuGroup(childMenuItem, group.MenuGroup)">
                            {{ childMenuItem | json }}
                          </span>
                        </a>
                      </li>
                    </ul>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </ul>
  </div>
</div>

<!-- Mobile Menu -->
<div *ngIf="slideAnimationActive" class="slide-animation w-100" #slideAnimationContainer>
  <ul [@animSlider]="counter" class="active nav nav-pills flex-column position-absolute w-100">
    <li *ngFor="let group of MenuGroups">
      <button #refEl class="nav-link d-flex w-100 menu-group-header" (click)="showGroup(refEl, group)">
        <span>{{ group.GroupName }}</span>
      </button>
      <ul class="nav nav-pills flex-column position-absolute w-100">
        <li>
          <button class="nav-link d-flex w-100" (click)="menuItemGoBack()">
            <span class="icon-wrapper nav-back me-3">
              <i class="fa-light fa-angle-left"></i>
            </span>
            <span class="menu-title"> Back to Main Menu </span>
          </button>
        </li>
        <div *ngFor="let menuItem of group.MenuItems">
          <ng-container *ngIf="menuItem.children?.length === 0; else mobileSubMenu">
            <li routerLinkActive="active" class="nav-item w-100">
              <button class="nav-link d-flex w-100" [routerLink]="[menuItem.path]" (click)="menuItemClicked()" routerLinkActive="active">
                <span class="icon-wrapper" [hidden]="shouldHideIcon(menuItem.icon)">
                  <i class="fa-light fa-{{ menuItem.icon }}"></i>
                </span>
                <span class="menu-title">{{ menuItem.title }}</span>
              </button>
            </li>
          </ng-container>

          <ng-template #mobileSubMenu>
            <div ngbAccordion (shown)="onMenuShown($event)">
              <div ngbAccordionItem>
                <button ngbAccordionButton class="nav-link">
                  <span class="icon-wrapper" [hidden]="shouldHideIcon(menuItem.icon)">
                    <i class="fa-light fa-{{ menuItem.icon }}"></i>
                  </span>
                  <span class="menu-title">
                    {{ menuItem.title }}
                  </span>
                </button>
                <!-- Accordion content -->
                <div ngbAccordionCollapse>
                  <div ngbAccordionBody>
                    <ng-template>
                      <ul class="nav-sub-pills">
                        <li class="nav-item px-0" routerLinkActive="active">
                          <button
                            class="nav-link"
                            (click)="menuItemClicked()"
                            routerLinkActive="active"
                            [routerLink]="[menuItem.path]"
                            [routerLinkActiveOptions]="{ exact: true }">
                            <span class="menu-title">View All</span>
                          </button>
                        </li>
                        <li *ngFor="let childMenuItem of menuItem.children" class="nav-item px-0">
                          <a
                            class="nav-link"
                            (click)="menuItemClicked()"
                            routerLinkActive="active"
                            [routerLink]="childMenuItem.path"
                            [routerLinkActiveOptions]="{ exact: true }">
                            <span class="menu-title" [innerHTML]="getLabelForMenuItemAndMenuGroup(childMenuItem, group.MenuGroup)">
                              {{ childMenuItem | json }}
                            </span>
                          </a>
                        </li>
                      </ul>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </ul>
    </li>
    <li class="ng-tns-c553-3 ng-star-inserted" style="">
      <button class="nav-link d-flex w-100" (click)="goToProfile()">
        <span>Profile and Password</span>
      </button>
    </li>
    <li class="ng-tns-c553-3 ng-star-inserted" style="">
      <button class="nav-link d-flex w-100" (click)="goToSignOut()">
        <span>Sign Out</span>
      </button>
    </li>
  </ul>
</div>