import { Component, Input, OnChanges, OnInit, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-radio-button-list',
  templateUrl: './radio-button-list.component.html',
  styleUrls: ['./radio-button-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonListComponent),
      multi: true
    }
  ]
})
export class RadioButtonListComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() orientation: string;
  @Input() groupName: string;
  @Input() dataLabelField: string;
  @Input() dataValueField: string;
  @Input() showInline: boolean;
  @Input() set data(value) {
    this._data.next(value);
  }
  @Input() set checkedValue(value) {
    this._checkedValue.next(value);
  }
  private _data = new BehaviorSubject<any[]>([]);
  private _checkedValue = new BehaviorSubject<any>([]);

  enumData: any[];
  selectedItem: any;

  constructor(private logger: NGXLogger) {}

  ngOnInit() {}

  writeValue(value: any) {
    if (value !== undefined) {
      this.selectedItem = value;
    }
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  get data() {
    // get the latest value from _data BehaviorSubject
    return this._data.getValue();
  }

  get checkedValue() {
    return this._checkedValue.getValue();
  }

  ngOnChanges(changes: SimpleChanges) {
    // only run when property "data" changed
    if (changes['data']) {
      this.enumData = this.data;
    }
    if (changes['checkedValue']) {
      this.selectedItem = this.checkedValue;
    }
  }

  onSelectionChange(entry) {
    if (this.selectedItem instanceof BehaviorSubject) {
      let bs = this.selectedItem as BehaviorSubject<any>;
      bs.next(entry);
    } else {
      this.selectedItem = Object.assign({}, this.selectedItem, entry);
    }
    this.propagateChange(this.selectedItem[this.dataValueField]);
  }

  getValueField(row): any {
    return row[this.dataValueField];
  }

  getLabelField(row): any {
    return row[this.dataLabelField];
  }
}
