<div class="row">
    <div class="col-xs-12">
        <p>You can now add users to an Orgnization</p>
    </div>
</div>
<div class="row">
    <div class="col-xs-12 col-md-6">
        <h4>Organizations</h4>
        <kendo-grid [data]="organizationsGridView">
            <kendo-grid-column field="name" title="Organization Name">
            </kendo-grid-column>
            <kendo-grid-column field="role" title="Role">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-dropdownlist [data]="roles" [textField]="'roleName'" [valueField]="'roleId'" [defaultItem]="this.roles[0]">
                    </kendo-dropdownlist>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Actions" class="k-col-actions">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <button kendoButton type="button" [primary]="true" [icon]="'plus'" (click)="addToOrganization(dataItem.id)">Add</button>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </div>
    <div class="col-xs-12 col-md-6">
        <h4>Assigned Organizations</h4>
        <kendo-grid [data]="usersInOrganizationGridView">
            <kendo-grid-column field="organizationName" title="Organization Name">
            </kendo-grid-column>
            <kendo-grid-column field="roleName" title="Role Name">
            </kendo-grid-column>
            <kendo-grid-column title="Actions" class="k-col-actions">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <button kendoButton type="button" [primary]="true" [icon]="'minus'" (click)="removeFromOrganization(dataItem.organizationId)">Remove</button>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </div>
</div>
