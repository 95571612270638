<div class="">
    <label for="inputEmail3" class="form-label">Filter Tasks</label>
    <kendo-dropdownlist [data]="getLabRequestNames()" [(ngModel)]="selectedValue" [valuePrimitive]="true" class="form-control" [textField]="'labRequestName'" [valueField]="'id'" (valueChange)="valueChange($event)" (selectionChange)="selectionChange($event)"></kendo-dropdownlist>
</div>
<div class="row">
    <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
        <app-task-group [tasks]="getTasksDueToday()" [organizationId]="organizationId" [title]="'Tasks Due Today'"></app-task-group>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
        <app-task-group [tasks]="getTasksDueTomorrow()" [organizationId]="organizationId" [title]="'Tasks Due Tomorrow'"></app-task-group>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
        <app-task-group [tasks]="getTasksDueNext7Days()" [organizationId]="organizationId" [title]="'Tasks Due Next 7 Days'"></app-task-group>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
        <app-task-group [tasks]="getTasksDueMoreThan7Days()" [organizationId]="organizationId" [title]="'Tasks Due Next 30 Days'"></app-task-group>
    </div>
</div>