import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OAuth2CallbackComponent } from './components/oauth2-callback';
import { OAuth2LoginComponent } from './components/oauth2-login';
import { OAuth2LogoutComponent } from './components/oauth2-logout';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'callback',
        component: OAuth2CallbackComponent,
        data: {
          breadcrumb: 'Authenticating',
          showFooter: false,
          showHeader: false,
          showSidebar: false,
        },
      },
      {
        path: 'login',
        component: OAuth2LoginComponent,
        data: {
          breadcrumb: 'Login',
          showFooter: false,
          showHeader: false,
          showSidebar: false,
        },
      },
      {
        path: 'logout',
        component: OAuth2LogoutComponent,
        data: {
          breadcrumb: 'Logout',
          showFooter: false,
          showHeader: false,
          showSidebar: false,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
