import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RoomSupplyReportViewModel } from '@wo-api/index';

@Component({
  selector: 'app-quantity-display-component',
  templateUrl: './quantity-display-component.component.html',
  styleUrls: ['./quantity-display-component.component.scss']
})
export class QuantityDisplayComponentComponent implements OnChanges {
  @Input() dataItem: RoomSupplyReportViewModel;

  ngOnChanges(changes: SimpleChanges) {}

  public getQuantityWrapperClassName(dataItem: RoomSupplyReportViewModel): string {
    let addedClass = '';
    if (dataItem.quantity <= dataItem.stockingQuantity) {
      addedClass = 'danger';
    } else if (dataItem.quantity <= dataItem.stockingQuantity / 2) {
      addedClass = 'warning';
    }
    return `wrapper ${addedClass}`;
  }

  public getQuantityWrapperTitle(dataItem: RoomSupplyReportViewModel): string {
    let returnMessage = '';
    if (dataItem.quantity <= dataItem.stockingQuantity) {
      returnMessage = 'This items quantity in this room is below the stocking quantity.';
    } else if (dataItem.quantity <= dataItem.stockingQuantity / 2) {
      returnMessage = 'This items quantity in this room is approaching the stocking quantity.';
    }
    return `${returnMessage}`;
  }
}
