import { SkillService } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { SkillDetailComponent } from '@wo-app/components/dashboard/skills/skill-detail/skill-detail.component';
import { SkillsComponent } from '@wo-app/components/dashboard/skills/skills/skills.component';
import { TaskGroupComponent } from '@wo-app/components/dashboard/tasks/task-group/task-group.component';
import { ImpersonatingOrganizationCanActivate } from '@wo-app/core/guards';
import { RouteChild, RouteParent } from '@wo-app/shared/models/route';

export class SkillsRegistration {
  static Routes: RouteParent = {
    path: EntityGlobals.SKILLS.baseRoute,
    canActivate: [ImpersonatingOrganizationCanActivate],
    entityInformation: EntityGlobals.SKILLS,
    children: [
      {
        path: '',
        component: SkillsComponent,
        data: {
          breadcrumb: 'View All'
        }
      } as RouteChild,
      {
        path: 'edit/:id',
        component: SkillDetailComponent,
        data: {
          breadcrumb: 'Edit ' + EntityGlobals.SKILLS.singleName
        }
      } as RouteChild,
      {
        path: 'add',
        component: SkillDetailComponent,
        data: {
          breadcrumb: 'Add ' + EntityGlobals.SKILLS.singleName
        }
      } as RouteChild
    ]
  };

  static Services: any = [SkillService];

  static Components: any = [SkillsComponent, SkillDetailComponent, TaskGroupComponent];
}
