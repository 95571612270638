import { Injectable } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { NGXLogger } from 'ngx-logger';
import QRCode from 'qrcode';
import * as moment from 'moment';

export enum LabelType {
  Equipment = 'Equipment',
  ManikinAndTrainer = 'ManikinAndTrainer',
  Supply = 'Supply'
}

type EquipmentInfo = {
  manufacturer: string;
  modelNumber: string;
  serialNumber?: string;
};

type ManikinAndTrainerInfo = {
  manufacturer: string;
  type: string;
  nickname: string;
};

type SupplyInfo = {
  dimension1: string;
  dimension2: string;
  dimension3: string;
  location: string;
  building: string;
  room: string;
};

type ItemInfo = EquipmentInfo | ManikinAndTrainerInfo | SupplyInfo;

export type Label = {
  qrCode: string;
  datePrinted: string;
  customProperties: {
    [index: string]: string;
  };
};

@Injectable({
  providedIn: 'root'
})
export class QrLabelService {
  private readonly LocalStorageLabelPrefix: string = 'qr_label_data_';

  private getLabelTypeStorageKey(labelType: LabelType): string {
    return this.LocalStorageLabelPrefix + labelType.toLowerCase();
  }

  constructor(private logger: NGXLogger, protected localStorage: LocalStorage) {}

  private createQrCodeString(url: string): Promise<string> {
    return QRCode.toDataURL(url, { type: 'image/png' });
  }

  public async setQrLabelsInBrowserStorage(url: (info: ItemInfo) => string, labelType: LabelType, itemInfos: ItemInfo[]): Promise<void> {
    const qrCodePromises: Promise<string>[] = [];
    itemInfos.forEach(item => {
      qrCodePromises.push(this.createQrCodeString(url(item)));
    });
    const qrCodes = await Promise.all(qrCodePromises);
    const labels = itemInfos.map(
      (item, index) =>
        ({
          qrCode: qrCodes[index],
          datePrinted: moment().format('YYYY-MM-DD'),
          customProperties: item
        } as Label)
    );
    return new Promise(resolve => {
      this.localStorage.setItem(this.getLabelTypeStorageKey(labelType), JSON.stringify(labels)).subscribe(() => {
        resolve();
      });
    });
  }

  public async updateQrLabelsInBrowserStorage(labelType: LabelType, labels: Label[]): Promise<void> {
    return new Promise(resolve => {
      this.localStorage.setItem(this.getLabelTypeStorageKey(labelType), JSON.stringify(labels)).subscribe(() => {
        resolve();
      });
    });
  }

  public getQrLabelsFromBrowserStorage(labelType: LabelType): Promise<Label[]> {
    return new Promise<Label[]>(resolve => {
      this.localStorage.getItem(this.getLabelTypeStorageKey(labelType)).subscribe((data: any) => {
        resolve(JSON.parse(data));
      });
    });
  }

  public countOfLabelsInStorage(labelType: LabelType): Promise<number> {
    return new Promise<number>(resolve => {
      this.getQrLabelsFromBrowserStorage(labelType).then((value: Label[]) => {
        let countOfLabelsInStorage = value != null && value != undefined ? value.length : 0;
        resolve(countOfLabelsInStorage);
      });
    });
  }
}
