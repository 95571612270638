import { EntityGlobals } from '@wo-app/app.global';
import { MyProfileComponent } from '@wo-app/components/dashboard/my-profile/my-profile.component';
import { ImpersonatingOrganizationCanActivate } from '@wo-app/core/guards';
import { RouteChild, RouteParent } from '@wo-app/shared/models/route';

export class MyProfileRegistration {
  static Routes: RouteParent = {
    path: EntityGlobals.MYPROFILE.baseRoute,
    canActivate: [ImpersonatingOrganizationCanActivate],
    entityInformation: EntityGlobals.MYPROFILE,
    children: [
      {
        path: '',
        component: MyProfileComponent,
        data: {
          breadcrumb: 'My Profile'
        }
      } as RouteChild
    ]
  };

  static Services: any = [];

  static Components: any = [MyProfileComponent];
}
