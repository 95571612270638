import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { MultipleSortSettings } from '@progress/kendo-angular-grid';
import { SortDescriptor, process } from '@progress/kendo-data-query';
import { ListRoomsCommand, ListRoomsCommandResult, RoomService } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { CachedDataService, ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-rooms',
  templateUrl: './rooms.component.html',
  styleUrls: ['./rooms.component.scss']
})
export class RoomsComponent extends EntityBaseComponent implements OnInit {
  gridData: any[];

  public sort: SortDescriptor[] = [
    { dir: 'asc', field: 'buildingName' },
    { dir: 'asc', field: 'name' }
  ];
  public sortSettings: MultipleSortSettings = {
    mode: 'multiple',
    initialDirection: 'desc',
    allowUnsort: true,
    showIndexes: true
  };

  constructor(
    private dialogService: DialogService,
    private logger: NGXLogger,
    public cachedDataService: CachedDataService,
    private impersonationService: ImpersonationService,
    private roomService: RoomService,
    private route: ActivatedRoute,
    private router: Router,
    private loadingBar: LoadingBarService
  ) {
    super(EntityGlobals.ROOMS, router, route, impersonationService, logger);
    this.allData = this.allData.bind(this);
  }

  ngOnInit() {
    this.reloadData();
  }

  private reloadData() {
    this.loadingBar.useRef('router').start();
    let cmd: ListRoomsCommand = {};
    this.roomService
      .list(cmd)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: ListRoomsCommandResult) => {
        this.logger.debug('Room information received', result);
        this.loadingBar.useRef('router').complete();
        this.gridData = result.data.map(item => ({
          ...item,
          createdDate: new Date(item.created),
          lastUpdatedDate: new Date(item.lastUpdated)
        }));
      });
  }

  public allData(): ExcelExportData {
    this.logger.debug('this.gridData', this.gridData);
    const result: ExcelExportData = {
      data: process(this.gridData, {
        sort: this.sort
      }).data
      //group: this.group,
    };
    return result;
  }

  delete(id) {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this item?',
      actions: [
        { text: 'No' },
        {
          text: 'Yes',
          primary: true,
          id: id
        }
      ],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe((result: any) => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
        this.roomService._delete(result.id.toString()).subscribe((result: any) => {
          this.reloadData();
        });
      }
    });
  }
}
