import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthResult, NbAuthService, NbAuthToken } from '@nebular/auth';
import { AppGlobals } from '@wo-app/app.global';
import { ConfigLoaderService } from '@wo-app/core/services';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-oauth2-login',
  templateUrl: './oauth2-login.component.html',
  styleUrls: ['./oauth2-login.component.scss']
})
export class OAuth2LoginComponent implements OnDestroy, OnInit {
  logoUrl = AppGlobals.LOGO_URL;
  appVersion: any;
  siteName = AppGlobals.SITE_NAME;
  config: any;
  token: NbAuthToken;
  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private configLoader: ConfigLoaderService,
    private authService: NbAuthService,
    private logger: NGXLogger
  ) {
    this.configLoader.Config.subscribe(config => {
      this.config = config;

      this.authService.isAuthenticatedOrRefresh().subscribe((isAuthenticated: boolean) => {
        this.logger.debug('IS AUTHENTICATED', isAuthenticated);
        if (!isAuthenticated) {
          this.authService
            .authenticate(this.config.authServiceStrategyName)
            .pipe(takeUntil(this.destroy$))
            .subscribe((authResult: NbAuthResult) => {});
        } else {
          this.router.navigateByUrl('/');
        }
      });
    });
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
