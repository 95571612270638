/**
 * Wingman Operations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ImportJobType = 'Inventory' | 'Inventory Quantity Per Room' | 'Customer' | 'Manikin' | 'Equipment' | 'Equipment Line Item' | 'Manikin Line Item';

export const ImportJobType = {
    Inventory: 'Inventory' as ImportJobType,
    InventoryQuantityPerRoom: 'Inventory Quantity Per Room' as ImportJobType,
    Customer: 'Customer' as ImportJobType,
    Manikin: 'Manikin' as ImportJobType,
    Equipment: 'Equipment' as ImportJobType,
    EquipmentLineItem: 'Equipment Line Item' as ImportJobType,
    ManikinLineItem: 'Manikin Line Item' as ImportJobType
};

