import { Component, Input, OnInit } from '@angular/core';
import { TaskService, ToggleTaskCommand } from '@wo-api/index';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-board-cell',
  templateUrl: './board-cell.component.html',
  styleUrls: ['./board-cell.component.scss']
})
export class BoardCellComponent implements OnInit {
  @Input() organizationId: any;
  @Input() cellValue: any;
  public cellValueIsObject: boolean;
  public value: any;
  public checked: false;
  constructor(private logger: NGXLogger, private taskService: TaskService) {}

  ngOnInit() {
    this.cellValueIsObject = typeof this.cellValue === 'object';
    if (!this.cellValueIsObject) {
      this.value = this.cellValue;
    } else {
    }
  }

  changeCheckboxValue() {
    this.logger.debug(this.cellValue);
    const command: ToggleTaskCommand = {};
    command.id = this.cellValue.id;
    this.logger.debug(command);
    // this.taskService.tasksTogglestatusPost(command) .subscribe((result: ToggleTaskCommandResult) => {});
  }
}
