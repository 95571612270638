import { Component, Input, OnChanges, OnInit, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-checkbox-button-list',
  templateUrl: './checkbox-button-list.component.html',
  styleUrls: ['./checkbox-button-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxButtonListComponent),
      multi: true,
    },
  ],
})
export class CheckboxButtonListComponent
  implements OnInit, OnChanges, ControlValueAccessor {
  @Input() orientation: string;
  @Input() groupName: string;
  @Input() dataLabelField: string;
  @Input() dataValueField: string;
  @Input() showInline: boolean;
  @Input() set data(value) {
    this._data.next(value);
  }
  @Input() set checkedValue(value) {
    this._checkedValue.next(value);
  }
  private _data = new BehaviorSubject<any[]>([]);
  private _checkedValue = new BehaviorSubject<any>([]);

  enumData: any[];
  selectedValues: any[];
  constructor(private logger: NGXLogger) {}

  ngOnInit() {}

  writeValue(value: any) {
    if (value !== undefined) {
      this.selectedValues = value;
      this.logger.debug(value);
    }
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  get data() {
    return this._data.getValue();
  }

  get checkedValue() {
    return this._checkedValue.getValue();
  }

  ngOnChanges(changes: SimpleChanges) {
    // only run when property "data" changed
    if (changes['data']) {
      this.enumData = this.data;
    }
    if (changes['selectedValues']) {
      this.selectedValues = this.checkedValue;
    }
  }

  onSelectionChange(object) {
    const value = object.value;
    const index = this.selectedValues.indexOf(value);
    if (index < 0) {
      this.selectedValues.push(value);
    } else {
      this.selectedValues.splice(index, 1);
    }
    this.propagateChange(this.selectedValues);
  }

  getValueField(row): any {
    return row[this.dataValueField];
  }

  getLabelField(row): any {
    return row[this.dataLabelField];
  }
}
