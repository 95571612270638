/**
 * Wingman Operations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EntityType = 'Locations' | 'Buildings' | 'Rooms' | 'Beds' | 'Supply' | 'Equipment' | 'Manikins' | 'Vendors' | 'Category' | 'ImportJob';

export const EntityType = {
    Locations: 'Locations' as EntityType,
    Buildings: 'Buildings' as EntityType,
    Rooms: 'Rooms' as EntityType,
    Beds: 'Beds' as EntityType,
    Supply: 'Supply' as EntityType,
    Equipment: 'Equipment' as EntityType,
    Manikins: 'Manikins' as EntityType,
    Vendors: 'Vendors' as EntityType,
    Category: 'Category' as EntityType,
    ImportJob: 'ImportJob' as EntityType
};

