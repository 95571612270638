import { CommunicationBoardItemService } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { BoardCellComponent } from '@wo-app/components/dashboard/communication-board-item/board-cell/board-cell.component';
import { CommunicationBoardItemListComponent } from '@wo-app/components/dashboard/communication-board-item/communication-board-item-list/communication-board-item-list.component';
import { CommunicationBoardCalendarComponent } from '@wo-app/components/dashboard/communication-board/communication-board-calendar/communication-board-calendar.component';
import { CommunicationBoardDetailComponent } from '@wo-app/components/dashboard/communication-board/communication-board-detail/communication-board-detail.component';
import { ImpersonatingOrganizationCanActivate } from '@wo-app/core/guards';
import { RouteChild, RouteParent } from '@wo-app/shared/models/route';

export class CommunicationBoardItemRegistration {
  static Routes: RouteParent = {
    path: EntityGlobals.COMMUNICATION_BOARD.baseRoute,
    canActivate: [ImpersonatingOrganizationCanActivate],
    entityInformation: EntityGlobals.COMMUNICATION_BOARD,
    children: [
      {
        path: '',
        component: CommunicationBoardItemListComponent,
        data: {
          breadcrumb: 'View All'
        }
      } as RouteChild,
      {
        path: 'calendar',
        component: CommunicationBoardCalendarComponent,
        data: {
          breadcrumb: 'Calendar of Upcoming Scenarios'
        }
      } as RouteChild,
      {
        path: 'edit/:id',
        component: CommunicationBoardDetailComponent,
        data: {
          breadcrumb: EntityGlobals.COMMUNICATION_BOARD.singleName
        }
      } as RouteChild
    ]
  };

  static Services: any = [CommunicationBoardItemService];

  static Components: any = [
    CommunicationBoardCalendarComponent,
    CommunicationBoardItemListComponent,
    BoardCellComponent,
    CommunicationBoardDetailComponent
  ];
}
