<form [formGroup]="form" (ngSubmit)="onSubmit(false)" [hidden]="!showForm" class="entity-form">
    <div class="row">
        <div class="col-sm-12 col-md-6" [hidden]="editMode">
            <button kendoButton type="submit" [disabled]="!form.valid" [primary]="true" [icon]="'save'">Create {{this.GetSingleEntityName()}}</button>
        </div>
        <div class="col-sm-12 col-md-6" [hidden]="!editMode">
            <kendo-buttongroup>
                <!--<button kendoButton type="submit" [disabled]="!form.valid" [primary]="true" [icon]="'save'">Save and Continue</button>-->
                <button kendoButton type="button" (click)="submitAndClose()" [primary]="true" [disabled]="!form.valid" [icon]="'save'">Save and Close</button>
                <button kendoButton type="button" (click)="resetForm()" [primary]="false" [icon]="'cancel-circle'">Cancel</button>
            </kendo-buttongroup>
        </div>
        <div class="col-sm-12 col-md-6" [hidden]="!editMode">
            <div class="float-end">
                <kendo-buttongroup>
                    <!--<button kendoButton type="button" (click)="disable()" [icon]="'cancel-circle'">Disable {{this.GetSingleEntityName()}}</button>
                    <button kendoButton type="button" (click)="delete()" [icon]="'delete'">Delete {{this.GetSingleEntityName()}}</button>-->
                </kendo-buttongroup>
                <div kendoDialogContainer></div>
            </div>
        </div>
        <div class="col-sm-12" [hidden]="true">
            <kendo-buttongroup>
                <button kendoButton type="button" [primary]="true" [icon]="'user'">View Users</button>
            </kendo-buttongroup>
        </div>
        <div class="col-sm-12">
            <hr/>
        </div>
    </div>
    <app-validation-errors></app-validation-errors>
    <input type="hidden" id="id" formControlName="id" />
    <kendo-tabstrip>
        <kendo-tabstrip-tab [title]="'General'" [selected]="true">
            <ng-template kendoTabContent>
                <div class="row">
                    <div class=" col-sm-12">
                        <label for="name" class="col-form-label col-form-label-sm">Scenario Name</label>
                        <input kendoTextBox formControlName="name" id="name" class="form-control" placeholder="Scenario Name" />
                    </div>
                    <div class=" col-md-12">
                        <label for="description" class="col-form-label col-form-label-sm">Description</label>
                        <textarea kendoTextBox type="text" formControlName="description" id="description" class="form-control" placeholder="Description"></textarea>
                    </div>
                    <div class=" col-md-12">
                        <label for="learningObjectives" class="col-form-label col-form-label-sm">Learning Objectives</label>
                        <textarea kendoTextBox type="text" formControlName="learningObjectives" id="learningObjectives" class="form-control" placeholder="Learning Objectives"></textarea>
                    </div>
                    <div class=" col-md-12">
                        <label for="roomSetting" class="col-form-label col-form-label-sm">Room Setting</label>
                        <input kendoTextBox type="text" formControlName="roomSetting" id="roomSetting" class="form-control" placeholder="Room Setting">
                    </div>
                    <div class=" col-md-12">
                        <label for="lengthInMinutes" class="col-form-label col-form-label-sm">Length in Minutes</label>
                        <kendo-numerictextbox class="form-control" formControlName="lengthInMinutes" [min]="1" [max]="240" [format]="'n0'" ></kendo-numerictextbox>
                    </div>
                    <div class=" col-md-12">
                        <label for="patientMonitorDisplay" class="col-form-label col-form-label-sm">Patient Monitor Display</label>
                        <input kendoTextBox type="text" formControlName="patientMonitorDisplay" id="patientMonitorDisplay" class="form-control" placeholder="Patient Monitor Display">
                    </div>
                    <div class=" col-md-12">
                        <label for="specialInstructions" class="col-form-label col-form-label-sm">Special Instructions</label>
                        <textarea kendoTextBox type="text" formControlName="specialInstructions" id="specialInstructions" class="form-control" placeholder="Special Instructions"></textarea>
                    </div>
                    <input formControlName="organizationId" type="hidden">
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Patients'">
            <ng-template kendoTabContent>
                <app-patient-list-builder (patientListNotification)='onNotificationOfPatients($event)' [patients]='patients'></app-patient-list-builder>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Drugs'">
            <ng-template kendoTabContent>
                <!--<app-drug-list-builder (drugListNotification)='onNotificationOfDrugs($event)' [drugs]='drugs'></app-drug-list-builder>-->
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Supplies'">
            <ng-template kendoTabContent>

            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Equipment'">
            <ng-template kendoTabContent>

            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Metadata'" [disabled]="!editMode">
            <ng-template kendoTabContent>
                <app-metadata [metadata]="dataModel"></app-metadata>
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>
</form>