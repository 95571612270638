import { Route } from '@angular/router';
import { OrganizationService } from '@wo-api/index';
import { EntityGlobals, MenuGroupType } from '@wo-app/app.global';
import { OrganizationDetailComponent } from '@wo-app/components/dashboard/organizations/organization-detail/organization-detail.component';
import { OrganizationsComponent } from '@wo-app/components/dashboard/organizations/organizations.component';
import { NotImpersonatingOrganizationCanActivate } from '@wo-app/core/guards';
export class OrganizationsRegistration {
  static Routes: Route = {
    path: EntityGlobals.ORGANIZATIONS.baseRoute,
    canActivate: [NotImpersonatingOrganizationCanActivate],
    data: {
      entityInformation: EntityGlobals.ORGANIZATIONS,
      menuGroups: [MenuGroupType.Admin]
    },
    children: [
      {
        path: '',
        component: OrganizationsComponent,
        data: {
          breadcrumb: 'List '
        }
      },
      {
        path: 'edit/:id',
        component: OrganizationDetailComponent,
        data: {
          breadcrumb: 'Edit ' + EntityGlobals.ORGANIZATIONS.singleName
        }
      },
      {
        path: 'add',
        component: OrganizationDetailComponent,
        data: {
          breadcrumb: 'Add ' + EntityGlobals.ORGANIZATIONS.singleName
        }
      }
    ]
  };

  static Services: any = [OrganizationService];

  static Components: any = [OrganizationsComponent, OrganizationDetailComponent];
}
