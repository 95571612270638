<form class="entity-form" [hidden]="!showForm" [formGroup]="form" (ngSubmit)="onSubmit(false)">
  <div class="row">
    <!-- Action Buttons -->
    <div class="col-sm-12 col-md-6" [hidden]="editMode">
      <button kendoButton
              type="submit"
              [disabled]="!form.valid"
              [primary]="true"
              [icon]="'save'">
        Create {{this.GetSingleEntityName()}}
      </button>
    </div>

    <div class="col-sm-12 col-md-12" [hidden]="!editMode">
      <kendo-buttongroup>
          <button kendoButton
                  type="button"
                  [primary]="true"
                  [disabled]="isSaveAvailable()"
                  [icon]="'save'"
                  (click)="save()">
            Save
          </button>
          <button kendoButton
                  type="button"
                  [primary]="false"
                  [icon]="'cancel-circle'"
                  (click)="cancel()">
            Cancel
          </button>
          <button kendoButton
                  [disabled]="!canFillOrder()"
                  type="button"
                  [primary]="true"
                  [icon]="'shopping-cart'"
                  (click)="fillOrder()">
            Fill Order
          </button>
        </kendo-buttongroup>
    </div>

    <div class="col-sm-12 col-md-6" [hidden]="!editMode">
        <div class="float-end">
            <div kendoDialogContainer></div>
        </div>
    </div>

    <!-- What is this for? -->
    <div class="col-sm-12" [hidden]="true">
        <kendo-buttongroup>
            <button kendoButton
                    icon="user"
                    type="button"
                    [primary]="true">
              View Users
            </button>
        </kendo-buttongroup>
    </div>

    <div class="col-sm-12">
        <hr/>
    </div>
  </div>

  <app-validation-errors></app-validation-errors>
  <input type="hidden" id="id" formControlName="id"/>

  <kendo-tabstrip>
    <kendo-tabstrip-tab [title]="'General'" [selected]="true">
      <ng-template kendoTabContent>
        <div class="row">
          <div class="col-sm-12" [hidden]="copyMode">
            <h5 class="float-end">
              <span class="badge bg-primary">{{ pickOrderStatus }}</span>
            </h5>
          </div>
          <div class="col-sm-12">
            <label for="dateNeeded" class="col-form-label col-form-label-sm">Date Needed</label>
            <kendo-datepicker class="form-control" formControlName="dateNeeded" id="dateNeeded" placeholder="Choose a date ..."></kendo-datepicker>
            <div *ngIf="dateNeeded.invalid && dateNeeded.errors?.['isAfterDateOfReturn']" class="text-danger">
              <small>Date needed must be on or before date of return.</small>
            </div>
            <div *ngIf="dateNeeded.invalid && dateNeeded.errors?.['isInPast']" class="text-danger">
              <small>Date needed must be on or after today.</small>
            </div>
          </div>
          <div class="col-sm-12">
            <label for="courseName" class="col-form-label col-form-label-sm">
              Course
            </label>
            <input kendoTextBox
              formControlName="courseName"
              id="courseName"
              class="form-control"/>
          </div>
          <div class="col-sm-12">
            <label for="studentCount" class="col-form-label col-form-label-sm">
              Number of Students
            </label>
            <kendo-numerictextbox required add-required-asterisk
              formControlName="studentCount"
              id="studentCount"
              [min]="1"
              [format]="'n0'">
            </kendo-numerictextbox>
            <validation-error-message [control]="studentCount"></validation-error-message>
          </div>
          <div class="col-sm-12">
            <label for="customerId" class="col-form-label col-form-label-sm">
              Customer
            </label>
            <kendo-dropdownlist required add-required-asterisk
              class="form-control"
              [valuePrimitive]="true"
              formControlName="customerId"
              [data]="customersData"
              textField="fullName"
              valueField="id"
              [filterable]="true"
              (filterChange)="onCustomerFilterChange($event)"
              (selectionChange)="onCustomerSelectionChange($event)">
            </kendo-dropdownlist>
            <validation-error-message [control]="customerId"></validation-error-message>
          </div>
          <div class="col-sm-12">
            <label for="buildingId" class="col-form-label col-form-label-sm">
              Building
            </label>
            <kendo-dropdownlist required add-required-asterisk
              class="form-control"
              [valuePrimitive]="true"
              formControlName="buildingId"
              [data]="buildingsData"
              textField="name"
              valueField="id"
              [filterable]="true"
              (filterChange)="onBuildingsFilterChange($event)">
            </kendo-dropdownlist>
            <validation-error-message [control]="buildingId"></validation-error-message>
          </div>
          <div class="col-sm-12">
            <label for="deliveryLocation" class="col-form-label col-form-label-sm">
              Delivery Location
            </label>
            <input kendoTextBox required add-required-asterisk
              formControlName="deliveryLocation"
              id="deliveryLocation"
              class="form-control"/>
            <validation-error-message [control]="deliveryLocation"></validation-error-message>
          </div>
          <div class="col-sm-12">
            <label for="dateOfReturn" class="col-form-label col-form-label-sm">Date of Return</label>
            <kendo-datepicker class="form-control" formControlName="dateOfReturn" id="dateOfReturn" placeholder="Choose a date ..."></kendo-datepicker>
            <div *ngIf="dateOfReturn.invalid && dateOfReturn.errors?.['isBeforeNeeded']" class="text-danger">
              <small>Date of return must be on or after date needed.</small>
            </div>
          </div>
          <div class="col-sm-12">
            <label for="assigneeUserId" class="col-form-label col-form-label-sm">
              Assigned To
            </label>
            <kendo-dropdownlist
              class="form-control"
              formControlName="assigneeUserId"
              textField="fullName"
              valueField="id"
              [valuePrimitive]="true"
              [data]="usersData"
              [filterable]="true"
              (filterChange)="onUsersFilterChange($event)">
            </kendo-dropdownlist>
            <div *ngIf="!canAssignUser" class="text-warning">
              <small><i class="fa-solid fa-circle-info"></i> Add Items and Quantity Requested to the Pick Order before assigning.</small>
            </div>
          </div>
          <div class="col-md-12">
            <label for="orderNotes" class="col-form-label col-form-label-sm">
              Order Notes
            </label>
            <textarea kendoTextBox
              type="text"
              add-required-asterisk
              formControlName="orderNotes"
              id="orderNotes"
              class="form-control"
              [rows]="3">
            </textarea>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab [title]="'Inventory Items'">
      <ng-template kendoTabContent>

        <div class="col-sm-12 pb-4">
          <div class="row">
            <div class="row pb-2">
              <div class="col">
                <h6 for="supplyQuantities" class="col-form-label col-form-label-sm">Supplies</h6>
              </div>
              <div class="col">
                <button *ngIf="showInventoryItemsAction('add')" kendoButton type="button" class="btn btn-secondary text-light float-end " (click)="showSuppliesSelectionDialog()" [icon]="'plus'"> Add</button>
                <button *ngIf="showInventoryItemsAction('reserveAll')" kendoButton type="button" class="btn btn-secondary text-light float-end me-2" (click)="reserveAllAvailable(inventoryItemTypeSupply)" [icon]="'aggregate-fields'"> Reserve All Available</button>
                <button *ngIf="showInventoryItemsAction('refresh')"kendoButton type="button" class="btn btn-secondary text-light float-end me-2" (click)="getSupplies()" [icon]="'refresh'"> Refresh</button>
              </div>
            </div>
            <div class="col-align-self-end">
              <app-pick-order-supplies-grid *ngIf="this.selectedSupplies.length"
                [gridDataInput]="this.selectedSupplies"
                [pickOrderStatus]="pickOrderStatus"
                (gridDataChange)="onInventoryItemChange($event, inventoryItemTypeSupply)"
                (inventoryItemReservationEmitter)="reserveSupplies($event)">
              </app-pick-order-supplies-grid>
            </div>
          </div>
        </div>

        <hr *ngIf="!this.selectedSupplies.length" class="mt-2 mb-3"/>

        <div class="col-sm-12 pb-4">
          <div class="row">
            <div class="row pb-2">
              <div class="col">
                <h6 for="supplyQuantities" class="col-form-label col-form-label-sm">Equipment</h6>
              </div>
              <div class="col">
                <button *ngIf="showInventoryItemsAction('add')"  kendoButton type="button" class="btn btn-secondary text-light float-end" (click)="showEquipmentSelectionDialog()" [icon]="'plus'">Add</button>
                <button *ngIf="showInventoryItemsAction('reserveAll')" kendoButton type="button" class="btn btn-secondary text-light float-end me-2" (click)="reserveAllAvailable(inventoryItemTypeEquipment)" [icon]="'aggregate-fields'"> Reserve All Available</button>
                <button *ngIf="showInventoryItemsAction('refresh')"kendoButton type="button" class="btn btn-secondary text-light float-end me-2" (click)="getEquipment()" [icon]="'refresh'"> Refresh</button>
              </div>
            </div>
            <div class="col-align-self-end">
              <app-pick-order-equipment-grid *ngIf="this.selectedEquipment.length"
                [gridDataInput]="this.selectedEquipment"
                [pickOrderStatus]="pickOrderStatus"
                (gridDataChange)="onInventoryItemChange($event, inventoryItemTypeEquipment)"
                (inventoryItemReservationEmitter)="reserveEquipment($event)">
              </app-pick-order-equipment-grid>
            </div>
          </div>
        </div>

        <hr *ngIf="!this.selectedEquipment.length" class="mt-2 mb-3"/>

        <div class="col-sm-12 pb-4">
          <div class="row">
            <div class="row pb-2">
              <div class="col">
                <h6 for="supplyQuantities" class="col-form-label col-form-label-sm">Manikin/Trainer</h6>
              </div>
              <div class="col">
                <button *ngIf="showInventoryItemsAction('add')" kendoButton type="button" class="btn btn-secondary text-light float-end" (click)="showManikinsSelectionDialog()" [icon]="'plus'">Add</button>
                <button *ngIf="showInventoryItemsAction('reserveAll')" kendoButton type="button" class="btn btn-secondary text-light float-end me-2" (click)="reserveAllAvailable(inventoryItemTypeManikin)" [icon]="'aggregate-fields'"> Reserve All Available</button>
                <button *ngIf="showInventoryItemsAction('refresh')" kendoButton type="button" class="btn btn-secondary text-light float-end me-2" (click)="getManikins()" [icon]="'refresh'"> Refresh</button>
              </div>
            </div>
            <div class="col-align-self-end">
              <app-pick-order-manikins-grid *ngIf="this.selectedManikins.length"
                [gridDataInput]="this.selectedManikins"
                [pickOrderStatus]="pickOrderStatus"
                (gridDataChange)="onInventoryItemChange($event, inventoryItemTypeManikin)"
                (inventoryItemReservationEmitter)="reserveManikins($event)">
              </app-pick-order-manikins-grid>
            </div>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab [title]="'Metadata'" [disabled]="!editMode">
      <ng-template kendoTabContent>
          <app-metadata [metadata]="dataModel"></app-metadata>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</form>
