<autopilot-grid>
  <kendo-grid
    [data]="view"
    [style.maxHeight.%]="100"
    [pageSize]="state.take"
    [skip]="state.skip"
    [sort]="state.sort"
    [filterable]="false"
    [sortable]="true"
    [pageable]="true"
    (dataStateChange)="dataStateChange($event)">
      <ng-template kendoGridToolbarTemplate>
          <button kendoButton type="button" (click)="addNewWithOrganizationId(this.organizationId)" [icon]="'plus'">Add {{this.GetSingleEntityName()}}</button>
      </ng-template>
      <kendo-grid-column field="name" title="Name">
          <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem.name}}
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="created" title="Date Created">
          <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.created | amCalendar }}
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="lastUpdated" title="Date Last Updated">
          <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.lastUpdated | amCalendar }}
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Actions" class="k-col-actions">
          <ng-template kendoGridCellTemplate let-dataItem>
              <button kendoButton [primary]="true" [icon]="'pencil'" (click)="routeToEditWithOrganization(dataItem.id)">Edit</button>
          </ng-template>
      </kendo-grid-column>
  </kendo-grid>
</autopilot-grid>

<router-outlet></router-outlet>
