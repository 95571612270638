import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'default'
})
export class DefaultPipe implements PipeTransform {
  transform(value: any, defaultValue: string | number = '--'): string {
    if (typeof value === 'string') {
      value = value.trim();
    }

    return (value ? value : defaultValue).toString();
  }
}
