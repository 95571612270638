<form [formGroup]="form" (ngSubmit)="addNewDrawer()">
    <div class="btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar with button groups">
        <div class="btn-group" role="group" aria-label="First group">
        </div>
        <div class="input-group">
            <input kendoTextBox type="text" class="form-control-sm" formControlName="addChecklistDrawerName">
            <button kendoButton class="btn btn-primary" type="button" (click)="submitAndClose()" [primary]="true" [disabled]="!form.valid" [icon]="'add'">Add New Drawer</button>
        </div>
    </div>
</form>
<kendo-sortable [navigatable]="true" [animation]="true" [data]="listChecklistDrawers">
    <ng-template let-item="item">
        <div class="drawer">
            <nav class="navbar navbar-expand-lg navbar-light bg-light">
                <span class="navbar-brand">
                    <input kendoTextBox class="form-control form-control-lg" [(ngModel)]="item.name">
                </span>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <input [(colorPicker)]="item.hexColorBackground" [style.background]="item.hexColorBackground" />
                    <input [(colorPicker)]="item.hexColorForeground" [style.background]="item.hexColorForeground" />
                </div>
                <div class="btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar with button groups">
                    <div class="btn-group" role="group" aria-label="First group">
                    </div>
                    <kendo-buttongroup>
                        <button kendoButton [primary]="true" type="button" class="btn btn-primary" (click)="saveDrawer(item)" [icon]="'save'">Save</button>
                        <button kendoButton type="button" class="btn btn-primary" (click)="deleteDrawer(item)" [icon]="'delete'">Delete</button>
                    </kendo-buttongroup>
                </div>
            </nav>
            <div>
                <table class="table table-bordered table-sm checklist-table">
                    <thead>
                        <th>Item Name</th>
                        <th>Quantity</th>
                        <th>Actions</th>
                    </thead>
                    <tbody>
                        <tr *ngFor='let row of item.drawerContents'>
                            <td><input kendoTextBox class="form-control form-control-sm" [(ngModel)]="row.name"></td>
                            <td>
                                <kendo-numerictextbox class="form-control form-control-sm" [(ngModel)]="row.quantity" [min]="1" [max]="999" [format]="'n0'" ></kendo-numerictextbox>
                            </td>
                            <td><button kendoButton type="button" class="btn btn-primary" (click)="deleteRowFromDrawer(item, row)">Delete Row</button></td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="3"><button kendoButton class="btn btn-primary" type="button" (click)="addRowToDrawer(item)">Add Row</button></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </ng-template>
</kendo-sortable>