<div class="row text-center">
  <h1>{{getBaseType()}}: {{manikinLineItem?.nickname}}</h1>
  <p>Type: <strong>{{getSubType()}}</strong></p>
  <p>Model: <strong>{{getModelName()}}</strong></p>
</div>
<hr>
<div class="row text-center">
  <p class="fs-6">Location: <strong>{{getLocationName()}}</strong></p>
  <p class="fs-6">Building: <strong>{{getBuildingName()}}</strong></p>
  <p class="fs-6">Room: <strong>{{getRoomName()}}</strong></p>
  <p class="fs-6">Station: <strong>{{getStationName()}}</strong></p>
</div>
<hr>
<kendo-label text="Location">
  <kendo-dropdownlist
    [data]="locations"
    textField="name"
    [value]="selectedLocation"
    (valueChange)="onSelectLocation($event)"
    valueField="id">
  </kendo-dropdownlist>
</kendo-label>
<kendo-label text="Building">
  <kendo-dropdownlist
    [data]="filteredBuildings"
    [disabled]="!selectedLocation"
    textField="name"
    [value]="selectedBuilding"
    (valueChange)="onSelectBuilding($event)"
    valueField="id">
  </kendo-dropdownlist>
</kendo-label>
<kendo-label text="Room">
  <kendo-dropdownlist
    [data]="filteredRooms"
    [disabled]="!selectedBuilding"
    textField="name"
    [value]="selectedRoom"
    (valueChange)="onSelectRoom($event)"
    valueField="id">
  </kendo-dropdownlist>
</kendo-label>
<kendo-label text="Station">
  <kendo-dropdownlist
    [data]="filteredStations"
    [disabled]="!selectedRoom"
    textField="customId"
    [value]="selectedStation"
    (valueChange)="onSelectStation($event)"
    valueField="id">
  </kendo-dropdownlist>
</kendo-label>
<div class="row text-center">
  <button
    kendoButton
    [disabled]="!isRequiredFieldsDefined()"
    size="large"
    themeColor="success"
    (click)="toggleDialog()"
  >
    Move Manikin/Trainer
  </button>
</div>
<kendo-dialog
  title="Please confirm"
  *ngIf="isDialogVisible"
  (close)="toggleDialog()"
>
  <div class="row text-center">
    <p>Confirm that you want to move this manikin/trainer to:</p>
    <p>Location: <strong>{{selectedLocation.name}}</strong></p>
    <p>Building: <strong>{{selectedBuilding.name}}</strong></p>
    <p>Room: <strong>{{selectedRoom.name}}</strong></p>
    <p>Station: <strong>{{selectedStation?.customId || 'None'}}</strong></p>
  </div>
  <kendo-dialog-actions>
    <button
      kendoButton
      themeColor="primary"
      (click)="onConfirm()"
    >
      Confirm
    </button>
  </kendo-dialog-actions>
</kendo-dialog>