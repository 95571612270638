<autopilot-grid>
  <kendo-grid
  [kendoGridBinding]="gridData"
  [style.maxHeight.%]="100"
  [pageSize]="state.take"
  [filterable]="'menu'"
  [sortable]="sortSettings"
  [sort]="sort"
  [pageable]="true">
  <ng-template kendoGridToolbarTemplate>
      <div class="container-fluid g-0 d-flex flex-wrap justify-content-center">
          <form class="col-auto me-auto" role="search">
              <button kendoButton type="button" class="btn btn-secondary text-light" (click)="addNewWithOrganizationId(organizationId)" [icon]="'plus'">Add {{this.GetSingleEntityName()}}</button>
          </form>
          <div class="text-end">
                  <button kendoGridPDFCommand class="btn btn-secondary text-white" title="Export as PDF"><i class="fa-light fa-file-pdf"></i></button>
                  <button kendoGridExcelCommand title="Export to Excel" class="btn btn-secondary text-white"><i class="fa-light fa-file-excel"></i></button>
          </div>
      </div>
  </ng-template>
  <kendo-grid-column title="Actions" [width]="108" class="k-col-actions">
      <ng-template kendoGridCellTemplate let-dataItem>
              <button kendoButton
                      class="btn btn-outline-primary default-action-button"
                      [primary]="true"
                      (click)="routeToEditWithOrganization(dataItem.id)"
                      title="Edit this item">
                  <i class="fas fa-edit"></i>
              </button>
              <button kendoButton
                      [primary]="true"
                      class="btn btn-outline-danger default-action-button"
                      (click)="delete(dataItem.id)"
                      title="Delete this item">
                <i class="fas fa-trash"></i>
              </button>
      </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="customId" title="Station">
      <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
          <kendo-grid-string-filter-menu
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
              [extra]="false">
          </kendo-grid-string-filter-menu>
      </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="roomName" title="Room">
      <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
          <kendo-grid-string-filter-menu
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
              [extra]="false">
          </kendo-grid-string-filter-menu>
      </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="createdDate" title="Date Created">
      <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
          <kendo-grid-date-filter-menu
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
              [extra]="false">
          </kendo-grid-date-filter-menu>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.createdDate | amCalendar }}
      </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="lastUpdatedDate" title="Date Last Updated">
      <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
          <kendo-grid-date-filter-menu
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
              [extra]="false">
          </kendo-grid-date-filter-menu>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.lastUpdatedDate | amCalendar }}
      </ng-template>
  </kendo-grid-column>
  <kendo-grid-pdf [fileName]="pdfFileName" paperSize="A4" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
  </kendo-grid-pdf>
  <kendo-grid-excel [fileName]="excelFileName"></kendo-grid-excel>
</kendo-grid>
</autopilot-grid>

<router-outlet></router-outlet>
<div kendoDialogContainer></div>
