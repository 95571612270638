import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import {
  CategoryService,
  CreateCategoryCommand,
  CreateCategoryCommandResult,
  CreateSupplyCommand,
  CreateSupplyCommandResult,
  EntityType,
  EnumListResult,
  EnumValue,
  ListCategoryViewModel,
  ListCategorysCommand,
  ListCategorysCommandResult,
  ListVendorsCommandResult,
  OrganizationService,
  ReadSupplyCommandResult,
  SupplyLocationQuantityService,
  SupplyService,
  UpdateSupplyCommand,
  VendorService,
  VendorViewModel
} from '@wo-api/index';
import { EntityGlobals, ValidationMaxStringLength } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { CachedDataService, ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
import { Observable, map, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-detail',
  templateUrl: './inventory-item-detail.component.html',
  styleUrls: ['./inventory-item-detail.component.scss']
})
export class InventoryItemDetailComponent extends EntityBaseComponent implements OnInit {
  ReadSupplyCommandResult: ReadSupplyCommandResult = {};
  dataModel: ReadSupplyCommandResult;
  UpdateSupplyCommand: UpdateSupplyCommand = {};
  CreateSupplyCommand: CreateSupplyCommand = {};
  form: FormGroup;
  originalFormState: FormGroup;
  fromKey: string;
  inventoryItemTaskTypes: EnumValue[] = [];
  public $inventoryItemTypes: Observable<EnumListResult>;
  public $categories: Observable<ListCategorysCommandResult>;
  public formGroup: FormGroup;
  public locationsAndStocks: Observable<GridDataResult>;

  //TODO: Why is there even a grid state here? I don't see a kendo grid in this form at all..
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 5
  };
  public listItems: any[] = [];
  public $vendors: Observable<ListVendorsCommandResult>;
  public vendorDefaultItem: VendorViewModel = {
    id: -1,
    vendorName: 'Select an item...'
  };

  public valueNormalizer = (text$: Observable<string>): any =>
    text$.pipe(
      switchMap((text: string) => {
        // Search in values
        const matchingValue: any = this.listItems.find((item: ListCategoryViewModel) => {
          // Search for matching item to avoid duplicates
          return item.categoryName.toLowerCase() === text.toLowerCase();
        });

        if (matchingValue) {
          // Return the already selected matching value and the component will remove it
          return of(matchingValue);
        }

        // Search in data
        const matchingItem: any = this.listItems.find((item: ListCategoryViewModel) => {
          return item.categoryName.toLowerCase() === text.toLowerCase();
        });

        if (matchingItem) {
          return of(matchingItem);
        } else {
          return of(text).pipe(switchMap(this.service$));
        }
      })
    );

  public service$ = (text: string): any => {
    let cmd: CreateCategoryCommand = {
      name: text,
      entityType: EntityType.Supply
    };
    return this.categoryService.create(cmd).subscribe((value: CreateCategoryCommandResult) => {
      this.listItems.push(value);
      return value;
    });
  };

  get isNew() {
    return !this.id && !this.editMode;
  }

  get name() {
    return this.form.get('name');
  }

  get stockingQuantityUnitOfMeasurement() {
    return this.form.get('stockingQuantityUnitOfMeasurement');
  }

  get customId() {
    return this.form.get('customId');
  }

  get description() {
    return this.form.get('description');
  }

  get notes() {
    return this.form.get('notes');
  }

  get manufacturerVendorId() {
    return this.form.get('manufacturerVendorId');
  }

  get manufacturerNumber() {
    return this.form.get('manufacturerNumber');
  }

  get accountName() {
    return this.form.get('accountName');
  }

  get accountNumber() {
    return this.form.get('accountNumber');
  }

  get productSku() {
    return this.form.get('productSku');
  }

  get purchaseOrderNumber() {
    return this.form.get('purchaseOrderNumber');
  }

  get invoiceNumber() {
    return this.form.get('invoiceNumber');
  }

  get purchasingNotes() {
    return this.form.get('purchasingNotes');
  }

  get categories() {
    return this.form.get('categories');
  }

  constructor(
    private logger: NGXLogger,
    private vendorService: VendorService,
    private categoryService: CategoryService,
    public cachedDataService: CachedDataService,
    private impersonationService: ImpersonationService,
    private organizationService: OrganizationService,
    private SupplyService: SupplyService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService,
    private SupplyLocationQuantityService: SupplyLocationQuantityService,
    breadcrumbService: BreadcrumbsService,
    titleService: Title
  ) {
    super(EntityGlobals.SUPPLIES, router, route, impersonationService, logger, breadcrumbService, titleService);

    this.$inventoryItemTypes = this.SupplyService.listSupplyTypes();
    this.$vendors = this.vendorService.list();

    let cmd: ListCategorysCommand = { entityType: EntityType.Supply };
    this.$categories = this.categoryService.list(cmd);
    this.$categories.subscribe((value: ListCategorysCommandResult) => {
      this.logger.debug('Categories for Supplies', value);
      this.listItems = value.data;
    });
  }

  public ngOnInit() {
    this._createForm();
    this.fromKey = 'FormModel';

    if (this.editMode) {
      this._getData();
    }

    if (!this.isNew) {
      this._refreshDataOnPage();
    }

    this.showForm = true;
  }

  private _createForm() {
    this.form = this.fb.group({
      id: [''],
      name: ['', [Validators.required, Validators.maxLength(ValidationMaxStringLength.Default)]],
      minimumStockingQuantity: [0],
      stockingQuantityUnitOfMeasurement: ['', [Validators.maxLength(ValidationMaxStringLength.Short)]],
      customId: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      description: ['', [Validators.maxLength(ValidationMaxStringLength.Long)]],
      notes: ['', [Validators.maxLength(ValidationMaxStringLength.Long)]],

      manufacturerVendorId: ['', Validators.required],
      manufacturerNumber: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],

      created: [''],
      createByUsername: [''],
      createdByUserId: [''],
      lastUpdated: [''],
      lastUpdatedByUsername: [''],
      lastUpdatedByUserId: [''],

      purchasingVendorId: [null],
      costPerItem: [null],
      costPerUnit: [null],
      accountName: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      accountNumber: ['', [Validators.maxLength(ValidationMaxStringLength.Short)]],
      productSku: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      purchaseOrderNumber: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      invoiceNumber: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      purchasingNotes: ['', [Validators.maxLength(ValidationMaxStringLength.Long)]],
      fundedBy: [''],
      dateAcquired: [null],
      categories: ['']
    });
    this.originalFormState = this.form;
  }

  private _getData(): void {
    this.SupplyService.read(this.id).subscribe((result: ReadSupplyCommandResult) => {
      this.UpdateBreadcrumbPageTitle(result.id, result.name);
      this._convertServerDataToFormModel(result);
    });
  }

  private _refreshDataOnPage() {
    //TODO: why is this necessary? We're not using grid in this form.
    this.locationsAndStocks = this.SupplyLocationQuantityService.forInventoryItem(this.id).pipe(
      map(data => process(data.data, this.gridState))
    );
  }

  private _convertServerDataToFormModel(result: ReadSupplyCommandResult) {
    this.dataModel = result;
    this.dataModel = this._parseObjectDates(this.dataModel);
    this.logger.debug(this.dataModel);
    this.form.patchValue(this.dataModel);
    this.logger.debug(this.originalFormState.pristine, this.originalFormState);
    if (this.originalFormState.pristine) {
      this.logger.debug('Setting Original Form State Value');
      this.originalFormState.patchValue(this.dataModel);
    }
  }

  private _parseObjectDates(target: any): any {
    this.logger.debug('Parsing Object Dates....');
    const result = Object.assign({}, target);
    var dateFields = ['dateAcquired', 'warrantyExpirationDate'];
    dateFields.forEach(key => {
      const date = new Date(result[key]);
      if (!isNaN(date.getTime())) {
        result[key] = date;
      } else {
        result[key] = null;
      }
    });
    return result;
  }

  public resetForm() {
    this.logger.debug(this.originalFormState);
    this.form.reset(this.originalFormState.value);
    this.routeToEntityList();
  }

  public onSubmit(closeOnSuccess) {
    if (this.editMode) {
      this._updateItem(closeOnSuccess);
    } else {
      this._createItem();
    }
  }

  private _updateItem(closeOnSuccess: boolean) {
    this.UpdateSupplyCommand = this.form.value;
    this.UpdateSupplyCommand.categories = !!this.categories.value ? this.categories.value?.map(item => item.id) : null;

    this.SupplyService.update(this.UpdateSupplyCommand.id.toString(), this.UpdateSupplyCommand).subscribe(result => {
      this.logger.debug(result);
      if (closeOnSuccess) {
        this.routeToEntityList();
      } else {
        this._convertServerDataToFormModel(result);
        this.toastService.success('Success!', 'This item has been saved.');
      }
    });
  }

  private _createItem() {
    this.CreateSupplyCommand = this.form.value;
    this.CreateSupplyCommand.categories = !!this.categories.value ? this.categories.value?.map(item => item.id) : null;

    this.SupplyService.create(this.CreateSupplyCommand).subscribe((result: CreateSupplyCommandResult) => {
      this.routeToEntityList();
      this.editMode = true;
      this.toastService.success('Success!', 'This item has been saved.');
    });
  }

  public submitAndClose() {
    this.onSubmit(true);
  }

  public disable() {
    //TODO: implement
  }

  public delete() {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this inventoryItem?',
      actions: [{ text: 'No' }, { text: 'Yes', primary: true }],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
      }
      this.toastService.success('Success!', 'This item has been saved.');
    });
  }
}
