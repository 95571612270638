export const AppGlobals = {
    SITE_NAME: 'AutoPilot by Wingman Operations',
    ADMIN_ROUTE_PREFIX: '',
    LOGO_URL: 'assets/images/logo/logo_horizontal.svg',
    CACHE_KEY_PREFIX: 'AutoPilot'
};

export function GetEntityInformation(singleEntityName: string, pluralEntityName: string, baseRoute: string, icon?: string): any {
    return {
        singleEntityName: singleEntityName,
        pluralEntityName: pluralEntityName,
        baseRoute: baseRoute,
        icon: icon
    };
}
export class EntityInformation {
    constructor(singleEntityName: string, pluralEntityName: string, baseRoute: string, icon?: string, description?: string) {
        this.singleName = singleEntityName;
        this.pluralName = pluralEntityName;
        this.baseRoute = baseRoute;
        this.icon = icon;
        this.description = description;
    }

    public readonly singleName: string;
    public readonly pluralName: string;
    public readonly baseRoute: string;
    public readonly icon?: string;
    public readonly description?: string;
}

export enum MenuGroupType {
    Admin,
    Organization,
    PhysicalSpaces,
    People,
    InventoryAndEquipment,
    Settings
}

export const EntityGlobals = {
  // ADMIN ONLY ITEMS
  ORGANIZATIONS: new EntityInformation('Organization', 'Organizations', 'organizations', 'sitemap'),
  DASHBOARDS: new EntityInformation('Dashboard', 'Dashboards', '', 'dashboard'),
  USERS: new EntityInformation('User', 'Users', 'users', 'users'),
  MANIKIN_MAKES_AND_MODELS: new EntityInformation(
    'Manikin or Trainer',
    'Manikins & Trainers',
    AppGlobals.ADMIN_ROUTE_PREFIX + 'manikin-make-and-model',
    'fal fa-users-cog',
    'For tracking and managing manikin inventory.'
  ),
  MANIKIN_MAKES_AND_MODEL_PROCEDURES: new EntityInformation(
    'Manikin Make and Model Procedure',
    'Manikin Make and Model Procedures',
    AppGlobals.ADMIN_ROUTE_PREFIX + 'manikin-make-and-model-procedures',
    'tasks'
  ),
  //APPLICATION_DEPLOYMENTS: new EntityInformation('Application Deployment', 'Application Deployments', AppGlobals.ADMIN_ROUTE_PREFIX + 'application-deployments', 'cogs'),
  //APPLICATION_BUILDS: new EntityInformation('Application Build', 'Application Builds', AppGlobals.ADMIN_ROUTE_PREFIX + 'application-builds', 'box-open'),
  EMAIL_TEMPLATES: new EntityInformation(
    'Email Template',
    'Email Templates',
    AppGlobals.ADMIN_ROUTE_PREFIX + 'email-templates',
    'envelope'
  ),
  SETTINGS: new EntityInformation('Setting', 'Settings', AppGlobals.ADMIN_ROUTE_PREFIX + 'settings', 'gear'),
  MYPROFILE: new EntityInformation('My Profile', 'My Profile', 'my-profile', 'user-pen'),

  DRUGS: new EntityInformation('Drug', 'Drugs', 'drug', 'capsules'),
  SUPPLIES: new EntityInformation('Supply', 'Supplies', 'supplies', 'inventory', 'For tracking and managing consumable supplies.'),
  EQUIPMENT: new EntityInformation('Equipment', 'Equipment', 'equipment', 'wheelchair', 'For tracking and managing equipment.'),
  LOCATIONS: new EntityInformation(
    'Location',
    'Locations',
    'locations',
    'globe-americas',
    'A Location is a specific general campus or area, sometimes sharing the exact same address.'
  ),
  CUSTOMERS: new EntityInformation(
    'Customer',
    'Customers',
    'customers',
    'fa-light fa-head-side-headphones',
    'A Customer is a person that is utilizing the services of the lab.'
  ),
  VENDORS: new EntityInformation(
    'Vendor',
    'Vendors',
    'vendors',
    'people-carry-box',
    'A vendor is a third-party company that makes, sells or supports objects within our system.'
  ),
  BUILDINGS: new EntityInformation(
    'Building',
    'Buildings',
    'buildings',
    'hospital',
    'A Building is a specific general campus or area, sometimes sharing the exact same address.'
  ),
  ROOMS: new EntityInformation('Room', 'Rooms', 'rooms', 'person-booth', 'A room is a specific room inside of a building.'),
  BEDS: new EntityInformation(
    'Station',
    'Stations',
    'stations',
    'bed',
    'A station is a specific station/bed within a room, inside of a building which exists at a location.'
  ),
  COURSES: new EntityInformation('Course', 'Courses', 'courses', 'graduation-cap'),
  PATIENTS: new EntityInformation('Patient', 'Patients', 'patients', 'heartbeat'),
  CHECKLISTS: new EntityInformation('Checklist', 'Checklists', 'checklists', 'tasks'),
  SIM_MAINTENANCE_TASKS: new EntityInformation('Sim Maintenance Task', 'Sim Maintenance Tasks', 'sim-naintenance-tasks', 'square'),
  LAB_MAINTENANCE_TASKS: new EntityInformation('Lab Maintenance Task', 'Lab Maintenance Tasks', 'lab-maintenance-tasks', 'thumbtack'),
  SCENARIOS: new EntityInformation('Scenario', 'Scenarios', 'scenarios', 'clipboard-list'),
  SKILLS: new EntityInformation('Skill', 'Skills', 'skills', 'hand-paper'),
  LAB_REQUESTS: new EntityInformation('Lab Request', 'Lab Requests', 'lab-requests', 'flask'),
  //MANIKIN_INVENTORY: new EntityInformation('Manikin/Trainer', 'Manikins/Trainers', 'manikins-and-trainers', 'fal fa-users-cog', "For tracking and managing manikin inventory."),
  MANIKIN_INVENTORY_PROCEDURES: new EntityInformation(
    'Manikin Supply Procedure',
    'Manikin Supply Procedures',
    'manikin-inventory-item-procedures',
    'square'
  ),
  COMMUNICATION_BOARD_ACTION_ITEMS: new EntityInformation(
    'Communication Board Action Item',
    'Communication Board Action Items',
    'communication-board-action-items',
    'square'
  ),
  COMMUNICATION_BOARD: new EntityInformation('Communication Board', 'Communication Board', 'board', 'chalkboard'),
  TIMESHEETS: new EntityInformation('Timesheets', 'Timesheets', 'timesheets', 'clock'),
  TASKS: new EntityInformation('Tasks', 'Tasks', 'tasks', 'tasks'),
  CALENDAR: new EntityInformation('Calendar', 'Calendars', 'calendar', 'calendar'),
  PICKORDERS: new EntityInformation('Pick Order', 'Pick Orders', 'pick-orders', 'boxes-stacked'),
  IMPORT_JOBS: new EntityInformation(
    'Import Job',
    'Import Jobs',
    'import-jobs',
    'file-import',
    'For tracking and managing new and old data imports.'
  ),
  PRINTERS: new EntityInformation(
    'Printer',
    'Printers',
    'printer',
    'print',
    'For adding and managing label printers for inventory tracking.'
  )
};

export enum ValidationMaxStringLength {
    Short = 50,
    Default = 255,
    Long = 1024
}

export const ZipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;
export const PhoneNumberRegex = /^\+?[\d-]{0,5}\(?\d{3}\)?[ -]?\d{3}[ -]?\d{4}$/;
export const UrlRegex = /^((http|https|ftp):\/\/)?([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?$/;
