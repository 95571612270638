import { PatientService } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { PatientListBuilderComponent } from '@wo-app/components/dashboard/patients/patient-list-builder/patient-list-builder.component';
import { PatientsDetailComponent } from '@wo-app/components/dashboard/patients/patients.detail/patients.detail.component';
import { PatientsComponent } from '@wo-app/components/dashboard/patients/patients.list.component';
import { ImpersonatingOrganizationCanActivate } from '@wo-app/core/guards';
import { RouteChild, RouteParent } from '@wo-app/shared/models/route';

export class PatientsRegistration {
  static Routes: RouteParent = {
    path: EntityGlobals.PATIENTS.baseRoute,
    canActivate: [ImpersonatingOrganizationCanActivate],
    entityInformation: EntityGlobals.PATIENTS,
    children: [
      {
        path: '',
        component: PatientsComponent,
        data: {
          breadcrumb: 'View All'
        }
      } as RouteChild,
      {
        path: 'edit/:id',
        component: PatientsDetailComponent,
        data: {
          breadcrumb: 'Edit ' + EntityGlobals.PATIENTS.singleName
        }
      } as RouteChild,
      {
        path: 'add',
        component: PatientsDetailComponent,
        data: {
          breadcrumb: 'Add ' + EntityGlobals.PATIENTS.singleName
        }
      } as RouteChild
    ]
  };

  static Services: any = [PatientService];

  static Components: any = [PatientsComponent, PatientsDetailComponent, PatientListBuilderComponent];
}
