import { Pipe } from '@angular/core';

@Pipe({
  name: 'website'
})
export class WebsitePipe {
  transform(websiteUrl, args: any = []) {
    if (websiteUrl == null) return '';
    var value = websiteUrl.toString().trim().replace(/^\+/, '');
    return `<a href="${value}" target="_blank"><i class="fa-light fa-link"></i> ${value}</a>`;
  }
}
