import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { SearchSupplysCommand, SearchSupplysCommandResult, SupplyService } from '@wo-api/index';
import { NGXLogger } from 'ngx-logger';
import { from } from 'rxjs';
import { delay, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-inventory-item-list-builder-row',
  templateUrl: './inventory-item-list-builder-row.component.html',
  styleUrls: ['./inventory-item-list-builder-row.component.scss'],
})
export class InventoryItemListBuilderRowComponent
  implements OnInit, AfterViewInit {
  @Input() objectData: any;
  @Input() index: number;
  @ViewChild('autocomplete', { static: true })
  private autocomplete: AutoCompleteComponent;
  @Output()
  inventoryItemListBuilderRowChange: EventEmitter<any> = new EventEmitter<any>();
  public id: number;
  public quantity: number;
  public supplyId: number;
  public supplyName: string;
  public minimum: -999;

  constructor(
    private logger: NGXLogger,
    private SupplyService: SupplyService
  ) {}
  filterResults: any[] = [];
  ngOnInit() {
    this.logger.debug(this.objectData);
    this.quantity = this.objectData.quantity;
    this.supplyId = this.objectData.supplyId;
    this.id = this.objectData.id;
    this.supplyName = this.objectData.supplyName;
  }

  autoCompleteFilterChange(value) {}

  autocompleteValueChange(e) {
    const filterResults = this.filterResults;
    const found = filterResults.filter((item) => item.name === e);
    this.logger.debug(found);
    this.supplyId = found[0].id;
    this.objectData = {
      index: this.index,
      id: this.id,
      supplyId: this.supplyId,
      quantity: this.quantity,
    };
    this.inventoryItemListBuilderRowChange.emit(this.objectData);
  }

  onValueChange() {
    this.objectData = {
      index: this.index,
      id: this.id,
      supplyId: this.supplyId,
      quantity: this.quantity,
    };
    this.inventoryItemListBuilderRowChange.emit(this.objectData);
  }

  ngAfterViewInit() {
    this.autocomplete.filterChange
      .asObservable()
      .pipe(
        switchMap((value) =>
          from([this.filterResults]).pipe(
            tap(() => (this.autocomplete.loading = true)),
            delay(1000),
            map((data) => {
              const command: SearchSupplysCommand = {};
              command.search = value;
              this.SupplyService
                .search(command)
                .subscribe((result: SearchSupplysCommandResult) => {
                  this.logger.debug(result.data);
                  if(result && result.data){
                    this.filterResults = result.data;
                  }
                });
            })
          )
        )
      )
      .subscribe((x) => {
        this.autocomplete.loading = false;
      });
  }
}
