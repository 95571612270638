import { Component, Input, OnInit } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { RowArgs, SelectableSettings, SelectionEvent } from '@progress/kendo-angular-grid';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid/data/change-event-args.interface';
import { GridDataResult } from '@progress/kendo-angular-grid/data/data.collection';
import { CompositeFilterDescriptor, State, orderBy } from '@progress/kendo-data-query';
import { filterBy } from '@progress/kendo-data-query';
import { ListSupplyViewModel, ListSupplysCommandResult, PickOrderSupplyViewModel, SupplyService } from '@wo-api/index';
import { ModalService } from '@wo-app/core/services';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-pick-order-supplies-selection-dialog',
  templateUrl: './pick-order-supplies-selection-dialog.component.html',
  styleUrls: ['./pick-order-supplies-selection-dialog.component.scss']
})
export class PickOrderSuppliesSelectionDialogComponent implements OnInit {
  @Input() public inputs: any;
  @Input() public outputs: any;

  public title: string;
  public buttons: string[] = [];

  public gridData: ListSupplyViewModel[] = [];
  public view: GridDataResult;
  public isLoading = false;
  public pageSize = 25;

  public state: State = {
    skip: 0,
    take: 25,
    sort: [{ dir: 'asc', field: 'supplyName' }],
    filter: {
      logic: 'and',
      filters: []
    }
  };

  private preSelectedItems: PickOrderSupplyViewModel[] = [];
  public selectableSettings: SelectableSettings;
  public selectedIds: number[] = [];

  constructor(
    private modalService: ModalService,
    private supplyService: SupplyService,
    private loadingBar: LoadingBarService,
    private logger: NGXLogger
  ) {
    this.state.sort = [{ dir: 'asc', field: 'supplyName' }];
    this.pageSize = 25;
    this.setSelectableSettings();
  }

  private setSelectableSettings(): void {
    this.selectableSettings = {
      checkboxOnly: true,
      mode: 'multiple',
      drag: false
    };
  }

  ngOnInit(): void {
    this.preSelectedItems = this.inputs.selectedItems ? this.inputs.selectedItems : [];
    this.selectedIds = this.preSelectedItems.map(({ inventoryItemId }) => inventoryItemId);
    this.title = this.inputs.title ? this.inputs.title : 'Select Items';
    this.defineButtons();
    this.reloadData();
  }

  private defineButtons() {
    if (this.inputs.buttons) {
      this.buttons = this.inputs.buttons;
    } else {
      this.buttons.push('Close');
    }
  }

  public reloadData(): void {
    this.isLoading = true;
    this.loadingBar.useRef('router').start();
    this.supplyService.list().subscribe((value: ListSupplysCommandResult) => {
      this.logger.debug('Got Data from List(ListSupplysCommand)', value);
      this.loadingBar.useRef('router').complete();
      this.gridData = value.data as ListSupplyViewModel[];
      this.query();
      this.isLoading = false;
    });
  }

  public query() {
    const filteredData = filterBy(this.gridData, this.filter);
    const sortedGridData = orderBy(filteredData, this.state.sort ?? [{ dir: 'asc', field: 'name' }]);
    this.view = {
      data: sortedGridData.slice(this.state.skip, (this.state.skip ?? 0) + this.pageSize),
      total: sortedGridData.length
    };
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.logger.debug('Filtering has changed');
    this.filter = filter;
    this.query();
  }

  public filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: []
  };

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.query();
  }

  public processRowSelection(event: SelectionEvent): void {
    if (event.selectedRows.length > 0) {
      this.addSelectedRows(event.selectedRows);
    }
    if (event.deselectedRows.length > 0) {
      this.removeSelectedRows(event.deselectedRows);
    }
  }

  private addSelectedRows(rows: RowArgs[]) {
    rows.forEach(item => {
      this.selectedIds = this.selectedIds.filter(f => f !== item.dataItem.id).concat([item.dataItem.id]);
    });
  }

  private removeSelectedRows(rows: RowArgs[]) {
    rows.forEach(item => {
      const index = this.selectedIds.indexOf(item.dataItem.Id, 0);
      if (index > -1) {
        this.selectedIds.splice(index, 1);
      }
    });
  }

  public close(answer: any) {
    let result = {
      items: [],
      answer
    };
    if (answer === 'Confirm') {
      let selectedSupplies: PickOrderSupplyViewModel[] = [];
      this.selectedIds.forEach(e => {
        let supply = this.gridData.filter(item => item.id == e)[0];
        let pickOrderSupply: PickOrderSupplyViewModel = {
          inventoryItemId: supply.id,
          supplyName: supply.name,
          supplyManufacturerNumber: supply.manufacturerNumber
        };
        selectedSupplies.push(pickOrderSupply);
      });
      result.items = selectedSupplies;
    }
    this.modalService.destroy(result);
  }
}
