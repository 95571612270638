import { AnimationBuilder, AnimationPlayer, animate, group, query, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbAccordionDirective, NgbAccordionItem, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AppGlobals, MenuGroupType } from '@wo-app/app.global';
import { ImpersonationService } from '@wo-app/core/services/impersonation.service';
import { HamburgerService } from '@wo-app/core/side-menu/hamburger.service';
import { MenuGrouping } from '@wo-app/shared/models/menu/menu-grouping.model';
import { MenuItem } from '@wo-app/shared/models/menu/menu-item.model';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { SideMenuService } from './side-menu.service';

const left = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
  group([
    query(':enter', [style({ transform: 'translateX(-100%)' }), animate('.3s ease-out', style({ transform: 'translateX(0%)' }))], {
      optional: true
    }),
    query(':leave', [style({ transform: 'translateX(0%)' }), animate('.3s ease-out', style({ transform: 'translateX(100%)' }))], {
      optional: true
    })
  ])
];

const right = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
  group([
    query(':enter', [style({ transform: 'translateX(100%)' }), animate('.3s ease-out', style({ transform: 'translateX(0%)' }))], {
      optional: true
    }),
    query(':leave', [style({ transform: 'translateX(0%)' }), animate('.3s ease-out', style({ transform: 'translateX(-100%)' }))], {
      optional: true
    })
  ])
];

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  animations: [trigger('animSlider', [transition(':increment', right), transition(':decrement', left)])]
})
export class SideMenuComponent implements OnInit {
  public MenuGroups: MenuGrouping[] = [];
  public isImpersonatingOrganization: boolean;
  public applicationName = AppGlobals.SITE_NAME;
  public logoUrl = AppGlobals.LOGO_URL;

  activeMenuItem$: Observable<MenuItem>;
  counter: number = 0;
  left: number = 0;
  parentUl: HTMLElement = null;
  animationPlayer: AnimationPlayer = null;

  @Input() slideAnimationActive: boolean = false;
  @Input() showBrandLogo: boolean = true;
  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('slideAnimationContainer') slideAnimationContainer: ElementRef;
  @ViewChildren(NgbAccordionItem) accordionItems: QueryList<NgbAccordionItem>;

  constructor(
    private hamburgerService: HamburgerService,
    public offcanvasService: NgbOffcanvas,
    private renderer: Renderer2,
    private animationBuilder: AnimationBuilder,
    private logger: NGXLogger,
    private impersonationService: ImpersonationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sideMenuService: SideMenuService
  ) {}

  ngOnInit() {
    //this.MenuGroups.push(new MenuGrouping('Administrator Menu', this.sideMenuService.getGroupingOfType(MenuGroup.Admin), true));
    this.MenuGroups.push(
      new MenuGrouping(
        'Physical Spaces',
        MenuGroupType.PhysicalSpaces,
        this.sideMenuService.getMenuItemsOfType(MenuGroupType.PhysicalSpaces),
        true
      )
    );
    this.MenuGroups.push(
      new MenuGrouping(
        'Inventory',
        MenuGroupType.InventoryAndEquipment,
        this.sideMenuService.getMenuItemsOfType(MenuGroupType.InventoryAndEquipment),
        true
      )
    );
    this.MenuGroups.push(
      new MenuGrouping('People', MenuGroupType.People, this.sideMenuService.getMenuItemsOfType(MenuGroupType.People), true)
    );
    this.MenuGroups.push(
      new MenuGrouping('Settings', MenuGroupType.Settings, this.sideMenuService.getMenuItemsOfType(MenuGroupType.Settings), true)
    );
    //this.MenuGroups.push(new MenuGrouping('Organization Administrator Menu', this.sideMenuService.getGroupingOfType(MenuGroup.Organization), true));
  }

  shouldHideIcon(icon?: string) {
    return !icon;
  }

  onEvent(event, menuItem) {
    this.router.navigate([menuItem.path + '/add']);
  }

  playAnimation(value: number) {
    const animation = this.animationBuilder.build([
      style({
        transform: `translateX(-${this.left}%)`
      }),
      animate(
        300,
        style({
          transform: `translateX(-${this.left + value}%)`
        })
      )
    ]);
    this.animationPlayer = animation.create(this.parentUl);
    this.animationPlayer.play();
    this.left += value;
  }

  showGroup(el: HTMLElement, group: MenuGrouping): void {
    if (this.parentUl == null) {
      this.parentUl = el.closest('ul');
    }
    let allUls = this.parentUl.querySelectorAll('ul');
    for (let i = 0; i < allUls.length; ++i) {
      allUls[i].classList.remove('active');
    }

    let siblingUl = el.parentElement.querySelector('ul');
    siblingUl.classList.add('active');
    this.playAnimation(100);
  }

  onMenuShown(menuId: string) {
    this.accordionItems.forEach(a => {
      if (a.id !== menuId) a.collapse();
    });
  }

  menuItemClicked() {
    this.hamburgerService.setInactive();
    this.offcanvasService.dismiss();
  }

  menuItemGoBack() {
    this.playAnimation(-100);
  }

  getLabelForMenuItemAndMenuGroup(menuItem: MenuItem, menuGroup: MenuGroupType): string {
    let foundMenuGroup = menuItem.menuGroups.filter(x => x.groupType == menuGroup);
    if (foundMenuGroup && foundMenuGroup.length > 0) {
      return foundMenuGroup[0].label;
    }
    return '';
  }
  goToSignOut() {
    this.router.navigate(['/oauth/logout']);
    this.hamburgerService.setInactive();
    this.offcanvasService.dismiss();
  }

  goToProfile() {
    this.router.navigate(['/my-profile']);
    this.hamburgerService.setInactive();
    this.offcanvasService.dismiss();
  }
}
