<form class="" *ngIf="modelInfo != null">
    <div class="card mb-3">
        <div class="card-body">
            <h5 class="card-title">Manikin / Trainer Information</h5>
            <div class="row manikin-info-wrapper">
                <div class="col-xs-12 col-sm-6 mb-2">
                    <label class="col-form-label-sm">Manufacturer Name</label>
                    <input type="text" class="form-control-plaintext fw-bold" readonly [value]="modelInfo.manufacturerName" />
                </div>
                <div class="col-xs-12 col-sm-6 mb-2">
                    <label class="col-form-label-sm">Model Name</label>
                    <input type="text" class="form-control-plaintext fw-bold" readonly [value]="modelInfo.modelName" />
                </div>
                <div class="col-xs-12 col-sm-6 mb-2">
                    <label class="col-form-label-sm">Model Number</label>
                    <input type="text" class="form-control-plaintext fw-bold" readonly [value]="modelInfo.modelNumber" />
                </div>
                <div class="col-xs-12 col-sm-6 mb-2">
                    <label class="col-form-label-sm">Type</label>
                    <input type="text" class="form-control-plaintext fw-bold" readonly [value]="getTypeLabel(modelInfo)" />
                </div>
                <div class="col-xs-12 col-sm-6 mb-2">
                    <label class="col-form-label-sm">Manual and Training</label>
                    <div>
                        <a class="btn btn-secondary btn-sm text-light me-2" [hidden]="modelInfo.manualLink == null" [attr.href]="modelInfo.manualLink" target="_blank">
                            <i class="fa-light fa-arrow-up-right-from-square"></i> Manual Link
                        </a>
                        <a class="btn btn-secondary btn-sm text-light"  [hidden]="modelInfo.trainingLink == null" [attr.href]="modelInfo.trainingLink" target="_blank">
                            <i class="fa-light fa-arrow-up-right-from-square"></i> Training Link
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>