<div class="row text-center">
  <h1>{{supplyLocationQuantity?.supplyName}}</h1>
</div>
<div class="row text-center">
  <h2>Current Quantity: {{supplyLocationQuantity?.quantity}}</h2>
</div>
<div class="row text-center">
  <h2>Stocking Quantity: {{supplyLocationQuantity?.stockingQuantity}}</h2>
</div>
<div class="row text-center">
  <p class="fs-6">Room: <strong>{{supplyLocationQuantity?.roomName}}</strong></p>
  <p class="fs-6">Building: <strong>{{supplyLocationQuantity?.buildingName}}</strong></p>
  <p class="fs-6">Location: <strong>{{supplyLocationQuantity?.locationName}}</strong></p>
</div>
<div class="row text-center">
  <kendo-numerictextbox
    format="n0"
    [min]="0"
    [step]="1"
    [value]="numberToChange"
    (valueChange)="numberToChange = $event"
    >
  </kendo-numerictextbox>
</div>
<div class="row text-center">
  <button
    #add
    kendoButton
    size="large"
    themeColor="success"
    (click)="toggleDialog('add')"
  >
    Add
  </button>
</div>
<div class="row text-center">
  <button
    #remove
    class="btn-lg"
    kendoButton
    size="large"
    themeColor="error"
    (click)="toggleDialog('remove')"
  >
    Remove
  </button>
</div>
<kendo-dialog
  title="Please confirm"
  *ngIf="isDialogVisible"
  (close)="toggleDialog()"
>
  <p>Confirm that you want to {{action}} {{numberToChange}} {{supplyLocationQuantity?.supplyName}} in {{supplyLocationQuantity?.roomName}}.</p>
  <kendo-dialog-actions>
    <button
      kendoButton
      (click)="confirm()"
      themeColor="primary">
      Yes
    </button>
    <button
      kendoButton
      (click)="toggleDialog()">
      No
    </button>
  </kendo-dialog-actions>
</kendo-dialog>