import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  ListManikinInventoryItemsCommand,
  ListManikinInventoryItemsCommandResult,
  ListPatientsCommand,
  ListPatientsCommandResult,
  ManikinInventoryItemService,
  PatientListViewModel
} from '@wo-api/index';

import { LoadingBarService } from '@ngx-loading-bar/core';
import { PatientService } from '@wo-api/index';
import { NGXLogger } from 'ngx-logger';
@Component({
  selector: 'app-patient-list-builder',
  templateUrl: './patient-list-builder.component.html',
  styleUrls: ['./patient-list-builder.component.scss']
})
export class PatientListBuilderComponent implements OnInit, OnChanges {
  @Input() patients: any[];
  @Input() organizationId: number;
  @Output() patientListNotification: EventEmitter<any> = new EventEmitter<any>();
  patientList: any[] = [];
  defaultMakeAndModel: { id: -1; label: 'Select a Manikin from Your Inventory' };
  selectedManikin: any;
  listManikinInventoryItemsCommand: ListManikinInventoryItemsCommand = {};
  manikinMakesAndModels: any[] = [];
  organizationPatients: PatientListViewModel[] = [];

  public events: string[][] = [[], []];
  constructor(
    private loadingBar: LoadingBarService,
    private logger: NGXLogger,
    private patientService: PatientService,
    private manikinInventoryItemService: ManikinInventoryItemService
  ) {
    this.loadingBar.useRef('router').start();
    let listPatientsCommand: ListPatientsCommand = {};
    this.patientService.list(listPatientsCommand).subscribe((result: ListPatientsCommandResult) => {
      this.loadingBar.stop();
      if (result && result.data) {
        this.organizationPatients = result.data;
        this.logger.debug(this.organizationPatients);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // only run when property "data" changed
    if (changes['organizationId']) {
      this.logger.debug(this.listManikinInventoryItemsCommand);
      this.manikinInventoryItemService
        .list(this.listManikinInventoryItemsCommand)
        .subscribe((result: ListManikinInventoryItemsCommandResult) => {
          const newArray = new Array();
          for (const item of result.data) {
            //const merged = {...item, ...{label: item.manufacturerName + ' | ' + item.modelName}};
            //newArray.push(merged);
          }
          this.manikinMakesAndModels = newArray;
          this.logger.debug(this.manikinMakesAndModels);
        });
    }
  }

  ngOnInit() {
    this.patientList = [];
    if (this.patients == null) {
      this.patients = [];
    }

    for (const patient of this.patients) {
      this.logger.debug(patient);
      this.patientList.push(
        this.createPatient(
          patient.sortOrder,
          patient.firstName,
          patient.middleName,
          patient.lastName,
          patient.dateOfBirth,
          patient.gender,
          patient.allergies,
          patient.manikinInventoryItemId
        )
      );
    }
  }

  addPatient() {
    const newPatient = this.createPatient('', '', '', '', new Date(), 1, '', -1);
    this.patientList.push(newPatient);
  }

  deleteRow(index) {
    this.patientList.splice(index, 1);
  }

  savePatients() {
    this.recalculateSortOrders();
    this.patientListNotification.emit(this.patientList);
  }

  calculateAge(birthdate) {
    if (birthdate) {
      const timeDiff = Math.abs(Date.now() - birthdate);
      return Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
    }
    return 0;
  }

  createPatient(sortOrder, firstName, middleName, lastName, dateOfBirth, gender, allergies, manikinInventoryItemId) {
    return {
      sortOrder: sortOrder,
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      dateOfBirth: new Date(dateOfBirth),
      gender: gender,
      allergies: allergies,
      age: this.calculateAge(dateOfBirth),
      manikinInventoryItemId: manikinInventoryItemId
    };
  }

  recalculateSortOrders() {
    this.logger.debug(this.patientList);
    let index = 0;
    for (const patient of this.patientList) {
      patient.sortOrder = index;
      index++;
    }
  }

  public onDataAdd(e: any): void {
    this.recalculateSortOrders();
  }

  public onDataRemove(e: any): void {
    this.recalculateSortOrders();
  }

  public onDragEnd(e: any): void {
    this.recalculateSortOrders();
  }

  public onDragOver(e: any): void {
    // Not logging due to the large number of events
  }

  public onDragStart(e: any): void {}

  manikinInventorySelectorChanged(manikinInventoryId, itemIndex) {
    this.logger.debug(manikinInventoryId, itemIndex);
    this.patientList[0].manikinInventoryId = manikinInventoryId;
  }
}
