import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  LabRequestInventoryItemService,
  LabRequestInventoryItemViewModel,
  ListLabRequestInventoryItemsCommand,
  ListLabRequestInventoryItemsCommandResult,
  SupplyService,
  UpsertLabRequestInventoryItemsCommand,
  UpsertLabRequestInventoryItemsCommandResult
} from '@wo-api/index';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-inventory-item-list-builder',
  templateUrl: './inventory-item-list-builder.component.html',
  styleUrls: ['./inventory-item-list-builder.component.scss'],
})
export class InventoryItemListBuilderComponent implements OnInit, OnChanges {
  @Input() organizationId: any;
  @Input() labRequestId: any;
  @Input() inventoryItemTypeId: number;
  @Input() allowNegativeQuantities: boolean;
  @Input() emitToParentComponent: boolean;
  @Input() setQuantitiesToZero: boolean;
  @Output()
  inventoryItemListBuilderRowChange: EventEmitter<any> = new EventEmitter<any>();

  listData: any[] = [];
  filteredInventoryItems: LabRequestInventoryItemViewModel[] = [];
  dataArrayToReturn: any[] = [];

  constructor(
    private logger: NGXLogger,
    private SupplyService: SupplyService,
    private labRequestInvntoryItemService: LabRequestInventoryItemService
  ) { }

  createRow(
    id: number,
    supplyId: number,
    name: string,
    quantity: number
  ) {
    return {
      id,
      supplyId,
      name,
      quantity,
    };
  }

  onNotificationOfSupply(supply) {
    this.logger.debug(supply);
    const index = supply.index;
    delete supply['index'];
    this.dataArrayToReturn[index] = supply;
    this.dataArrayToReturn[
      index
    ].inventoryItemTypeId = this.inventoryItemTypeId;
    this.onValuesChanges();
  }

  ngOnInit() { }

  addNewRow(): void {
    const newItem = this.createRow(null, null, '', 1);
    this.filteredInventoryItems.push(newItem);
  }

  removeRow(row): void {
    this.filteredInventoryItems.splice(row, 1);
    this.dataArrayToReturn.splice(row, 1);
    this.logger.debug(row);
    if (!this.emitToParentComponent) {
      this.labRequestInvntoryItemService
        ._delete(this.labRequestId, row.id, this.organizationId)
        .subscribe((result) => {
          this.logger.debug(result);
        });
    }
  }

  saveInventoryItems(): void {
    if (!this.emitToParentComponent) {
      this.logger.debug(this.dataArrayToReturn);
      const command: UpsertLabRequestInventoryItemsCommand = {};
      command.labRequestId = this.labRequestId;
      command.items = this.dataArrayToReturn;
      this.labRequestInvntoryItemService
        .upsertItems(command)
        .subscribe((result: UpsertLabRequestInventoryItemsCommandResult) => {
          this.logger.debug(result);
        });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['labRequestId']) {
      this.labRequestId = changes['labRequestId'].currentValue;
    }
    if (changes['organizationId']) {
      this.organizationId = changes['organizationId'].currentValue;
    }
    if (changes['inventoryItemTypeId']) {
      this.inventoryItemTypeId = changes['inventoryItemTypeId'].currentValue;
    }
    if (changes['allowNegativeQuantities']) {
      this.allowNegativeQuantities =
        changes['allowNegativeQuantities'].currentValue;
    }
    if (changes['setQuantitiesToZero']) {
      this.setQuantitiesToZero = changes['setQuantitiesToZero'].currentValue;
    }

    // If the OrganizationID and LabRequestId are found, then get the current inventory Items related to that Lab Request
    if (this.organizationId && this.labRequestId) {
      this.getInventoryItems();
    }
  }

  onValuesChanges() {
    if (this.emitToParentComponent) {
      this.logger.debug(this.dataArrayToReturn);
      this.inventoryItemListBuilderRowChange.emit(this.dataArrayToReturn);
    }
  }

  getInventoryItems() {
    const command: ListLabRequestInventoryItemsCommand = {};
    command.labRequestId = this.labRequestId;
    this.labRequestInvntoryItemService
      .list(command)
      .subscribe((result: ListLabRequestInventoryItemsCommandResult) => {
        this.logger.debug(result);
        if (this.setQuantitiesToZero) {
          if (result && result.data) {
            const dataWithZeroedQuantities = result.data;
            dataWithZeroedQuantities.forEach((el) => {
              el.quantity = 0;
            });
            this.filteredInventoryItems = dataWithZeroedQuantities;
          } else {
            this.filteredInventoryItems = result.data;
          }
        }
      });
  }
}
