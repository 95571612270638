import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { orderBy } from '@progress/kendo-data-query';
import { ListSimMaintenanceTasksCommand, SimMaintenanceTaskService } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-sim-maintenance-tasks',
  templateUrl: './simmaintenancetasks.component.html',
  styleUrls: ['./simmaintenancetasks.component.scss']
})
export class SimMaintenanceTasksComponent extends EntityBaseComponent implements OnInit {
  gridData: any[];

  listSimMaintenanceTasksCommand: ListSimMaintenanceTasksCommand = {};
  public view: GridDataResult;

  constructor(
    private loadingBar: LoadingBarService,
    private logger: NGXLogger,
    private impersonationService: ImpersonationService,
    private simMaintenanceTaskService: SimMaintenanceTaskService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super(EntityGlobals.SIM_MAINTENANCE_TASKS, router, route, impersonationService, logger);
    this.loadingBar.useRef('router').start();
    this.OrganizationId.subscribe(() => {
      this.listSimMaintenanceTasksCommand.skip = this.state.skip;
      this.listSimMaintenanceTasksCommand.take = this.state.take;
      this.listSimMaintenanceTasksCommand.sort = this.state.sort;
      this.simMaintenanceTaskService.list(this.listSimMaintenanceTasksCommand).subscribe((result: any) => {
        this.loadingBar.useRef('router').complete();
        this.gridData = result.data;
        this.query();
      });
    });
  }

  ngOnInit() {}

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.query();
  }

  public query() {
    const sortedGridData = orderBy(this.gridData, this.state.sort ?? [{ dir: 'desc', field: 'lastUpdated' }]);
    this.view = {
      data: sortedGridData.slice(this.state.skip, (this.state.skip ?? 0) + this.state.take),
      total: sortedGridData.length
    };
  }
}
