<form class="row" [formGroup]="form">
    <div class=" col-sm-12 col-md-6">
        <label for="password" class="col-form-label col-form-label-sm">Password</label>
        <input kendoTextBox id="password" formControlName="password" type="password" class="form-control" autocomplete="current-password" placeholder="Password"
            [class.is-valid]="form?.errors?.['passwordIsValid'] == true"
            [class.is-invalid]="form?.errors?.['passwordIsValid'] == false"
        />
    </div>
    <div class=" col-sm-12 col-md-6">
        <label for="username" class="col-form-label col-form-label-sm">Password Confirmation</label>
        <input kendoTextBox id="passwordConfirmation" formControlName="passwordConfirmation" type="password" autocomplete="new-password" class="form-control" placeholder="Password Confirmation" 
            [class.is-valid]="form?.errors?.['passwordConfirmationIsValid'] == true"
            [class.is-invalid]="form?.errors?.['passwordConfirmationIsValid'] == false"
        />
    </div>
    <div class="col-xs-12">
        <ol class="my-3" [hidden]="!form.controls['password'].value">
            <li 
                [class.text-success]="shouldShowCheckMark(form.errors, 'characterCountWithinRange')"
                [class.text-danger]="shouldShowXMark(form.errors, 'characterCountWithinRange')">
                <i class="fa-light"
                    [class.fa-square-check]="shouldShowCheckMark(form.errors, 'characterCountWithinRange')" 
                    [class.fa-square-xmark]="shouldShowXMark(form.errors, 'characterCountWithinRange')">
                </i>
                Password must be at least 8 characters.
            </li>
            <li 
                [class.text-success]="shouldShowCheckMark(form.errors, 'hasOneUppercaseLetter')"
                [class.text-danger]="shouldShowXMark(form.errors, 'hasOneUppercaseLetter')">
                <i class="fa-light"
                    [class.fa-square-check]="shouldShowCheckMark(form.errors, 'hasOneUppercaseLetter')" 
                    [class.fa-square-xmark]="shouldShowXMark(form.errors, 'hasOneUppercaseLetter')">
                </i>
                Password must contain at least one uppercase letter: A-Z.
            </li>
            <li 
                [class.text-success]="shouldShowCheckMark(form.errors, 'hasOneLowercaseLetter')"
                [class.text-danger]="shouldShowXMark(form.errors, 'hasOneLowercaseLetter')">
                <i class="fa-light" 
                    [class.fa-square-check]="shouldShowCheckMark(form.errors, 'hasOneLowercaseLetter')" 
                    [class.fa-square-xmark]="shouldShowXMark(form.errors, 'hasOneLowercaseLetter')">
                </i>
                Password must contain at least one lowercase letter: a-z
            </li>
            <li 
                [class.text-success]="shouldShowCheckMark(form.errors, 'hasOneSpecialCharacter')"
                [class.text-danger]="shouldShowXMark(form.errors, 'hasOneSpecialCharacter')">
                <i class="fa-light" 
                    [class.fa-square-check]="shouldShowCheckMark(form.errors, 'hasOneSpecialCharacter')" 
                    [class.fa-square-xmark]="shouldShowXMark(form.errors, 'hasOneSpecialCharacter')">
                </i>
                Password must contain at least one special character: ^ $ * . [ ] {{ '{' }} {{ '}' }} ( ) ? - &quot; ! @ # % &amp; / \ , &gt; &lt; &#39; : ; | _ ~ ` + =.</li>
            <li 
                [class.text-success]="shouldShowCheckMark(form.errors, 'hasOneNumber')"
                [class.text-danger]="shouldShowXMark(form.errors, 'hasOneNumber')">
                <i class="fa-light" 
                    [class.fa-square-check]="shouldShowCheckMark(form.errors, 'hasOneNumber')" 
                    [class.fa-square-xmark]="shouldShowXMark(form.errors, 'hasOneNumber')">
                </i>
                Password must contain at least one number: 0-9.
            </li>
            <li 
                [class.text-success]="shouldShowCheckMark(form.errors, 'passwordConfirmationIsValid')"
                [class.text-danger]="shouldShowXMark(form.errors, 'passwordConfirmationIsValid')">
                <i class="fa-light" 
                    [class.fa-square-check]="shouldShowCheckMark(form.errors, 'passwordConfirmationIsValid')" 
                    [class.fa-square-xmark]="shouldShowXMark(form.errors, 'passwordConfirmationIsValid')">
                </i>
                Password confirmation must match password.
            </li>
        </ol>
    </div>
</form>