import { MenuGroupType } from '@wo-app/app.global';

export class MenuGroupListingItem {
  constructor(nestingLevel: number, sortOrder: number, groupType: MenuGroupType, label: string) {
    this.nestingLevel = nestingLevel;
    this.sortOrder = sortOrder;
    this.groupType = groupType;
    this.label = label;
  }

  public sortOrder: number;
  public nestingLevel: number;
  public groupType: MenuGroupType;
  public label: string;
}
