import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InventoryImportSignalRService {
  private connection: signalR.HubConnection;
  private GroupMessage: Subject<any>;
  constructor(private logger: NGXLogger) {
    /*this.logger.debug('Testing SignalR Connection');
    this.connection = new signalR.HubConnectionBuilder()
    .withUrl("https://localhost:44364/inventory-import-hub")
    .build();

    this.connection.on("messageReceived", (username: string, message: string) => {
      this.logger.debug("SignalR Test Starting...");
      this.logger.debug(username, message);
    });

    this.connection.on("broadcasttogroup", (message: string) =>{
      this.GroupMessage.next(message);
    });

    this.connection.start()
    .then(() => this.logger.debug('InventoryImportSignalRService Connection started'))
    .then(() => {
      this.connection.invoke("getConnectionId").then((value:any)=>{
        this.logger.debug("ConnectionId", value);
      });
    })
    .catch((err) => this.logger.error(err));*/
  }
}
