import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthService, NbTokenService, NbAuthToken } from '@nebular/auth';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { CachedDataService } from '@wo-app/core/services';
import { IdentityToken } from '@wo-app/shared/models';
import { decodeToken } from '@wo-app/shared/utils';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss']
})
export class AccountMenuComponent {
  @Input() additionalClasses: string;
  @Input() darkMode: boolean;

  public IdToken: IdentityToken | null = null;

  constructor(
    public offcanvasService: NgbOffcanvas,
    private authService: NbAuthService,
    protected tokenService: NbTokenService,
    public cachedDataService: CachedDataService,
    private logger: NGXLogger,
    public router: Router
  ) {
    this.logger.debug('Loading account-menu.component.ts');
    this.authService.getToken().subscribe((authToken: NbAuthToken) => {
      let decoded_token = decodeToken(authToken.getPayload().id_token);
      this.IdToken = new IdentityToken('', '', decoded_token['email'], decoded_token['cognito:username'], decoded_token['phone_number']);
    });
  }
}
