import { Injectable } from '@angular/core';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { NGXLogger } from 'ngx-logger';
import { interval } from 'rxjs';
import { ConfigLoaderService } from './config-loader.service';

@Injectable()
export class ApplicationUpdateService {
  checkForUpdateMinuteInterval = 5;

  constructor(private configService: ConfigLoaderService, private logger: NGXLogger, private swUpdate: SwUpdate) {
    this.logger.debug('Starting Application Update Service');

    // Checks for updates to the App every X minutes
    if (this.swUpdate.isEnabled) {
      interval(this.checkForUpdateMinuteInterval * 60000).subscribe(() =>
        this.swUpdate.checkForUpdate().then(() => this.logger.debug('Checking for application updates'))
      );
    } else {
      this.logger.debug('Application Update Service not Enabled');
    }
  }

  /** Public method to check for changes */
  public checkForUpdates(): void {
    this.logger.debug('ApplicationUpdateService: Checking for application updates');
    if (this.swUpdate.isEnabled) {
      this.logger.debug('ApplicationUpdateService: swUpdate is enabled');
      this.swUpdate.versionUpdates.subscribe((event: VersionEvent) => {
        this.logger.debug('swUpdate.versionUpdates event', event);
        this.promptUser(event);
      });
    }
  }

  /** When changes are found, show the message to the user. */
  private promptUser(event: VersionEvent): void {
    if (event.type == 'VERSION_READY') {
      this.logger.debug('updating to new version');
      let isLocalHost = window.location.host.indexOf('localhost') > -1;
      if (!isLocalHost) {
        alert('An update of the application is now available for you.');
        this.swUpdate.activateUpdate().then(() => document.location.reload());
      }
    }
  }
}
