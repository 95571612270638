import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { getErrorMessage } from '@wo-app/shared/utils';

@Component({
  selector: 'validation-error-message',
  template: `<ng-container *ngIf="errorMessage !== null">{{ errorMessage }}</ng-container>`,
  host: { class: 'k-form-error' }
})
export class ErrorMessageComponent {
  @Input() control!: AbstractControl;

  constructor() {}

  get errorMessage() {
    if (!this.control || !this.control.touched || !this.control.errors) return null;

    for (const validatorName in this.control.errors) {
      return getErrorMessage(validatorName, this.control.errors[validatorName]);
    }

    return null;
  }
}
