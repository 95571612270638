<autopilot-grid [columnsNotToAutosize]="columnsNotToAutosize">
  <kendo-grid [kendoGridBinding]="gridData" [style.maxHeight.%]="100" [pageSize]="state.take" [filterable]="'menu'"
    [sortable]="sortSettings" [sort]="sort" [pageable]="true">
    <ng-template kendoGridToolbarTemplate>
      <div class="container-fluid g-0 d-flex flex-wrap justify-content-center">
        <form class="col-auto me-auto" role="search">
          <button kendoButton type="button" class="btn btn-secondary text-white"
            (click)="addNewWithOrganizationId(organizationId)" [icon]="'plus'">Add
            {{this.GetSingleEntityName()}}</button>
        </form>
        <div class="text-end">
          <button kendoGridPDFCommand class="btn btn-secondary text-white" title="Export as PDF"><i
              class="fa-light fa-file-pdf"></i></button>
          <button kendoGridExcelCommand title="Export to Excel" class="btn btn-secondary text-white"><i
              class="fa-light fa-file-excel"></i></button>
        </div>
      </div>
    </ng-template>
    <kendo-grid-column title="Actions" [width]="108" class="k-col-actions">
      <ng-template kendoGridCellTemplate let-dataItem>
        <button kendoButton class="btn btn-outline-primary default-action-button" title="Edit this item"
          [primary]="true" (click)="routeToEditWithOrganization(dataItem.id)">
          <i class="fas fa-edit"></i>
        </button>
        <button kendoButton class="btn btn-outline-danger default-action-button" title="Delete this item"
          [primary]="true" (click)="delete(dataItem.id)">
          <i class="fas fa-trash"></i>
        </button>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="vendorName" title="Name">
      <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
        <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService"
          [extra]="false">
        </kendo-grid-string-filter-menu>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="techSupportEmail" title="Tech Support Email">
      <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
        <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService"
          [extra]="false">
        </kendo-grid-string-filter-menu>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span [innerHtml]="dataItem?.techSupportEmail | email | safe: 'html'"></span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="techSupportPhone" title="Tech Support Phone">
      <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
        <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService"
          [extra]="false">
        </kendo-grid-string-filter-menu>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span [innerHtml]="dataItem?.techSupportPhone | phone | safe: 'html'"></span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="techSupportWebsite" title="Tech Support Website" [width]="200">
      <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
        <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService"
          [extra]="false">
        </kendo-grid-string-filter-menu>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span [innerHtml]="dataItem?.techSupportWebsite | website | safe: 'html'"></span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="customerServiceEmail" title="Customer Service Email">
      <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
        <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService"
          [extra]="false">
        </kendo-grid-string-filter-menu>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span [innerHtml]="dataItem?.customerServiceEmail | email | safe: 'html'"></span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="customerServicePhone" title="Customer Service Phone">
      <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
        <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService"
          [extra]="false">
        </kendo-grid-string-filter-menu>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span [innerHtml]="dataItem?.customerServicePhone | phone | safe: 'html'"></span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="customerServiceWebsite" title="Customer Service Website" [width]="200">
      <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
        <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService"
          [extra]="false">
        </kendo-grid-string-filter-menu>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span [innerHtml]="dataItem?.customerServiceWebsite | website | safe: 'html'"></span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="salesRepName" title="Sales Rep Name">
      <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
        <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService"
          [extra]="false">
        </kendo-grid-string-filter-menu>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="salesRepEmail" title="Sales Rep Email">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span [innerHtml]="dataItem?.salesRepEmail | email | safe: 'html'"></span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="salesRepPhone" title="Sales Rep Phone">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span [innerHtml]="dataItem?.salesRepPhone | phone | safe: 'html'"></span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="salesWebsite" title="Sales Website">
      <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
        <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService"
          [extra]="false">
        </kendo-grid-string-filter-menu>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span [innerHtml]="dataItem?.salesWebsite | website | safe: 'html'"></span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="notes" title="Notes">
      <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
        <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService"
          [extra]="false">
        </kendo-grid-string-filter-menu>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="id" title="ID">
      <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
        <kendo-grid-numeric-filter-menu [column]="column" [filter]="filter" [filterService]="filterService"
          [extra]="false">
        </kendo-grid-numeric-filter-menu>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-pdf [fileName]="pdfFileName" paperSize="A4" [scale]="0.8" [repeatHeaders]="true" [allPages]="true"
      [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
    </kendo-grid-pdf>
    <kendo-grid-excel [fileName]="excelFileName"></kendo-grid-excel>
  </kendo-grid>
</autopilot-grid>

<router-outlet></router-outlet>
<div kendoDialogContainer></div>