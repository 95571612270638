import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConvertImageToZplCommandResult, ZebraPrinterService } from '@wo-api/index';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LabelaryImageService {
  private readonly ApiPrefix: string = 'https://api.labelary.com/v1/';

  constructor(private http: HttpClient, private zebraService: ZebraPrinterService, private logger: NGXLogger) {
    this.logger.log('Constructing LabelaryImageService');
  }

  public GeneratePreviewLabel(zplTemplate: string, widthInInches: number = 3, heightInInches: number = 1): Observable<any> {
    // Valid values are 6dpmm, 8dpmm, 12dpmm, and 24dpmm. See your printer's documentation for more information.
    const dpmmValue = 12;

    let apiUrl = this.ApiPrefix + `printers/${dpmmValue}dpmm/labels/${widthInInches}x${heightInInches}/0/`;

    const formData = new FormData();
    formData.append('file', zplTemplate);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'image/png'
    });

    return this.http.post(apiUrl, formData, { headers, responseType: 'blob' });
  }

  public async ConvertImageToZPLGraphic(
    qrCodeDataUrl: string,
    positionX?: number,
    positionY?: number,
    resolution?: number,
    width?: number,
    height?: number
  ): Promise<Observable<ConvertImageToZplCommandResult>> {
    const blob = await (await fetch(qrCodeDataUrl)).blob();
    return this.zebraService.convertImageToZpl(positionX, positionY, resolution, width, height, blob);
  }
}
