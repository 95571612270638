import { NgModule } from '@angular/core';
import { PreloadAllModules, Route, Router, RouterModule } from '@angular/router';

import { BedsRegistration } from './arearegistration/beds.registration';
import { BuildingsRegistration } from './arearegistration/buildings.registration';
import { ChecklistsRegistration } from './arearegistration/checklists.registration';
import { CommunicationBoardActionItemRegistration } from './arearegistration/communication-board-action-item.registration';
import { CommunicationBoardItemRegistration } from './arearegistration/communication-board-item.registration';
import { CoursesRegistration } from './arearegistration/courses.registration';
import { CustomersRegistration } from './arearegistration/customers.registration';
import { DrugsRegistration } from './arearegistration/drugs.registration';
import { EmailTemplatesRegistration } from './arearegistration/email-templates.registration';
import { EquipmentRegistration } from './arearegistration/equipment.registration';
import { ImportJobsRegistration } from './arearegistration/import-jobs.registration';
import { LabMaintenanceTasksRegistration } from './arearegistration/lab-maintenance-tasks.registration';
import { LabRequestsRegistration } from './arearegistration/lab-requests.registration';
import { LocationsRegistration } from './arearegistration/locations.registration';
import { ManikinInventoryItemProcedureRegistration } from './arearegistration/manikin-inventory-items-procedures.registration';
import { ManikinMakeAndModelRegistration } from './arearegistration/manikin-make-model.registration';
import { ManikinMakeAndModelProcedureRegistration } from './arearegistration/manikin-make-model-procedure.registration';
import { OrganizationsRegistration } from './arearegistration/organizations.registration';
import { PatientsRegistration } from './arearegistration/patients.registration';
import { RoomsRegistration } from './arearegistration/rooms.registration';
import { ScenariosRegistration } from './arearegistration/scenarios.registration';
import { SimMaintenanceTasksRegistration } from './arearegistration/sim-maintenance-tasks.registration';
import { SkillsRegistration } from './arearegistration/skills.registration';
import { SuppliesRegistration } from './arearegistration/supplies.registration';
import { TasksRegistration } from './arearegistration/tasks.registration';
import { TimesheetsRegistration } from './arearegistration/timesheets.registration';
import { UsersRegistration } from './arearegistration/users.registration';
import { VendorsRegistration } from './arearegistration/vendors.registration';
import { BreadcrumbsModule } from './breadcrumbs/breadcrumbs.module';
import { BreadcrumbsService } from './breadcrumbs/shared/services/breadcrumbs.service';
import { DashboardComponent } from './dashboard/dashboard.component';

import { Routes } from '@angular/router';
import { MyProfileRegistration } from './arearegistration/my-profile.registration';
import { PickOrdersRegistration } from './arearegistration/pick-orders.registration';
import { PrintersRegistration } from './arearegistration/printers.registration';
import { LoggedInAuthGuard } from './core/guards/logged-in-auth.guard';
import { OfflineComponent } from './core/common/offline';
import { PageNotFoundComponent } from './core/common/page-not-found';
import { RouteData } from './shared/models/route';
import { EntityGlobals } from './app.global';

export const routingComponents = [
  DashboardComponent,
  ImportJobsRegistration.Components,
  OrganizationsRegistration.Components,
  UsersRegistration.Components,
  ManikinMakeAndModelRegistration.Components,
  ManikinMakeAndModelProcedureRegistration.Components,
  BuildingsRegistration.Components,
  PrintersRegistration.Components,
  RoomsRegistration.Components,
  CoursesRegistration.Components,
  DrugsRegistration.Components,
  PatientsRegistration.Components,
  ChecklistsRegistration.Components,
  SuppliesRegistration.Components,
  SimMaintenanceTasksRegistration.Components,
  LabMaintenanceTasksRegistration.Components,
  ScenariosRegistration.Components,
  SkillsRegistration.Components,
  LabRequestsRegistration.Components,
  CommunicationBoardActionItemRegistration.Components,
  ManikinInventoryItemProcedureRegistration.Components,
  CommunicationBoardItemRegistration.Components,
  TasksRegistration.Components,
  LocationsRegistration.Components,
  TimesheetsRegistration.Components,
  EmailTemplatesRegistration.Components,
  BedsRegistration.Components,
  EquipmentRegistration.Components,
  VendorsRegistration.Components,
  CustomersRegistration.Components,
  MyProfileRegistration.Components,
  PickOrdersRegistration.Components
];

const appRoutes: Routes = [
  {
    path: '',
    canActivateChild: [LoggedInAuthGuard],
    canActivate: [LoggedInAuthGuard],
    component: DashboardComponent,
    data: {
      breadcrumb: 'Dashboard',
      entityInformation: EntityGlobals.DASHBOARDS
    } as RouteData
  },
  {
    path: 'offline',
    component: OfflineComponent,
    data: {
      breadcrumb: 'Offline',
      showInMenu: false
    }
  },
  {
    path: 'oauth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent
  }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
    enableTracing: false,
    preloadingStrategy: PreloadAllModules
}),
        BreadcrumbsModule,
    ],
    providers: [
        BreadcrumbsService,
        ImportJobsRegistration.Services,
        OrganizationsRegistration.Services,
        UsersRegistration.Services,
        ManikinMakeAndModelRegistration.Services,
        BuildingsRegistration.Services,
        RoomsRegistration.Services,
        CoursesRegistration.Services,
        DrugsRegistration.Services,
        PrintersRegistration.Services,
        PatientsRegistration.Services,
        ChecklistsRegistration.Services,
        SuppliesRegistration.Services,
        SimMaintenanceTasksRegistration.Services,
        LabMaintenanceTasksRegistration.Services,
        ScenariosRegistration.Services,
        SkillsRegistration.Services,
        LabRequestsRegistration.Services,
        ManikinInventoryItemProcedureRegistration.Services,
        ManikinMakeAndModelProcedureRegistration.Services,
        CommunicationBoardActionItemRegistration.Services,
        CommunicationBoardItemRegistration.Services,
        TasksRegistration.Services,
        LocationsRegistration.Services,
        TimesheetsRegistration.Services,
        EmailTemplatesRegistration.Services,
        BedsRegistration.Services,
        EquipmentRegistration.Services,
        VendorsRegistration.Services,
        CustomersRegistration.Services,
        MyProfileRegistration.Services,
        PickOrdersRegistration.Services
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private router: Router) {
    appRoutes[0].children = [
      OrganizationsRegistration.Routes,
      UsersRegistration.Routes,
      ImportJobsRegistration.Routes,
      ManikinMakeAndModelRegistration.Routes,
      ManikinMakeAndModelProcedureRegistration.Routes,
      EmailTemplatesRegistration.Routes,
      LocationsRegistration.Routes,
      BuildingsRegistration.Routes,
      RoomsRegistration.Routes,
      PrintersRegistration.Routes,
      BedsRegistration.Routes,
      CoursesRegistration.Routes,
      DrugsRegistration.Routes,
      PatientsRegistration.Routes,
      ChecklistsRegistration.Routes,
      SuppliesRegistration.Routes,
      SimMaintenanceTasksRegistration.Routes,
      LabMaintenanceTasksRegistration.Routes,
      ScenariosRegistration.Routes,
      SkillsRegistration.Routes,
      LabRequestsRegistration.Routes,
      ManikinInventoryItemProcedureRegistration.Routes,
      CommunicationBoardActionItemRegistration.Routes,
      CommunicationBoardItemRegistration.Routes,
      TasksRegistration.Routes,
      TimesheetsRegistration.Routes,
      EquipmentRegistration.Routes,
      VendorsRegistration.Routes,
      CustomersRegistration.Routes,
      MyProfileRegistration.Routes,
      PickOrdersRegistration.Routes
    ];
    this.router.resetConfig(appRoutes);

    for (const route of this.router.config) {
      this.addFriendlyName(route, 0, '');
    }
  }

  addFriendlyName(parentRoute: Route, level: number, parentRoutePath: string) {
    const path = parentRoutePath + parentRoute.path;
    if (parentRoute.children !== undefined) {
      for (const routeChild of parentRoute.children) {
        const newLevel = level + 1;
        this.addFriendlyName(routeChild, newLevel, path + '/');
      }
    }
  }
}
