import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { FilterService, MultipleSortSettings } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import {
  ListManikinMakeAndModelsCommandResult,
  ManikinMakeAndModelService,
  ManikinOrTrainerBaseType,
  ManikinType,
  TrainerType
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
@Component({
  selector: 'app-manikin-make-and-model-list',
  templateUrl: './manikin-make-and-model-list.component.html',
  styleUrls: ['./manikin-make-and-model-list.component.scss']
})
export class ManikinMakeAndModelListComponent extends EntityBaseComponent implements OnInit {
  gridData: any[];

  public sort: SortDescriptor[] = [];
  public sortSettings: MultipleSortSettings = {
    mode: 'multiple',
    initialDirection: 'desc',
    allowUnsort: true,
    showIndexes: true
  };

  public baseTypes = Object.values(ManikinOrTrainerBaseType);
  public manikinTypes = Object.values(ManikinType);
  public trainerTypes = Object.values(TrainerType);
  public enumFilterPlaceholder = 'Select one or more filter values';

  constructor(
    private loadingBar: LoadingBarService,
    private logger: NGXLogger,
    private impersonationService: ImpersonationService,
    private manikinMakeAndModelService: ManikinMakeAndModelService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private dialogService: DialogService
  ) {
    super(EntityGlobals.MANIKIN_MAKES_AND_MODELS, router, route, impersonationService, logger);
  }

  public enumFilterChange(values: string[], filterService: FilterService, fieldName: string): void {
    filterService.filter({
      filters: values.map(value => ({
        field: fieldName,
        operator: 'eq',
        value
      })),
      logic: 'or'
    });
  }

  reloadData(): void {
    this.loadingBar.useRef('router').start();
    this.manikinMakeAndModelService.list().subscribe((result: ListManikinMakeAndModelsCommandResult) => {
      this.loadingBar.useRef('router').complete();
      this.logger.debug(result);
      this.gridData = result.data.map(item => ({
        ...item,
        createdDate: new Date(item.created),
        lastUpdatedDate: new Date(item.lastUpdated)
      }));
    });
  }

  ngOnInit() {
    this.reloadData();
  }

  delete(id) {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this item?',
      actions: [
        { text: 'No' },
        {
          text: 'Yes',
          primary: true,
          id: id
        }
      ],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe((result: any) => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
        this.manikinMakeAndModelService
          ._delete(result.id.toString())
          .toPromise()
          .then((newResult: any) => {
            this.toastService.success('', 'This item has been deleted.');
            this.reloadData();
          });
      }
    });
  }
}
