<router-outlet></router-outlet>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
<ng-template #offlineModule>
    <div class="modal-body">
        <h4 class="modal-title">Application Offline</h4>
        <p>We are currently experiencing connectivity issues, please try again.</p>
    </div>
</ng-template>
<ng-template #AppHelperModalWindow>
    <div class="modal-header">
        <h2 class="modal-title">Looking for Help?</h2>
        <button type="button" class="btn-close" (click)="this.closeAppHelperModalWindow()" aria-label="Close"></button>
      </div>
    <div class="modal-body">
        <p>TODO: Add shortcuts to the app</p>
        <div class="my-3" *ngIf="ShouldShowDemoDataButton">
            <button type="button" class="btn btn-primary" kendoButton (click)="resetDemoData()">Reset Demo Data</button>
            <div [innerHTML]="DemoDataResultText"></div>
        </div>
        <div class="my-3">
            <h4>System Information</h4>
            <ul>
                <li>API Environment: {{ this.configLoaderService.Environment }}</li>
                <li>API Application Version: {{ this.configLoaderService.ApplicationVersion }}</li>
                <li>Client Application URL: {{ this.configLoaderService.ApplicationUrl }}</li>
            </ul>
        </div>
    </div>
</ng-template>

<div id="modal-container" class="hidden"></div>
<div id="overlay" class="hidden"></div>