/**
 * Wingman Operations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PickOrderInventoryItemStatus = 'NotSaved' | 'NotStarted' | 'Pending' | 'Reserved' | 'Rejected' | 'Canceled' | 'CheckedOut';

export const PickOrderInventoryItemStatus = {
    NotSaved: 'NotSaved' as PickOrderInventoryItemStatus,
    NotStarted: 'NotStarted' as PickOrderInventoryItemStatus,
    Pending: 'Pending' as PickOrderInventoryItemStatus,
    Reserved: 'Reserved' as PickOrderInventoryItemStatus,
    Rejected: 'Rejected' as PickOrderInventoryItemStatus,
    Canceled: 'Canceled' as PickOrderInventoryItemStatus,
    CheckedOut: 'CheckedOut' as PickOrderInventoryItemStatus
};

