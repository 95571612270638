import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {
  CreateSimMaintenanceTaskCommand,
  CreateSimMaintenanceTaskCommandResult,
  EnumValue,
  OrganizationService,
  ReadSimMaintenanceTaskCommandResult,
  SimMaintenanceTaskService,
  UpdateSimMaintenanceTaskCommand,
  UpdateSimMaintenanceTaskCommandResult
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { CachedDataService, ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-simmaintenancetask-detail',
  templateUrl: './simmaintenancetask-detail.component.html',
  styleUrls: ['./simmaintenancetask-detail.component.scss']
})
export class SimMaintenanceTaskDetailComponent extends EntityBaseComponent implements OnInit {
  override showForm: boolean;
  readSimMaintenanceTaskCommandResult: ReadSimMaintenanceTaskCommandResult = {};
  dataModel: ReadSimMaintenanceTaskCommandResult;
  updateSimMaintenanceTaskCommand: UpdateSimMaintenanceTaskCommand = {};
  createSimMaintenanceTaskCommand: CreateSimMaintenanceTaskCommand = {};
  form: FormGroup;
  originalFormState: FormGroup;
  fromKey: string;
  simMaintenanceTaskTypes: EnumValue[] = [];
  constructor(
    private logger: NGXLogger,
    public cachedDataService: CachedDataService,
    private impersonationService: ImpersonationService,
    private organizationService: OrganizationService,
    private simmaintenancetaskService: SimMaintenanceTaskService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService
  ) {
    super(EntityGlobals.SIM_MAINTENANCE_TASKS, router, route, impersonationService, logger);
    this.createForm();
    this.OrganizationId.subscribe(() => {
      this.cachedDataService.simMaintenanceTaskTypes.subscribe((value: EnumValue[]) => {
        this.simMaintenanceTaskTypes = value;
        this.showForm = false;
        this.fromKey = 'FormModel';
        if (this.editMode) {
          this.getData();
        } else {
          this.showForm = true;
        }
      });
    });
  }

  convertServerDataToFormModel(result: ReadSimMaintenanceTaskCommandResult) {
    this.dataModel = result;
    this.logger.debug(this.dataModel);
    this.form.patchValue(this.dataModel);
    this.logger.debug(this.originalFormState.pristine, this.originalFormState);
    if (this.originalFormState.pristine) {
      this.logger.debug('Setting Original Form State Value');
      this.originalFormState.patchValue(this.dataModel);
    }
  }

  getData() {
    this.simmaintenancetaskService.read(this.id).subscribe((result: ReadSimMaintenanceTaskCommandResult) => {
      this.convertServerDataToFormModel(result);
      this.showForm = true;
    });
  }

  ngOnInit() {}

  resetForm() {
    this.logger.debug(this.originalFormState);
    this.form.reset(this.originalFormState.value);
    this.routeToEntityList();
  }

  createForm() {
    this.form = this.fb.group({
      id: [''],
      name: ['', [Validators.required, Validators.minLength(5)]],
      description: [''],
      type: [''],
      onlyWhenInUseDuringTimePeriod: [''],
      organizationId: [''],
      organizationName: [''],
      buildingName: [''],
      buildingId: [''],
      created: [''],
      createByUsername: [''],
      createdByUserId: [''],
      lastUpdated: [''],
      lastUpdatedByUsername: [''],
      lastUpdatedByUserId: ['']
    });
    this.originalFormState = this.form;
  }

  onSubmit(closeOnSuccess) {
    if (this.editMode) {
      this.updateSimMaintenanceTaskCommand = this.form.value;
      this.logger.debug(this.updateSimMaintenanceTaskCommand);
      this.simmaintenancetaskService
        .update(this.updateSimMaintenanceTaskCommand.id.toString(), this.updateSimMaintenanceTaskCommand)
        .subscribe((result: UpdateSimMaintenanceTaskCommandResult) => {
          this.logger.debug(result);
          if (closeOnSuccess) {
            this.routeToEntityList();
          } else {
            this.convertServerDataToFormModel(result);
            this.toastService.success('Success!', 'This item has been saved.');
          }
        });
    } else {
      this.createSimMaintenanceTaskCommand = this.form.value;
      this.logger.debug(this.createSimMaintenanceTaskCommand);
      this.simmaintenancetaskService
        .create(this.createSimMaintenanceTaskCommand)
        .subscribe((result: CreateSimMaintenanceTaskCommandResult) => {
          this.convertServerDataToFormModel(result);
          this.routeToEntityList();
          this.editMode = true;
          this.toastService.success('Success!', 'This item has been saved.');
        });
    }
  }
  submitAndClose() {
    this.onSubmit(true);
  }
  disable() {}
  delete() {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this simmaintenancetask?',
      actions: [{ text: 'No' }, { text: 'Yes', primary: true }],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
      }
      this.toastService.success('Oops.', 'This item has been saved.');
    });
  }
}
