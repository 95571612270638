import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { orderBy } from '@progress/kendo-data-query';
import { LabMaintenanceTaskService, ListLabMaintenanceTasksCommand } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-lab-maintenance-tasks',
  templateUrl: './labmaintenancetasks.component.html',
  styleUrls: ['./labmaintenancetasks.component.scss']
})
export class LabMaintenanceTasksComponent extends EntityBaseComponent implements OnInit {
  constructor(
    private logger: NGXLogger,
    private impersonationService: ImpersonationService,
    private labMaintenanceTaskService: LabMaintenanceTaskService,
    private route: ActivatedRoute,
    private router: Router,
    private loadingBar: LoadingBarService
  ) {
    super(EntityGlobals.LAB_MAINTENANCE_TASKS, router, route, impersonationService, logger);
    this.loadingBar.useRef('router').start();
    this.listLabMaintenanceTasksCommand.skip = this.state.skip;
    this.listLabMaintenanceTasksCommand.take = this.state.take;
    this.listLabMaintenanceTasksCommand.sort = this.state.sort;
    this.impersonationService.organizationId.subscribe((organizationId: number) => {
      this.labMaintenanceTaskService
        .list(this.listLabMaintenanceTasksCommand)
        .toPromise()
        .then((result: any) => {
          this.loadingBar.useRef('router').complete();
          this.gridData = result.data;
          this.query();
        });
    });
  }
  gridData: any[];

  listLabMaintenanceTasksCommand: ListLabMaintenanceTasksCommand = {};
  public view: GridDataResult;

  ngOnInit() {}

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.query();
  }

  public query() {
    const sortedGridData = orderBy(this.gridData, this.state.sort ?? [{ dir: 'desc', field: 'lastUpdated' }]);
    this.view = {
      data: sortedGridData.slice(this.state.skip, (this.state.skip ?? 0) + this.state.take),
      total: sortedGridData.length
    };
  }
}
