import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {
  CategoryService,
  CreateCategoryCommand,
  CreateCategoryCommandResult,
  CreateVendorCommand,
  CreateVendorCommandResult,
  EntityType,
  ListCategoryViewModel,
  ListCategorysCommand,
  ListCategorysCommandResult,
  ReadVendorCommandResult,
  UpdateVendorCommand,
  UpdateVendorCommandResult,
  VendorService
} from '@wo-api/index';
import { EntityGlobals, PhoneNumberRegex, ValidationMaxStringLength } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { patternValidator } from '@wo-app/shared/utils';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-vendors-detail',
  templateUrl: './vendors-detail.component.html',
  styleUrls: ['./vendors-detail.component.scss']
})
export class VendorsDetailComponent extends EntityBaseComponent implements OnInit {
  override showForm: boolean;
  readVendorCommandResult: ReadVendorCommandResult = {};
  dataModel: ReadVendorCommandResult;
  updateVendorCommand: UpdateVendorCommand = {};
  createVendorCommand: CreateVendorCommand = {};
  form: FormGroup;
  originalFormState: FormGroup;
  fromKey: string;
  organizations: any[];
  selectedOrganizationId: string;
  public $categories: Observable<ListCategorysCommandResult>;
  public listItems: any[] = [];

  get vendorName() {
    return this.form.get('vendorName');
  }

  get accountNumber() {
    return this.form.get('accountNumber');
  }

  get techSupportPhone() {
    return this.form.get('techSupportPhone');
  }

  get techSupportEmail() {
    return this.form.get('techSupportEmail');
  }

  get customerServicePhone() {
    return this.form.get('customerServicePhone');
  }

  get customerServiceEmail() {
    return this.form.get('customerServiceEmail');
  }

  get salesRepName() {
    return this.form.get('salesRepName');
  }

  get salesRepPhone() {
    return this.form.get('salesRepPhone');
  }

  get salesRepEmail() {
    return this.form.get('salesRepEmail');
  }

  constructor(
    private logger: NGXLogger,
    private impersonationService: ImpersonationService,
    private vendorService: VendorService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService,
    breadcrumbService: BreadcrumbsService,
    private categoryService: CategoryService,
    titleService: Title
  ) {
    super(EntityGlobals.VENDORS, router, route, impersonationService, logger, breadcrumbService, titleService);

    let cmd: ListCategorysCommand = {
      entityType: EntityType.Vendors
    };
    this.$categories = categoryService.list(cmd);
    this.$categories.subscribe((value: ListCategorysCommandResult) => {
      this.logger.debug('Categories for Supplies', value);
      this.listItems = value.data;
    });
  }

  public ngOnInit() {
    this._createForm();
    this.showForm = false;
    this.fromKey = 'FormModel';
    if (this.editMode) {
      this._getData();
    } else {
      this.showForm = true;
    }
  }

  private _createForm() {
    this.form = this.fb.group({
      id: [''],
      vendorName: ['', [Validators.required, Validators.maxLength(ValidationMaxStringLength.Default)]],
      accountNumber: ['', [Validators.maxLength(ValidationMaxStringLength.Short)]],

      techSupportPhone: ['', [patternValidator(PhoneNumberRegex)]],
      techSupportEmail: ['', [Validators.email]],
      techSupportWebsite: [''],

      customerServicePhone: ['', [patternValidator(PhoneNumberRegex)]],
      customerServiceEmail: ['', [Validators.email]],
      customerServiceWebsite: [''],

      salesRepName: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      salesRepPhone: ['', [patternValidator(PhoneNumberRegex)]],
      salesRepEmail: ['', [Validators.email]],
      salesWebsite: [''],

      categories: [''],
      notes: [''],

      created: [''],
      createByUsername: [''],
      createdByUserId: [''],
      lastUpdated: [''],
      lastUpdatedByUsername: [''],
      lastUpdatedByUserId: ['']
    });
    this.originalFormState = this.form;
  }

  private _convertServerDataToFormModel(result: ReadVendorCommandResult) {
    this.dataModel = result;
    this.form.patchValue(this.dataModel);
    if (this.originalFormState.pristine) {
      this.logger.debug('Setting Original Form State Value');
      this.originalFormState.patchValue(this.dataModel);
    }
  }

  private _getData() {
    this.vendorService
      .read(this.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: ReadVendorCommandResult) => {
        this.UpdateBreadcrumbPageTitle(result.id, result.vendorName);
        this.logger.debug(result);
        this._convertServerDataToFormModel(result);
        this.showForm = true;
      });
  }

  public resetForm() {
    this.logger.debug(this.originalFormState);
    this.form.reset(this.originalFormState.value);
    this.routeToEntityList();
  }

  public onSubmit(closeOnSuccess) {
    // If the Form is in Edit Mode, Update Object
    if (this.editMode) {
      this.updateVendorCommand = this.form.value;
      this.updateVendorCommand.categories = this.form.controls['categories'].value?.map(item => item.id);
      this.logger.debug(this.updateVendorCommand);
      this.vendorService
        .update(this.updateVendorCommand.id.toString(), this.updateVendorCommand)
        .pipe(takeUntil(this.destroy$))
        .subscribe((result: UpdateVendorCommandResult) => {
          this.logger.debug(result);
          if (closeOnSuccess) {
            this.routeToEntityList();
          } else {
            this._convertServerDataToFormModel(result);
            this.toastService.success('Success!', 'This item has been saved.');
          }
        });
    } else {
      // Otherwise, create a new object
      this.createVendorCommand = this.form.value;
      let categories = this.form.controls['categories'].value;
      if (categories?.length) {
        this.createVendorCommand.categories = categories.map(item => item.id);
      } else {
        this.createVendorCommand.categories = [];
      }
      this.logger.debug(this.createVendorCommand);
      this.vendorService
        .create(this.createVendorCommand)
        .pipe(takeUntil(this.destroy$))
        .subscribe((result: CreateVendorCommandResult) => {
          this._convertServerDataToFormModel(result as UpdateVendorCommandResult);
          this.routeToEntityList();
          this.editMode = true;
          this.toastService.success('Success!', 'This item has been saved.');
        });
    }
  }

  public valueNormalizer = (text$: Observable<string>): any =>
    text$.pipe(
      switchMap((text: string) => {
        // Search in values
        const matchingValue: any = this.listItems.find((item: ListCategoryViewModel) => {
          // Search for matching item to avoid duplicates
          return item.categoryName.toLowerCase() === text.toLowerCase();
        });

        if (matchingValue) {
          // Return the already selected matching value and the component will remove it
          return of(matchingValue);
        }

        // Search in data
        const matchingItem: any = this.listItems.find((item: ListCategoryViewModel) => {
          return item.categoryName.toLowerCase() === text.toLowerCase();
        });

        this.logger.debug(text, matchingValue, matchingItem);

        if (matchingItem) {
          return of(matchingItem);
        } else {
          return of(text).pipe(switchMap(this.service$));
        }
      })
    );

  public service$ = (text: string): any => {
    let cmd: CreateCategoryCommand = {
      name: text,
      entityType: EntityType.Vendors
    };
    let done = this.categoryService.create(cmd);
    done.subscribe((value: CreateCategoryCommandResult) => {
      this.listItems.push(value);
    });
    return done;
  };

  public submitAndClose() {
    this.onSubmit(true);
  }

  public disable() {}

  public delete() {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this vendor?',
      actions: [{ text: 'No' }, { text: 'Yes', primary: true }],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.pipe(takeUntil(this.destroy$)).subscribe(result => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
      }
      this.toastService.success('Oops.', 'This item has been saved.');
    });
  }
}
