import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { SupplyLocationQuantityService } from '@wo-api/index';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InventoryRoomQrService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class QrLandingCanActivate implements CanActivate {
  constructor(
    private _router: Router,
    private _supplyLocationQuantityService: SupplyLocationQuantityService,
    private _inventoryRoomQrService: InventoryRoomQrService
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const supplyLocationQuantityId: number = parseInt(route.params['supplyLocationQuantityId']);

    return this._supplyLocationQuantityService.validate(supplyLocationQuantityId).pipe(
      map(response => {
        if (!response.isValid) {
          this._inventoryRoomQrService.setValidationMessage(response.errorMessage);
          return this._router.createUrlTree(['/supplies/qr/not-found']);
        } else {
          return true;
        }
      })
    );
  }
}
