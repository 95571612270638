<form [formGroup]="form" (ngSubmit)="onSubmit(false)" [hidden]="!showForm" class="entity-form">
    <div class="row">
        <div class="col-sm-12 col-md-6" [hidden]="editMode">
            <button kendoButton type="submit" [disabled]="!form.valid" [primary]="true" [icon]="'save'">Create {{this.GetSingleEntityName()}}</button>
        </div>
        <div class="col-sm-12 col-md-6" [hidden]="!editMode">
            <kendo-buttongroup>
                <!--<button kendoButton type="submit" [disabled]="!form.valid" [primary]="true" [icon]="'save'">Save and Continue</button>-->
                <button kendoButton type="button" (click)="submitAndClose()" [primary]="true" [disabled]="!form.valid" [icon]="'save'">Save and Close</button>
                <button kendoButton type="button" (click)="resetForm()" [primary]="false" [icon]="'cancel-circle'">Cancel</button>
            </kendo-buttongroup>
        </div>
        <div class="col-sm-12 col-md-6" [hidden]="!editMode">
            <div class="float-end">
                <kendo-buttongroup>
                    <!--<button kendoButton type="button" (click)="disable()" [icon]="'cancel-circle'">Disable {{this.GetSingleEntityName()}}</button>
                  <button kendoButton type="button" (click)="delete()" [icon]="'delete'">Delete {{this.GetSingleEntityName()}}</button>-->
                </kendo-buttongroup>
                <div kendoDialogContainer></div>
            </div>
        </div>
        <div class="col-sm-12" [hidden]="true">
            <kendo-buttongroup>
                <button kendoButton type="button" [primary]="true" [icon]="'user'">View Users</button>
            </kendo-buttongroup>
        </div>
        <div class="col-sm-12">
            <hr/>
        </div>
    </div>
    <app-validation-errors></app-validation-errors>
    <input type="hidden" id="id" formControlName="id" />
    <kendo-tabstrip>
        <kendo-tabstrip-tab [title]="'General'" [selected]="true">
            <ng-template kendoTabContent>
                <div class="row">
                    <div class=" col-sm-12">
                        <label for="name" class="col-form-label col-form-label-sm">Task Name</label>
                        <input kendoTextBox formControlName="name" id="name" class="form-control" placeholder="Task Name" />
                    </div>
                    <div class=" col-md-12">
                        <label for="labMaintenanceTaskType" class="col-form-label col-form-label-sm">Task Type</label>
                        <kendo-dropdownlist class="form-control" [data]="cachedDataService.labMaintenanceTaskTypes | async" [valuePrimitive]="true" [textField]="'name'" [valueField]="'value'" required formControlName="labMaintenanceTaskType"></kendo-dropdownlist>
                    </div>
                    <div class=" col-md-12">
                        <label for="description" class="col-form-label col-form-label-sm">Description</label>
                        <textarea kendoTextBox type="text" formControlName="description" id="description" class="form-control" placeholder="Description and Instructions"></textarea>
                    </div>
                    <div class=" col-md-12">
                        <div class="form-check">
                            <input class="form-check-input" formControlName="onlyWhenInUseDuringTimePeriod" type="checkbox">
                            <label class="form-check-label" for="onlyWhenInUseDuringTimePeriod">Only When In Use During Time Period</label>
                        </div>
                    </div>
                    <input formControlName="organizationId" type="hidden">
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Metadata'" [disabled]="!editMode">
            <ng-template kendoTabContent>
                <app-metadata [metadata]="dataModel"></app-metadata>
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>
</form>