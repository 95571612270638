import { CustomerService } from '@wo-api/index';
import { EntityGlobals, MenuGroupType } from '@wo-app/app.global';
import { CustomersDetailComponent } from '@wo-app/components/dashboard/customers/customer-detail/customers-detail.component';
import { CustomersComponent } from '@wo-app/components/dashboard/customers/customers/customers.component';
import { ImpersonatingOrganizationCanActivate } from '@wo-app/core/guards';
import { MenuGroupListingItem } from '@wo-app/shared/models/menu';
import { RouteChild, RouteParent } from '@wo-app/shared/models/route';

export class CustomersRegistration {
  static Routes: RouteParent = {
    path: EntityGlobals.CUSTOMERS.baseRoute,
    canActivate: [ImpersonatingOrganizationCanActivate],
    entityInformation: EntityGlobals.CUSTOMERS,
    children: [
      {
        path: '',
        component: CustomersComponent,
        data: {
          breadcrumb: 'View All ' + EntityGlobals.CUSTOMERS.pluralName,
          menuGroups: [new MenuGroupListingItem(0, 1, MenuGroupType.People, EntityGlobals.CUSTOMERS.pluralName)],
          entityInformation: EntityGlobals.CUSTOMERS
        }
      } as RouteChild,
      {
        path: 'edit/:id',
        component: CustomersDetailComponent,
        data: {
          breadcrumb: 'Edit ' + EntityGlobals.CUSTOMERS.singleName,
          entityInformation: EntityGlobals.CUSTOMERS
        }
      } as RouteChild,
      {
        path: 'add',
        component: CustomersDetailComponent,
        data: {
          breadcrumb: 'Add ' + EntityGlobals.CUSTOMERS.singleName,
          entityInformation: EntityGlobals.CUSTOMERS
        }
      } as RouteChild
    ]
  };

  static Services: any = [CustomerService];

  static Components: any = [CustomersComponent, CustomersDetailComponent];
}
