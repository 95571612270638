import { TimesheetService } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { TimesheetCellEditorComponent } from '@wo-app/components/dashboard/timesheets/timesheet-cell-editor/timesheet-cell-editor.component';
import { TimesheetCellModalComponent } from '@wo-app/components/dashboard/timesheets/timesheet-cell-modal/timesheet-cell-modal.component';
import { TimesheetsComponent } from '@wo-app/components/dashboard/timesheets/timesheets.component';
import { ImpersonatingOrganizationCanActivate } from '@wo-app/core/guards';
import { RouteChild, RouteParent } from '@wo-app/shared/models/route';

export class TimesheetsRegistration {
  static Routes: RouteParent = {
    path: EntityGlobals.TIMESHEETS.baseRoute,
    canActivate: [ImpersonatingOrganizationCanActivate],
    entityInformation: EntityGlobals.TIMESHEETS,
    children: [
      {
        path: '',
        component: TimesheetsComponent,
        data: {
          breadcrumb: 'View All'
        }
      } as RouteChild
    ]
  };

  static Services: any = [TimesheetService];

  static Components: any = [TimesheetsComponent, TimesheetCellEditorComponent, TimesheetCellModalComponent];
}
