/**
 * Wingman Operations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SkinTone = 'Light' | 'Medium' | 'Dark' | 'N/A';

export const SkinTone = {
    Light: 'Light' as SkinTone,
    Medium: 'Medium' as SkinTone,
    Dark: 'Dark' as SkinTone,
    NA: 'N/A' as SkinTone
};

