<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <div class="card text-white bg-danger mb-3" [hidden]="failedPermanently">
                <div class="card-body">
                    <h4 class="card-title">There is an issue.</h4>
                    <p class="card-text">The application cannot connect to the server.</p>
                    <p class="card-text">We will try again attempt {{attemptCounter}} in {{ticks}} seconds.</p>
                </div>
            </div>
            <div class="card text-white bg-danger mb-3" [hidden]="!failedPermanently">
                <div class="card-header">Oops...</div>
                <div class="card-body">
                    <h4 class="card-title">Cannot Connect to Application</h4>
                    <p class="card-text">Please try again later.</p>
                </div>
            </div>
        </div>
    </div>
</div>