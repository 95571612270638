import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { State, orderBy } from '@progress/kendo-data-query';
import { ListManikinMakeAndModelProceduresCommand, ManikinMakeAndModelProcedureService } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ManikinMakeAndModelListProcedureComponent extends EntityBaseComponent implements OnInit {
  gridData: any[];

  listManikinMakeAndModelProceduresCommand: ListManikinMakeAndModelProceduresCommand = {};
  public view: GridDataResult;
  public override state: State = {
    skip: 0,
    take: 100,
    sort: [{ dir: 'desc', field: 'lastUpdated' }],
    filter: {
      logic: 'and',
      filters: []
    }
  };

  constructor(
    private logger: NGXLogger,
    private impersonationService: ImpersonationService,
    private manikinMakeAndModelProcedureService: ManikinMakeAndModelProcedureService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private dialogService: DialogService
  ) {
    super(EntityGlobals.MANIKIN_MAKES_AND_MODEL_PROCEDURES, router, route, impersonationService, logger);
    this.listManikinMakeAndModelProceduresCommand.skip = this.state.skip;
    this.listManikinMakeAndModelProceduresCommand.take = this.state.take;
    this.listManikinMakeAndModelProceduresCommand.sort = this.state.sort;
    this.getDataList();
  }

  getDataList() {
    this.manikinMakeAndModelProcedureService.list(this.listManikinMakeAndModelProceduresCommand).subscribe((result: any) => {
      this.gridData = result.data;
      this.query();
    });
  }

  ngOnInit() {}

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.query();
  }

  public query() {
    const sortedGridData = orderBy(this.gridData, this.state.sort ?? [{ dir: 'desc', field: 'lastUpdated' }]);
    this.view = {
      data: sortedGridData.slice(this.state.skip, (this.state.skip ?? 0) + this.state.take),
      total: sortedGridData.length
    };
  }

  delete(id) {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this item?',
      actions: [
        { text: 'No' },
        {
          text: 'Yes',
          primary: true,
          id: id
        }
      ],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe((result: any) => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
        this.manikinMakeAndModelProcedureService
          ._delete(result.id.toString())
          .toPromise()
          .then((newResult: any) => {
            this.toastService.success('', 'This item has been deleted.');
            this.getDataList();
          });
      }
    });
  }
}
