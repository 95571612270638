/**
 * Wingman Operations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ImportJobLineItemStatus = 'Started' | 'Validated' | 'Imported' | 'Failed';

export const ImportJobLineItemStatus = {
    Started: 'Started' as ImportJobLineItemStatus,
    Validated: 'Validated' as ImportJobLineItemStatus,
    Imported: 'Imported' as ImportJobLineItemStatus,
    Failed: 'Failed' as ImportJobLineItemStatus
};

