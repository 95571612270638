import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import {
  CancelEvent,
  EditEvent,
  GridComponent,
  GridDataResult,
  MultipleSortSettings,
  RemoveEvent,
  SaveEvent,
  SelectAllCheckboxState,
  SelectionEvent
} from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import {
  BedService,
  BuildingService,
  CreateSupplyCommand,
  EnumListResult,
  EnumValue,
  ListInventoryItemLocationQuantitiesForInventoryItemCommandResult,
  LocationService,
  OrganizationService,
  ReadSupplyCommandResult,
  RoomService,
  SupplyLocationQuantityService,
  SupplyService,
  UpdateInventoryItemLocationQuantityCommand,
  UpdateSupplyCommand,
  UpdateSupplyLocationQuantityCommandResult
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services/toast.service';
import { CachedDataService, ImpersonationService, LabelType, QrLabelService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
import { Observable, forkJoin } from 'rxjs';

@Component({
  selector: 'app-room-inventory-item-stock-list',
  templateUrl: './room-inventory-item-stock-list.component.html',
  styleUrls: ['./room-inventory-item-stock-list.component.scss']
})
export class RoomInventoryItemStockListComponent extends EntityBaseComponent implements OnInit {
  ReadSupplyCommandResult: ReadSupplyCommandResult = {};
  dataModel: ReadSupplyCommandResult;
  UpdateSupplyCommand: UpdateSupplyCommand = {};
  CreateSupplyCommand: CreateSupplyCommand = {};
  form: FormGroup;
  originalFormState: FormGroup;
  fromKey: string;
  inventoryItemTaskTypes: EnumValue[] = [];
  public $inventoryItemTypes: Observable<EnumListResult>;
  public formGroup: FormGroup;
  public locationsAndStocks: Observable<GridDataResult>;
  private editedRowIndex: number;
  qrLink: string;
  isQrModalVisible = false;
  public listInventoryItemLocationQuantitiesForInventoryItemCommandResult: Observable<ListInventoryItemLocationQuantitiesForInventoryItemCommandResult>;
  gridData: any[];
  public selectedIds: number[] = [];
  public selectAllState: SelectAllCheckboxState = 'unchecked';

  public sort: SortDescriptor[] = [{ dir: 'asc', field: 'locationName' }];

  public sortSettings: MultipleSortSettings = {
    mode: 'multiple',
    initialDirection: 'desc',
    allowUnsort: true,
    showIndexes: true
  };

  constructor(
    private logger: NGXLogger,
    public cachedDataService: CachedDataService,
    public locationService: LocationService,
    public buildingService: BuildingService,
    public roomService: RoomService,
    public bedService: BedService,
    private impersonationService: ImpersonationService,
    private organizationService: OrganizationService,
    private SupplyService: SupplyService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService,
    private SupplyLocationQuantityService: SupplyLocationQuantityService,
    private _breadcrumbService: BreadcrumbsService,
    private _title: Title,
    private loadingBar: LoadingBarService,
    private qrLabelService: QrLabelService
  ) {
    super(EntityGlobals.SUPPLIES, router, route, impersonationService, logger, _breadcrumbService, _title);
    this.editMode = false;
    this.$inventoryItemTypes = this.SupplyService.listSupplyTypes();
  }

  ngOnInit() {
    this.fromKey = 'FormModel';
    if (this.editMode) {
    } else {
      this.showForm = true;
    }
    this.refreshDataOnPage();
  }

  public editHandler(args: EditEvent): void {
    // define all editable fields validators and default values
    const { dataItem } = args;
    this.closeEditor(args.sender);

    this.logger.debug('dataItem', dataItem);

    const objectKeys = Object.keys(dataItem) as Array<string>;

    this.formGroup = new FormGroup({
      id: new FormControl(dataItem.id)
    });

    objectKeys.forEach((key: string) => {
      this.formGroup.addControl(key, new FormControl(dataItem[key]));
    });

    this.editedRowIndex = args.rowIndex;

    // put the row in edit mode, with the `FormGroup` build above
    args.sender.editRow(args.rowIndex, this.formGroup);
  }

  public cancelHandler(args: CancelEvent): void {
    // close the editor for the given row
    this.closeEditor(args.sender, args.rowIndex);
  }

  refreshDataOnPage(callback?: () => void) {
    this.loadingBar.useRef('router').start();
    this.SupplyLocationQuantityService.forInventoryItem(this.id).subscribe(
      (result: ListInventoryItemLocationQuantitiesForInventoryItemCommandResult) => {
        this.UpdateBreadcrumbPageTitle(result.data[0].supplyId, `${result.data[0].supplyId} - ${result.data[0].supplyName}`);

        const locationData = this.locationService.list();
        const buildingData = this.buildingService.list();
        const roomData = this.roomService.list();

        forkJoin([locationData, buildingData, roomData]).subscribe(([locations, buildings, rooms]) => {
          const locationMap = new Map(locations.data?.map(location => [location.id, location.name]));
          const buildingMap = new Map(buildings.data?.map(building => [building.id, building.name]));
          const roomMap = new Map(rooms.data?.map(room => [room.id, room.name]));
          this.gridData = result.data?.map(lineItem => ({
            ...lineItem,
            locationName: locationMap.get(lineItem.locationId),
            buildingName: buildingMap.get(lineItem.buildingId),
            roomName: roomMap.get(lineItem.roomId)
          }));
          this.logger.log('Updated data', this.gridData);
          this.loadingBar.useRef('router').complete();
          if (callback) {
            callback();
          }
        });
      }
    );
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew }: SaveEvent): void {
    this.logger.debug(sender, rowIndex, formGroup);
    let formData = formGroup.value;
    let request: UpdateInventoryItemLocationQuantityCommand = {
      id: formData.id,
      supplyId: formData.invent,
      roomId: formData.roomId,
      quantity: formData.quantity,
      trackInventoryInThisRoom: formData.trackInventoryInThisRoom.toString().toLowerCase() == 'true',
      dimension1: formData.dimension1,
      dimension2: formData.dimension2,
      dimension3: formData.dimension3,
      stockingQuantity: formData.stockingQuantity,
      stockingQuantityUnitOfMeasurement: formData.stockingQuantityUnitOfMeasurement
    };
    this.logger.debug('Update Request', request);
    this.SupplyLocationQuantityService.update(formData.id, request).subscribe((result: UpdateSupplyLocationQuantityCommandResult) => {
      this.refreshDataOnPage(() => sender.closeRow(rowIndex));
    });
  }

  public removeHandler(args: RemoveEvent): void {
    // remove the current dataItem from the current data source,
    // `editService` in this example
    //this.editService.remove(args.dataItem);
  }

  public onQrClick(supplyLocationQuantityId: number): void {
    this.qrLink = `${location.origin}/supplies/qr/supply-location-quantity/${supplyLocationQuantityId}`;
    this.isQrModalVisible = true;
  }

  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex) {
    // close the editor
    grid.closeRow(rowIndex);
    // reset the helpers
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  saveRowEdit(dataItem: any) {
    this.logger.debug('dataItem', dataItem);
  }

  public onSelectionChange(e: SelectionEvent) {
    e.selectedRows.forEach(row => {
      if (!this.selectedIds.includes(row.dataItem.id)) {
        this.selectedIds.push(row.dataItem.id);
      }
    });
    e.deselectedRows.forEach(row => {
      const index = this.selectedIds.indexOf(row.dataItem.id);
      if (index > -1) {
        this.selectedIds.splice(index, 1);
      }
    });

    const selectionLength = this.selectedIds.length;
    if (selectionLength === 0) {
      this.selectAllState = 'unchecked';
    } else if (selectionLength > 0 && selectionLength < this.gridData.length) {
      this.selectAllState = 'indeterminate';
    } else {
      this.selectAllState = 'checked';
    }
  }

  public onSelectAll(checkedState: SelectAllCheckboxState) {
    if (checkedState === 'checked') {
      this.selectedIds = this.gridData.map(item => item.id);
      this.selectAllState = 'checked';
    } else {
      this.selectedIds = [];
      this.selectAllState = 'unchecked';
    }
  }

  public async onPrintLabels(): Promise<void> {
    const labelType = LabelType.Supply;
    const selectedItems = this.gridData.filter(gridRow => this.selectedIds.includes(gridRow.id));
    await this.qrLabelService.setQrLabelsInBrowserStorage(
      (item: any) => `${location.origin}/${EntityGlobals.SUPPLIES.baseRoute}/qr/supply-location-quantity/${item.id}`,
      labelType,
      selectedItems
    );
    this.router.navigateByUrl(`/printer/print-labels/${labelType.toLowerCase()}`);
  }

  @ViewChild('selectAllCheckbox') selectAllCheckbox: ElementRef;
  clickSelectAllCheckbox() {
    this.selectAllCheckbox.nativeElement.click();
  }
}
