import { Component, Input } from '@angular/core';
import { ListManikinMakeAndModelsCommandViewModel, ManikinOrTrainerBaseType } from '@wo-api/index';

@Component({
  selector: 'app-manikin-and-trainer-info-header',
  templateUrl: './manikin-and-trainer-info-header.component.html',
  styleUrls: ['./manikin-and-trainer-info-header.component.scss']
})
export class ManikinAndTrainerInfoHeaderComponent {
  @Input() public modelInfo: ListManikinMakeAndModelsCommandViewModel;

  getTypeLabel(modelInfo: ListManikinMakeAndModelsCommandViewModel): string {
    if (modelInfo.manikinOrTrainerBaseType == ManikinOrTrainerBaseType.Manikin) {
      return `${modelInfo.manikinOrTrainerBaseType} - ${modelInfo.manikinType}`;
    }
    if (modelInfo.manikinOrTrainerBaseType == ManikinOrTrainerBaseType.Trainer) {
      return `${modelInfo.manikinOrTrainerBaseType} - ${modelInfo.trainerType}`;
    }
    throw new Error('This type of manikin/trainer is not allowed.');
  }
}
