import { DrugService } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { DrugListBuilderComponent } from '@wo-app/components/dashboard/drugs/drug-list-builder/drug-list-builder.component';
import { DrugsComponent } from '@wo-app/components/dashboard/drugs/drugs/drugs.component';
import { ImpersonatingOrganizationCanActivate } from '@wo-app/core/guards';
import { RouteChild, RouteParent } from '@wo-app/shared/models/route';

export class DrugsRegistration {
  static Routes: RouteParent = {
    path: EntityGlobals.DRUGS.baseRoute,
    canActivate: [ImpersonatingOrganizationCanActivate],
    entityInformation: EntityGlobals.DRUGS,
    children: [
      {
        path: '',
        component: DrugsComponent,
        data: {
          breadcrumb: 'View All'
        }
      } as RouteChild
    ]
  };

  static Services: any = [DrugService];

  static Components: any = [DrugsComponent, DrugListBuilderComponent];
}
