<kendo-grid *ngIf="gridData"
    [data]="gridData"
    [style.maxHeight.%]="100">
    <kendo-grid-column field="roomName" title="Room Name">
          <ng-template kendoGridCellTemplate let-dataItem>
            <a href="#" class="link" [routerLink]="'/rooms/edit/' + dataItem.roomId">{{ dataItem.roomName }} <i class="fa-light fa-arrow-up-right-from-square"></i></a>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column-group title="Quantities">
        <kendo-grid-column field="quantity" title="In Place">
        </kendo-grid-column>
        <kendo-grid-column field="quantityReserved" title="Reserved">
        </kendo-grid-column>
        <kendo-grid-column field="quantityAvailable" title="Available">
        </kendo-grid-column>
    </kendo-grid-column-group>
    <kendo-grid-column-group title="Stocking Levels">
        <kendo-grid-column field="stockingQuantity" title="Quantity"></kendo-grid-column>
        <kendo-grid-column field="stockingQuantityUnitOfMeasurement" title="Unit of Measurement"></kendo-grid-column>
    </kendo-grid-column-group>
    <kendo-grid-column-group title="Dimensions">
        <kendo-grid-column field="dimension1" title="Dimension 1"></kendo-grid-column>
        <kendo-grid-column field="dimension2" title="Dimension 2"></kendo-grid-column>
        <kendo-grid-column field="dimension3" title="Dimension 3"></kendo-grid-column>
    </kendo-grid-column-group>
</kendo-grid>
