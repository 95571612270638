import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { KendoModule } from '@kendo/kendo.module';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services/toast.service';
import { LoginComponent } from './common/login';
import { OfflineComponent } from './common/offline';
import { PageNotFoundComponent } from './common/page-not-found';
import { ToastMessageComponent } from './common/toast-message';
import { ToastsContainerComponent } from './common/toast-message/toasts-container.component';
import { ValidationErrorsComponent, ValidationErrorsService } from './common/validation-errors';
import {
  ImpersonatingOrganizationCanActivate,
  LoggedInAuthGuard,
  NotImpersonatingOrganizationCanActivate,
  OrganizationModuleCanActivate
} from './guards';
import { ErrorCatchingInterceptor } from './interceptors';
import { ImpersonationService } from './services';
import { HamburgerService, SideMenuComponent, SideMenuService } from './side-menu';

@NgModule({
  imports: [CommonModule, FormsModule, KendoModule, RouterModule, ToastsContainerComponent, NgbAccordionModule],
  declarations: [
    LoginComponent,
    OfflineComponent,
    PageNotFoundComponent,
    ValidationErrorsComponent,
    SideMenuComponent,
    ToastMessageComponent
  ],
  providers: [
    ToastService,
    SideMenuService,
    HamburgerService,
    ValidationErrorsService,
    ImpersonationService,

    LoggedInAuthGuard,
    OrganizationModuleCanActivate,
    ImpersonatingOrganizationCanActivate,
    NotImpersonatingOrganizationCanActivate,
    ToastsContainerComponent,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCatchingInterceptor,
      multi: true
    }
  ],
  exports: [
    LoginComponent,
    OfflineComponent,
    PageNotFoundComponent,
    ValidationErrorsComponent,
    SideMenuComponent,
    ToastMessageComponent,
    ToastsContainerComponent
  ]
})
export class CoreModule {}
