import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SupplyService } from '@wo-api/index';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-inventory-change-tracker',
  templateUrl: './inventory-change-tracker.component.html',
  styleUrls: ['./inventory-change-tracker.component.scss']
})
export class InventoryChangeTrackerComponent implements OnInit {
  public labRequestId: number;
  public organizationId: number;
  data: any[] = [];

  constructor(private logger: NGXLogger, private SupplyService: SupplyService, private route: ActivatedRoute, private router: Router) {
    this.labRequestId = 6;
    this.organizationId = 1;
  }

  ngOnInit() {}

  onNotificationOfSupply($event) {
    this.logger.debug($event);
    this.data = $event;
  }

  submitChanges(): void {
    this.logger.debug(this.data);
    //const command: CreateSupplyAlignmentCommand = {};
    //command.items = this.data;
    //command.labRequestId = this.labRequestId;
    /*this.SupplyService.createAlignment(command)
    .subscribe((result) => {
      this.logger.debug(result);
      if (this.labRequestId) {
        this.router.navigate([this.organizationId, this.labRequestId]);
      }
    });*/
  }
}
