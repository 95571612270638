import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { FilterService, GridDataResult, MultipleSortSettings, SelectAllCheckboxState, SelectionEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, process } from '@progress/kendo-data-query';
import {
  BedService,
  BuildingService,
  EquipmentLineItemService,
  EquipmentStatus,
  ListBedsCommandResult,
  ListBuildingsCommandResult,
  ListEquipmentLineItemsCommand,
  ListEquipmentLineItemsCommandResult,
  ListLocationsCommandResult,
  ListRoomsCommandResult,
  LocationService,
  RoomService
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services/toast.service';
import { ImpersonationService, LabelType, QrLabelService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import ArrayUtilities from '@wo-app/shared/utils/array-utils';
import { NGXLogger } from 'ngx-logger';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-line-item-list',
  templateUrl: './line-item-list.component.html',
  styleUrls: ['./line-item-list.component.scss']
})
export class EquipmentLineItemListComponent extends EntityBaseComponent implements OnInit {
  gridData: any[];
  totalInventoryItemCount: number = 0;
  ListEquipmentLineItemsCommand: ListEquipmentLineItemsCommand = {};
  public view: GridDataResult;
  public equipmentId: number;
  public data: ListEquipmentLineItemsCommandResult = null;
  public locations: ListLocationsCommandResult;
  public buildings: ListBuildingsCommandResult;
  public rooms: ListRoomsCommandResult;
  public beds: ListBedsCommandResult;
  public qrLink: string;
  public isQrModalVisible = false;
  public selectedIds: number[] = [];
  public selectAllState: SelectAllCheckboxState = 'unchecked';
  ArrayUtilities = ArrayUtilities;

  constructor(
    private dialogService: DialogService,
    private loadingBar: LoadingBarService,
    private logger: NGXLogger,
    private impersonationService: ImpersonationService,
    private equipmentLineItemService: EquipmentLineItemService,
    private route: ActivatedRoute,
    _breadcrumbService: BreadcrumbsService,
    _titleService: Title,
    private router: Router,
    private locationService: LocationService,
    private buildingService: BuildingService,
    private roomService: RoomService,
    private bedService: BedService,
    private qrLabelService: QrLabelService,
    private toastService: ToastService
  ) {
    super(EntityGlobals.EQUIPMENT, router, route, impersonationService, logger, _breadcrumbService, _titleService);
    this.allData = this.allData.bind(this);
    this.state.sort = [{ dir: 'asc', field: 'supplyName' }];
    this.locationService.list().subscribe((data: any) => (this.locations = data));
    this.buildingService.list().subscribe((data: any) => (this.buildings = data));
    this.roomService.list().subscribe((data: any) => (this.rooms = data));
    this.bedService.list().subscribe((data: any) => (this.beds = data));
  }

  public sort: SortDescriptor[] = [];
  public sortSettings: MultipleSortSettings = {
    mode: 'multiple',
    initialDirection: 'desc',
    allowUnsort: true,
    showIndexes: true
  };

  public equipmentStatuses = Object.values(EquipmentStatus);
  public enumFilterPlaceholder = 'Select one or more filter values';

  public enumFilterChange(values: string[], filterService: FilterService, fieldName: string): void {
    filterService.filter({
      filters: values.map(value => ({
        field: fieldName,
        operator: 'eq',
        value
      })),
      logic: 'or'
    });
  }

  ngOnInit() {
    this.equipmentId = this.route.snapshot.params['equipmentId'];
    this.reloadData();
  }

  public allData(): ExcelExportData {
    this.logger.debug('this.gridData', this.gridData);
    const result: ExcelExportData = {
      data: process(this.gridData, {
        sort: this.state.sort
      }).data
    };
    return result;
  }

  reloadData(): void {
    this.loadingBar.useRef('router').start();
    this.ListEquipmentLineItemsCommand.equipmentId = this.equipmentId;

    let locationData = this.locationService.list();
    let buildingData = this.buildingService.list();
    let roomData = this.roomService.list();
    let bedData = this.bedService.list();
    let equipmentLineItemsData = this.equipmentLineItemService.list(this.ListEquipmentLineItemsCommand);

    forkJoin([locationData, buildingData, roomData, bedData, equipmentLineItemsData]).subscribe(
      ([locations, buildings, rooms, beds, equipmentLineItems]) => {
        this.loadingBar.useRef('router').complete();
        console.log(equipmentLineItems);
        const locationMap = new Map(locations.data?.map(location => [location.id, location.name]));
        const buildingMap = new Map(buildings.data?.map(building => [building.id, building.name]));
        const roomMap = new Map(rooms.data?.map(room => [room.id, room.name]));
        const bedMap = new Map(beds.data?.map(bed => [bed.id, bed.customId]));
        this.gridData = equipmentLineItems.data?.map(equipmentLineItem => ({
          ...equipmentLineItem,
          locationName: locationMap.get(equipmentLineItem.currentLocation.locationId),
          buildingName: buildingMap.get(equipmentLineItem.currentLocation.buildingId),
          roomName: roomMap.get(equipmentLineItem.currentLocation.roomId),
          bedName: bedMap.get(equipmentLineItem.currentLocation.bedId)
        }));
      }
    );
  }

  delete(id) {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this item?',
      actions: [
        { text: 'No' },
        {
          text: 'Yes',
          primary: true,
          id: id
        }
      ],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe((result: any) => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
        this.equipmentLineItemService._delete(result.id.toString()).subscribe((result: any) => {
          this.reloadData();
        });
      }
    });
  }

  public onQrClick(equipmentLineItemId: number) {
    this.qrLink = `${location.origin}/${EntityGlobals.EQUIPMENT.baseRoute}/line-item/${equipmentLineItemId}/qr`;
    this.isQrModalVisible = true;
  }

  public onSelectionChange(e: SelectionEvent) {
    e.selectedRows.forEach(row => {
      if (!this.selectedIds.includes(row.dataItem.id)) {
        this.selectedIds.push(row.dataItem.id);
      }
    });
    e.deselectedRows.forEach(row => {
      const index = this.selectedIds.indexOf(row.dataItem.id);
      if (index > -1) {
        this.selectedIds.splice(index, 1);
      }
    });

    const selectionLength = this.selectedIds.length;
    if (selectionLength === 0) {
      this.selectAllState = 'unchecked';
    } else if (selectionLength > 0 && selectionLength < this.gridData.length) {
      this.selectAllState = 'indeterminate';
    } else {
      this.selectAllState = 'checked';
    }
  }

  public onSelectAll(checkedState: SelectAllCheckboxState) {
    if (checkedState === 'checked') {
      this.selectedIds = this.gridData.map(item => item.id);
      this.selectAllState = 'checked';
    } else {
      this.selectedIds = [];
      this.selectAllState = 'unchecked';
    }
  }

  public async onPrintLabels(): Promise<void> {
    const labelType = LabelType.Equipment;
    const selectedItems = this.gridData.filter(gridRow => this.selectedIds.includes(gridRow.id));
    await this.qrLabelService.setQrLabelsInBrowserStorage(
      (item: any) => `${location.origin}/${EntityGlobals.EQUIPMENT.baseRoute}/line-item/${item.id}/qr`,
      labelType,
      selectedItems
    );
    this.router.navigateByUrl(`/printer/print-labels/${labelType.toLowerCase()}`);
  }

  @ViewChild('selectAllCheckbox') selectAllCheckbox: ElementRef;
  clickSelectAllCheckbox() {
    this.selectAllCheckbox.nativeElement.click();
  }
}
