<div [hidden]="doAnyLabelsExistInBrowserStorage()">
    There are no labels to print in the system.
</div>
<div [hidden]="!doAnyLabelsExistInBrowserStorage()">
    <div>
        <div class="row">
            <div class="col-md-12 col-lg-6">
                <label for="nickname" class="col-form-label col-form-label-sm">Which labels would you like to print?</label>
                <kendo-dropdownlist [disabled]="startButtonDisabled" class="form-control" [data]="labelTypeOptions" textField="name" valueField="value" [valuePrimitive]="false" [ngModel]="selectedLabelType | async" (ngModelChange)="selectedLabelType.next($event)"></kendo-dropdownlist>
            </div>
        </div>        
        <div class="row">
            <div class=" col-md-12 col-lg-6" *ngIf="selectedLabelType$ | async">
                <div *ngFor="let label of (labelData$ | async)?.slice(0, 1)">
                    <app-zpl-label-preview [Label]="label" [LabelType]="ConvertLabelTypeRadioOptionToLabelType(selectedLabelType$ | async)"></app-zpl-label-preview>
                </div>
                <div>
                    <button kendoButton class="btn btn-primary" (click)="startPrinting($event)" [disabled]="startButtonDisabled">Start Printing {{(labelData$ | async)?.length}} {{ ((labelData$ | async)?.length == 1 ? "label" : "labels") }}</button>
                </div>
                <div *ngIf="progressBarValue != 0" class="my-3 card">
                    <div class="card-body">
                        <h3 class="card-title">Printing Status</h3>
                        <kendo-progressbar [value]="progressBarValue"></kendo-progressbar>
                    <div [hidden]="progressBarValue != 100">
                        <div>
                            <p>The printing of your labels is complete.</p>
                        </div>
                        <div>
                            <button kendoButton class="btn btn-primary" (click)="restartPage($event)"><i class="fa-light fa-arrows-rotate"></i> Restart Process</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
