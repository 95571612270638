/**
 * Wingman Operations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Severity = 'Error' | 'Warning' | 'Info';

export const Severity = {
    Error: 'Error' as Severity,
    Warning: 'Warning' as Severity,
    Info: 'Info' as Severity
};

