<table class="table">
    <thead>
        <th>Date</th>
        <th>Start Time</th>
        <th>End Time</th>
        <th>Actions</th>
    </thead>
    <tbody>
        <tr *ngFor="let item of inputData; let i = index;">
            <td>
                <kendo-datepicker [(ngModel)]="item.dateTimeStart" placeholder="Date" (valueChange)="datePickerValueChange($event, item)"></kendo-datepicker>
            </td>
            <td>
                <kendo-timepicker [(ngModel)]="item.dateTimeStart" [min]="timePickerMin" [max]="timePickerMax" (valueChange)="onTimePickerValueChange($event, item)"></kendo-timepicker>
            </td>
            <td>
                <kendo-timepicker [(ngModel)]="item.dateTimeEnd" [min]="timePickerMin" [max]="timePickerMax" (valueChange)="onTimePickerValueChange($event, item)"></kendo-timepicker>
            </td>
            <td>
                <button kendoButton type="button" class="" (click)='removeItem(item)' title="Remove Date and Time"><i class="fa-light fa-trash"></i></button>
            </td>
        </tr>
    </tbody>
    <tfoot>
        <td colspan="4">
            <button kendoButton type="button" class="" (click)='addItem()'>Add Row</button>
        </td>
    </tfoot>
</table>