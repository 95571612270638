import { TaskService } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { TaskCheckBoxComponent } from '@wo-app/components/dashboard/tasks/task-check-box/task-check-box.component';
import { TaskDetailComponent } from '@wo-app/components/dashboard/tasks/task-detail/task-detail.component';
import { TaskListComponent } from '@wo-app/components/dashboard/tasks/task-list/task-list.component';
import { ImpersonatingOrganizationCanActivate } from '@wo-app/core/guards';
import { RouteChild, RouteParent } from '@wo-app/shared/models/route';

export class TasksRegistration {
  static Routes: RouteParent = {
    path: EntityGlobals.TASKS.baseRoute,
    canActivate: [ImpersonatingOrganizationCanActivate],
    entityInformation: EntityGlobals.TASKS,
    children: [
      {
        path: '',
        component: TaskListComponent,
        data: {
          breadcrumb: 'View All'
        }
      } as RouteChild,
      {
        path: 'edit/:id',
        component: TaskDetailComponent,
        data: {
          breadcrumb: 'Edit ' + EntityGlobals.TASKS.singleName
        }
      } as RouteChild,
      {
        path: 'add',
        component: TaskDetailComponent,
        data: {
          breadcrumb: 'Add ' + EntityGlobals.TASKS.singleName
        }
      } as RouteChild
    ]
  };

  static Services: any = [TaskService];

  static Components: any = [TaskListComponent, TaskDetailComponent, TaskCheckBoxComponent];
}
