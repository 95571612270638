import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ListTasksCommand, ListTasksCommandResult, ListTasksViewModel, TaskService } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent extends EntityBaseComponent implements OnInit {
  tasks: Array<ListTasksViewModel> = [];
  selectedValue: any;

  constructor(
    private loadingBar: LoadingBarService,
    private logger: NGXLogger,
    activatedRoute: ActivatedRoute,
    router: Router,
    private impersonationService: ImpersonationService,
    private tasksService: TaskService,
    private cd: ChangeDetectorRef
  ) {
    super(EntityGlobals.TASKS, router, activatedRoute, impersonationService, logger);
    this.selectedValue = 'All Lab Requests';
  }
  selectionChange(e) {
    this.logger.debug(e);
  }

  valueChange(e) {
    this.logger.debug(e);
  }

  ngOnInit() {
    this.getTasks();
  }

  getLabRequestNames(): any[] {
    const filterArray: any[] = [];
    this.tasks.forEach(item => {
      filterArray.push({ labRequestName: item.labRequestName, id: item.labRequestId });
    });
    return this.unique(filterArray, 'id');
  }

  unique(array, propertyName) {
    return array.filter((e, i) => array.findIndex(a => a[propertyName] === e[propertyName]) === i);
  }

  getTasksDueToday(): ListTasksViewModel[] {
    return this.tasks.filter(task => {
      return moment(task.dateTimeDue).diff(moment(), 'days') === 0;
    });
  }

  getTasksDueTomorrow(): ListTasksViewModel[] {
    return this.tasks.filter(task => {
      return moment(task.dateTimeDue).diff(moment(), 'days') === 1;
    });
  }

  getTasksDueNext7Days(): ListTasksViewModel[] {
    return this.tasks.filter(task => {
      const dayDifference = moment(task.dateTimeDue).diff(moment(), 'days');
      return dayDifference > 1 && dayDifference <= 7;
    });
  }

  getTasksDueMoreThan7Days(): ListTasksViewModel[] {
    return this.tasks.filter(task => {
      const dayDifference = moment(task.dateTimeDue).diff(moment(), 'days');
      return dayDifference > 7;
    });
  }

  /** Load all tasks for this Organization */
  getTasks(): void {
    this.loadingBar.useRef('router').start();
    const command: ListTasksCommand = {};
    this.tasksService.listTasks(command).subscribe((result: ListTasksCommandResult) => {
      this.loadingBar.useRef('router').complete();
      this.tasks = result.data;
      this.cd.detectChanges();
    });
  }
}
