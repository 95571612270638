import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {
  CancelEvent,
  DataStateChangeEvent,
  EditEvent,
  GridComponent,
  GridDataResult,
  RemoveEvent,
  SaveEvent
} from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, State, filterBy, orderBy } from '@progress/kendo-data-query';
import {
  AssignPickOrderCommand,
  ListPickOrdersCommand,
  ListPickOrdersCommandResult,
  ListRoomsCommand,
  PickOrderService,
  PickOrderStatus,
  UpdatePickOrderAssigneeCommand,
  UpdatePickOrderStatusCommand
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { CachedDataService, ImpersonationService, ModalService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
import { PickOrderUserSelectionDialogComponent } from '../pick-order-user-selection-dialog/pick-order-user-selection-dialog.component';
import { ListPickOrdersCommandViewModel } from '@wo-api/index';

@Component({
  selector: 'app-pick-order-listing',
  templateUrl: './pick-order-listing.component.html',
  styleUrls: ['./pick-order-listing.component.scss']
})
export class PickOrderListingComponent extends EntityBaseComponent implements OnInit {
  public isLoading = false;
  public pageSize = 25;
  public assignPickOrderCommand: AssignPickOrderCommand = {
    id: 0,
    assigneeUserId: ''
  };

  private editedRowIndex: number;
  public formGroup: FormGroup;
  public statusList: PickOrderStatus[] = [PickOrderStatus.Complete, PickOrderStatus.Closed, PickOrderStatus.Canceled];
  private editingItemId: number;

  constructor(
    private dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute,
    private impersonationService: ImpersonationService,
    private logger: NGXLogger,
    private pickOrderService: PickOrderService,
    public cachedDataService: CachedDataService,
    private loadingBar: LoadingBarService,
    breadcrumbService: BreadcrumbsService,
    titleService: Title,
    private modalService: ModalService,
    private toastService: ToastService
  ) {
    super(EntityGlobals.PICKORDERS, router, route, impersonationService, logger, breadcrumbService, titleService);
    this.pageSize = 25;
  }

  gridData: Array<ListPickOrdersCommandViewModel>;

  listRoomsCommand: ListRoomsCommand = {};
  public view: GridDataResult;
  defaultSort = [
    { dir: 'asc', field: 'roomName' },
    { dir: 'asc', field: 'buildingId' }
  ];
  public override state: State = {
    skip: 0,
    take: 25,
    sort: [{ dir: 'desc', field: 'id' }],
    filter: {
      logic: 'and',
      filters: []
    }
  };

  ngOnInit() {
    this.reloadData();
  }

  private reloadData() {
    this.isLoading = true;
    this.loadingBar.useRef('router').start();
    let cmd: ListPickOrdersCommand = {};
    this.pickOrderService.list(cmd).subscribe((result: ListPickOrdersCommandResult) => {
      this.isLoading = false;
      this.loadingBar.useRef('router').complete();
      if (result && result.data) {
        this.gridData = result.data.map(item => ({
          ...item,
          createdDate: new Date(item.created),
          dateNeededDate: new Date(item.dateNeeded)
        }));

        this.query();
      }
    });
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.query();
  }

  public query() {
    const filteredData = filterBy(this.gridData, this.filter);
    const sortedGridData = orderBy(filteredData, this.state.sort ?? [{ dir: 'asc', field: 'name' }]);
    this.view = {
      data: sortedGridData.slice(this.state.skip, (this.state.skip ?? 0) + this.pageSize),
      total: sortedGridData.length
    };
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.logger.debug('Filtering has changed');
    this.filter = filter;
    this.query();
  }

  public filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: []
  };

  public removeHandler(args: RemoveEvent): void {
    this.closeEditor(args.sender, args.rowIndex);
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this item?',
      actions: [
        { text: 'No' },
        {
          text: 'Yes',
          primary: true,
          id: args.dataItem.id
        }
      ],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe((result: any) => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
        this.pickOrderService._delete(result.id.toString()).subscribe((result: any) => {
          this.reloadData();
        });
      }
    });
  }

  public copyItem(id) {
    this.logger.log(`Route to Add(Copy) PickOrder with Id:${id}`);
    this.router.navigate(['/' + this.GetEntityUrl() + '/add/' + id]);
  }

  public assignItem(id) {
    let inputs = {
      title: 'Assign Pick Order',
      buttons: ['Confirm', 'Cancel']
    };
    this.modalService.init(PickOrderUserSelectionDialogComponent, inputs, {});
    let disposable = this.modalService.GetAnswer().subscribe(res => {
      disposable.unsubscribe();
      if (res && res.answer === 'Confirm') {
        const updatePickOrderAssigneeCommand: UpdatePickOrderAssigneeCommand = {
          id: id,
          assigneeUserId: res.selectedUserId
        };

        this.pickOrderService
          .updateAssignee(updatePickOrderAssigneeCommand.id.toString(), updatePickOrderAssigneeCommand)
          .subscribe((result: any) => {
            this.logger.debug(result);
            this.toastService.success('Success!', 'Pick order assigned.');
            this.reloadData();
          });
      }
    });
  }

  public editHandler(args: EditEvent): void {
    const { dataItem } = args;
    this.closeEditor(args.sender);

    this.formGroup = new FormGroup({
      pickOrderStatus: new FormControl(dataItem.pickOrderStatus)
    });

    this.editingItemId = dataItem.id;

    this.editedRowIndex = args.rowIndex;
    args.sender.editRow(args.rowIndex, this.formGroup);
  }

  public cancelHandler(args: CancelEvent): void {
    this.closeEditor(args.sender, args.rowIndex);
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew }: SaveEvent): void {
    const updatePickOrderStatusCommand: UpdatePickOrderStatusCommand = {
      id: this.editingItemId,
      pickOrderStatus: formGroup.value.pickOrderStatus
    };
    this.pickOrderService.updateStatus(this.editingItemId.toString(), updatePickOrderStatusCommand).subscribe((result: any) => {
      this.reloadData();
      this.toastService.success('Success!', 'Status updated.');
    });
    sender.closeRow(rowIndex);
  }

  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public getStatusList(pickOrderStatus: PickOrderStatus): PickOrderStatus[] {
    let statusList: PickOrderStatus[] = [];
    switch (pickOrderStatus) {
      case PickOrderStatus.Started:
        statusList = [PickOrderStatus.Canceled];
        break;

      case PickOrderStatus.InProgress:
        statusList = [PickOrderStatus.Complete, PickOrderStatus.Canceled];
        break;
      
      case PickOrderStatus.Complete:
        statusList = [PickOrderStatus.Closed];
        break;
    }

    return statusList;
  }
}
