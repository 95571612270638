<form class="entity-form"
      [formGroup]="form"
      [hidden]="!showForm"
      (ngSubmit)="onSubmit(false)">
  <div class="row">
    <div class="col-sm-12 col-md-6" [hidden]="editMode">
      <button kendoButton
              type="submit"
              [disabled]="!form.valid"
              [primary]="true"
              [icon]="'save'">
        Create {{this.GetSingleEntityName()}}
      </button>
    </div>
    <div class="col-sm-12 col-md-6" [hidden]="!editMode">
      <kendo-buttongroup>
          <button kendoButton
                  type="button"
                  [primary]="true"
                  [disabled]="!form.valid"
                  [icon]="'save'"
                  (click)="submitAndClose()">
            Save and Close
          </button>
          <button kendoButton
                  type="button"
                  [primary]="false"
                  [icon]="'cancel-circle'"
                  (click)="resetForm()">
            Cancel
          </button>
      </kendo-buttongroup>
    </div>
    <div class="col-sm-12 col-md-6" [hidden]="!editMode">
      <div class="float-end">
        <kendo-buttongroup>
          <!--
            <button kendoButton type="button" (click)="disable()" [icon]="'cancel-circle'">Disable {{this.GetSingleEntityName()}}</button>
            <button kendoButton type="button" (click)="delete()" [icon]="'delete'">Delete {{this.GetSingleEntityName()}}</button>
          -->
        </kendo-buttongroup>
        <div kendoDialogContainer></div>
      </div>
    </div>
    <div class="col-sm-12" [hidden]="true">
      <kendo-buttongroup>
        <button kendoButton
                type="button"
                [primary]="true"
                [icon]="'user'">
          View Users
        </button>
      </kendo-buttongroup>
    </div>
    <div class="col-sm-12">
      <hr/>
    </div>
  </div>

  <app-validation-errors></app-validation-errors>
  <input type="hidden" id="id" formControlName="id" />

  <kendo-tabstrip>
    <kendo-tabstrip-tab [title]="'General'" [selected]="true">
      <ng-template kendoTabContent>
          <div class="row">
              <div class=" col-sm-12">
                <label for="name" class="col-form-label col-form-label-sm">
                  Name
                </label>
                <input kendoTextBox add-required-asterisk required
                      formControlName="name"
                      id="name"
                      class="form-control"
                      placeholder="Name" />
                <validation-error-message [control]="name"></validation-error-message>
              </div>
              <div class=" col-md-12">
                <label for="description" class="col-form-label col-form-label-sm">
                  Description
                </label>
                <textarea kendoTextBox
                          formControlName="description"
                          id="description"
                          class="form-control"
                          placeholder="Description">
                </textarea>
                <validation-error-message [control]="description"></validation-error-message>
              </div>
              <div class=" col-md-12">
                <label for="notes" class="col-form-label col-form-label-sm">
                  Notes
                </label>
                <textarea kendoTextBox
                          formControlName="notes"
                          id="notes"
                          class="form-control"
                          placeholder="Notes">
                </textarea>
                <validation-error-message [control]="notes"></validation-error-message>
              </div>
              <div class="col-md-12">
                <label for="manufacturerVendorId" class="col-form-label col-form-label-sm">
                  Manufacturer Vendor
                </label>
                <kendo-dropdownlist add-required-asterisk
                  [defaultItem]="vendorDefaultItem"
                  id="manufacturerVendorId"
                  name="manufacturerVendorId"
                  formControlName="manufacturerVendorId"
                  [valuePrimitive]="true"
                  [data]="($vendors | async)?.data"
                  [filterable]="false"
                  valueField="id"
                  textField="vendorName">
                </kendo-dropdownlist>
                <validation-error-message [control]="manufacturerVendorId"></validation-error-message>
              </div>
              <div class=" col-md-12">
                <label for="manufacturerNumber" class="col-form-label col-form-label-sm">
                  Manufacturer Number
                </label>
                <input kendoTextBox
                      type="text"
                      formControlName="manufacturerNumber"
                      id="manufacturerNumber"
                      class="form-control"
                      placeholder="Manufacturer Number">
                <validation-error-message [control]="manufacturerNumber"></validation-error-message>
              </div>
              <div class=" col-md-12">
                <label for="modelNumber" class="col-form-label col-form-label-sm">
                  Model Number
                </label>
                <input kendoTextBox
                      type="text"
                      formControlName="modelNumber"
                      id="modelNumber"
                      class="form-control"
                      placeholder="Model Number">
                <validation-error-message [control]="modelNumber"></validation-error-message>
              </div>
              <div class="col-md-12">
                <div class="row">
                    <div class="col-md-3">
                        <label for="minimumStockingQuantity" class="col-form-label col-form-label-sm">
                          Minimum Stocking Quantity
                        </label>
                        <input kendoTextBox
                              type="number"
                              formControlName="minimumStockingQuantity"
                              id="minimumStockingQuantity"
                              class="form-control"
                              placeholder="Minimum Stocking Quantity">
                    </div>
                    <div class="col-md-3">
                        <label for="stockingQuantityUnitOfMeasurement" class="col-form-label col-form-label-sm">
                          Unit of Measurement
                        </label>
                        <input kendoTextBox
                              type="text"
                              formControlName="stockingQuantityUnitOfMeasurement"
                              id="stockingQuantityUnitOfMeasurement"
                              class="form-control"
                              placeholder="Unit of Measurement">
                        <validation-error-message [control]="stockingQuantityUnitOfMeasurement"></validation-error-message>
                    </div>
                </div>
              </div>
              <div class="col-md-12">
                  <label for="categories" class="col-form-label col-form-label-sm">
                    Categories
                  </label>
                  <kendo-multiselect
                      textField="categoryName"
                      valueField="id"
                      [allowCustom]="true"
                      [data]="listItems"
                      formControlName="categories"
                      [valueNormalizer]="valueNormalizer">
                  </kendo-multiselect>
              </div>
              <div class=" col-sm-12">
                  <label for="manualLink" class="col-form-label col-form-label-sm">
                    Manual Link
                  </label>
                  <input kendoTextBox
                        formControlName="manualLink"
                        id="manualLink"
                        class="form-control"
                        placeholder="Manual Link" />
                  <validation-error-message [control]="manualLink"></validation-error-message>
              </div>
              <div class=" col-sm-12">
                  <label for="trainingLink" class="col-form-label col-form-label-sm">
                    Training Link
                  </label>
                  <input kendoTextBox
                        formControlName="trainingLink"
                        id="trainingLink"
                        class="form-control"
                        placeholder="Training Link" />
                  <validation-error-message [control]="trainingLink"></validation-error-message>
              </div>
          </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab [title]="'Metadata'" [disabled]="!editMode">
        <ng-template kendoTabContent>
            <app-metadata [metadata]="dataModel"></app-metadata>
        </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</form>
