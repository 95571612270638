import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ManikinMakeAndModelService } from '@wo-api/index';
@Component({
  selector: 'app-manikin-selector',
  templateUrl: './manikin-selector.component.html',
  styleUrls: ['./manikin-selector.component.scss']
})
export class ManikinSelectorComponent implements OnInit, AfterViewInit {
  @Input() form: FormGroup;
  @Output() manikinsAssignedThisProcedureChange = new EventEmitter<any[]>();
  manikinIds: number[] = [];
  selectedItems: any[] = [];
  manikinMakesAndModels: any[] = [];
  visibleModels: any[] = [];
  flatModels: any[] = [];

  constructor(private manikinMakeAndModelService: ManikinMakeAndModelService) {}

  ngAfterViewInit() {}

  ngOnInit() {}

  shouldItemBeHidden(item): boolean {
    const index = this.selectedItems.indexOf(item.id);
    return index === -1 ? false : true;
  }

  addToList(item) {
    const index = this.selectedItems.indexOf(item.id);
    if (index === -1) {
      this.selectedItems.push(item.id);
      this.manikinsAssignedThisProcedureChange.emit(this.selectedItems);
    }
  }

  removeFromList(item) {
    const index = this.selectedItems.indexOf(item);
    if (index > -1) {
      this.selectedItems.splice(index, 1);
      this.manikinsAssignedThisProcedureChange.emit(this.selectedItems);
    }
  }

  getLabelForItem(id): string {
    let label = ''.toString();
    const foundItem = this.flatModels.filter(function (model) {
      return model.id === id;
    });
    if (foundItem) {
      label = foundItem[0].name.toString();
    }
    return label;
  }

  manikinManufacturerChange(event) {
    const foundManufacturer = this.manikinMakesAndModels.filter(function (manufacturer) {
      return manufacturer.manufacturerName === event.manufacturerName;
    });

    if (foundManufacturer.length === 0) {
      return;
    }
    if (foundManufacturer[0].models.length > 0) {
      this.visibleModels = foundManufacturer[0].models;
    }
  }
}
