<autopilot-grid>
  <kendo-grid
    [data]="emailTemplates"
    [style.maxHeight.%]="100"
    [pageSize]="state.take"
    [skip]="state.skip"
    [sort]="state.sort"
    [filterable]="false"
    [sortable]="true"
    [pageable]="true">
    <ng-template kendoGridToolbarTemplate>
      <div class="float-start">
        <button kendoButton type="button" class="btn btn-primary" (click)="addNewWithOrganizationId(organizationId)" [icon]="'plus'">Add
          {{this.GetSingleEntityName()}}</button>
      </div>
      <div class="float-end">
        <button kendoGridPDFCommand class="btn btn-secondary text-white me-1" title="Export as PDF"><i class="fa-light fa-file-pdf"></i></button>
        <button kendoGridExcelCommand title="Export to Excel" class="btn btn-secondary text-white"><i class="fa-light fa-file-excel"></i></button>
      </div>
    </ng-template>
    <kendo-grid-column field="name" title="Template Name">
      <ng-template kendoGridCellTemplate let-item>
        {{item.name}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="CreatedTimestamp" title="Date Created">
      <ng-template kendoGridCellTemplate let-item>
        {{ item.CreatedTimestamp | amCalendar }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Actions" class="k-col-actions">
      <ng-template kendoGridCellTemplate let-item>
        <button kendoButton [primary]="true" [icon]="'pencil'"
          (click)="routeToEditWithOrganization(item.name)">Edit</button>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-pdf [fileName]="pdfFileName" paperSize="A4" [scale]="0.8" [repeatHeaders]="true" [allPages]="true"
      [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
    </kendo-grid-pdf>
    <kendo-grid-excel [fileName]="excelFileName"></kendo-grid-excel>
    <kendo-grid-messages
      [noRecords]="'There are no ' + GetPluralEntityName() + ' available to show here. Please create one above.'">
    </kendo-grid-messages>
  </kendo-grid>
</autopilot-grid>

<router-outlet></router-outlet>
