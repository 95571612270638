import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DomService } from './dom.service';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private answer = new Subject<boolean>();
  private modalElementId = 'modal-container';
  private overlayElementId = 'overlay';

  constructor(private domService: DomService) {}

  init(component: any, inputs: object, outputs: object) {
    const componentConfig = {
      inputs,
      outputs
    };

    this.domService.appendComponentTo(this.modalElementId, component, componentConfig);
    document.getElementById(this.modalElementId).className = 'show';
    document.getElementById(this.overlayElementId).className = 'show';
  }

  destroy(answer?: any) {
    if (answer) {
      this.SetAnswer(answer);
    }
    this.domService.removeComponent();
  }

  private SetAnswer(value: any): void {
    if (value) {
      return this.answer.next(value);
    }
  }

  public GetAnswer(): Observable<any> {
    return this.answer.asObservable();
  }
}
