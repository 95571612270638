import { LocationService } from '@wo-api/index';
import { EntityGlobals, MenuGroupType } from '@wo-app/app.global';
import { LocationsDetailComponent } from '@wo-app/components/dashboard/locations/locations-detail/locations-detail.component';
import { LocationsComponent } from '@wo-app/components/dashboard/locations/locations/locations.component';
import { ImpersonatingOrganizationCanActivate } from '@wo-app/core/guards';
import { MenuGroupListingItem } from '@wo-app/shared/models/menu';
import { RouteChild, RouteParent } from '@wo-app/shared/models/route';

export class LocationsRegistration {
  static Routes: RouteParent = {
    path: EntityGlobals.LOCATIONS.baseRoute,
    canActivate: [ImpersonatingOrganizationCanActivate],
    entityInformation: EntityGlobals.LOCATIONS,
    children: [
      {
        path: '',
        component: LocationsComponent,
        data: {
          breadcrumb: 'View All ' + EntityGlobals.LOCATIONS.pluralName,
          menuGroups: [new MenuGroupListingItem(0, 0, MenuGroupType.PhysicalSpaces, EntityGlobals.LOCATIONS.pluralName)],
          entityInformation: EntityGlobals.LOCATIONS
        }
      } as RouteChild,
      {
        path: 'edit/:id',
        component: LocationsDetailComponent,
        data: {
          breadcrumb: 'Edit ' + EntityGlobals.LOCATIONS.singleName,
          entityInformation: EntityGlobals.LOCATIONS
        }
      } as RouteChild,
      {
        path: 'add',
        component: LocationsDetailComponent,
        data: {
          breadcrumb: 'Add ' + EntityGlobals.LOCATIONS.singleName,
          entityInformation: EntityGlobals.LOCATIONS
        }
      } as RouteChild
    ]
  };

  static Services: any = [LocationService];

  static Components: any = [LocationsComponent, LocationsDetailComponent];
}
