import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ValidationErrorsService {
  private subject = new Subject<any>();
  private validationErrors: any[];
  constructor(private logger: NGXLogger) {}

  ClearValidationErrors() {
    this.subject.next([]);
  }

  AddValidationErrors(validationErrors) {
    //this.validationErrors = validationErrors;
    this.subject.next(validationErrors);
    validationErrors.forEach((msg) => {
      this.logger.debug('Request Validation Error: ' + msg);
    });
  }

  GetValidationErrors(): Observable<any> {
    return this.subject.asObservable();
  }
}
