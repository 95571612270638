import { EntityGlobals, MenuGroupType } from '@wo-app/app.global';
import { ImportDataDetailComponent } from '@wo-app/components/dashboard/import-job/import-job-detail/import-job-detail.component';
import { ImportJobsComponent } from '@wo-app/components/dashboard/import-job/import-jobs.component';
import { LineItemErrorResolverComponent } from '@wo-app/components/dashboard/import-job/line-item-error-resolver/line-item-error-resolver.component';
import { ViewImportJobComponent } from '@wo-app/components/dashboard/import-job/view-import-job/view-import-job.component';
import { MenuGroupListingItem } from '@wo-app/shared/models/menu';
import { RouteChild, RouteParent } from '@wo-app/shared/models/route';

export class ImportJobsRegistration {
  static Routes: RouteParent = {
    path: EntityGlobals.IMPORT_JOBS.baseRoute,
    entityInformation: EntityGlobals.IMPORT_JOBS,
    children: [
      {
        path: '',
        component: ImportJobsComponent,
        data: {
          breadcrumb: 'View All ' + EntityGlobals.IMPORT_JOBS.pluralName,
          menuGroups: [new MenuGroupListingItem(0, 0, MenuGroupType.Settings, EntityGlobals.IMPORT_JOBS.pluralName)],
          entityInformation: EntityGlobals.IMPORT_JOBS
        }
      } as RouteChild,
      {
        path: 'view/:id',
        component: ViewImportJobComponent,
        data: {
          breadcrumb: 'View ' + EntityGlobals.IMPORT_JOBS.singleName,
          entityInformation: EntityGlobals.IMPORT_JOBS
        }
      } as RouteChild,
      {
        path: 'add/:importJobType',
        component: ImportDataDetailComponent,
        data: {
          breadcrumb: 'Add ' + EntityGlobals.IMPORT_JOBS.singleName,
          entityInformation: EntityGlobals.IMPORT_JOBS
        }
      } as RouteChild,
      {
        path: 'add',
        component: ImportDataDetailComponent,
        data: {
          breadcrumb: 'Add ' + EntityGlobals.IMPORT_JOBS.singleName,
          entityInformation: EntityGlobals.IMPORT_JOBS
        }
      } as RouteChild
    ],
    canActivate: []
  };

  static Services: any = [];

  static Components: any = [ImportJobsComponent, ImportDataDetailComponent, ViewImportJobComponent, LineItemErrorResolverComponent];
}
