import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MarkAsteriskDirective } from '@wo-app/shared/directives';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { NGXLogger } from 'ngx-logger';
declare var require: any;
let Provinces: any[] = require('provinces');

@Component({
  selector: 'app-country-and-province-selector',
  templateUrl: './country-and-province-selector.component.html',
  styleUrls: ['./country-and-province-selector.component.scss']
})
export class CountryAndProvinceSelectorComponent implements OnInit {
  @Input() public CountryModel: string;
  @Output() CountryModelChange = new EventEmitter<string>();
  @Input() public StateOrProvinceModel: string;
  @Output() StateOrProvinceModelChange = new EventEmitter<string>();
  @Input() showRequiredAsteriskOnLabel: boolean = false;

  public requiredAsteriskHtml: string = MarkAsteriskDirective.requiredAsteriskHtml;

  public countryData: Country[];
  public stateOrProvinceData: any[] = [];
  constructor(private logger: NGXLogger) {}

  get hideStateOrProvinceSelector(): boolean {
    return this.CountryModel.length === 0;
  }

  ngOnInit() {
    if (this.showRequiredAsteriskOnLabel) {
    }

    i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
    this.CountryModel = 'US';
    this.StateOrProvinceModel = 'TX';
    let countries = i18nIsoCountries.getNames('en');
    let result = Object.keys(countries).map(function (key) {
      return new Country(key, countries[key]);
    });
    this.countryData = result;
    this.setStateOrProvinceData(this.CountryModel);
  }

  public countrySelectionChange($event) {
    this.setStateOrProvinceData($event);
  }

  public setStateOrProvinceData(countryCode: string): void {
    let provinces = Provinces.filter(item => item['country'] === countryCode);
    let mappedProvinces = provinces.map((value, index, array) => {
      return new StateOrProvince(value.short, value.name);
    });
    this.stateOrProvinceData = mappedProvinces;
  }
}

export class StateOrProvince {
  public Code: string;
  public Name: string;
  constructor(code: string, name: string) {
    this.Code = code;
    this.Name = name;
  }
}

export class Country {
  public Code: string;
  public Name: string;
  constructor(code: string, name: string) {
    this.Code = code;
    this.Name = name;
  }
}
