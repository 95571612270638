<div class="row" *ngIf="ReportData">
    <div [class]="SmallGridItemClass">
        <div class="card w-100 mh-100">
            <div class="card-body">
                <h5 class="card-title text-center mb-4">Count of Manikins & Trainers by Status</h5>
                <kendo-chart>
                    <kendo-chart-legend position="bottom"></kendo-chart-legend>
                    <kendo-chart-series>
                        <kendo-chart-series-item type="pie"
                            [data]="ReportData?.countsOfManikinLineItemsByStatus" field="count"
                            categoryField="equipmentStatus" [labels]="{ visible: true }" [autoFit]="true">
                            <kendo-chart-series-item-labels position="outsideEnd" color="#000" [content]="getLabelForManikinTrainersByStatusReport">
                            </kendo-chart-series-item-labels>
                        </kendo-chart-series-item>
                    </kendo-chart-series>
                </kendo-chart>
            </div>
        </div>
    </div>
    <div [class]="SmallGridItemClass">
        <div class="card w-100 mh-100">
            <div class="card-body">
                <h5 class="card-title text-center mb-4">Warranties Expiring within <span [innerHTML]="ReportData?.warrantyExpirationLimitInDays"></span> days</h5>
                <kendo-tabstrip class="align-items-stretch d-flex">
                    <kendo-tabstrip-tab title="Total" [selected]="true">
                        <ng-template kendoTabContent>
                            <div class="text-center p-5 large-number fw-bold text-orange" [innerHTML]="itemsWithSoonExpiringWarrantiesTotal"></div>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="Equipment">
                        <ng-template kendoTabContent>
                            <kendo-grid [data]="equipmentView" [scrollable]="'scrollable'" [pageable]="pagerSettings" [pageSize]="equipmentsState.take" (dataStateChange)="equipmentDataStateChange($event)">
                                <kendo-grid-column field="equipmentName" title="Name">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <a class="link" [routerLink]="'/equipment/' + dataItem.equipmentId + '/line-items/edit/' + dataItem.id">
                                            {{ dataItem.equipmentName }} <i class="fa-light fa-arrow-up-right-from-square"></i>
                                        </a>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="serialNumber" title="Serial #"></kendo-grid-column>
                                <kendo-grid-column field="warrantyExpirationDate" title="Expiration Date">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        {{ dataItem.warrantyExpirationDate | amCalendar }}
                                    </ng-template>
                                </kendo-grid-column>
                            </kendo-grid>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="Manikins">
                        <ng-template kendoTabContent>
                            <kendo-grid [data]="manikinsAndTrainersView" [scrollable]="'scrollable'" [pageable]="pagerSettings" [pageSize]="manikinsAndTrainersState.take" (dataStateChange)="manikinsAndTrainersDataStateChange($event)">
                                <kendo-grid-column field="modelName" title="Model Name">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <a class="link" [routerLink]="'/manikin-make-and-model/' + dataItem.manikinMakeAndModelId + '/line-items/edit/' + dataItem.id">
                                            {{ dataItem.modelName }} <i class="fa-light fa-arrow-up-right-from-square"></i>
                                        </a>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="serialNumber" title="Serial #"></kendo-grid-column>
                                <kendo-grid-column field="warrantyExpirationDate" title="Expiration Date">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        {{ dataItem.warrantyExpirationDate | amCalendar }}
                                    </ng-template>
                                </kendo-grid-column>
                            </kendo-grid>
                        </ng-template>
                    </kendo-tabstrip-tab>
                </kendo-tabstrip>
            </div>
        </div>
    </div>
    <div [class]="SmallGridItemClass">
        <div class="card w-100 mh-100">
            <div class="card-body">
                <h5 class="card-title text-center mb-4">Open Pick Orders</h5>
                <!-- TODO - Display the chart. API has been updated and is sending the correct data, but Kendo error is being thrown that prevents the chart from being displayed. -->
                <!-- <kendo-chart-series>
                    <kendo-chart-series-item
                        type="pie"
                        [autoFit]="true"
                        [data]="ReportData?.openPickOrders.statusTotals"
                        field="count"
                        categoryField="pickOrderStatus"
                        [labels]="{ visible: true }" >
                        <kendo-chart-series-item-labels position="outsideEnd" color="#000" [content]="getLabelForPickOrderChart">
                        </kendo-chart-series-item-labels>
                    </kendo-chart-series-item>
                </kendo-chart-series> -->
                <kendo-tabstrip>
                    <kendo-tabstrip-tab title="Total" [selected]="true">
                        <ng-template kendoTabContent>
                            <div class="text-center p-5 large-number fw-bold text-orange" [innerHTML]="pickOrdersGridData?.length"></div>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="Data">
                        <ng-template kendoTabContent>
                            <kendo-grid [data]="pickOrdersView" [scrollable]="'scrollable'" [pageable]="pagerSettings" [pageSize]="pickOrdersState.take" (dataStateChange)="pickOrdersDataStateChange($event)">
                                <kendo-grid-column field="dateNeeded" title="Date Needed">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <a class="link" [routerLink]="'/pick-orders/edit/' + dataItem.id">
                                            {{ dataItem.dateNeeded | amCalendar  }} <i class="fa-light fa-arrow-up-right-from-square"></i>
                                        </a>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="pickOrderStatus" title="Status"></kendo-grid-column>
                            </kendo-grid>
                        </ng-template>
                    </kendo-tabstrip-tab>
                </kendo-tabstrip>
            </div>
        </div>
    </div>

    <div [class]="SmallGridItemClass">
        <div class="card w-100 mh-100">
            <div class="card-body">
                <h5 class="card-title text-center mb-4">Preventive Maintenance Due within 90 days</h5>
                <kendo-tabstrip>
                    <kendo-tabstrip-tab title="Total" [selected]="true">
                        <ng-template kendoTabContent>
                            <div class="text-center p-5 large-number fw-bold text-orange" [innerHTML]="itemsWithSoonPreventiveMaintenance.count"></div>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="Equipment">
                        <ng-template kendoTabContent>
                            <kendo-grid
                                [data]="equipmentPreventiveMaintenanceView"
                                [scrollable]="'scrollable'"
                                [pageable]="pagerSettings"
                                [pageSize]="equipmentPreventiveMaintenanceState.take"
                                (dataStateChange)="equipmentPreventiveMaintenanceDataStateChange($event)">
                                <kendo-grid-column field="equipmentName" title="Name">
                                  <ng-template kendoGridCellTemplate let-dataItem>
                                    <a class="link" [routerLink]="'/equipment/' + dataItem.equipmentId + '/line-items/edit/' + dataItem.id">
                                        {{ dataItem.equipmentName }} <i class="fa-light fa-arrow-up-right-from-square"></i>
                                    </a>
                                  </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="serialNumber" title="Serial #"></kendo-grid-column>
                                <kendo-grid-column field="preventativeMaintenanceExpirationDate" title="Expiration Date">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                      {{ dataItem.preventativeMaintenanceExpirationDate | amCalendar  }}
                                    </ng-template>
                                </kendo-grid-column>
                            </kendo-grid>
                        </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="Manikins">
                        <ng-template kendoTabContent>
                            <kendo-grid 
                                [data]="manikinsPreventiveMaintenanceView"
                                [scrollable]="'scrollable'"
                                [pageable]="pagerSettings"
                                [pageSize]="manikinsPreventiveMaintenanceState.take"
                                (dataStateChange)="manikinsPreventiveMaintenanceDataStateChange($event)">
                                <kendo-grid-column field="modelName" title="Model Name">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <a class="link" [routerLink]="'/manikin-make-and-model/' + dataItem.manikinMakeAndModelId + '/line-items/edit/' + dataItem.id">
                                            {{ dataItem.modelName }} <i class="fa-light fa-arrow-up-right-from-square"></i>
                                        </a>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="serialNumber" title="Serial #"></kendo-grid-column>
                                <kendo-grid-column field="warrantyExpirationDate" title="Expiration Date">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        {{ dataItem.warrantyExpirationDate | amCalendar }}
                                    </ng-template>
                                </kendo-grid-column>
                            </kendo-grid>
                        </ng-template>
                    </kendo-tabstrip-tab>

                </kendo-tabstrip>
            </div>
        </div>
    </div>

    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title text-center">Low Stocking Quantity Alerts</h5>
                <autopilot-grid>
                  <kendo-grid
                    [data]="SupplyStockingQuantityAlertsView"
                    [style.maxHeight.%]="100"
                    [sort]="stockingQuantityGridState.sort"
                    [sortable]="true"
                    [pageSize]="stockingQuantityGridState.take"
                    [pageable]="true"
                    (pageChange)="stockingQuantityAlertGridPageChange($event)"
                    (dataStateChange)="StockingQuantityAlertItemsDataStateChange($event)">
                      <kendo-grid-column field="supplyName" title="Name">
                          <ng-template kendoGridCellTemplate let-dataItem>
                              <a class="link" [routerLink]="'/supplies/room-inventory-item-stock/' + dataItem.supplyId">{{ dataItem.supplyName }} <i class="fa-light fa-arrow-up-right-from-square"></i></a>
                          </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="customId" title="Custom Id"></kendo-grid-column>
                      <kendo-grid-column-group title="Location">
                          <kendo-grid-column field="locationId" title="Location">
                              <ng-template kendoGridCellTemplate let-dataItem>
                                  {{ ArrayUtilities.SearchArrayForValue(locations?.data, "id", dataItem.locationId)?.name }}
                              </ng-template>
                          </kendo-grid-column>
                          <kendo-grid-column field="buildingId" title="Building">
                              <ng-template kendoGridCellTemplate let-dataItem>
                                  {{ ArrayUtilities.SearchArrayForValue(buildings?.data, "id", dataItem.buildingId)?.name }}
                              </ng-template>
                          </kendo-grid-column>
                          <kendo-grid-column field="roomId" title="Room">
                              <ng-template kendoGridCellTemplate let-dataItem>
                                  {{ ArrayUtilities.SearchArrayForValue(rooms?.data, "id", dataItem.roomId)?.name }}
                              </ng-template>
                          </kendo-grid-column>
                      </kendo-grid-column-group>
                      <kendo-grid-column field="quantity" title="Actual Quantity"></kendo-grid-column>
                      <kendo-grid-column field="differenceInStockingQuantity" title="Difference"></kendo-grid-column>
                      <kendo-grid-column-group title="Stocking Levels">
                          <kendo-grid-column field="stockingQuantity" title="Quantity"></kendo-grid-column>
                          <kendo-grid-column field="stockingQuantityUnitOfMeasurement" title="Unit Of Measurement"></kendo-grid-column>
                      </kendo-grid-column-group>
                  </kendo-grid>
                </autopilot-grid>
            </div>
        </div>
    </div>
</div>
