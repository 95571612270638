<form [formGroup]="form" (ngSubmit)="onSubmit(false)" [hidden]="!showForm" class="entity-form">
    <div class="row">
        <div class="col-sm-12 col-md-6" [hidden]="editMode">
            <button kendoButton type="submit" [disabled]="!form.valid || submitInProgress" [primary]="true" [icon]="'save'">Create {{this.GetSingleEntityName()}}</button>
        </div>
        <div class="col-sm-12 col-md-6" [hidden]="!editMode">
            <kendo-buttongroup>
                <button kendoButton type="button" (click)="submitAndClose()" [primary]="true" [disabled]="!form.valid" [icon]="'save'">Save and Close</button>
                <button kendoButton type="button" (click)="resetForm()" [primary]="false" [icon]="'cancel-circle'">Cancel</button>
            </kendo-buttongroup>
        </div>
        <div class="col-sm-12 col-md-6" [hidden]="!editMode">
            <div class="float-end">
                <div kendoDialogContainer></div>
            </div>
        </div>
        <div class="col-sm-12">
            <hr/>
        </div>
    </div>
    <app-validation-errors></app-validation-errors>
    <input type="hidden" id="id" formControlName="id" />
    <kendo-tabstrip>
        <kendo-tabstrip-tab [title]="'General'" [selected]="true">
            <ng-template kendoTabContent>
                <div class="row">
                    <div class=" col-md-12">
                        <label for="importJobType" class="col-form-label col-form-label-sm">Import Job Type</label>
                        <kendo-dropdownlist [(ngModel)]="selectImportJobType" class="form-control" [data]="(importJobTypes | async)?.data" textField="name" add-required-asterisk valueField="value" [valuePrimitive]="true" formControlName="importJobType"></kendo-dropdownlist>
                    </div>
                </div>
                <div class="row" [hidden]="selectImportJobType == null">
                    <div class="col-md-12">
                        <label class="col-form-label col-form-label-sm">
                            If needed, you can download a sample file to use as the basis for this import. The headers much match this file exactly.
                        </label>
                        <button class="form-control btn btn-primary" (click)="downloadSampleCsvFile()" type="button">
                            Download Sample File <i class="fa-light fa-download"></i>
                        </button>
                    </div>
                </div>
                <div class="row" [hidden]="selectImportJobType == null">
                    <div class=" col-md-12">
                        <label for="fileUpload" class="col-form-label col-form-label-sm">Import Job Type</label>
                        <kendo-fileselect class="form-control" formControlName="file" [multiple]="false" [restrictions]="myRestrictions"></kendo-fileselect>
                    </div>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Metadata'" [disabled]="!editMode">
            <ng-template kendoTabContent>
                <app-metadata [metadata]="dataModel"></app-metadata>
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>
</form>