import { ChecklistDrawerDrawerService, ChecklistService } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { ChecklistDetailComponent } from '@wo-app/components/dashboard/checklists/checklist-detail/checklist-detail.component';
import { ChecklistDrawerListComponent } from '@wo-app/components/dashboard/checklists/checklist-drawer-list/checklist-drawer-list.component';
import { ChecklistsComponent } from '@wo-app/components/dashboard/checklists/checklists/checklists.component';
import { ImpersonatingOrganizationCanActivate } from '@wo-app/core/guards';
import { RouteChild, RouteParent } from '@wo-app/shared/models/route';

export class ChecklistsRegistration {
  static Routes: RouteParent = {
    path: EntityGlobals.CHECKLISTS.baseRoute,
    canActivate: [ImpersonatingOrganizationCanActivate],
    entityInformation: EntityGlobals.CHECKLISTS,
    children: [
      {
        path: '',
        component: ChecklistsComponent,
        data: {
          breadcrumb: 'View All'
        }
      } as RouteChild,
      {
        path: 'edit/:id',
        component: ChecklistDetailComponent,
        data: {
          breadcrumb: 'Edit ' + EntityGlobals.CHECKLISTS.singleName
        }
      } as RouteChild,
      {
        path: 'add',
        component: ChecklistDetailComponent,
        data: {
          breadcrumb: 'Add ' + EntityGlobals.CHECKLISTS.singleName
        }
      } as RouteChild
    ]
  };

  static Services: any = [ChecklistService, ChecklistDrawerDrawerService];

  static Components: any = [ChecklistsComponent, ChecklistDetailComponent, ChecklistDrawerListComponent];
}
