import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { GlobalRole } from '@wo-api/index';
import { tap } from 'rxjs/operators';

@Injectable()
export class OrganizationModuleCanActivate implements CanActivate {
  public GlobalRoles: GlobalRole[] = [];
  constructor(private authService: NbAuthService, private router: Router) {}

  canActivate() {
    return this.authService.isAuthenticatedOrRefresh().pipe(
      tap(authenticated => {
        if (!authenticated) {
          //this.router.navigate(['auth/login']);
        }
      })
    );
  }
}
