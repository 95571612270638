import { SimMaintenanceTaskService } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { SimMaintenanceTaskDetailComponent } from '@wo-app/components/dashboard/simmaintenancetasks/simmaintenancetask-detail/simmaintenancetask-detail.component';
import { SimMaintenanceTasksComponent } from '@wo-app/components/dashboard/simmaintenancetasks/simmaintenancetasks/simmaintenancetasks.component';
import { ImpersonatingOrganizationCanActivate } from '@wo-app/core/guards';
import { RouteChild, RouteParent } from '@wo-app/shared/models/route';

export class SimMaintenanceTasksRegistration {
  static Routes: RouteParent = {
    path: EntityGlobals.SIM_MAINTENANCE_TASKS.baseRoute,
    canActivate: [ImpersonatingOrganizationCanActivate],
    entityInformation: EntityGlobals.SIM_MAINTENANCE_TASKS,
    children: [
      {
        path: '',
        component: SimMaintenanceTasksComponent,
        data: {
          breadcrumb: 'View All'
        }
      } as RouteChild,
      {
        path: 'edit/:id',
        component: SimMaintenanceTaskDetailComponent,
        data: {
          breadcrumb: 'Edit ' + EntityGlobals.SIM_MAINTENANCE_TASKS.singleName
        }
      } as RouteChild,
      {
        path: 'add',
        component: SimMaintenanceTaskDetailComponent,
        data: {
          breadcrumb: 'Add ' + EntityGlobals.SIM_MAINTENANCE_TASKS.singleName
        }
      } as RouteChild
    ]
  };

  static Services: any = [SimMaintenanceTaskService];

  static Components: any = [SimMaintenanceTasksComponent, SimMaintenanceTaskDetailComponent];
}
