<div class="card task-group">
    <h5 class="card-header"><i class="fa-light fa-tasks"></i> {{title}}</h5>
    <ul class="list-group list-group-flush">
        <li class="list-group-item" [hidden]="tasks.length > 0">
            <span>No Tasks to Show</span>
        </li>
        <li class="list-group-item" *ngFor="let item of tasks">
            <app-task-check-box [isChecked]="item.taskStatus == 'Completed'" [taskId]="item.id" [organizationId]="organizationId" [dueDate]="item.dateTimeDue" [taskName]="item.name" [labRequestName]="item.labRequestName"></app-task-check-box>
        </li>
    </ul>
</div>