import { ManikinMakeAndModelService } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { ManikinMakeAndModelProcedureDetailComponent } from '@wo-app/components/dashboard/manikin-make-and-model-procedures/detail/detail.component';
import { ManikinMakeAndModelListProcedureComponent } from '@wo-app/components/dashboard/manikin-make-and-model-procedures/list/list.component';
import { ManikinSelectorComponent } from '@wo-app/components/dashboard/manikin-make-and-model-procedures/manikin-selector/manikin-selector.component';
import { NotImpersonatingOrganizationCanActivate } from '@wo-app/core/guards';
import { RouteChild, RouteParent } from '@wo-app/shared/models/route';

export class ManikinMakeAndModelProcedureRegistration {
  static Routes: RouteParent = {
    path: EntityGlobals.MANIKIN_MAKES_AND_MODEL_PROCEDURES.baseRoute,
    canActivate: [NotImpersonatingOrganizationCanActivate],
    entityInformation: EntityGlobals.MANIKIN_MAKES_AND_MODEL_PROCEDURES,
    children: [
      {
        path: '',
        component: ManikinMakeAndModelListProcedureComponent,
        data: {
          breadcrumb: 'View All'
        }
      } as RouteChild,
      {
        path: 'edit/:id',
        component: ManikinMakeAndModelProcedureDetailComponent,
        data: {
          breadcrumb: 'Edit ' + EntityGlobals.MANIKIN_MAKES_AND_MODEL_PROCEDURES.singleName
        }
      } as RouteChild,
      {
        path: 'add',
        component: ManikinMakeAndModelProcedureDetailComponent,
        data: {
          breadcrumb: 'Add ' + EntityGlobals.MANIKIN_MAKES_AND_MODEL_PROCEDURES.singleName
        }
      } as RouteChild
    ]
  };

  static Services: any = [ManikinMakeAndModelService];

  static Components: any = [
    ManikinSelectorComponent,
    ManikinMakeAndModelListProcedureComponent,
    ManikinMakeAndModelProcedureDetailComponent
  ];
}
