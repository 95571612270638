<div [hidden]="(validationErrorCount() < 1)">
    <div class="card text-dark bg-warning mb-3">
        <div class="card-body">
            <h4 class="card-title">Oops...</h4>
            <p class="card-text">There were errors saving your information.</p>
            <ul>
                <li *ngFor="let error of validationErrors; let i = index">
                    {{error}}
                </li>
            </ul>
        </div>
    </div>
</div>