<div *ngIf="historyList | async as item">
    <div>
        <div class="row">
            <div class="col-sm-12">
                <label class="col-form-label col-form-label-sm">Location Name</label>
                <span class="form-control">{{item.locationName}}</span>
            </div>
            <div class="col-sm-12">
                <label class="col-form-label col-form-label-sm">Building Name</label>
                <span class="form-control">{{item.buildingName}}</span>
            </div>
            <div class="col-sm-12">
                <label class="col-form-label col-form-label-sm">Room Name</label>
                <span class="form-control">{{item.roomName}}</span>
            </div>
            <div class="col-sm-12">
                <label class="col-form-label col-form-label-sm">Supply Name</label>
                <span class="form-control">{{item.supplyName}}</span>
            </div>
        </div>
    </div>
    <div class="mt-3">
      <autopilot-grid>
        <kendo-grid
          [data]="item.data"
          [style.maxHeight.%]="100"
          [sort]="state.sort"
          [sortable]="true"
          [pageable]="false">
            <!--<ng-template kendoGridToolbarTemplate>
                <div class="container-fluid g-0 d-flex flex-wrap justify-content-center">
                    <form class="col-auto me-auto" role="search">
                    </form>
                    <div class="text-end">
                        <button kendoGridPDFCommand class="btn btn-secondary text-white me-1" title="Export as PDF"><i class="fa-light fa-file-pdf"></i></button>
                        <button kendoGridExcelCommand title="Export to Excel" class="btn btn-secondary text-white"><i class="fa-light fa-file-excel"></i></button>
                    </div>
                </div>
            </ng-template>-->
            <kendo-grid-column field="quantityBefore" title="Quantity Before"></kendo-grid-column>
            <kendo-grid-column field="quantityAfter" title="Quantity After"></kendo-grid-column>
            <kendo-grid-column field="quantityChange" title="Quantity Change"></kendo-grid-column>
            <kendo-grid-column field="notes" title="Notes"></kendo-grid-column>
            <kendo-grid-column field="createByUsername" title="Created By"></kendo-grid-column>
            <kendo-grid-column field="created" title="Date Created">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{ dataItem.created | amCalendar }}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-excel [fileName]="excelFileName"></kendo-grid-excel>
        </kendo-grid>
      </autopilot-grid>

        <div kendoDialogContainer></div>
    </div>
</div>
