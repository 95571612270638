import { UserService } from '@wo-api/index';
import { EntityGlobals, MenuGroupType } from '@wo-app/app.global';
import { UserDetailComponent } from '@wo-app/components/dashboard/users/user-detail/user-detail.component';
import { UsersInOrganizationComponent } from '@wo-app/components/dashboard/users/users-in-organization/users-in-organization.component';
import { UsersComponent } from '@wo-app/components/dashboard/users/users.component';
import { MenuGroupListingItem } from '@wo-app/shared/models/menu';
import { RouteChild, RouteParent } from '@wo-app/shared/models/route';

export class UsersRegistration {
  static Routes: RouteParent = {
    path: EntityGlobals.USERS.baseRoute,
    entityInformation: EntityGlobals.USERS,
    canActivate: [],
    children: [
      {
        path: '',
        component: UsersComponent,
        data: {
          breadcrumb: 'View All ' + EntityGlobals.USERS.pluralName,
          menuGroups: [new MenuGroupListingItem(0, 0, MenuGroupType.People, EntityGlobals.USERS.pluralName)],
          entityInformation: EntityGlobals.USERS
        }
      } as RouteChild,
      {
        path: 'add',
        component: UserDetailComponent,
        data: {
          breadcrumb: 'Add ' + EntityGlobals.USERS.singleName,
          entityInformation: EntityGlobals.USERS
        }
      } as RouteChild,
      {
        path: 'edit/:id',
        component: UserDetailComponent,
        data: {
          breadcrumb: 'Edit ' + EntityGlobals.USERS.singleName,
          entityInformation: EntityGlobals.USERS
        }
      } as RouteChild
    ]
  };

  static UsersInOrganizationRoutes: any = {
    path: EntityGlobals.USERS.baseRoute,
    data: {
      breadcrumb: EntityGlobals.USERS.pluralName,
      icon: EntityGlobals.USERS.icon
    },
    children: [
      {
        path: '',
        component: UsersComponent,
        data: {
          breadcrumb: 'View All',
          entityInformation: EntityGlobals.USERS
        }
      },
      {
        path: 'edit/:id',
        component: UserDetailComponent,
        data: {
          breadcrumb: 'Edit ' + EntityGlobals.USERS.singleName,
          entityInformation: EntityGlobals.USERS
        }
      },
      {
        path: 'add',
        component: UserDetailComponent,
        data: {
          breadcrumb: 'Add ' + EntityGlobals.USERS.singleName,
          entityInformation: EntityGlobals.USERS
        }
      }
    ]
  };

  static Services: any = [UserService];

  static Components: any = [UserDetailComponent, UsersComponent, UsersInOrganizationComponent];
}
