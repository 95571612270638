import { Component, OnInit } from '@angular/core';
import { InventoryRoomQrService } from '../shared';

@Component({
  selector: 'inventory-room-qr-not-found',
  templateUrl: './inventory-room-qr-not-found.component.html',
  styleUrls: ['./inventory-room-qr-not-found.component.scss']
})
export class InventoryRoomQrNotFoundComponent implements OnInit {
  public errorMessage: string;

  constructor(private qrService: InventoryRoomQrService) {}

  ngOnInit() {
    this.qrService.validationMessage$.subscribe(errorMessage => {
      this.errorMessage = errorMessage || 'Not Found';
    });
  }
}
