<div class="container-fluid">
  <div class="row">
    <div class="col-sm-6 col-md-9">
      <kendo-dropdownlist class="form-control" [defaultItem]="defaultItem" [(ngModel)]="selectedRoom"
        [valuePrimitive]="false" [data]="rooms" textField="name" valueField="id"></kendo-dropdownlist>
    </div>
    <div class="col-sm-6 col-md-3">
      <button kendoButton type="button" class="btn-primary" (click)="addNewLabRequestRoom()">Add Room and Select
        Beds</button>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class=" col-sm-12" *ngFor="let row of labRequestRooms; let roomIndex = index"
      [attr.data-index]="roomIndex">
      <div class="row">
        <div class="col-sm-5">
          {{row.roomName}}
        </div>
        <div class="col-sm-5">
          <ul class="bed-switch-list">
            <li *ngFor="let bed of bedsForRooms[roomIndex]; let i = index" [attr.data-index]="i">
              <label class="switch-label">{{bed.bedCustomId}}</label>
              <kendo-switch (afterValueChanged)="onAfterValueChange(bed, $event)" [valueChangeDelay]="1000"
                [(ngModel)]="bed.scheduled"></kendo-switch>
            </li>
          </ul>
          <div *ngIf="bedsForRooms[roomIndex]?.length === 0">
            There are no beds assigned to this room.
          </div>
        </div>
        <div class="col-sm-2">
          <button kendoButton type="button" class="btn-primary" (click)="removeLabRequestRoomRow(row)">Remove
            Item</button>
        </div>
      </div>
    </div>
  </div>
</div>
