<form [formGroup]="form" (ngSubmit)="onSubmit(false)" [hidden]="!showForm" class="entity-form">
    <div class="row">
        <div class="col-sm-12 col-md-6" [hidden]="editMode">
            <button kendoButton type="submit" [disabled]="!form.valid" [primary]="true" [icon]="'save'">Create {{this.GetSingleEntityName()}}</button>
        </div>
        <div class="col-sm-12 col-md-6" [hidden]="!editMode">
            <kendo-buttongroup>
                <!--<button kendoButton type="submit" [disabled]="!form.valid" [primary]="true" [icon]="'save'">Save and Continue</button>-->
                <button kendoButton type="button" (click)="submitAndClose()" [primary]="true" [disabled]="!form.valid" [icon]="'save'">Save and Close</button>
                <button kendoButton type="button" (click)="resetForm()" [primary]="false" [icon]="'cancel-circle'">Cancel</button>
            </kendo-buttongroup>
        </div>
        <div class="col-sm-12 col-md-6" [hidden]="!editMode">
            <div class="float-end">
                <kendo-buttongroup>
                    <!--<button kendoButton type="button" (click)="disable()" [icon]="'cancel-circle'">Disable {{this.GetSingleEntityName()}}</button>
              <button kendoButton type="button" (click)="delete()" [icon]="'delete'">Delete {{this.GetSingleEntityName()}}</button>-->
                </kendo-buttongroup>
                <div kendoDialogContainer></div>
            </div>
        </div>
        <div class="col-sm-12" [hidden]="true">
            <kendo-buttongroup>
                <button kendoButton type="button" [primary]="true" [icon]="'user'">View Users</button>
            </kendo-buttongroup>
        </div>
        <div class="col-sm-12">
            <hr/>
        </div>
    </div>
    <app-validation-errors></app-validation-errors>

    <app-manikin-and-trainer-info-header [modelInfo]="modelInfo"></app-manikin-and-trainer-info-header>
    
    <input type="hidden" id="id" formControlName="id" />
    <kendo-tabstrip>
        <kendo-tabstrip-tab [title]="'General'" [selected]="true">
            <ng-template kendoTabContent>
                <div class="row">
                    <div class=" col-sm-12">
                        <label for="wingmanId" class="col-form-label col-form-label-sm">Wingman ID</label>
                        <input kendoTextBox formControlName="wingmanId" id="wingmanId" add-required-asterisk class="form-control" placeholder="Wingman Id will be automatically generated" />
                    </div>
                    <div class=" col-sm-12">
                        <label for="customId" class="col-form-label col-form-label-sm">Custom ID</label>
                        <input kendoTextBox formControlName="customId" id="customId" class="form-control" placeholder="Custom ID" />
                    </div>
                    <div class=" col-md-12">
                        <label for="nickname" class="col-form-label col-form-label-sm">Nick Name</label>
                        <input kendoTextBox type="text" formControlName="nickname" add-required-asterisk  id="nickname" class="form-control" placeholder="Nick Name" />
                    </div>
                    <div class=" col-md-12">
                        <label for="serialNumber" class="col-form-label col-form-label-sm">Serial Number</label>
                        <input kendoTextBox type="text" formControlName="serialNumber" add-required-asterisk  id="serialNumber" class="form-control" placeholder="Serial Number" />
                    </div>
                    <div class=" col-md-12">
                        <label for="description" class="col-form-label col-form-label-sm">Description</label>
                        <textarea kendoTextBox formControlName="description" add-required-asterisk  id="description" class="form-control" placeholder="Description"></textarea>
                    </div>
                    <div class=" col-md-12">
                        <label for="status" class="col-form-label col-form-label-sm">Status</label>
                        <app-radio-button-list formControlName="status" [data]="(statuses | async)?.data" [orientation]="'horizontal'" [groupName]="'statuses'" [showInline]="true" [dataLabelField]="'name'" [dataValueField]="'name'"></app-radio-button-list>
                    </div>
                    <div class=" col-md-12">
                        <label for="skinTone" class="col-form-label col-form-label-sm">Skin Tone</label>
                        <app-radio-button-list formControlName="skinTone" [data]="(skinTones | async)?.data" [orientation]="'horizontal'" [groupName]="'skinTones'" [showInline]="true" [dataLabelField]="'name'" [dataValueField]="'name'"></app-radio-button-list>
                    </div>
                    <div class=" col-md-12">
                        <label for="gender" class="col-form-label col-form-label-sm">Gender</label>
                        <app-radio-button-list formControlName="gender" [data]="(genders | async)?.data" [orientation]="'horizontal'" [groupName]="'genders'" [showInline]="true" [dataLabelField]="'name'" [dataValueField]="'name'"></app-radio-button-list>
                    </div>
                    <div class=" col-md-12">
                        <label for="notes" class="col-form-label col-form-label-sm">Notes</label>
                        <textarea kendoTextBox formControlName="notes" id="notes" add-required-asterisk  class="form-control"></textarea>
                    </div>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Location'">
            <ng-template kendoTabContent>
                <div class="row">
                    <div class="col-md-12">
                        <label for="primaryLocationSelector" class="col-form-label col-form-label-sm"><strong>Primary</strong></label>
                        <app-location-selector (onFormGroupChange)="primaryLocationSelectorChangeEvent($event)" name="primaryLocationSelector" [events]="primaryLocationSubject.asObservable()"></app-location-selector>
                    </div>
                    <div class="col-md-12">
                        <label for="currentLocationSelector" class="col-form-label col-form-label-sm"><strong>Current</strong></label>
                        <app-location-selector (onFormGroupChange)="currentLocationSelectorChangeEvent($event)" name="currentLocationSelector" [events]="currentLocationSubject.asObservable()"></app-location-selector>
                    </div>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Purchasing'">
            <ng-template kendoTabContent>
                <div class="row">
                    <div class="col-md-12">
                        <label for="purchasingVendorId" class="col-form-label col-form-label-sm">Purchasing Vendor</label>
                        <kendo-dropdownlist
                            [defaultItem]="vendorDefaultItem"
                            id="purchasingVendorId"
                            name="purchasingVendorId"
                            formControlName="purchasingVendorId"
                            [valuePrimitive]="true"
                            [data]="($vendors | async)?.data"
                            [filterable]="false"
                            valueField="id"
                            textField="vendorName">
                        </kendo-dropdownlist>
                    </div>
                    <div class=" col-md-12">
                        <label for="fundedBy" class="col-form-label col-form-label-sm">Funded By</label>
                        <input kendoTextBox type="text" formControlName="fundedBy" add-required-asterisk  id="fundedBy" class="form-control" placeholder="Funded By" />
                    </div>
                    <div class=" col-sm-12">
                        <label for="costPerItem" class="col-form-label col-form-label-sm">Cost Per Item</label>
                        <input kendoTextBox type="number" formControlName="costPerItem" id="costPerItem" add-required-asterisk class="form-control" placeholder="Cost Per Item" />
                    </div>
                    <div class=" col-sm-12">
                        <label for="costPerUnit" class="col-form-label col-form-label-sm">Cost Per Unit</label>
                        <input kendoTextBox type="number" formControlName="costPerUnit" id="costPerUnit" add-required-asterisk class="form-control" placeholder="Cost Per Unit" />
                    </div>
                    <div class=" col-sm-12">
                        <label for="accountName" class="col-form-label col-form-label-sm">Account Name</label>
                        <input kendoTextBox formControlName="accountName" id="accountName" add-required-asterisk class="form-control" placeholder="Account Name" />
                    </div>
                    <div class=" col-sm-12">
                        <label for="accountNumber" class="col-form-label col-form-label-sm">Account Number</label>
                        <input kendoTextBox formControlName="accountNumber" id="accountNumber" add-required-asterisk class="form-control" placeholder="Account Number" />
                    </div>
                    <div class=" col-sm-12">
                        <label for="purchaseOrderNumber" class="col-form-label col-form-label-sm">Purchase Order Number</label>
                        <input kendoTextBox formControlName="purchaseOrderNumber" id="purchaseOrderNumber" add-required-asterisk class="form-control" placeholder="Purchase Order Number" />
                    </div>
                    <div class=" col-sm-12">
                        <label for="invoiceNumber" class="col-form-label col-form-label-sm">Invoice Number</label>
                        <input kendoTextBox formControlName="invoiceNumber" id="invoiceNumber" add-required-asterisk class="form-control" placeholder="Invoice Number" />
                    </div>
                    <div class=" col-sm-12">
                        <label for="productSku" class="col-form-label col-form-label-sm">Product SKU</label>
                        <input kendoTextBox formControlName="productSku" id="productSku" add-required-asterisk class="form-control" placeholder="Product SKU" />
                    </div>
                    <div class=" col-sm-12">
                        <label for="purchasingNotes" class="col-form-label col-form-label-sm">Purchasing Notes</label>
                        <textarea kendoTextBox formControlName="purchasingNotes" id="purchasingNotes" add-required-asterisk class="form-control" placeholder="Purchasing Notes"></textarea>
                    </div>
                    <div class=" col-md-12">
                        <label for="dateAcquired" class="col-form-label col-form-label-sm">Date Acquired</label>
                        <kendo-datepicker formControlName="dateAcquired" id="dateAcquired" add-required-asterisk  class="form-control"></kendo-datepicker>
                    </div>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Warranty'">
            <ng-template kendoTabContent>
                <div class="row">
                    <div class="col-md-12">
                        <label for="warrantyVendorId" class="col-form-label col-form-label-sm">Warranty Vendor</label>
                        <kendo-dropdownlist
                            [defaultItem]="vendorDefaultItem"
                            id="warrantyVendorId"
                            name="warrantyVendorId"
                            formControlName="warrantyVendorId"
                            [valuePrimitive]="true"
                            [data]="($vendors | async)?.data"
                            [filterable]="false"
                            valueField="id"
                            textField="vendorName">
                        </kendo-dropdownlist>
                    </div>
                    <div class="col-sm-12">
                        <label for="preventativeMaintenanceExpirationDate" class="col-form-label col-form-label-sm">Preventative Maintenance Expiration Date</label>
                        <kendo-datepicker formControlName="preventativeMaintenanceExpirationDate" id="preventativeMaintenanceExpirationDate" add-required-asterisk format></kendo-datepicker>
                    </div>
                    <div class="col-sm-12">
                        <label for="warrantyName" class="col-form-label col-form-label-sm">Warranty Name</label>
                        <input kendoTextBox formControlName="warrantyName" id="warrantyName" add-required-asterisk class="form-control" placeholder="Warranty Name" />
                    </div>
                    <div class="col-sm-12">
                        <label for="warrantyCost" class="col-form-label col-form-label-sm">Warranty Cost</label>
                        <input kendoTextBox type="number" formControlName="warrantyCost" id="warrantyCost" add-required-asterisk class="form-control" placeholder="Warranty Cost" />
                    </div>
                    <div class="col-sm-12">
                        <label for="warrantyCostPerYear" class="col-form-label col-form-label-sm">Warranty Cost Per Year</label>
                        <input kendoTextBox type="number" formControlName="warrantyCostPerYear" id="warrantyCostPerYear" add-required-asterisk class="form-control" placeholder="Warranty Cost Per Year" />
                    </div>
                    <div class=" col-sm-12">
                        <label for="warrantyExpirationDate" class="col-form-label col-form-label-sm">Warranty Expiration Date</label>
                        <kendo-datepicker formControlName="warrantyExpirationDate" add-required-asterisk id="warrantyExpirationDate" format></kendo-datepicker>
                    </div>
                    <div class=" col-sm-12">
                        <label for="warrantyNotes" class="col-form-label col-form-label-sm">Warranty Notes</label>
                        <textarea kendoTextBox formControlName="warrantyNotes" id="warrantyNotes" add-required-asterisk class="form-control" placeholder="Warranty Notes"></textarea>
                    </div>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Metadata'" [disabled]="!editMode">
            <ng-template kendoTabContent>
                <app-metadata [metadata]="dataModel"></app-metadata>
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>
</form>