import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonsModule, ButtonModule } from '@progress/kendo-angular-buttons';
import {  GridModule,  PDFModule,  ExcelModule,} from '@progress/kendo-angular-grid';
import { ColorPickerModule,InputsModule, } from '@progress/kendo-angular-inputs';
import {  DateInputsModule,  TimePickerModule,} from '@progress/kendo-angular-dateinputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { UploadModule } from '@progress/kendo-angular-upload';
import { QRCodeModule } from '@progress/kendo-angular-barcodes';
import { LabelModule } from '@progress/kendo-angular-label';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';

@NgModule({
  declarations: [],
  exports: [
    CommonModule,
    PDFModule,
    ExcelModule,
    InputsModule,
    DateInputsModule,
    TimePickerModule,
    DropDownsModule,
    LayoutModule,
    DialogModule,
    SortableModule,
    ColorPickerModule,
    ButtonsModule,
    GridModule,
    ButtonsModule,
    ButtonModule,
    UploadModule,
    QRCodeModule,
    LabelModule,
    TooltipsModule,
  ],
})
export class KendoModule {}
