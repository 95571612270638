import { LocationService } from '@wo-api/index';
import { EntityGlobals, MenuGroupType } from '@wo-app/app.global';
import { VendorsDetailComponent } from '@wo-app/components/dashboard/vendors/vendors-detail/vendors-detail.component';
import { VendorsComponent } from '@wo-app/components/dashboard/vendors/vendors/vendors.component';
import { ImpersonatingOrganizationCanActivate } from '@wo-app/core/guards';
import { MenuGroupListingItem } from '@wo-app/shared/models/menu';
import { RouteChild, RouteParent } from '@wo-app/shared/models/route';

export class VendorsRegistration {
  static Routes: RouteParent = {
    path: EntityGlobals.VENDORS.baseRoute,
    canActivate: [ImpersonatingOrganizationCanActivate],
    entityInformation: EntityGlobals.VENDORS,
    children: [
      {
        path: '',
        component: VendorsComponent,
        data: {
          breadcrumb: 'View All ' + EntityGlobals.VENDORS.pluralName,
          menuGroups: [new MenuGroupListingItem(0, 2, MenuGroupType.People, EntityGlobals.VENDORS.pluralName)],
          entityInformation: EntityGlobals.VENDORS
        }
      } as RouteChild,
      {
        path: 'edit/:id',
        component: VendorsDetailComponent,
        data: {
          breadcrumb: 'Edit ' + EntityGlobals.VENDORS.singleName,
          entityInformation: EntityGlobals.VENDORS
        }
      } as RouteChild,
      {
        path: 'add',
        component: VendorsDetailComponent,
        data: {
          breadcrumb: 'Add ' + EntityGlobals.VENDORS.singleName,
          entityInformation: EntityGlobals.VENDORS
        }
      } as RouteChild
    ]
  };

  static Services: any = [LocationService];

  static Components: any = [VendorsComponent, VendorsDetailComponent];
}
