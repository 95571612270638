import { CommunicationBoardActionItemService } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { CommunicationBoardActionItemDetailComponent } from '@wo-app/components/dashboard/communication-board-action-item/communication-board-action-item-detail/communication-board-action-item-detail.component';
import { CommunicationBoardActionItemListComponent } from '@wo-app/components/dashboard/communication-board-action-item/communication-board-action-item-list/communication-board-action-item-list.component';
import { ImpersonatingOrganizationCanActivate } from '@wo-app/core/guards';
import { RouteChild, RouteParent } from '@wo-app/shared/models/route';

export class CommunicationBoardActionItemRegistration {
  static Routes: RouteParent = {
    path: EntityGlobals.COMMUNICATION_BOARD_ACTION_ITEMS.baseRoute,
    canActivate: [ImpersonatingOrganizationCanActivate],
    entityInformation: EntityGlobals.COMMUNICATION_BOARD_ACTION_ITEMS,
    children: [
      {
        path: '',
        component: CommunicationBoardActionItemListComponent,
        data: {
          breadcrumb: 'View All'
        }
      } as RouteChild,
      {
        path: 'edit/:id',
        component: CommunicationBoardActionItemDetailComponent,
        data: {
          breadcrumb: 'Edit ' + EntityGlobals.COMMUNICATION_BOARD_ACTION_ITEMS.singleName
        }
      } as RouteChild,
      {
        path: 'add',
        component: CommunicationBoardActionItemDetailComponent,
        data: {
          breadcrumb: 'Add ' + EntityGlobals.COMMUNICATION_BOARD_ACTION_ITEMS.singleName
        }
      } as RouteChild
    ]
  };

  static Services: any = [CommunicationBoardActionItemService];

  static Components: any = [CommunicationBoardActionItemListComponent, CommunicationBoardActionItemDetailComponent];
}
