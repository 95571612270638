import { Component, Input, OnInit } from '@angular/core';
import {
  ChecklistDrawerDrawerService,
  CreateChecklistDrawerCommand,
  CreateChecklistDrawerCommandResult,
  InventoryItemInChecklistDrawer,
  ListChecklistDrawersCommand,
  ListChecklistDrawersCommandResult,
  UpdateChecklistDrawerCommand,
  UpdateChecklistDrawerCommandResult
} from '@wo-api/index';

import { FormBuilder, FormGroup } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
@Component({
  selector: 'app-checklist-drawer-list',
  templateUrl: './checklist-drawer-list.component.html',
  styleUrls: ['./checklist-drawer-list.component.scss']
})
export class ChecklistDrawerListComponent implements OnInit {
  @Input() organizationId: number;
  @Input() checklistId: number;
  listChecklistDrawers: any;
  form: FormGroup;
  constructor(private logger: NGXLogger, private checklistDrawerDrawerService: ChecklistDrawerDrawerService, private fb: FormBuilder) {
    this.createForm();
  }

  ngOnInit() {
    this.refreshDrawerData();
  }

  refreshDrawerData() {
    this.logger.debug('Refreshing Drawer Data');
    const listChecklistDrawersCommand: ListChecklistDrawersCommand = {};
    listChecklistDrawersCommand.checklistId = this.checklistId;
    this.checklistDrawerDrawerService.list(listChecklistDrawersCommand).subscribe((result: ListChecklistDrawersCommandResult) => {
      this.listChecklistDrawers = result.data;
    });
  }

  saveDrawer(drawer) {
    const updateDrawerCommand: UpdateChecklistDrawerCommand = {};
    updateDrawerCommand.id = drawer.id;
    updateDrawerCommand.name = drawer.name;
    updateDrawerCommand.checklistId = this.checklistId;
    updateDrawerCommand.drawerContents = drawer.drawerContents;
    updateDrawerCommand.hexColorBackground = drawer.hexColorBackground;
    updateDrawerCommand.hexColorForeground = drawer.hexColorForeground;

    this.checklistDrawerDrawerService.update(drawer.id, updateDrawerCommand).subscribe((result: UpdateChecklistDrawerCommandResult) => {
      this.refreshDrawerData();
    });
  }

  addRowToDrawer(item) {
    const newItem: InventoryItemInChecklistDrawer = {};
    newItem.name = '';
    newItem.quantity = 1;
    item.drawerContents.push(newItem);
  }

  submitAndClose() {
    const newChecklistDrawerName = this.form.controls['addChecklistDrawerName'].value;
    const createChecklistDrawerCommand: CreateChecklistDrawerCommand = {};

    createChecklistDrawerCommand.name = newChecklistDrawerName;
    createChecklistDrawerCommand.checklistId = this.checklistId;

    this.checklistDrawerDrawerService.create(createChecklistDrawerCommand).subscribe((result: CreateChecklistDrawerCommandResult) => {
      this.logger.debug(result);
      this.refreshDrawerData();
    });
  }
  addNewDrawer() {
    this.logger.debug(this.form);
  }

  deleteRowFromDrawer(item, row) {
    const index = item.drawerContents.indexOf(row);
    item.drawerContents.splice(index, 1);
  }

  deleteDrawer(drawer) {
    this.checklistDrawerDrawerService._delete(drawer.id, this.checklistId).subscribe(result => {
      this.refreshDrawerData();
    });
  }

  createForm() {
    this.form = this.fb.group({
      addChecklistDrawerName: ['']
    });
  }
}
