/**
 * Wingman Operations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PickOrderStatus = 'Started' | 'InProgress' | 'Complete' | 'Closed' | 'Canceled';

export const PickOrderStatus = {
    Started: 'Started' as PickOrderStatus,
    InProgress: 'InProgress' as PickOrderStatus,
    Complete: 'Complete' as PickOrderStatus,
    Closed: 'Closed' as PickOrderStatus,
    Canceled: 'Canceled' as PickOrderStatus
};

