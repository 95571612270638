import { Injectable, TemplateRef } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription, fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplicationOfflineService {
  offlineEvent: Observable<Event>;
  onlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  closeResult = '';
  private appOfflineModal: NgbModalRef;

  constructor(private logger: NGXLogger, private modalService: NgbModal) {}

  public handleAppConnectivityChanges(offlineModule: TemplateRef<any>): void {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(
      this.onlineEvent.subscribe(e => {
        this.logger.debug('Application is back online');
        this.closeOfflineMessage();
      })
    );

    this.subscriptions.push(
      this.offlineEvent.subscribe(e => {
        // handle offline mode
        this.logger.debug('Application is now offline');
        this.showOfflineMessage(offlineModule);
      })
    );
  }

  closeOfflineMessage(): void {
    this.appOfflineModal.close();
  }

  showOfflineMessage(offlineModule: TemplateRef<any> = null, content: string = null): void {
    let options: NgbModalOptions = {
      ariaLabelledBy: 'modal-basic-title',
      backdrop: 'static',
      centered: true,
      backdropClass: 'blurred'
    };

    this.logger.debug(offlineModule);

    this.appOfflineModal = this.modalService.open(offlineModule ?? content, options);
    this.appOfflineModal.result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
