import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { State } from '@progress/kendo-data-query';
import {
  BuildingService,
  CreateBuildingCommand,
  CreateBuildingCommandResult,
  ListLocationsCommandResult,
  LocationService,
  OrganizationService,
  ReadBuildingCommandResult,
  UpdateBuildingCommand,
  UpdateBuildingCommandResult
} from '@wo-api/index';
import { EntityGlobals, ValidationMaxStringLength } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { CachedDataService, ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
import { Observable, takeUntil } from 'rxjs';
@Component({
  selector: 'app-building-detail',
  templateUrl: './building-detail.component.html',
  styleUrls: ['./building-detail.component.scss']
})
export class BuildingDetailComponent extends EntityBaseComponent implements OnInit {
  locationId: any;
  override showForm: boolean;
  readBuildingCommandResult: ReadBuildingCommandResult = {};
  dataModel: ReadBuildingCommandResult;
  updateBuildingCommand: UpdateBuildingCommand = {};
  createBuildingCommand: CreateBuildingCommand = {};
  form: FormGroup;
  originalFormState: FormGroup;
  fromKey: string;
  organizations: any[];
  selectedLocation: number;
  public $locations: Observable<ListLocationsCommandResult>;

  get location() {
    return this.form.get('locationId');
  }

  get name() {
    return this.form.get('name');
  }

  get description() {
    return this.form.get('description');
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private impersonationService: ImpersonationService,
    private logger: NGXLogger,
    public cachedDataService: CachedDataService,
    private organizationService: OrganizationService,
    private locationService: LocationService,
    private buildingService: BuildingService,
    private fb: FormBuilder,
    private dialogService: DialogService,
    private toastService: ToastService,
    breadcrumbService: BreadcrumbsService,
    titleService: Title
  ) {
    super(EntityGlobals.BUILDINGS, router, route, impersonationService, logger, breadcrumbService, titleService);
    this.$locations = this.locationService.list();
  }
  public override state: State = {
    skip: 0,
    take: 100,
    sort: [{ dir: 'asc', field: 'name' }],
    filter: {
      logic: 'and',
      filters: []
    }
  };

  public ngOnInit() {
    this._createForm();
    this.showForm = false;
    this.fromKey = 'FormModel';
    if (this.editMode) {
      this._getData();
    } else {
      this.showForm = true;
    }
  }

  private _createForm() {
    this.form = this.fb.group({
      id: [''],
      locationId: ['', [Validators.required]],
      name: ['', [Validators.required, Validators.maxLength(ValidationMaxStringLength.Default)]],
      description: ['', [Validators.maxLength(ValidationMaxStringLength.Long)]],
      created: [''],
      createByUsername: [''],
      createdByUserId: [''],
      lastUpdated: [''],
      lastUpdatedByUsername: [''],
      lastUpdatedByUserId: ['']
    });

    this.originalFormState = this.form;
  }

  private _convertServerDataToFormModel(result: ReadBuildingCommandResult) {
    this.dataModel = result;
    this.form.patchValue(this.dataModel);
    if (this.originalFormState.pristine) {
      this.logger.debug('Setting Original Form State Value');
      this.originalFormState.patchValue(this.dataModel);
    }
  }

  private _getData() {
    this.buildingService
      .read(this.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: ReadBuildingCommandResult) => {
        this.UpdateBreadcrumbPageTitle(result.id, result.name);
        this.selectedLocation = result.locationId;
        this._convertServerDataToFormModel(result);
        this.showForm = true;
      });
  }

  public resetForm() {
    this.logger.debug(this.originalFormState);
    this.form.reset(this.originalFormState.value);
    this.routeToEntityList();
  }

  public locationValueChange($location) {
    this.logger.debug($location);
    this.selectedLocation = $location;
  }

  public onSubmit(closeOnSuccess) {
    // If the Form is in Edit Mode, Update Object
    if (this.editMode) {
      this.updateBuildingCommand = this.form.value;
      this.updateBuildingCommand.locationId = this.selectedLocation;
      this.logger.debug(this.updateBuildingCommand);
      this.buildingService
        .update(this.updateBuildingCommand.id.toString(), this.updateBuildingCommand)
        .pipe(takeUntil(this.destroy$))
        .subscribe((result: UpdateBuildingCommandResult) => {
          this.logger.debug(result);
          if (closeOnSuccess) {
            this.routeToEntityList();
          } else {
            this._convertServerDataToFormModel(result);
            this.toastService.success('Success!', 'This item has been saved.');
          }
        });
    } else {
      // Otherwise, create a new object
      this.createBuildingCommand = this.form.value;
      this.createBuildingCommand.locationId = this.selectedLocation;
      //this.updateBuildingCommand.locationName = this.$locations..find(location => location.id === this.selectedLocation).name;
      this.logger.debug(this.createBuildingCommand);
      this.buildingService
        .create(this.createBuildingCommand)
        .pipe(takeUntil(this.destroy$))
        .subscribe((result: CreateBuildingCommandResult) => {
          this._convertServerDataToFormModel(result);
          this.routeToEntityList();
          this.editMode = true;
          this.toastService.success('Success!', 'This item has been saved.');
        });
    }
  }

  public submitAndClose() {
    this.onSubmit(true);
  }

  public disable() {}

  public delete() {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this building?',
      actions: [{ text: 'No' }, { text: 'Yes', primary: true }],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.pipe(takeUntil(this.destroy$)).subscribe(result => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
      }
      this.toastService.success('Oops.', 'This item has been saved.');
    });
  }
}
