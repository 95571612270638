import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BuildingService,
  LocationService,
  RoomService,
  SupplyLocationQuantityService,
  SupplyLocationQuantityViewModel
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
import { forkJoin, switchMap, take } from 'rxjs';

@Component({
  selector: 'inventory-qr-landing',
  templateUrl: './inventory-room-qr-landing.component.html'
})
export class InventoryRoomQrLandingComponent extends EntityBaseComponent implements OnInit {
  public action: 'add' | 'remove';
  public isDialogVisible = false;
  public numberToChange = 0;
  public supplyLocationQuantityId: number;

  public supplyLocationQuantity: SupplyLocationQuantityViewModel & {
    roomName: string;
    buildingName: string;
    locationName: string;
  };

  constructor(
    private _breadcrumbService: BreadcrumbsService,
    private buildingService: BuildingService,
    private impersonationService: ImpersonationService,
    private locationService: LocationService,
    private roomService: RoomService,
    private supplyLocationQuantityService: SupplyLocationQuantityService,
    private logger: NGXLogger,
    private route: ActivatedRoute,
    private router: Router,
    private title: Title
  ) {
    super(EntityGlobals.SUPPLIES, router, route, impersonationService, logger, _breadcrumbService, title);
  }

  ngOnInit(): void {
    this.fetchData();
  }

  private fetchData(): void {
    const roomsObservable = this.roomService.list();
    const buildingsObservable = this.buildingService.list();
    const locationsObservable = this.locationService.list();
    const supplyLocationQuantityObservable = this.route.params.pipe(
      switchMap(params => {
        this.supplyLocationQuantityId = +params['supplyLocationQuantityId'];
        return this.supplyLocationQuantityService.read(this.supplyLocationQuantityId);
      }),
      take(1)
    );


    forkJoin([roomsObservable, buildingsObservable, locationsObservable, supplyLocationQuantityObservable]).subscribe(
      ([rooms, buildings, locations, supplyLocationQuantity]) => {
        this.supplyLocationQuantity = {
          ...supplyLocationQuantity,
          roomName: rooms.data?.find(room => room.id === supplyLocationQuantity.roomId).name,
          buildingName: buildings.data?.find(building => building.id === supplyLocationQuantity.buildingId).name,
          locationName: locations.data?.find(location => location.id === supplyLocationQuantity.locationId).name
        }
      }
    );
  }

  public toggleDialog(action?: 'add' | 'remove'): void {
    this.isDialogVisible = !this.isDialogVisible;
    this.action = action;
  }

  public confirm(): void {
    const newQuantity =
      this.action === 'add'
        ? this.supplyLocationQuantity.quantity + this.numberToChange
        : this.supplyLocationQuantity.quantity - this.numberToChange;
    this.supplyLocationQuantityService
      .update(this.supplyLocationQuantityId.toString(), {
        ...this.supplyLocationQuantity,
        quantity: newQuantity
      })
      .subscribe(result => {
        this.supplyLocationQuantity = {
          ...this.supplyLocationQuantity,
          quantity: result.quantity
        };
        this.toggleDialog();
      });
  }
}
