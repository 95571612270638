import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ModalService } from '@wo-app/core/services';
import { NGXLogger } from 'ngx-logger';
import { 
  BuildingService,
  ReadBuildingCommandResult,
  RoomViewModel,
  PickOrderSupplyViewModel,
  PickOrderEquipmentViewModel,
  PickOrderManikinViewModel,
  ListSupplyViewModel,
  ListEquipmentViewModel,
  ListManikinMakeAndModelsCommandViewModel,
  RoomQuantityViewModel
} from '@wo-api/index';
import { GridDataResult } from '@progress/kendo-angular-grid/data/data.collection';
import { CompositeFilterDescriptor, State, filterBy, orderBy } from '@progress/kendo-data-query';
import { CancelEvent, CellClickEvent, CellCloseEvent, EditEvent, GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomEmmitterService } from '../../../../core/services/custom-emmitter.service';

export type InventoryItemType = 'supply' | 'equipment' | 'manikin';
export type InventoryItemStatus = 'reserved' | 'updated';

export interface IInventoryItemRoom {
  id?: number;
  name?: string;
  type?: string;
  quantityNeeded?: number;
  rooms: RoomQuantityViewModel[];
  status?: string;
}

@Component({
  selector: 'app-pick-order-supply-room-selection-dialog',
  templateUrl: './pick-order-supply-room-selection-dialog.component.html',
  styleUrls: ['./pick-order-supply-room-selection-dialog.component.scss']
})
export class PickOrderSupplyRoomSelectionDialogComponent implements OnInit, OnDestroy {
  @Input() public inputs: any;
  @Input() public outputs: any;

  private buildingId: number;
  public rooms: RoomViewModel[] = [];

  public title: string;
  public buttons: string[] = [];

  public gridData: IInventoryItemRoom[] = [];
  public view: GridDataResult;

  public isLoading = false;
  public pageSize = 25;

  public state: State = {
    skip: 0,
    take: 25,
    sort: [{ dir: 'asc', field: 'name' }],
    filter: {
      logic: 'and',
      filters: []
    }
  };

  // public defaultRoom: RoomViewModel = {
  //   id: -1,
  //   name: 'Select...'
  // };

  subscription: any;

  constructor(
    private buildingService: BuildingService,
    private modalService: ModalService,
    private loadingBar: LoadingBarService,
    private logger: NGXLogger,
    private formBuilder: FormBuilder,
    private customEmmitterService: CustomEmmitterService
  ) {
    this.state.sort = [{ dir: 'asc', field: 'name' }];
    this.pageSize = 25;
  }

  ngOnInit(): void {
    this.processInventoryItem(this.inputs.supplies, 'supply');
    this.processInventoryItem(this.inputs.equipment, 'equipment');
    this.processInventoryItem(this.inputs.manikins, 'manikin');
    this.title = this.inputs.title ? this.inputs.title : 'Fill Pick Order from Rooms';
    this.defineButtons();
    this.getBuilding();
    this.subscribeToRoomChange();
  }

  private subscribeToRoomChange() {
    this.subscription = this.customEmmitterService.getInventoryItemRoomSelectionEmitter()
      .subscribe(res => {
        let changedItem = this.gridData.find(e => e.id === res.id && e.type === res.type);
        if (changedItem) {
          changedItem.rooms = res.rooms;
          this.reloadData();
        }
      });
  }

  private processInventoryItem(items: any[], type: InventoryItemType) {
    if (items && items.length) {
      items.forEach(e => {
        let inventoryItemRoom: IInventoryItemRoom = {
          id: e.id,
          name: this.getInventoryItemName(e, type),
          type: type,
          quantityNeeded: e.quantityRequested,
          rooms: e.rooms,
          status: e.status
        };

        // inventoryItemRoom.rooms.forEach(e => {
        //   e.roomName = e.roomId ? this.rooms.find(room => room.id === e.roomId)?.name : '';
        // });

        this.gridData.push(inventoryItemRoom);
      });
    }
  }

  private getInventoryItemName(inentoryItem: any, type: InventoryItemType): string {
    switch (type) {
      case 'equipment':
        return inentoryItem.equipmentName;
      
      case 'manikin':
        return inentoryItem.manikinModelName;
      
      case 'supply':
        return inentoryItem.supplyName;
    
      default:
        return '-';
    }
  }

  private defineButtons() {
    if (this.inputs.buttons) {
      this.buttons = this.inputs.buttons;
    } else {
      this.buttons.push('Close');
    }
  }
  
  private getBuilding() {
    this.isLoading = true;
    this.buildingId = this.inputs.buildingId;
    this.loadingBar.useRef('router').start();
    this.buildingService.read(this.buildingId).subscribe((result: ReadBuildingCommandResult) => {
      if (result && result.rooms) {
        this.rooms = result.rooms.filter(room => room.isInventoryStorageRoom === true);
        this.gridData.forEach(gridItem => {
          if (gridItem.rooms) {
            gridItem.rooms.forEach(e => {
              e.roomName = this.rooms.find(room => room.id === e.roomId)?.name;
            });
          }
        });
      }
      this.reloadData();
      this.isLoading = false;
    });
  }

  public reloadData(): void {
      this.query();
  }

  public query() {
    const filteredData = filterBy(this.gridData, this.filter);
    
    const sortedGridData = orderBy(filteredData, this.state.sort ?? [{ dir: 'asc', field: 'name' }]);
    this.view = {
      data: sortedGridData.slice(this.state.skip, (this.state.skip ?? 0) + this.pageSize),
      total: sortedGridData.length
    };
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.logger.debug('Filtering has changed');
    this.filter = filter;
    this.query();
  }

  public filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: []
  };

  public close(answer: any) {
    if (answer === 'Confirm') {
      let supplies = this.inputs.supplies as PickOrderSupplyViewModel[];
      supplies.forEach(e => {
        const item = this.gridData.find(s => s.type === 'supply' && s.id === e.id);
        if (item) { e.rooms = item.rooms }
      });

      let equipment = this.inputs.equipment as PickOrderEquipmentViewModel[];
      equipment.forEach(e => {
        const item = this.gridData.find(s => s.type === 'equipment' && s.id === e.id);
        if (item) { e.rooms = item.rooms }
      });

      let manikins = this.inputs.manikins as PickOrderManikinViewModel[];
      manikins.forEach(e => {
        const item = this.gridData.find(s => s.type === 'manikin' && s.id === e.id);
        if (item) { e.rooms = item.rooms }
      });

      let result = {
        supplies,
        equipment,
        manikins,
        answer
      };

      this.modalService.destroy(result);
    } else {
      let result = {
        items: [],
        answer
      };
      this.modalService.destroy(result);
    }
  }

  public rowCallback = (context: RowClassArgs) => {
    if (context.dataItem.status === 'CheckedOut') {
      return '';
    } else if (context.dataItem?.rooms?.length) {
      const isItemFilled = this.isFilled(context.dataItem.quantityNeeded, context.dataItem.rooms);
      return isItemFilled ? { green: true } : { pink: true };
    } else {
      return { pink: true };
    }
  };

  private isFilled(quantityNeeded: number, rooms: any[]): boolean {
    if (rooms?.length > 0) {
      const roomsQuantitySum = rooms.reduce((n, {quantity}) => n + quantity, 0);
      if (roomsQuantitySum === quantityNeeded) {
        return true;
      }
    }
    return false;
  }

  public showOnlyNonCheckedOutItems(dataItem: IInventoryItemRoom): boolean {
    return dataItem.status !== 'CheckedOut';
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

