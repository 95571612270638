import { Component, Input, OnChanges, OnInit, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BluetoothPrinterService, Label, LabelType } from '@wo-app/core/services';
import { LabelaryImageService } from '@wo-app/core/services/zplpreview-image.service';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-zpl-label-preview',
  templateUrl: './label-preview.component.html',
  styleUrls: ['./label-preview.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LabelPreviewComponent),
      multi: true
    }
  ]
})
export class LabelPreviewComponent implements OnInit, OnChanges, ControlValueAccessor {
  constructor(
    protected sanitizer: DomSanitizer,
    private ZPLPreviewImageService: LabelaryImageService,
    private bluetoothPrinterService: BluetoothPrinterService,
    private logger: NGXLogger
  ) {
    this.logger.log('LabelPreviewComponent is loading');
  }

  @Input() Label: Label = null;
  @Input() LabelType: LabelType = null;
  @Input() HeightInInches: number = 1;
  @Input() WidthInInches: number = 3;
  ZPLTemplate: string = '';
  ImageUrl: SafeUrl = null;
  BypassPreviewImage: boolean = false;

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.logger.log('LabelPreviewComponent ngOnChanges', changes);
    if (changes['Label']) {
      this.Label = changes['Label'].currentValue;
    }
    if (changes['LabelType']) {
      this.LabelType = changes['LabelType'].currentValue;
    }
    if (changes['HeightInInches']) {
      this.HeightInInches = changes['HeightInInches'].currentValue;
    }
    if (changes['WidthInInches']) {
      this.WidthInInches = changes['WidthInInches'].currentValue;
    }

    if (this.Label != null && this.LabelType != null) {
      this.generateImage();
    }
  }

  writeValue(obj: any): void {}
  registerOnChange(fn: any): void {}
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}

  private async generateImage() {
    try {
      this.logger.log('Attempting to generate preview image');
      this.ZPLTemplate = await this.bluetoothPrinterService.GetZplTemplateForLabel(this.Label, this.LabelType);

      this.ZPLPreviewImageService.GeneratePreviewLabel(this.ZPLTemplate).subscribe((returnValue: any) => {
        this.logger.log('ReturnValue', returnValue);
        let objectURL = URL.createObjectURL(returnValue);
        this.ImageUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      });
    } catch (e) {
      this.logger.log('There was an error');
      if (e instanceof Error) {
        this.logger.error(e);
        this.BypassPreviewImage = true;
      }
    }
  }
}
