<div [ngClass]="{ '': allowBootstrap, 'fluid-bread': true}">
    <nav aria-label="breadcrumb">
        <ol [ngClass]="{ 'breadcrumb': allowBootstrap}" class="{{addClass ? '' + addClass : ''}}">
            <li *ngFor="let breadcrumb of breadcrumbs; let last = last" [ngClass]="{ 'breadcrumb-item': allowBootstrap, 'list': true, 'active': last }">
                <a *ngIf="!last" [routerLink]="hasParams(breadcrumb)">
                    <i class="fa-light fa-{{breadcrumb.icon}}" *ngIf="breadcrumb.icon"></i> {{breadcrumb.label}}
                </a>
                <span *ngIf="last">
                    <i class="fa-light fa-{{breadcrumb.icon}}" *ngIf="breadcrumb.icon"></i> {{ breadcrumb.label }}
                </span>
            </li>
        </ol>
    </nav>
</div>